import { useMemo } from "react"
import { DoingIcon } from "../icons/DoingIcon"
import { DoneIcon } from "../icons/DoneIcon"
import { NoneIcon } from "../icons/NoneIcon"
import styles from "./UserStatusIconStyles.module.css"

// TODO to types
interface UserStatusIconProps {
  variant: "none" | "doing" | "done"
}

export function UserStatusIcon(props: UserStatusIconProps) {
  const { variant } = props

  const colorTick: { color: string; tick: () => JSX.Element } = useMemo(() => {
    switch (variant) {
      case "none":
        return { color: "#E6E6E6", tick: NoneIcon }
      case "doing":
        return { color: "#FFDE79", tick: DoingIcon }
      case "done":
        return { color: "#8BFDD4", tick: DoneIcon }
    }
  }, [variant])

  return (
    <div className={styles.userStatusIcon} style={{ backgroundColor: colorTick.color }}>
      {colorTick.tick()}
    </div>
  )
}

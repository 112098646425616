import classNames from "classnames"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Pages } from "../../common/types"
import { Button, Modal } from "../../project/system/UX"
import { AlertModal } from "../../project/system/components"
import styles from "./SuperadminStyles.module.css"
import { DashboardGraphSettingsForm } from "./components/DashboardGraphSettingsForm"
import { createDashboard, deleteDashboard, getDashboardProjects, setGraphSettings } from "./http"
import {
  DashboardProjectGeneralSettings,
  DashboardProjectsListOut,
  ForceAtlasConfig,
  Layouts
} from "./types"
import { handleChange } from "./utils"

export function SuperadminDashboardsPage() {
  const navigate = useNavigate()
  const [dashboards, setDashboards] = useState<DashboardProjectsListOut[]>([])
  const [dashboardSettingsModal, setDashboardSettingsModal] = useState<number | null>(null)
  const [alert, setAlert] = useState("")
  const [dashboardToDelete, setDashboardToDelete] = useState<number | null>(null)
  const [generalSettings, setGeneralSettings] = useState<DashboardProjectGeneralSettings | null>(
    null
  )
  // TODO сделать layout по человечески тоже
  const [layout, setLayout] = useState<Layouts>(Layouts.forceAtlas)
  const [forceAtlasConfig, setForceAtlasConfig] = useState<ForceAtlasConfig | null>(null)

  useEffect(() => {
    getDashboardProjects()
      .then(data => {
        setDashboards(data)
      })
      .catch(error => console.log(error))
  }, [])

  function onCloseHandler() {
    setGeneralSettings(null)
    setForceAtlasConfig(null)
    setLayout(Layouts.forceAtlas)
    setDashboardSettingsModal(null)
  }

  function saveSettingsHandler(projectId: number) {
    setDashboards(state => {
      return state.map(project => {
        if (project.id === projectId) {
          return {
            ...project,
            settings: {
              ...project.settings,
              ...(generalSettings && { general_settings: generalSettings }),
              layout: layout,
              ...(forceAtlasConfig && { force_atlas_config: forceAtlasConfig })
            }
          }
        }
        return project
      })
    })
    let layoutSettings
    if (layout === Layouts.forceAtlas && forceAtlasConfig) {
      layoutSettings = { force_atlas_config: forceAtlasConfig }
    }
    const settingsOut = {
      ...(generalSettings && {general_settings: generalSettings}),
      layout: layout,
      ...layoutSettings
    }
    setGraphSettings(String(projectId), settingsOut)
      .then(() => setAlert("Настройки успешно сохранены."))
      .catch(error => console.log(error))
  }

  function handleChangeGraphSettings(projectId: number) {
    const settings = dashboards.find(project => project.id === projectId)?.settings
    if (settings) {
      settings.general_settings && setGeneralSettings(settings.general_settings)
      setLayout(settings.layout)
      if (settings.layout === Layouts.forceAtlas && settings.force_atlas_config) {
        setForceAtlasConfig(settings.force_atlas_config)
      }
    }
    setDashboardSettingsModal(projectId)
  }

  function createDashboardHandler(projectId: number) {
    createDashboard(String(projectId))
      .then(() => {
        setAlert("Дашборд создан. Установлены настройки по умолчанию.")
        setDashboards(state =>
          state?.map(project => {
            if (project.id === projectId) {
              return { ...project, dashboard: true }
            }
            return project
          })
        )
      })
      .catch(error => console.log(error))
  }

  function deleteDashboardHandler(projectId: number) {
    deleteDashboard(String(projectId))
      .then(() => {
        setDashboardToDelete(null)
        setDashboards(state =>
          state?.map(project => {
            if (project.id === projectId) {
              return { ...project, dashboard: false }
            }
            return project
          })
        )
      })
      .catch(error => console.log(error))
  }

  return (
    <div className={styles.superadminLayout}>
      <button
        className={classNames(styles.superadminButton, styles.superadminBigButton)}
        style={{ fontWeight: "bold" }}
        onClick={() => navigate(Pages.superadmin_page)}
      >
        &#9668; к списку клиентов
      </button>
      {dashboards?.map(project => (
        <div key={project.id} className={styles.dashboardProjectRow}>
          <li className={styles.dashboardProjectDesc}>
            <p>
              <span style={{ fontWeight: "bold" }}>{project.id}. </span>
              {project.dashboard ? (
                <a href={`http://${window.location.host}/dashboard/${project.id}/`}>
                  {project.name}
                </a>
              ) : (
                project.name
              )}
            </p>
            {project.admins
              ? project.admins.map(admin => (
                  <p key={admin.id}>
                    {admin.name} {admin.email ? `email: ${admin.email}` : ""}
                  </p>
                ))
              : "Нет информации об админах"}
          </li>
          <div className={styles.superadminProjectButtonRow}>
            {project.dashboard ? (
              <>
                <button
                  className={classNames(styles.superadminButton)}
                  onClick={() => handleChangeGraphSettings(project.id)}
                >
                  Настройки графа
                </button>
                <button
                  className={classNames(styles.superadminButton)}
                  onClick={() => navigate(`${Pages.superadmin_dashboards}/${project.id}/groups`)}
                >
                  Настройки групп
                </button>
                <button
                  className={classNames(styles.superadminButton)}
                  onClick={() => setDashboardToDelete(project.id)}
                >
                  Удалить
                </button>
              </>
            ) : (
              <button
                className={classNames(styles.superadminButton)}
                onClick={() => createDashboardHandler(project.id)}
              >
                Создать дашборд
              </button>
            )}
          </div>
        </div>
      ))}
      {dashboardSettingsModal && generalSettings && forceAtlasConfig && (
        <Modal className={styles.superadminSettingsModal}>
          <DashboardGraphSettingsForm
            generalSettings={generalSettings}
            layout={layout}
            forceAtlasConfig={forceAtlasConfig}
            setForceAtlasConfig={setForceAtlasConfig}
            setGeneralSettings={setGeneralSettings}
            handleChange={handleChange}
            setLayout={setLayout}
          />
          <div className={styles.superadminProjectButtonRow}>
            <Button onClick={onCloseHandler}>Отмена</Button>
            <Button onClick={() => saveSettingsHandler(dashboardSettingsModal)}>Сохранить</Button>
          </div>
        </Modal>
      )}
      {alert && (
        <AlertModal onSubmit={() => setAlert("")} submitText="OK">
          {alert}
        </AlertModal>
      )}
      {dashboardToDelete && (
        <Modal className={styles.superadminSettingsModal}>
          Все настройки и группы будут удалены. Продолжить?
          <div className={styles.superadminProjectButtonRow}>
            <Button onClick={() => setDashboardToDelete(null)}>Отмена</Button>
            <Button onClick={() => deleteDashboardHandler(dashboardToDelete)}>Удалить</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

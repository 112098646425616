import { FC, ReactNode } from 'react';

import styles from './IconTextButtonStyles.module.css';

interface IconButtonProps {
    onClick?: () => void,
    label: string
    children: ReactNode
    color?: string
    className?: string
}

export const IconTextButton: FC<IconButtonProps> = (props) => {
    const { onClick, children, label, color = '#323232', className } = props

    return (
        <button 
            className={className || styles.addButton}
            onClick={onClick}
            style={ className ? {} : { borderColor: color } }
        >
            {children}
            {label && className && label}
            {label && !className && <span style={{ color }}>{label}</span>}
        </button>
    );
};
import React from 'react';

const GradeSlide = () => {
    return (
        <svg width="34.25rem" height="27.9rem" viewBox="0 0 548 447" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M393.334 23H12.6657C6.22289 23 1 28.5436 1 35.382V126.618C1 133.456 6.22289 139 12.6657 139H393.334C399.777 139 405 133.456 405 126.618V35.382C405 28.5436 399.777 23 393.334 23Z" fill="white" stroke="#ADADAD"/>
            <path d="M378.666 93.3313C385.108 93.3313 390.331 88.1084 390.331 81.6657C390.331 75.2229 385.108 70 378.666 70C372.223 70 367 75.2229 367 81.6657C367 88.1084 372.223 93.3313 378.666 93.3313Z" fill="#D9D9D9"/>
            <rect x="172" y="41" width="162" height="12" rx="6" fill="#ADADAD"/>
            <rect x="327" width="103" height="95" rx="47.5" fill="url(#paint0_linear_6394_59096)"/>
            <path d="M21.3333 117.667L15 111.333M15 111.333L21.3333 105M15 111.333H27.6667C29.3461 111.333 30.9568 112 32.1454 113.188C33.3329 114.377 34 115.987 34 117.667C34 119.346 33.3329 120.957 32.1454 122.145C30.9568 123.333 29.3461 124 27.6667 124H24.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M374.35 45.2501L379.431 34C380.442 34 381.412 34.3952 382.125 35.0984C382.84 35.8017 383.242 36.7555 383.242 37.75V42.7502H390.431C390.799 42.7461 391.164 42.8208 391.5 42.9692C391.836 43.1175 392.135 43.3361 392.376 43.6095C392.619 43.8824 392.797 44.2056 392.9 44.5533C393.002 44.901 393.026 45.2665 392.972 45.6251L391.219 56.8753C391.126 57.4712 390.819 58.0153 390.352 58.4053C389.885 58.7953 389.29 59.0067 388.678 58.9998H374.35M374.35 45.2501V58.9998M374.35 45.2501H370.54C369.866 45.2501 369.221 45.5133 368.744 45.9824C368.267 46.4515 368 47.087 368 47.7497V56.5003C368 57.163 368.267 57.7998 368.744 58.2676C369.221 58.7367 369.866 58.9998 370.54 58.9998H374.35" stroke="#02D589" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M384.527 118.383L380.024 128.513C379.129 128.513 378.27 128.157 377.637 127.524C377.003 126.891 376.647 126.032 376.647 125.137V120.634H370.276C369.95 120.637 369.627 120.57 369.33 120.436C369.031 120.303 368.766 120.106 368.552 119.86C368.337 119.613 368.18 119.323 368.089 119.01C367.998 118.697 367.976 118.367 368.026 118.045L369.579 107.914C369.66 107.377 369.933 106.888 370.346 106.536C370.76 106.184 371.287 105.994 371.83 106H384.527M384.527 118.383V106M384.527 118.383H387.533C388.169 118.394 388.789 118.17 389.272 117.755C389.756 117.34 390.07 116.763 390.155 116.131V108.252C390.07 107.62 389.756 107.042 389.272 106.627C388.789 106.212 388.169 105.989 387.533 106H384.527" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="15.0293" y="40.5898" width="114.868" height="12" rx="6" fill="#ADADAD"/>
            <rect x="135.125" y="40.5898" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
            <rect x="15.0293" y="61.4805" width="175.782" height="12" rx="6" fill="#ADADAD"/>
            <path d="M422.334 330H41.6657C35.2229 330 30 335.544 30 342.382V433.618C30 440.456 35.2229 446 41.6657 446H422.334C428.777 446 434 440.456 434 433.618V342.382C434 335.544 428.777 330 422.334 330Z" fill="white" stroke="#ADADAD"/>
            <path d="M50.3333 424.667L44 418.333M44 418.333L50.3333 412M44 418.333H56.6667C58.3461 418.333 59.9568 419 61.1454 420.188C62.3329 421.377 63 422.987 63 424.667C63 426.346 62.3329 427.957 61.1454 429.145C59.9568 430.333 58.3461 431 56.6667 431H53.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M402.628 351.131L407.131 341C408.026 341 408.885 341.356 409.518 341.989C410.151 342.622 410.508 343.481 410.508 344.377V348.879H416.878C417.205 348.876 417.528 348.943 417.826 349.077C418.123 349.21 418.389 349.407 418.602 349.653C418.817 349.899 418.976 350.19 419.067 350.503C419.157 350.816 419.178 351.145 419.13 351.468L417.577 361.599C417.495 362.136 417.222 362.626 416.808 362.977C416.394 363.328 415.868 363.518 415.325 363.512H402.628M402.628 351.131V363.512M402.628 351.131H399.251C398.654 351.131 398.082 351.368 397.659 351.79C397.237 352.213 397 352.785 397 353.382V361.261C397 361.858 397.237 362.432 397.659 362.853C398.082 363.275 398.654 363.512 399.251 363.512H402.628" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M413.527 425.383L409.024 435.513C408.129 435.513 407.27 435.157 406.637 434.524C406.003 433.891 405.647 433.032 405.647 432.137V427.634H399.276C398.95 427.637 398.627 427.57 398.33 427.436C398.031 427.303 397.766 427.106 397.552 426.86C397.337 426.613 397.18 426.323 397.089 426.01C396.998 425.697 396.976 425.367 397.026 425.045L398.579 414.914C398.66 414.377 398.933 413.888 399.346 413.536C399.76 413.184 400.287 412.994 400.83 413H413.527M413.527 425.383V413M413.527 425.383H416.533C417.169 425.394 417.789 425.17 418.272 424.755C418.756 424.34 419.07 423.763 419.155 423.131V415.252C419.07 414.62 418.756 414.042 418.272 413.627C417.789 413.212 417.169 412.989 416.533 413H413.527" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="357" y="341" width="102" height="95" rx="47.5" fill="url(#paint1_linear_6394_59096)"/>
            <path d="M417.831 388.666C417.831 394.28 413.28 398.831 407.666 398.831C402.051 398.831 397.5 394.28 397.5 388.666C397.5 383.051 402.051 378.5 407.666 378.5C413.28 378.5 417.831 383.051 417.831 388.666Z" stroke="#323232" strokeWidth="3"/>
            <rect x="44.0293" y="347.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
            <rect x="164.125" y="347.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
            <rect x="201" y="348" width="162" height="12" rx="6" fill="#ADADAD"/>
            <path d="M511.334 167H130.666C124.223 167 119 172.544 119 179.382V270.618C119 277.456 124.223 283 130.666 283H511.334C517.777 283 523 277.456 523 270.618V179.382C523 172.544 517.777 167 511.334 167Z" fill="white" stroke="#ADADAD"/>
            <path d="M139.333 261.667L133 255.333M133 255.333L139.333 249M133 255.333H145.667C147.346 255.333 148.957 256 150.145 257.188C151.333 258.377 152 259.987 152 261.667C152 263.346 151.333 264.957 150.145 266.145C148.957 267.333 147.346 268 145.667 268H142.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M496.666 237.331C503.108 237.331 508.331 232.108 508.331 225.666C508.331 219.223 503.108 214 496.666 214C490.223 214 485 219.223 485 225.666C485 232.108 490.223 237.331 496.666 237.331Z" fill="#D9D9D9"/>
            <rect x="446" y="207" width="102" height="95" rx="47.5" fill="url(#paint2_linear_6394_59096)"/>
            <path d="M491.628 188.131L496.131 178C497.026 178 497.885 178.356 498.518 178.989C499.151 179.622 499.508 180.481 499.508 181.377V185.879H505.878C506.205 185.876 506.528 185.943 506.826 186.077C507.123 186.21 507.389 186.407 507.602 186.653C507.817 186.899 507.976 187.19 508.067 187.503C508.157 187.816 508.178 188.145 508.13 188.468L506.577 198.599C506.495 199.136 506.222 199.626 505.808 199.977C505.394 200.328 504.868 200.518 504.325 200.512H491.628M491.628 188.131V200.512M491.628 188.131H488.251C487.654 188.131 487.082 188.368 486.659 188.79C486.237 189.213 486 189.785 486 190.382V198.261C486 198.858 486.237 199.432 486.659 199.853C487.082 200.275 487.654 200.512 488.251 200.512H491.628" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M502.157 262.2L497.483 273C496.553 273 495.662 272.62 495.005 271.945C494.347 271.271 493.977 270.354 493.977 269.4V264.6H487.363C487.024 264.604 486.689 264.532 486.38 264.389C486.071 264.248 485.795 264.037 485.573 263.775C485.35 263.512 485.187 263.203 485.093 262.869C484.998 262.535 484.975 262.183 485.026 261.84L486.639 251.04C486.723 250.468 487.006 249.946 487.436 249.572C487.866 249.197 488.412 248.994 488.976 249H502.157M502.157 262.2V249M502.157 262.2H505.278C505.939 262.212 506.582 261.974 507.083 261.531C507.586 261.089 507.912 260.474 508 259.8V251.4C507.912 250.727 507.586 250.111 507.083 249.669C506.582 249.226 505.939 248.988 505.278 249H502.157" stroke="#FF0000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="133.029" y="184.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
            <rect x="253.125" y="184.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
            <defs>
                <linearGradient id="paint0_linear_6394_59096" x1="378.5" y1="0" x2="378.5" y2="95" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFD556" stop-opacity="0.44"/>
                    <stop offset="1" stop-color="#FFD556" stop-opacity="0.86"/>
                </linearGradient>
                <linearGradient id="paint1_linear_6394_59096" x1="408" y1="341" x2="408" y2="436" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFD556" stop-opacity="0.44"/>
                    <stop offset="1" stop-color="#FFD556" stop-opacity="0.86"/>
                </linearGradient>
                <linearGradient id="paint2_linear_6394_59096" x1="497" y1="207" x2="497" y2="302" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFD556" stop-opacity="0.44"/>
                    <stop offset="1" stop-color="#FFD556" stop-opacity="0.86"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradeSlide;
export function LegendGravityIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4.3rem" height="1.875rem" viewBox="0 0 69 30" fill="none">
      <line x1="4.31813" y1="25.5107" x2="64.3494" y2="4.90297" stroke="#BABABA" />
      <line x1="7.94701" y1="18.3301" x2="62.6024" y2="4.89029" stroke="#BABABA" />
      <line x1="1.72736" y1="12.9443" x2="62.6547" y2="4.88036" stroke="#BABABA" />
      <circle cx="9.41133" cy="11.1999" r="6.21992" fill="#B2B2B2" stroke="white" />
      <circle cx="17.0268" cy="25.0876" r="3.97995" fill="#B2B2B2" stroke="white" />
      <circle cx="8.96234" cy="19.7119" r="7.56391" fill="#B2B2B2" stroke="white" />
      <circle cx="63.1701" cy="4.92794" r="4.42794" fill="#CCCCCC" stroke="white" />
    </svg>
  )
}

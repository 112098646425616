import React, {FC} from 'react';

interface CardCounterIconProps {
    width?: string
    height?: string
}

export const CardCounterIcon: FC<CardCounterIconProps> = (props) => {
    const { width = 14, height = 14 } = props

    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4.5V3C10 2.60218 9.84196 2.22064 9.56066 1.93934C9.27936 1.65804 8.89782 1.5 8.5 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V8.5C1.5 8.89782 1.65804 9.27936 1.93934 9.56066C2.22064 9.84196 2.60218 10 3 10H4.5M10 4.5H11C11.3978 4.5 11.7794 4.65804 12.0607 4.93934C12.342 5.22064 12.5 5.60218 12.5 6V11C12.5 11.3978 12.342 11.7794 12.0607 12.0607C11.7794 12.342 11.3978 12.5 11 12.5H6C5.60218 12.5 5.22064 12.342 4.93934 12.0607C4.65804 11.7794 4.5 11.3978 4.5 11V10M10 4.5H6C5.60218 4.5 5.22064 4.65804 4.93934 4.93934C4.65804 5.22064 4.5 5.60218 4.5 6V10" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
import { ChangeEvent, useMemo } from "react"
import { IconButton, Input, MinusIcon, PlusIcon } from "../../../../system/UX"
import styles from "../CardSortingAdminStyles.module.css"

interface TemplateCardsControlProps {
  hidden: boolean
  fields?: string[]
  addField: () => void
  removeField: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>, index: number) => void
}

export function TemplateCardsControl(props: TemplateCardsControlProps) {
  const { hidden, fields, addField, removeField, onChange } = props

  const style = useMemo(() => hidden ? { height: "0" } : {}, [hidden])

  // TODO можно вычислять высоту динамически, если кол-во полей не ограничено
  // const { height, setHeight } = useState(0)

  return (
    <div className={styles.settingsTemplateCardsControl} style={style}>
        {fields?.map((field, index) => (
          <Input
            key={index}
            inputClassName={styles.settingsTemplateCardsControlInput}
            value={field}
            placeholder="..."
            onChange={event => onChange && onChange(event, index)}
          />
        ))}
      <div className={styles.settingsTemplateCardsControlButtonsContainer}>
        <IconButton
          className={styles.settingsTemplateCardsControlButtonDelete}
          isSmall={true}
          onClick={removeField}
          disabled={!onChange}
        >
          <MinusIcon />
        </IconButton>
        <IconButton
          className={styles.settingsTemplateCardsControlButtonAdd}
          isSmall={true}
          onClick={addField}
          disabled={!onChange}
        >
          <PlusIcon />
        </IconButton>
      </div>
    </div>
  )
}

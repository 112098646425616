import { SigmaContainer } from "@react-sigma/core"
import { MouseEventHandler, useMemo } from "react"
import { createPortal } from "react-dom"
import {
  GraphRefType,
  GraphologyGradeCategory,
  GraphologyIdeaGroup,
  LegendStyles
} from "../../../../../../pages/DashboardPage/types/types"
import { Legend } from "../../../../../system/components/Legend/Legend"
import { BackIcon } from "./BackIcon"
import styles from "./FullscreemGraphStyle.module.css"

interface FullscreenGraphProps extends GraphRefType {
  gradeCategories: GraphologyGradeCategory[]
  ideaGroups: GraphologyIdeaGroup[]
  fullscreenControl?: MouseEventHandler
  legendStyles: LegendStyles
}

export function FullscreenGraph(props: FullscreenGraphProps) {
  const { ideaGroups, gradeCategories, fullscreenControl, graph, sigmaSettings, legendStyles } =
    props

  const graphType = useMemo(() => graph?.getAttribute("type"), [graph])
  
  // TODO translates
  return createPortal(
    <div className={styles.fullScreenGraph}>
      <div className={styles.fullScreenControls}>
        <div className={styles.fullScreenBack} onClick={fullscreenControl}>
          <BackIcon />
          <p>Назад</p>
        </div>

        <div className={styles.fullScreenGraphLegendBlock}>
          <p>
            {graphType === "groups"
              ? "Карта идей, раскрашенная по общим трендам"
              : "Карта идей, раскрашенная по результатам голосования"}
          </p>
          <div className={styles.fullScreenGraphLegend}>
            <Legend
              items={graphType === "groups" ? ideaGroups : gradeCategories}
              CSSstyles={legendStyles}
            />
          </div>
        </div>
      </div>
      <SigmaContainer settings={sigmaSettings} style={{ height: "100vh" }} graph={graph} />
    </div>,
    document.body
  )
}

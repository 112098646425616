import {Translates} from "./translates/translates";
import {AdminUserSortingStatusWithAdmins, ProjectStatus, QuadrantSortArea} from "./types";

export const getTranslatedAdminUserSortingStatus = (
    status: AdminUserSortingStatusWithAdmins | ProjectStatus,
    translates: Translates
) => {
    switch (status) {
        case "admins":
            return translates.userManager.adminUserSortingStatuses.admins
        case "collecting_cards":
            return translates.userManager.adminUserSortingStatuses.collectingCards
        case "sorting_not_started":
            return translates.userManager.adminUserSortingStatuses.sortingNotStarted
        case "sorting_started":
            return translates.userManager.adminUserSortingStatuses.sortingStarted
        case "sorting_finished":
            return translates.userManager.adminUserSortingStatuses.sortingFinished
        case "waiting_for_sorting":
            return translates.userManager.adminUserSortingStatuses.waitingForSorting
        case "project_not_opened":
            return translates.userManager.adminUserSortingStatuses.projectNotOpened
        case "project_settings_unset":
            return translates.userManager.adminUserSortingStatuses.projectSettingsUnset
        case "doing_sort":
            return translates.userManager.adminUserSortingStatuses.doingSort
        case "doing_quadrant_sort":
            return translates.userManager.adminUserSortingStatuses.doingQuadrantSort
        case "finished_quadrant_sort":
            return translates.userManager.adminUserSortingStatuses.finishedQuadrantSort
        default:
            console.log(status)
            return "missing translate"
    }
}

export function compareAreas(activeAreas: QuadrantSortArea[], targetArea: QuadrantSortArea) {
    if (activeAreas.length === 0) {
      return false
    } else {
      return activeAreas.some(
        area => area.axis === targetArea.axis && area.index === targetArea.index
      )
    }
  }
import { ChangeEventHandler, FC, MouseEventHandler } from "react";
import styles from "./MailingConfirmModalStyles.module.css";
// @ts-ignore
// import emailExample from "../../../../../public/pictures/emailExample.png";
import { Button, Input, Modal } from "../../UX";
import { Portal } from "../../service";

interface MailingConfirmModalProps {
  children?: string;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  confirmText: string;
  cancelText: string;
  pClassName?: string;
  isPending?: boolean;
  imageLabel?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputValue?: string;
  modalLabel?: string;
  inputOnChange?: ChangeEventHandler<HTMLInputElement>;
}

export const MailingConfirmModal: FC<MailingConfirmModalProps> = (props) => {
  const {
    children,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    imageLabel,
    isPending = false,
    inputValue,
    inputLabel,
    modalLabel,
    inputPlaceholder,
    inputOnChange,
  } = props;

  return (
    <Portal>
      <Modal>
        <p className={styles.modalText}>{children}</p>
        <div className={styles.modalContainer}>
          <p>{modalLabel}</p>
          <div className={styles.imageContainer}>
            <p>{imageLabel}</p>
            <img
              alt="Example of email"
              src={`${process.env.PUBLIC_URL}/pictures/emailExample.png`}
              className={styles.image}
            ></img>
          </div>
          <Input
            isTextareaView={true}
            inputClassName={styles.input}
            placeholder={inputPlaceholder}
            label={inputLabel}
            containerClassName={styles.userOrProjectInputContainer}
            value={inputValue}
            onChange={inputOnChange}
          ></Input>
          <div className={styles.buttonsContainer}>
            <Button
              style={{ width: "8rem" }}
              onClick={onCancel}
              variant="outlined"
              disabled={isPending}
            >
              {cancelText}
            </Button>
            <Button style={{ width: "8rem" }} onClick={onConfirm} isPending={isPending}>
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NodeMouseHintStyles_mouseHint__K670D {\n  display: flex;\n  flex-direction: column;\n  gap: 0.2rem;\n  position: absolute;\n  background: #323232;\n  border-radius: 0.625rem;\n  z-index: 1000;\n  padding: 0.5rem 1rem;\n  max-width: 16rem;\n}\n\n.NodeMouseHintStyles_mouseHint__K670D p {\n  color: white;\n  font-size: 0.875rem;\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/components/NodeMouseHint/NodeMouseHintStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".mouseHint {\n  display: flex;\n  flex-direction: column;\n  gap: 0.2rem;\n  position: absolute;\n  background: #323232;\n  border-radius: 0.625rem;\n  z-index: 1000;\n  padding: 0.5rem 1rem;\n  max-width: 16rem;\n}\n\n.mouseHint p {\n  color: white;\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mouseHint": "NodeMouseHintStyles_mouseHint__K670D"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import { useAppSelector } from "../../../../../../store/store"
import { SortStage } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../../translates/useTranslate"
import { GradesCounterControl } from "../GradesCounterControl"
import { CardCounterIcon } from "../icons/CardCounterIcon"
import { QuadrantGroupTypeIcon } from "../icons/QuadrantGroupTypeIcon/QuadrantGroupTypeIcon"
import { MobileCard } from "./MobileCard"
import { MobileGroup } from "./MobileGroup"
import "./MobileScrollbarStyles.css"
import { MobileSortingControls } from "./MobileSortingControls"
import styles from "./MobileSortingStyles.module.css"
import { quadrantGroupColors3x3 } from "../../../../../../common/constants"

const startWindowInnerHeight = window.innerHeight
const containersPaddingY = 0.5

// TODO common props
interface MobileSortingProps {
  showInstruction: () => void
  userGroupColorMap?: Map<number, string>
}

export const MobileSorting = (props: MobileSortingProps) => {
  const translate = useTranslate()

  const { showInstruction, userGroupColorMap } = props

  const remValue = useAppSelector(state => state.appReducer.remValue)
  const { sortStage, user_groups } = useAppSelector(state => state.cardSortingReducer)
  const group_state = useAppSelector(state => state.cardSortingReducer.group_state)
  const { total_number_of_cards, bank, groups } = group_state

  const mobileControlsHeight = 3.3 * remValue
  const baseContainerHeight = startWindowInnerHeight / 2 - mobileControlsHeight - 2 * remValue
  const [heightDifference, setHeightDifference] = useState(0)

  const bankScrollableContainer = useRef<HTMLDivElement>(null)
  const groupScrollableContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (bankScrollableContainer.current && groupScrollableContainer.current) {
      if (
        bankScrollableContainer.current.clientHeight > baseContainerHeight &&
        groupScrollableContainer.current.clientHeight > baseContainerHeight
      ) {
        setHeightDifference(0)
      } else {
        if (bankScrollableContainer.current.clientHeight < baseContainerHeight) {
          setHeightDifference(
            -(
              baseContainerHeight -
              bankScrollableContainer.current.clientHeight -
              containersPaddingY * remValue
            )
          )
        }

        if (groupScrollableContainer.current.clientHeight < baseContainerHeight) {
          setHeightDifference(
            baseContainerHeight -
              groupScrollableContainer.current.clientHeight -
              containersPaddingY * remValue
          )
        }
      }
    }
  }, [groups, bank])

  const bankScrollableHeight = baseContainerHeight + heightDifference + "px"
  const groupScrollableHeight = baseContainerHeight - heightDifference + "px"

  return (
    <div className={styles.sortingContainer}>
      <div className={styles.bankContainer}>
        <div className={styles.bankHeader}>
          <div className={styles.cardCounterContainer}>
            <CardCounterIcon />
            <span className={styles.cardCounter}>
              {total_number_of_cards - bank.length}/{total_number_of_cards}{" "}
              {translate.mobileTranslates.sorted}
            </span>
          </div>
          {sortStage === SortStage.QUADRANT_SORT && <GradesCounterControl />}
        </div>
        <div
          className={styles.bank}
          style={{
            paddingTop: containersPaddingY + "rem",
            paddingBottom: containersPaddingY + "rem"
          }}
        >
          <SimpleBar
            style={{
              maxHeight: bankScrollableHeight,
              paddingBottom: "0.05rem"
            }}
            autoHide={false}
            className="thinScrollbar"
          >
            {user_groups && userGroupColorMap && (
              <div className={styles.bankCardContainer} ref={bankScrollableContainer}>
                {user_groups.map(group => (
                  <>
                    <div className={styles.bankGroup}>
                      <QuadrantGroupTypeIcon color={userGroupColorMap.get(group.id)} />
                      <p key={group.id}>{group.name}</p>
                    </div>
                    {bank &&
                      bank.map(
                        card =>
                          card.group === group.id && (
                            <MobileCard
                              key={card.id}
                              id={card.id}
                              text={card.text}
                              grade_status={card.grade_status}
                              animationDestination={card.animationDestination}
                              color={userGroupColorMap.get(group.id)}
                            />
                          )
                      )}
                  </>
                ))}
              </div>
            )}
            {!user_groups && (
              <div className={styles.bankCardContainer} ref={bankScrollableContainer}>
                {bank.map(card => (
                  <MobileCard
                    key={card.id}
                    id={card.id}
                    text={card.text}
                    grade_status={card.grade_status}
                    animationDestination={card.animationDestination}
                  />
                ))}
              </div>
            )}
          </SimpleBar>
        </div>
      </div>
      <MobileSortingControls showInstruction={showInstruction} height={mobileControlsHeight} />
      <div
        className={styles.groups}
        style={{
          paddingTop: containersPaddingY + "rem",
          paddingBottom: containersPaddingY + "rem",
          ...(sortStage === SortStage.QUADRANT_SORT && { borderRadius: "0.75rem" })
        }}
      >
        <SimpleBar
          style={{
            maxHeight: groupScrollableHeight,
            paddingBottom: "0.05rem"
          }}
          autoHide={false}
          className="thinScrollbar"
        >
          <div className={styles.groupsContainer} ref={groupScrollableContainer}>
            {groups.map(group => (
              <MobileGroup
                key={group.id}
                id={group.id}
                name={group.name}
                cards={group.cards}
                userGroupColorMap={userGroupColorMap}
                color={(group.index !== undefined && quadrantGroupColors3x3.get(group.index)) || group.color}
              />
            ))}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

import { useEffect } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { getErrorUrl } from "../../../common/utils"
import { ScatterChart } from "../../../project/modules/main/ScatterChart/ScatterChart"
import { PreloadBar } from "../../../project/system/UX"
import { DashboardHttp } from "../http"
import { ContextType } from "../types/types"

export function ScatterChartPage() {
  const { projectId } = useParams()
  const { scatterData, setScatterData } = useOutletContext<ContextType>()
  const navigate = useNavigate()

  useEffect(() => {
    if (projectId) {
      DashboardHttp.getScatterData(Number(projectId))
        .then(data => {
          setScatterData(data)
        })
        .catch(error => navigate(getErrorUrl(error.message)))
    }
  }, [projectId])

  return scatterData ? <ScatterChart data={scatterData} /> : <PreloadBar isFullPage={true} />
}
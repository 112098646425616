import classNames from "classnames"
import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Pages } from "../../common/types"
import { AlertModal, ConfirmModal } from "../../project/system/components"
import styles from "./SuperadminStyles.module.css"
import { DashboardGroupsSettingsForm } from "./components/DashboardGroupsSettingsForm"
import {
  createIdeaGroup,
  deleteIdeaGroup,
  getDashboardCards,
  getGroupsSettings,
  getIdeaGroups,
  getNames,
  setSplitGroupsSettings,
  splitGroups,
  updateCardGroup,
  updateIdeaGroupNames
} from "./http"
import {
  DashboardIdeaGroupIn,
  DashboardIdeaGroupOut,
  DashboardProjectCardOut,
  DashboardProjectGroupsSettings
} from "./types"
import { handleChange } from "./utils"

const promptDefault =
  "Придумай название каждой группе. Название должно: отражать общую суть " +
  "ВСЕХ карточек в группе, быть написано с большой буквы, быть не длиннее 7 слов." +
  'Например, плохие названия: "Коммуникация", "Улучшения", "Хобби". Хорошие ' +
  'названия: "Улучшение коммуникации с отделом маркетинга", "Создание комфортных ' +
  'условий работы в офисе", "Недорогие хобби для малоактивных людей.'

export function SuperadminDashboardGroupsPage() {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [confirmSaveSettings, setConfirmSaveSettings] = useState(false)
  const [confirmSavePrompt, setConfirmSavePrompt] = useState(false)
  const [ideaGroups, setIdeaGroups] = useState<DashboardIdeaGroupOut[]>([])
  const [ideaGroupNames, setIdeaGroupNames] = useState<DashboardIdeaGroupIn[]>([])
  const [cards, setCards] = useState<DashboardProjectCardOut[]>([])
  const [selectedCards, setSelectedCards] = useState<number[]>([])
  const [toDeleteGroup, setToDeleteGroup] = useState<number | null>(null)
  const [newGroupName, setNewGroupName] = useState("")
  const [needUpdate, setNeedUpdate] = useState(false)
  const [groupToSet, setGroupToSet] = useState<number | null>(null)
  const [groupsSettings, setGroupsSettings] = useState<DashboardProjectGroupsSettings | null>(null)
  const [prompt, setPrompt] = useState(promptDefault)
  const [pending, setPending] = useState(false)
  const [alert, setAlert] = useState("")

  useEffect(() => {
    if (projectId) {
      getIdeaGroups(projectId)
        .then(data => {
          setIdeaGroups(data)
          setIdeaGroupNames(data)
          setNeedUpdate(false)
        })
        .catch(e => console.log(e))
    }
  }, [projectId, needUpdate])

  useEffect(() => {
    if (projectId) {
      getDashboardCards(projectId)
        .then(data => {
          setCards(data)
          setNeedUpdate(false)
        })
        .catch(e => console.log(e))
    }
  }, [projectId, needUpdate])

  useEffect(() => {
    if (projectId) {
      getGroupsSettings(projectId)
        .then(data => {
          setGroupsSettings(data)
          data.prompt && setPrompt(data.prompt)
          setNeedUpdate(false)
        })
        .catch(e => console.log(e))
    }
  }, [projectId, needUpdate])

  function handleCheckboxChange(cardId: number) {
    setSelectedCards(prevSelectedCards =>
      prevSelectedCards.includes(cardId)
        ? prevSelectedCards.filter(id => id !== cardId)
        : [...prevSelectedCards, cardId]
    )
  }

  function onDeleteGroupHandler() {
    if (projectId && toDeleteGroup) {
      deleteIdeaGroup(projectId, toDeleteGroup)
        .then(() => {
          setIdeaGroups(ideaGroups.filter(group => group.id !== toDeleteGroup))
          const updatedCards = cards.map(card => {
            if (card.idea_group === toDeleteGroup) {
              return { ...card, idea_group: undefined }
            }
            return card
          })
          setCards(updatedCards)
          setToDeleteGroup(null)
        })
        .catch(error => console.log(error))
    }
  }

  function onCreateHandler() {
    if (projectId) {
      createIdeaGroup(projectId, {
        name: newGroupName
      })
        .then(data => {
          setIdeaGroups(state => [...state, data])
          setIdeaGroupNames(state => [...state, data])
          setNewGroupName("")
        })
        .catch(error => console.log(error))
    }
  }

  function handleGroupNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    const groupId = parseInt(event.target.name)
    const value = event.target.value
    const updatedGroupNames = ideaGroupNames.map(group =>
      group.id === groupId ? { ...group, name: value } : group
    )
    setIdeaGroupNames(updatedGroupNames)
  }

  function onSaveNames() {
    if (projectId) {
      updateIdeaGroupNames(projectId, ideaGroupNames)
        .then(() => setAlert("Названия сохранены"))
        .catch(e => console.log(e))
    }
  }

  function handleCleanGroup() {
    if (projectId) {
      const updatedCards = cards.map(card => {
        if (selectedCards.includes(card.id)) {
          return { ...card, idea_group: undefined }
        }
        return card
      })
      updateCardGroup(projectId, updatedCards)
        .then(() => setCards(updatedCards))
        .catch(error => console.log(error))
      setSelectedCards([])
    }
  }

  function handleSetGroup() {
    if (projectId && groupToSet) {
      const updatedCards = cards.map(card => {
        if (selectedCards.includes(card.id)) {
          return { ...card, idea_group: groupToSet }
        }
        return card
      })
      updateCardGroup(projectId, updatedCards)
        .then(() => setCards(updatedCards))
        .catch(error => console.log(error))
      setSelectedCards([])
    }
  }

  function onSaveSettings() {
    if (projectId && groupsSettings) {
      setSplitGroupsSettings(projectId, groupsSettings)
        .then(() => {
          setConfirmSaveSettings(false)
          setPending(true)
          splitGroups(projectId).then(() => {
            setNeedUpdate(true)
            setPending(false)
          })
        })
        .catch(e => console.log(e))
    }
  }

  function onSavePrompt() {
    if (projectId && groupsSettings) {
      setSplitGroupsSettings(projectId, { ...groupsSettings, prompt: prompt })
        .then(() => {
          setConfirmSavePrompt(false)
          setPending(true)
          getNames(projectId).then(() => {
            setPending(false)
            setNeedUpdate(true)
          })
        })
        .catch(error => {
          console.log(error)
          setPending(false)
          setAlert("Что-то пошло не так. Попробуйте ещё раз.")
        })
    }
  }

  return (
    <div className={styles.superadminLayout}>
      {alert && (
        <AlertModal
          onSubmit={() => {
            setAlert("")
            setNeedUpdate(true)
          }}
          submitText="OK"
        >
          {alert}
        </AlertModal>
      )}
      {pending && (
        <AlertModal onSubmit={() => {}} submitText="Ждём">
          Ждём ответа...
        </AlertModal>
      )}
      {confirmSaveSettings && (
        <ConfirmModal
          onConfirm={onSaveSettings}
          onCancel={() => setConfirmSaveSettings(false)}
          confirmText="Да"
          cancelText="Нет"
        >
          Это действие сбросит все группы и их названия. Продолжить?
        </ConfirmModal>
      )}
      {confirmSavePrompt && (
        <ConfirmModal
          onConfirm={onSavePrompt}
          onCancel={() => setConfirmSavePrompt(false)}
          confirmText="Да"
          cancelText="Нет"
        >
          Это действие изменит все названия. Продолжить?
        </ConfirmModal>
      )}
      {toDeleteGroup && (
        <ConfirmModal
          onConfirm={onDeleteGroupHandler}
          onCancel={() => setToDeleteGroup(null)}
          confirmText="Да"
          cancelText="Нет"
        >
          Удалить группу? Это действие НЕ удаляет карточки.
        </ConfirmModal>
      )}
      <button
        className={classNames(styles.superadminButton, styles.superadminBigButton)}
        style={{ fontWeight: "bold" }}
        onClick={() => navigate(Pages.superadmin_dashboards)}
      >
        &#9668; к списку дашбордов
      </button>
      <div className={styles.superadminGroupsBlock}>
        <h1>Управление группами</h1>
        {ideaGroups?.map(group => (
          <li key={group.id} className={styles.superadminGroupRow}>
            <label htmlFor={String(group.id)} style={{ fontWeight: "bold" }}>
              {group.id}.
            </label>
            <input
              name={String(group.id)}
              value={ideaGroupNames.find(groupName => groupName.id === group.id)?.name}
              onChange={handleGroupNameInputChange}
            />
            <button
              className={classNames(styles.superadminButton)}
              onClick={() => setToDeleteGroup(group.id)}
            >
              Удалить
            </button>
          </li>
        ))}
        <div className={styles.superadminGroupRow}>
          <label htmlFor="addGroup">Добавить группу:</label>
          <input
            id="addGroup"
            placeholder="Название"
            value={newGroupName}
            onChange={event => setNewGroupName(event.target.value)}
          />
          <button className={classNames(styles.superadminButton)} onClick={onCreateHandler}>
            Добавить
          </button>
        </div>
        <button className={classNames(styles.superadminButton)} onClick={onSaveNames}>
          Сохранить изменения названий
        </button>
      </div>
      <div className={styles.superadminGroupsBlock}>
        <h1>Настройки разделения на группы</h1>
        <a href="https://networkx.org/documentation/stable/reference/algorithms/generated/networkx.algorithms.community.modularity_max.greedy_modularity_communities.html#networkx.algorithms.community.modularity_max.greedy_modularity_communities">
          См. справку
        </a>
        {groupsSettings && (
          <DashboardGroupsSettingsForm
            groupsSettings={groupsSettings}
            setGroupsSettings={setGroupsSettings}
            handleChange={handleChange}
          />
        )}
        <button
          className={classNames(styles.superadminButton)}
          onClick={() => setConfirmSaveSettings(true)}
        >
          Сохранить настройки и разделить на группы заново
        </button>
      </div>
      <div className={styles.superadminGroupsBlock}>
        <h1>Промпт к OpenAPI</h1>
        <div className={styles.superadminGroupsPrompt}>
          <i>(Это сообщение отправляется с ролью system.)</i>
          <div className={styles.superadminGroupsPromptText}>
            <p>
              Ты получишь данные в формате JSON - список групп некоторых карточек вида
              &#91;&#123;&quot;group_id&quot;&#58; &quot;list_of_cards&quot;&#125;&#93; Карточки -
              это ответы разных людей на вопрос <i>question</i>.
            </p>
            <textarea
              id="prompt"
              name="prompt"
              value={prompt}
              onChange={event => {
                setPrompt(event.target.value)
              }}
            />
            <p>
              Верни данные в формате JSON вида: &#123;&quot;group_id&quot;:
              &quot;name&quot;,&#125;, где name - название группы с group_id
            </p>
          </div>
        </div>
        <button
          className={classNames(styles.superadminButton)}
          onClick={() => setConfirmSavePrompt(true)}
        >
          Сохранить промпт и запросить новые названия
        </button>
      </div>
      <div className={styles.superadminCardsBlock}>
        <h1>Управление карточками проекта</h1>
        {/* TODO add select all in group */}
        {ideaGroups?.map(group => (
          <div key={group.id} className={styles.superadminCardsGroup}>
            <h2>{group.name ? group.name : "(Группа без имени)"}</h2>
            {cards
              ?.filter(card => card.idea_group === group.id)
              .map(card => (
                <div key={card.id} className={styles.superadminCardsRow}>
                  <input
                    type="checkbox"
                    checked={selectedCards.includes(card.id)}
                    onChange={() => handleCheckboxChange(card.id)}
                  ></input>
                  <p>{card.text}</p>
                </div>
              ))}
          </div>
        ))}
        <div className={styles.superadminCardsGroup}>
          <h2>Карточки без группы</h2>
          {cards
            ?.filter(card => !card.idea_group)
            .map(card => (
              <div key={card.id} className={styles.superadminCardsRow}>
                <input
                  type="checkbox"
                  checked={selectedCards.includes(card.id)}
                  onChange={() => handleCheckboxChange(card.id)}
                ></input>
                <p>{card.text}</p>
              </div>
            ))}
        </div>
      </div>
      <div className={classNames(styles.superadminChangeGroup)}>
        <button className={classNames(styles.superadminButton)} onClick={() => handleSetGroup()}>
          Установить группу:
        </button>
        <select onChange={event => setGroupToSet(parseInt(event.target.value))}>
          {ideaGroups?.map(group => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
      <button className={classNames(styles.superadminButton)} onClick={handleCleanGroup}>
        Очистить группу
      </button>
    </div>
  )
}

import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import classNames from "classnames"
import { CSSProperties, FC, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { Portal } from "../../../../../system/service"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import {
  Card,
  GradeStatus,
  SortStage
} from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { movingCardsTransitionMS } from "../../utils"
import CardText from "../DesktopSorting/CardText"
import { MobileArrowBackIcon } from "../icons/MobileArrowBackIcon"
import { MobileLikeIcon } from "../icons/MobileLikeIcon"
import { MobileTrashIcon } from "../icons/MobileTrashIcon"
import styles from "./MobileSortingStyles.module.css"

interface MobileCardProps extends Card {
  place?: "bin" | "like modal" | "bank" | "group"
  setGradeForUngradedCard?: (id: number, grade: GradeStatus) => void
  color?: string
}

export const MobileCard: FC<MobileCardProps> = props => {
  const {
    id,
    text,
    grade_status,
    animationDestination,
    place = "bank",
    color,
    setGradeForUngradedCard
  } = props

  const { moveFromBankToBin, togglePickedCard, moveFromBinToBank, moveFromGroupToBank, setGrade } =
    cardSortingSlice.actions
  const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
  const sortStage = useAppSelector(state => state.cardSortingReducer.sortStage)
  const dispatch = useAppDispatch()
  const [isAnimationStart, setIsAnimationStart] = useState(false)

  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (typeof animationDestination === "number") {
      setTimeout(() => {
        setIsAnimationStart(true)
      })
    } else {
      setIsAnimationStart(false)
    }
  }, [animationDestination])

  const toggleCardHandler = () => {
    if (place === "bank") dispatch(togglePickedCard({ group: "bank", cardId: id }))
  }

  const cardStyles: CSSProperties = {
    backgroundColor: pickedCards.cards.includes(id) ? "#c8cde0" : "white",
    opacity: typeof animationDestination === "number" ? 0 : 1,
    ...(color && {
      border: 0,
      borderTop: `0.6rem solid ${color}`,
      outline: "0.0625rem solid black",
      outlineOffset: "-0.0625rem"
    })
  }

  const startAnimatedCardStyles: CSSProperties = {
    backgroundColor: pickedCards.cards.includes(id) ? "#c8cde0" : "white",
    transition: `top ${movingCardsTransitionMS}ms, transform ${movingCardsTransitionMS}ms`,
    position: "absolute",
    top: cardRef.current ? cardRef.current.getClientRects()[0].y : 0,
    left: cardRef.current ? cardRef.current.getClientRects()[0].x : 0,
    width: cardRef.current ? cardRef.current.getClientRects()[0].width : "auto"
  }

  const endAnimatedCardStyles: CSSProperties = {
    ...startAnimatedCardStyles,
    transform: "scale(0)",
    top: `${animationDestination}px`
  }

  const moveHandler = (e: any, moveFunction: ActionCreatorWithPayload<number>, id: number) => {
    e.stopPropagation()
    dispatch(moveFunction(id))
  }

  const card = (cardStyles: CSSProperties) => (
    <div className={styles.card} style={cardStyles} ref={cardRef} onClick={toggleCardHandler}>
      <p className={styles.cardText}>
        <CardText>{text}</CardText>
      </p>
      <div className={styles.cardControls}>
        {place === "bank" && (
          <MobileTrashIcon onClick={e => moveHandler(e, moveFromBankToBin, id)} />
        )}
        {place === "group" && (
          <MobileArrowBackIcon onClick={e => moveHandler(e, moveFromGroupToBank, id)} />
        )}
        {place === "bin" && (
          <MobileArrowBackIcon onClick={e => moveHandler(e, moveFromBinToBank, id)} />
        )}
        {sortStage === SortStage.QUADRANT_SORT && (
          <div className={styles.gradeControls} onClick={e => e.stopPropagation()}>
            <MobileLikeIcon
              isDislike={true}
              className={classNames(
                styles.gradeControl,
                grade_status === "disliked" && styles.activeDislike
              )}
              onClick={() => {
                dispatch(setGrade({ id, grade: "disliked" }))
                setGradeForUngradedCard && setGradeForUngradedCard(id, "disliked")
              }}
            />
            <div
              className={classNames(
                styles.neutralGradeControl,
                grade_status === "neutral" && styles.activeNeutralGrade
              )}
              onClick={() => {
                dispatch(setGrade({ id, grade: "neutral" }))
                setGradeForUngradedCard && setGradeForUngradedCard(id, "neutral")
              }}
            />
            <MobileLikeIcon
              className={classNames(
                styles.gradeControl,
                grade_status === "liked" && styles.activeLike
              )}
              onClick={() => {
                dispatch(setGrade({ id, grade: "liked" }))
                setGradeForUngradedCard && setGradeForUngradedCard(id, "liked")
              }}
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <>
      {typeof animationDestination === "number" && (
        <Portal>{card(isAnimationStart ? endAnimatedCardStyles : startAnimatedCardStyles)}</Portal>
      )}

      {card(cardStyles)}
    </>
  )
}

import {Translates} from "./translates";

export const enTranslates: Translates = {
    gotItButton: 'Got it!',
    save: 'Save',
    cardsNotCollectedTittle: 'Thank you! We received your cards.',
    cardsNotCollectedText: 'Not everyone has completed the task yet. As soon as we receive their answers, we will open the next exercise. We will let you know.',
    cardInputPlaceholder: 'Write a card text...',
    addCardButton: 'Add card',
    removeCardButton: 'Remove card',
    trashMouseHint: 'Move to a trash bin',
    trashButtonHint: 'Here you can view and restore all removed cards',
    wrapGroupsHint: 'Collapse all groups',
    unwrapGroupsHint: 'Expand all groups',
    sendCardsButtonHint: 'Button will become active when you fill out all the cards',
    backMouseHint: 'Move back to bank',
    hintNotification: 'Click if you want to see instruction',
    mobileHintNotification: 'Click to see instruction',
    deleteGroupHint: 'Delete Group',
    confirmDeleteGroup: 'Are you sure you want to delete this group? All cards of this group will fit back into the bank.',
    binHeader: 'Unclear cards',
    sortingFinishedTittle: 'Thank you, we saved your answers.',
    sortingFinishedText: 'You can proceed to the next step',
    group: 'Group',
    groupCardCounter: 'cards',
    groupRatedCardCounter: 'rated',
    addGroupButton: 'Add group',
    finishSortButton: 'Finish sorting',
    cardCounter: {
        ratedCards: 'Rated cards',
        sortedCards: 'Sorted cards',
    },
    yes: 'Yes',
    no: 'No',
    sortingErrors: {
        notAllCardsSorted: 'You didn\'t sort all cards',
        emptyGroupName: 'There are empty group names. Please name them',
        binNameUsed: '"bin" is a system name. Please rename it',
        bankNameUsed: '"bank" is a system name. Please rename it',
        notAllLikesGiven: 'You didn\'t give the required number of likes',
        notAllDislikesGiven: 'You didn\'t give the required number of dislikes'
    },
    saveSortingConfirm: 'You really want to save your sorting?',
    setLikesRequest: 'In order to save the sorting, you need to put down the missing grades.',
    groupContainerPlaceholder: 'Select cards that are similar in meaning and press “add group” to place them together in one group.',
    bankPlaceholder: 'Congratulations! You have sorted all the cards. You can finish sorting.',
    treasury: 'TREASURY',
    allTheIdeas: 'all the ideas as cards',
    instructionSteps: {
        firstStep: "Step 1",
        secondStep: "Step 2",
        thirdStep: "Step 3",
        repeatSecondThirdSteps: 'Repeat steps 2-3 until all cards are sorted',
        fourthStep: "Step 4",
        firstStepText: `Explore the <span style="font-size: 0.7rem; font-weight: bold;">TREASURY</span>, learn its concepts, find patterns, themes, and cards with similar meanings.`,
        secondStepText: `Select cards united by one theme and press <span style="font-size: 0.7rem; font-weight: bold;">+ Add group</span>. The cards will be grouped on the right.`,
        thirdStepFirstText: `Give meaningful name to the group and proceed to the next theme...`,
        thirdStepSecondText: `...or, create an empty group and move cards there by clicking them and the group title.`,
        repeatSecondThirdStepsFirstText: `Sort cards by content, not context or sentiment. For example, group cards A and B if they discuss pollution, not because they were both good solutions but in different areas.`,
        repeatSecondThirdStepsSecondText: `Use the <span style="font-size: 0.7rem; font-weight: bold;">back arrow</span> to remove a group and move the cards back to the <span style="font-size: 0.7rem; font-weight: bold;">TREASURY</span>. However, don’t overthink, trust your gut. The ideal structure will emerge from combining everyone’s input later.`,
        repeatSecondThirdStepsThirdText: `Delete cards you don't understand. They're in the <span style="font-size: 0.7rem; font-weight: bold;">Trash bin</span>, and you can always restore them should you change your mind`,
    },
    mobileTranslates: {
        sorted: 'sorted',
        addGroup: 'Add group',
        editGroup: 'Edit group',
        done: 'Done',
        deletedCards: 'Deleted cards',
        rateTheCards: 'Rate the cards',
        YouAreAlmostDone: 'You are almost done! Last step is to rate all unrated cards. Please, rate them to continue.',
        binIsEmpty: 'Bin is empty',
        groupIsEmpty: 'Group is empty'
    }
}
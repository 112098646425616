import AbstractGraph from "graphology-types"
import {
  ColoringType,
  DashboardEdgeInfo,
  DashboardGradesCount,
  DashboardNodeInfoInner,
  GraphologyNodeCategory
} from "../../../../../../pages/DashboardPage/types/types"

export function getControversial(grades: DashboardGradesCount) {
  const totalGrades = grades.liked + grades.disliked + grades.neutral
  return (
    grades.neutral <= totalGrades * 0.4 &&
    Math.abs(grades.disliked - grades.liked) < totalGrades * 0.3
  )
}

// TODO поудалять комментарии

type NodeAttributes = {
  key: string
  grades?: DashboardGradesCount
  pagerank?: number
  ideaGroupId?: number
  authorClusterId?: number
}

export function getPercentile<T extends keyof NodeAttributes>(
  array: NodeAttributes[],
  target: NodeAttributes,
  attr: T
): number {
  const sameOrLess = array.filter(item => {
    const itemValue = item[attr]
    const targetValue = target[attr]
    if (itemValue === undefined || targetValue === undefined) {
      return false
    }
    if (typeof itemValue === typeof targetValue) {
      return itemValue <= targetValue
    } else {
      throw new Error(`Types ${typeof itemValue} and ${typeof targetValue} are incompatible.`)
    }
  })
  return sameOrLess.length / (array.length - 1)
}

export function getDarkerColor(hex: string | undefined, darkerFor: number = 20) {
  if (!hex) {
    return "#FFFFFF"
  }

  if (hex.startsWith("#")) {
    hex = hex.slice(1)
  }

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(c => c + c)
      .join("")
  }

  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  r = Math.max(0, r - darkerFor)
  g = Math.max(0, g - darkerFor)
  b = Math.max(0, b - darkerFor)

  return "#" + [r, g, b].map(c => c.toString(16).padStart(2, "0")).join("")
}

export function getNodeAttributes(
  graph: AbstractGraph<
    DashboardNodeInfoInner,
    DashboardEdgeInfo,
    {
      name: string
      type?: ColoringType
    }
  >,
  node: string,
  coloring: ColoringType,
  clusterId: number | undefined
): NodeAttributes {
  const attributes = graph.getNodeAttributes(node)
  const pagerank = attributes.pagerank
  if (clusterId) {
    // const grades = attributes.clusterGrades
    // const targetClusterGrades = grades?.find(clusterGrades => clusterGrades.cluster === clusterId)
    return {
      key: node,
      // averageGrade: targetClusterGrades?.averageClusterGrade,
      grades: attributes.grades.find(clusterGrades => clusterGrades.cluster === clusterId)?.grades,
      pagerank: pagerank,
      // controversial: targetClusterGrades?.controversial,
      ...(coloring === "groups" && {
        ideaGroupId: attributes.idea_group_id,
        authorClusterId: attributes.author.cluster
      })
    }
  } else {
    return {
      key: node,
      grades: attributes.totalGradesCount,
      // averageGrade: attributes.averageGrade,
      pagerank: pagerank,
      // controversial: attributes.controversial,
      ...(coloring === "groups" && {
        ideaGroupId: attributes.idea_group_id,
        authorClusterId: attributes.author.cluster
      })
    }
  }
}

// export function getNodeCategory(
//   controversial: boolean | undefined,
//   averageGradePercentile: number,
//   pagerankPercentile: number,
//   mid_grade: number,
//   min_pagerank: number,
//   max_pagerank: number
// ): GraphologyNodeCategory {
//   if (controversial) {
//     return "controversial"
//   }

//   if (averageGradePercentile < mid_grade && pagerankPercentile < min_pagerank) {
//     return "bottom"
//   } else if (pagerankPercentile >= min_pagerank && pagerankPercentile < max_pagerank) {
//     return "indifferent"
//   } else if (averageGradePercentile < mid_grade && pagerankPercentile >= max_pagerank) {
//     return "resist"
//   } else if (averageGradePercentile >= mid_grade && pagerankPercentile < min_pagerank) {
//     return "local"
//   } else if (averageGradePercentile >= mid_grade && pagerankPercentile >= max_pagerank) {
//     return "top"
//   }
//   return "unset"
// }

export function getNodeCategory(
  // controversial: boolean | undefined,
  // averageGradePercentile: number,
  // pagerankPercentile: number,
  // mid_grade: number,
  // min_pagerank: number,
  // max_pagerank: number
  grades?: DashboardGradesCount
): GraphologyNodeCategory {
  // if (controversial) {
  //   return "controversial"
  // }
  if (!grades) {
    return "unset"
  }
  if (grades.liked === 0 && grades.neutral === 0) {
    return "bottom"
  } else if (grades.liked > 0 && grades.neutral === 0) {
    return "resist"
  } else if (grades.liked === 0 && grades.neutral > 0) {
    return "indifferent"
  } else if (
    grades.liked > 0 &&
    grades.neutral > 0 &&
    grades.liked + grades.neutral > (grades.liked + grades.neutral + grades.disliked) * 0.5
  ) {
    return "top"
  } else if (grades.liked > 0 && grades.neutral > 0) {
    return "local"
  }
  return "unset"
}

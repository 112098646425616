import { ChangeEvent, Dispatch, SetStateAction } from "react"

export function handleChange<T>(
  event: ChangeEvent<HTMLInputElement>,
  setter: Dispatch<SetStateAction<T>>
) {
  const { name, value, type, checked } = event.target
  setter((state: T) => ({
    ...state,
    [name]: type === "checkbox" ? checked : (value ? value : null),
  }))
}
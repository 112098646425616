import classNames from 'classnames';
import { CardSortingAdmin } from "../../project/modules";
import { Banner } from '../../project/system/components/Banner/Banner';
import { useAppSelector } from '../../store/store';
import styles from './CardSortingAdminPageStyles.module.css';

export const CardSortingAdminPage = () => {
    const isMobile = useAppSelector(state => state.appReducer.isMobile)
    return (
        <div className={classNames(styles.cardSortingAdminPage, isMobile && styles.mobile)}>
            <Banner />
            <CardSortingAdmin isMobile={isMobile}/>
        </div>
    );
};
import React, {FC} from 'react';

interface MobileArrowIconProps {
    onClick?: () => void
    className?: string
}

export const MobileArrowIcon: FC<MobileArrowIconProps> = (props) => {
    const {onClick, className } = props

    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            className={className}
            onClick={onClick}
        >
            <path d="M16.25 6.53125L10 12.4688L3.75 6.53125" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
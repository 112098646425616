// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardCardStyles_dashboardCard__B00Tv {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: flex-start;\n  padding: 0.8rem 1rem;\n  border-radius: 0.5rem;\n  border: 0.0625rem solid #D9D9D9;\n  background-color: white;\n}\n\n.DashboardCardStyles_dashboardCard__B00Tv > p {\n  color: #000;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.0175rem;\n  padding-left: 0.5rem;\n  cursor: default;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/DashboardCard/DashboardCardStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,2BAA2B;EAC3B,oBAAoB;EACpB,qBAAqB;EACrB,+BAA+B;EAC/B,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":[".dashboardCard {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: flex-start;\n  padding: 0.8rem 1rem;\n  border-radius: 0.5rem;\n  border: 0.0625rem solid #D9D9D9;\n  background-color: white;\n}\n\n.dashboardCard > p {\n  color: #000;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.0175rem;\n  padding-left: 0.5rem;\n  cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardCard": "DashboardCardStyles_dashboardCard__B00Tv"
};
export default ___CSS_LOADER_EXPORT___;

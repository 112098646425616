import {Translates} from "./translates";
import React from "react";

export const enTranslates: Translates = {
    gotItButton: 'Got it!',
    confirmButton: 'Yes',
    cancelButton: 'No',
    confirmEmailButton: 'Continue',
    cancelEmailButton: 'Cancel',
    alertSubmitText: 'Okay',
    cancel: 'Cancel',
    alertEmailsSentText: 'Invitations has been sent!',
    alertStageChangedText: 'The second stage has been started!',
    emailImageLabel: 'All project participants will receive this email:',
    inputEmailCustomTextLabel: 'You may alter the text or leave it as it is:',
    inputEmailCustomTextPlaceholder: 'Text...',
    userManager: {
        userSettingsTitle: 'User settings',
        confirmSaveUserSettings: 'Are you sure you want to save the selected projects and data for the user?',
        email: 'Login',
        name: 'Name',
        isAdmin: 'Is user an admin',
        userProjects: 'Projects in which user participates:',
        admin: 'admin',
        admins: 'Administrators',
        userManagementTitle: 'User management',
        createUserError: 'Current user already created',
        confirmDeleteUserMessage: 'Are you sure you want to delete this user?',
        deleteUserHint: 'Delete user',
        notStartedStatus: 'not started',
        openSettingsHint: 'Open user settings',
        refreshUserDataHint: 'Refresh user data',
        addUserLabel: 'Enter the new user\'s login',
        addUserPlaceholder: 'Add a new user',
        addUserButton: 'Add user',
        addUsersControl: 'Add multiple users',
        addUsersControlLabel: 'Enter each user\'s email on a new line',
        notAuthStatus: 'Not authorized',
        downloadXLSX: 'Download results in xlsx',
        downloadAuthLinks: 'Download the list of links for authorization',
        searchByNameOrLogin: 'Search by name or login',
        statusFilter: 'Status filters:',
        projectFilter: 'Project filters:',
        filter: 'Filters',
        clickToCopyLinkHint: 'Click to copy the authorization link for this project to the clipboard',
        clickToCopyAdminLinkHint: 'Click to copy admin authorization link',
        adminLink: 'Admin link',
        you: 'You',
        adminUserSortingStatuses: {
            admins: 'Admins',
            projectNotOpened: 'Project not opened',
            collectingCards: 'Collecting cards',
            waitingForSorting: 'Waiting for sorting',
            sortingNotStarted: 'Sorting not started',
            sortingStarted: 'Sorting',
            doingSort: 'Doing sort',
            doingQuadrantSort: 'Doing criterial sort',
            finishedQuadrantSort: 'Finished criterial sort',
            sortingFinished: 'Sorting finished',
            projectSettingsUnset: 'Settings unset'
        },
        linkCopied: 'Link copied',
    },
    sortingSettings: {
        errors: {
            emptyQuestion: 'You have not entered a question',
            emptyMinCardsPerQuestion: 'You have not entered the minimum number of cards',
            emptyMaxCardsPerQuestion: 'You have not entered the maximum number of cards',
            emptyLikeHint: 'You have not entered a hint for the "like" button',
            emptyDislikeHint: 'You have not entered a hint for the "dislike" button',
            emptyNeutralGradeHint: 'You have not entered a hint for the "neutral grade" button',
            emptyLikeAllHint: 'You have not entered a hint for the "like all" button',
            emptyDislikeAllHint: 'You have not entered a hint for the "dislike all" button',
            emptyInstructionTitle: 'You have not entered an instruction title',
            emptyInstructionText: 'You have not entered the instruction text',
            emptySortingInstructionTitle: 'You have not entered an sorting instruction title',
            emptySortingInstructionText: 'You have not entered the sorting instruction text',
            emptyLastStepInstructionText: 'You have not entered the last step sorting instruction text'
        },
        savedSuccess: 'Your changes have been saved',
        defaultEmptyLastStepInstructionText: '<span style="font-size: 0.7rem; font-weight: bold;">Upvote</span> pressing issues, <span style="font-size: 0.7rem; font-weight: bold;">downvote</span> least pressing matters; <span style="font-size: 0.7rem; font-weight: bold;">dot</span> for medium importance or if uncertain. If there are progress bars with thumbs up and down on top of the screen, they indicate minimum votes of each side you should give.',
        cardTrashIconHint: 'Delete card',
        copyCardIconHint: 'Copy card',
        cardEditorConfirmDelete: 'Are you sure you want to delete this card?',
        cardEditorConfirmSave: 'Danger of data loss! Some users are currently responding to the survey. If you save the pre-moderation results now, their responses may be lost. Save anyway?',
        cardEditorHeader: 'Pre-moderation of answers',
        cardEditorPlaceholder: 'No cards created',
        pressNameToSetUp: 'Click on the project name to set up',
        projectsHeader: 'Projects',
        passSort: 'Pass the survey by yourself',
        confirmStartSorting: 'Are you sure you want to proceed to the next step?',
        labelStartSorting: 'After switching the stage, it will not be possible to go back to the stage of collecting ideas.',
        confirmSendEmails: 'Are you sure you want to send the invitation letters?',
        confirmDeleteProject: 'Are you sure you want to delete this project?',
        inputProjectLabel: 'Enter the new project\'s name',
        inputProjectPlaceholder: 'Enter name here...',
        addButton: 'Add',
        deleteProjectHint: 'Delete project',
        sendEmailsHint: 'Send invitations',
        startSortingButton: 'Start sorting stage',
        preModerationButton: 'Pre-moderation of answers',
        seeResultsButton: 'See the results',
        shareResultsButton: 'Share the results',
        status: 'Status: ',
        settingsTitle: 'Project settings',
        settingsSaved: 'Project settings saved',
        cardSettingsDividerCollect: 'Collecting ideas settings',
        cardSettingsDividerSort: 'Sorting settings',
        inputQuestionLabel: 'Question text',
        inputQuestionPlaceholder: 'Enter question text...',
        inputMinCardsLabel: 'Minimum number of cards',
        inputMaxCardsLabel: 'Maximum number of cards',
        inputAlertWhenReadyCollect: 'Notify when ... % are done collecting',
        inputAlertWhenReadySort: 'Notify when ... are done sorting',
        inputLikeHintLabel: 'Like button hint',
        inputLikeHintPlaceholder: 'Enter like button hint...',
        inputDislikeHintLabel: 'Dislike button hint',
        inputDislikeHintPlaceholder: 'Enter dislike button hint...',
        inputLikeLimiterHintLabel: 'Limit on the number of likes in percentage (leave 0 if you don’t want to set a limit)',
        inputLikeLimiterPlaceholder: 'Enter limit on the number of likes...',
        inputNeutralGradeHintLabel: 'Neutral grade button hint',
        inputNeutralGradeHintPlaceholder: 'Enter neutral grade button hint...',
        inputDislikeLimiterHintLabel: 'Limit on the number of dislikes in percentage (leave 0 if you don’t want to set a limit)',
        inputDislikeLimiterPlaceholder: 'Enter limit on the number of dislikes...',
        inputInstructionTitlePlaceholder: 'Enter instruction title...',
        inputInstructionTitleLabel: 'Instruction title',
        inputInstructionTextLabel: 'Instruction text',
        inputInstructionTextPlaceholder: 'Enter Instruction text (use of <br> tag is allowed)',
        inputSortingInstructionTitleLabel: 'Sorting instruction title',
        inputSortingInstructionTitlePlaceholder: 'Enter sorting instruction title...',
        inputSortingInstructionTextLabel: 'Sorting instruction text',
        inputSortingInstructionTextPlaceholder: 'Enter sorting instruction text (use of <br> tag is allowed)',
        inputMobileSortingInstructionTextLabel: 'Mobile sorting instruction text',
        inputMobileSortingInstructionTextPlaceholder: 'Enter mobile sorting instruction text (use of <br> tag is allowed)',
        inputLastStepSortingInstructionTextLabel: 'Last step sorting instruction text',
        inputLastStepSortingInstructionTextPlaceholder: 'Enter last step sorting instruction text (use of HTML tags is allowed)',
        saveButton: 'Save'
    }
}
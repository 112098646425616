import React, {ReactNode, useEffect, useRef, useState} from 'react';

import styles from './MobileModalStyles.module.css'
import {Portal} from "../../../service";

interface MobileModalProps {
    onClose: () => void;
    children: ReactNode
    isCollapsible?: boolean
}

let startY = 0
const animationDurationMS = 300

export const MobileModal: React.FC<MobileModalProps> = (props) => {
    const {
        onClose,
        children,
        isCollapsible = false
    } = props

    const [isTransition, setIsTransition] = useState(true)
    const [backgroundColor, setBackgroundColor] = useState('rgba(0, 0, 0, 0)')
    const [currentY, setCurrentY] = useState(window.innerHeight)
    const modalRef = useRef<HTMLDivElement>(null)

    const closeWithAnimation = () => {
        setCurrentY((window.innerHeight - startY) + 30 + startY)
        setBackgroundColor('rgba(0, 0, 0, 0)')
        setTimeout(onClose, animationDurationMS)
    }

    useEffect(() => {
        setCurrentY(0)
        setBackgroundColor('rgba(0, 0, 0, 0.4)')
    }, [])

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsTransition(false)
        startY = e.touches[0].clientY
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        setCurrentY(e.touches[0].clientY)
    };

    const handleTouchEnd = () => {
        setIsTransition(true)
        if (currentY - startY > 40) {
            closeWithAnimation()
        } else {
            setCurrentY(0)
        }
    };

    const handleTouchAway = () => {
        if (modalRef.current) {
            startY = modalRef.current.clientTop
            closeWithAnimation()
        }
    }

    const modalStyles: React.CSSProperties = {
        bottom: startY < currentY ? startY - currentY : 0,
        transition: isTransition ? `bottom ${animationDurationMS}ms` : 'unset'
    };

    return (
        <Portal>
            <div
                className={styles.modalContainer}
                style={{
                    transition: `background-color ${animationDurationMS}ms`,
                    backgroundColor
                }}
                onClick={handleTouchAway}
            >
                <div className={styles.modalWindow} style={modalStyles} ref={modalRef} onClick={(e) => e.stopPropagation()}>
                    {isCollapsible && <div
                        className={styles.modalHeader}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div className={styles.modalHandle} />
                    </div>}
                    {children}
                </div>
            </div>
        </Portal>
    );
};
import { Pages, Route } from "../common/types"
import { SuperadminDashboardGroupsPage } from "../pages/SuperAdminPage/SuperadminDashboardGroupsPage"
import { SuperadminDashboardsPage } from "../pages/SuperAdminPage/SuperadminDashboardsPage"
import { SuperadminModerationPage } from "../pages/SuperAdminPage/SuperadminModerationPage"
import { SuperadminPage } from "../pages/SuperAdminPage/SuperadminPage"

export const superadminRoutes: Route[] = [
  {
    path: Pages.superadmin_page,
    element: SuperadminPage
  },
  {
    path: Pages.superadmin_management,
    element: SuperadminModerationPage
  },
  {
    path: Pages.superadmin_dashboards,
    element: SuperadminDashboardsPage
  },
  {
    path: Pages.superadmin_groups,
    element: SuperadminDashboardGroupsPage
  },
]

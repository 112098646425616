import classNames from "classnames"
import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useState
} from "react"
import { useToggle } from "../../../../../common/hooks"
import { AlertModal } from "../../../../system/components"
import { Portal } from "../../../../system/service"
import { Button, Checkbox, Input, LargeModal } from "../../../../system/UX"
import { CrossIcon } from "../../CardSorting/components/icons"
import styles from "../CardSortingAdminStyles.module.css"
import { getProjectSettings, saveProjectSettings } from "../http"
import { useTranslate } from "../translates/useTranslate"
import {
  Axis,
  ProjectInfo,
  ProjectSettingsIn,
  ProjectStatus,
  QuadrantSortAreas,
  QuadrantSortInfoState
} from "../types"
import { QuadrantSortControl } from "./QuadrantSortControl"
import { TemplateCardsControl } from "./TemplateCardsControl"
import { useAppSelector } from "../../../../../store/store"
import { UserRole } from "../../../../../store/appReducer/appReducerTypes"

interface CardSortingSettingsProps {
  projectId: number
  onClose: () => void
  setProjectList: Dispatch<SetStateAction<ProjectInfo[]>>
  isMobile: boolean
  projectStatus?: ProjectStatus
}

export const CardSortingSettings: FC<CardSortingSettingsProps> = props => {
  const translates = useTranslate()
  const { projectId, onClose, isMobile, projectStatus } = props
  const user_role = useAppSelector(state => state.appReducer.user?.user_role)
  const isPending = useToggle(false)
  const [alertMessage, setAlertMessage] = useState("")

  const errorHandler = (projectSettings: ProjectSettingsIn) => {
    if (!projectSettings.question) return translates.sortingSettings.errors.emptyQuestion
    if (!projectSettings.min_cards_per_question)
      return translates.sortingSettings.errors.emptyMinCardsPerQuestion
    if (!projectSettings.max_cards_per_question)
      return translates.sortingSettings.errors.emptyMaxCardsPerQuestion
    if (!projectSettings.instruction_title)
      return translates.sortingSettings.errors.emptyInstructionTitle
    if (!projectSettings.instruction_text)
      return translates.sortingSettings.errors.emptyInstructionText
    if (!projectSettings.sorting_instruction_title)
      return translates.sortingSettings.errors.emptySortingInstructionTitle
    if (!projectSettings.sorting_instruction_text)
      return translates.sortingSettings.errors.emptySortingInstructionText
    if (!projectSettings.like_hint) return translates.sortingSettings.errors.emptyLikeHint
    if (!projectSettings.neutral_grade_hint)
      return translates.sortingSettings.errors.emptyNeutralGradeHint
    if (!projectSettings.dislike_hint) return translates.sortingSettings.errors.emptyDislikeHint
    if (!projectSettings.last_step_instruction_text)
      return translates.sortingSettings.errors.emptyLastStepInstructionText
    if (useTemplateCards) return templateCardsErrorHandler(cardTemplateFields)
    if (quadrantSort)
      return quadrantSortErrorHandler(quadrantSortInfo, quadrantSortAreas, quadrantSortGroups)
    return ""
  }

  // TODO транслейты
  function templateCardsErrorHandler(cardTemplateFields: string[]) {
    if (cardTemplateFields.filter(field => field !== "").length === 0)
      return "Заполните хотя бы одно поле шаблонной карточки"
  }

  // TODO транслейты
  function quadrantSortErrorHandler(
    quadrantSortInfo: QuadrantSortInfoState,
    quadrantSortAreas: QuadrantSortAreas,
    quadrantSortGroups: string[]
  ) {
    if (!quadrantSortInfo.last_step_instruction_text) return "!last_step_instruction_text"
    if (!quadrantSortInfo.mobile_sorting_instruction_text)
      return "!mobile_sorting_instruction_text"
    if (!quadrantSortInfo.sorting_instruction_text) return "!sorting_instruction_text"
    if (!quadrantSortInfo.sorting_instruction_title) return "!sorting_instruction_title"
    for (let key in quadrantSortAreas) {
      // @ts-ignore
      if (quadrantSortAreas[key as Axis].includes("")) {
        return "Заполните все поля quadrant_sorting_areas"
      }
    }
    if (quadrantSortGroups.includes("")) return "Заполните все поля quadrant_sorting_groups"
  }

  const [useTemplateCards, setUseTemplateCards] = useState(false)
  // TODO use translates
  const [cardTemplateFields, setCardTemplateFields] = useState<string[]>([
    "Что:",
    "Как:",
    "Эффект:"
  ])

  // TODO use translates
  const [quadrantSort, setQuadrantSort] = useState(true)
  const [quadrantSortAreas, setQuadrantSortAreas] = useState<QuadrantSortAreas>({
    X: ["Высокая стоимость", "Средняя стоимость", "Низкая стоимость"],
    Y: ["Сложно", "Выполнимо", "Просто"]
  })
  // TODO 9
  const [quadrantSortGroups, setQuadrantSortGroups] = useState<string[]>(new Array(9).fill(""))

  const [quadrantSortInfo, setQuadrantSortInfo] = useState<QuadrantSortInfoState>({
    sorting_instruction_text: "",
    last_step_instruction_text: "",
    mobile_sorting_instruction_text: "",
    sorting_instruction_title: ""
  })

  const [projectSettings, setProjectSettings] = useState<ProjectSettingsIn>({
    question: "",
    max_cards_per_question: "",
    min_cards_per_question: "",
    instruction_text: "",
    instruction_title: "",
    last_step_instruction_text: translates.sortingSettings.defaultEmptyLastStepInstructionText,
    sorting_instruction_title: "",
    mobile_sorting_instruction_text: "",
    sorting_instruction_text: "",
    like_hint: "",
    dislike_hint: "",
    neutral_grade_hint: "",
    like_limiter: "0",
    dislike_limiter: "0",
    min_sorted_number: "3",
    min_collected_percent: "70"
  })

  const getGroupNames = (quadrantSortAreas: QuadrantSortAreas) => {
    const groupNames: string[] = []
    if (quadrantSortAreas.Y) {
      quadrantSortAreas.Y.forEach(y_name => {
        quadrantSortAreas.X.forEach(x_name => {
          groupNames.push(`${x_name}, ${y_name.toLocaleLowerCase()}`)
        })
      })
    } else {
      quadrantSortAreas.X.forEach(x_name => groupNames.push(x_name))
    }

    quadrantSortAreas.X.forEach(x_name => {
      if (quadrantSortAreas.Y) {
        quadrantSortAreas.Y.forEach(y_name => {
          groupNames.push(`${x_name}, ${y_name.toLocaleLowerCase()}`)
        })
      } else {
        groupNames.push(x_name)
      }
    })
    return groupNames
  }

  useEffect(() => {
    setQuadrantSortGroups(getGroupNames(quadrantSortAreas))
    getProjectSettings(projectId).then(data => {
      if (data) {
        setProjectSettings(data)
        if (data.card_template) {
          setUseTemplateCards(true)
          setCardTemplateFields(data.card_template)
        }
        if (data.quadrant_sort_info) {
          setQuadrantSort(true)
          setQuadrantSortInfo(data.quadrant_sort_info)
          setQuadrantSortAreas(data.quadrant_sort_info.areas)
          setQuadrantSortGroups(data.quadrant_sort_info.groups)
        }
      }
    })
  }, [projectId])

  function processTemplateFields(templateFields: string[]) {
    return templateFields
      .filter(field => field !== "")
      .map(field => {
        return /:$/.test(field) ? field : field + ":"
      })
  }

  const submit: FormEventHandler = e => {
    e.preventDefault()
    const error = errorHandler(projectSettings)
    if (error) return setAlertMessage(error)

    isPending.toggleValue()
    saveProjectSettings({
      ...projectSettings,
      id: Number(projectId),
      ...{
        card_template: useTemplateCards ? processTemplateFields(cardTemplateFields) : undefined
      },
      ...{
        quadrant_sort_info: quadrantSort
          ? { ...quadrantSortInfo, areas: quadrantSortAreas, groups: quadrantSortGroups }
          : undefined
      }
    }).then(() => {
      isPending.toggleValue()
      setAlertMessage(translates.sortingSettings.settingsSaved)
      props.setProjectList(projects =>
        projects.map(project => {
          if (project.id === props.projectId) {
            return {
              ...project,
              status: ProjectStatus.SORTING_NOT_STARTED
            }
          }

          return project
        })
      )
    })
  }

  function addCardTemplateField() {
    // TODO вынести константу или оставить возможность добавлять сколько угодно?
    if (cardTemplateFields.length < 3) {
      setCardTemplateFields(prevFields => [...prevFields, ""])
    }
  }

  function removeCardTemplateField() {
    if (cardTemplateFields.length > 1) {
      setCardTemplateFields(prevFields => prevFields.slice(0, -1))
    }
  }

  function handleCardTemplateFieldChange(event: ChangeEvent<HTMLInputElement>, index: number) {
    setCardTemplateFields(prevFields =>
      prevFields.map((field, targetIndex) => {
        return targetIndex === index ? event.target.value : field
      })
    )
  }

  function handleQuadrantSortAreaNameChange(
    event: ChangeEvent<HTMLInputElement>,
    axis: Axis,
    index: number
  ) {
    setQuadrantSortAreas(prevFields => {
      const updatedFields = { ...prevFields }
      if (!updatedFields[axis]) {
        // TODO убрать хадрдкод когда подниму стейт (сейчас эта строка вообще не должна срабатывать)
        updatedFields[axis] = new Array(3).fill("")
      }
      // TODO ???????? updatedFields[axis] Object is possibly 'undefined'
      // @ts-ignore
      updatedFields[axis][index] = event.target.value
      setQuadrantSortGroups(getGroupNames(updatedFields))
      return updatedFields
    })
  }

  function handleChangeGroupName(event: ChangeEvent<HTMLInputElement>, index: number) {
    setQuadrantSortGroups(prevFields =>
      prevFields.map((field, targetIndex) => (targetIndex === index ? event.target.value : field))
    )
  }

  return (
    <Portal>
      <LargeModal>
        <form
          className={classNames(
            styles.sortingSettingsContainer,
            isMobile && styles.mobileManagementContainer
          )}
          onSubmit={submit}
        >
          {alertMessage && (
            <AlertModal
              submitText={translates.gotItButton}
              onSubmit={() => {
                if (alertMessage === translates.sortingSettings.settingsSaved) {
                  onClose()
                }

                setAlertMessage("")
              }}
            >
              {alertMessage}
            </AlertModal>
          )}
          <h3 className={styles.sortSettingsHeader}>{translates.sortingSettings.settingsTitle}</h3>
          <div className={styles.closeSettings} onClick={onClose}>
            <CrossIcon />
          </div>
          <div className={styles.projectSettingsBlock}>
            <p className={styles.cardSettingsDividerText}>
              {translates.sortingSettings.cardSettingsDividerCollect}
            </p>
            <Input
              label={translates.sortingSettings.inputQuestionLabel}
              placeholder={translates.sortingSettings.inputQuestionPlaceholder}
              value={projectSettings.question}
              onChange={e => setProjectSettings({ ...projectSettings, question: e.target.value })}
              containerClassName={styles.settingsInputContainer}
              inputClassName={styles.questionAreaInput}
              isTextareaView={true}
            />
            {/* TODO use translates */}
            <div
              className={classNames(
                styles.checkboxField,
                // (projectStatus === ProjectStatus.SORTING_STARTED ||
                //   user_role !== UserRole.SUPERADMIN) &&
                  styles.checkboxFieldDisabled
              )}
              onClick={() => {
                // user_role === UserRole.SUPERADMIN && setQuadrantSort(!quadrantSort)
              }}
            >
              <Checkbox isChecked={quadrantSort} />
              <p>Сортировка с заданными группами</p>
            </div>
            <QuadrantSortControl
              hidden={!quadrantSort || (
                projectStatus === ProjectStatus.SORTING_STARTED && user_role !== UserRole.SUPERADMIN
              )}
              onChangeAreaName={handleQuadrantSortAreaNameChange}
              onChangeInfo={setQuadrantSortInfo}
              onChangeGroupNames={handleChangeGroupName}
              info={quadrantSortInfo}
              areas={quadrantSortAreas}
              groups={quadrantSortGroups}
            />
            <div
              className={classNames(
                styles.checkboxField,
                !(
                  projectStatus !== ProjectStatus.SORTING_STARTED &&
                  (projectStatus === ProjectStatus.UNSET || user_role === UserRole.SUPERADMIN)
                ) && styles.checkboxFieldDisabled
              )}
              onClick={() =>
                (projectStatus === ProjectStatus.UNSET || user_role === UserRole.SUPERADMIN) &&
                !(projectStatus === ProjectStatus.SORTING_STARTED) &&
                setUseTemplateCards(!useTemplateCards)
              }
            >
              <Checkbox isChecked={useTemplateCards} />
              <p>Использовать шаблонные карточки</p>
            </div>
            <TemplateCardsControl
              hidden={projectStatus === ProjectStatus.SORTING_STARTED || !useTemplateCards}
              fields={cardTemplateFields}
              addField={addCardTemplateField}
              removeField={removeCardTemplateField}
              onChange={
                projectStatus === ProjectStatus.UNSET || user_role === UserRole.SUPERADMIN
                  ? handleCardTemplateFieldChange
                  : undefined
              }
            />
            <Input
              label={translates.sortingSettings.inputMinCardsLabel}
              type="number"
              value={projectSettings.min_cards_per_question}
              onChange={e =>
                e.target.value.length < 3 &&
                setProjectSettings({
                  ...projectSettings,
                  min_cards_per_question: e.target.value
                })
              }
              inputClassName={styles.counterInput}
            />
            <Input
              label={translates.sortingSettings.inputMaxCardsLabel}
              type="number"
              value={projectSettings.max_cards_per_question}
              onChange={e =>
                e.target.value.length < 3 &&
                setProjectSettings({
                  ...projectSettings,
                  max_cards_per_question: e.target.value
                })
              }
              inputClassName={styles.counterInput}
            />
            <Input
              label={translates.sortingSettings.inputAlertWhenReadyCollect}
              type="number"
              value={projectSettings.min_collected_percent}
              onChange={e =>
                Number(e.target.value) <= 100 &&
                Number(e.target.value) > 0 &&
                setProjectSettings({
                  ...projectSettings,
                  min_collected_percent: e.target.value
                })
              }
              inputClassName={styles.counterInput}
            />
            <Input
              label={translates.sortingSettings.inputAlertWhenReadySort}
              type="number"
              value={projectSettings.min_sorted_number}
              onChange={e =>
                Number(e.target.value) >= 3 &&
                setProjectSettings({
                  ...projectSettings,
                  min_sorted_number: e.target.value
                })
              }
              inputClassName={styles.counterInput}
            />
            <p className={styles.cardSettingsDividerText}>
              {translates.sortingSettings.cardSettingsDividerSort}
            </p>
            <Input
              label={translates.sortingSettings.inputLikeLimiterHintLabel}
              type="number"
              placeholder={translates.sortingSettings.inputLikeLimiterPlaceholder}
              value={projectSettings.like_limiter}
              onChange={e =>
                setProjectSettings({ ...projectSettings, like_limiter: e.target.value })
              }
              inputClassName={styles.counterInput}
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputDislikeLimiterHintLabel}
              type="number"
              placeholder={translates.sortingSettings.inputDislikeLimiterPlaceholder}
              value={projectSettings.dislike_limiter}
              onChange={e =>
                setProjectSettings({ ...projectSettings, dislike_limiter: e.target.value })
              }
              inputClassName={styles.counterInput}
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputLikeHintLabel}
              placeholder={translates.sortingSettings.inputLikeHintPlaceholder}
              value={projectSettings.like_hint}
              onChange={e => setProjectSettings({ ...projectSettings, like_hint: e.target.value })}
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputNeutralGradeHintLabel}
              placeholder={translates.sortingSettings.inputNeutralGradeHintPlaceholder}
              value={projectSettings.neutral_grade_hint}
              onChange={e =>
                setProjectSettings({ ...projectSettings, neutral_grade_hint: e.target.value })
              }
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputDislikeHintLabel}
              placeholder={translates.sortingSettings.inputDislikeHintPlaceholder}
              value={projectSettings.dislike_hint}
              onChange={e =>
                setProjectSettings({ ...projectSettings, dislike_hint: e.target.value })
              }
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputInstructionTitleLabel}
              placeholder={translates.sortingSettings.inputInstructionTitlePlaceholder}
              value={projectSettings.instruction_title}
              onChange={e =>
                setProjectSettings({ ...projectSettings, instruction_title: e.target.value })
              }
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputInstructionTextLabel}
              placeholder={translates.sortingSettings.inputInstructionTextPlaceholder}
              value={projectSettings.instruction_text}
              onChange={e =>
                setProjectSettings({ ...projectSettings, instruction_text: e.target.value })
              }
              containerClassName={styles.settingsInputContainer}
              inputClassName={styles.textAreaInput}
              isTextareaView={true}
            />
            <Input
              label={translates.sortingSettings.inputSortingInstructionTitleLabel}
              placeholder={translates.sortingSettings.inputSortingInstructionTitlePlaceholder}
              value={projectSettings.sorting_instruction_title}
              onChange={e =>
                setProjectSettings({
                  ...projectSettings,
                  sorting_instruction_title: e.target.value
                })
              }
              containerClassName={styles.settingsInputContainer}
            />
            <Input
              label={translates.sortingSettings.inputSortingInstructionTextLabel}
              placeholder={translates.sortingSettings.inputSortingInstructionTextPlaceholder}
              value={projectSettings.sorting_instruction_text}
              onChange={e =>
                setProjectSettings({
                  ...projectSettings,
                  sorting_instruction_text: e.target.value
                })
              }
              containerClassName={styles.settingsInputContainer}
              inputClassName={styles.textAreaInput}
              isTextareaView={true}
            />
            <Input
              label={translates.sortingSettings.inputMobileSortingInstructionTextLabel}
              placeholder={translates.sortingSettings.inputMobileSortingInstructionTextPlaceholder}
              value={projectSettings.mobile_sorting_instruction_text}
              onChange={e =>
                setProjectSettings({
                  ...projectSettings,
                  mobile_sorting_instruction_text: e.target.value
                })
              }
              containerClassName={styles.settingsInputContainer}
              inputClassName={styles.textAreaInput}
              isTextareaView={true}
            />
            <Input
              label={translates.sortingSettings.inputLastStepSortingInstructionTextLabel}
              placeholder={
                translates.sortingSettings.inputLastStepSortingInstructionTextPlaceholder
              }
              value={projectSettings.last_step_instruction_text}
              onChange={e =>
                setProjectSettings({
                  ...projectSettings,
                  last_step_instruction_text: e.target.value
                })
              }
              containerClassName={styles.settingsInputContainer}
              inputClassName={styles.textAreaInput}
              isTextareaView={true}
            />
          </div>
          <Button type="submit" isPending={isPending.state}>
            {translates.sortingSettings.saveButton}
          </Button>
        </form>
      </LargeModal>
    </Portal>
  )
}

import { MouseEvent, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { ContextType } from "../../../../pages/DashboardPage/types/types"
import { MouseHint } from "../MouseHint"
import styles from "./UserPicStyles.module.css"

interface UserPicProps {
  userId: number
  showName?: boolean
}

// TODO styles (for gap, pic size, opacity)
// TODO переименовать
export function UserPic(props: UserPicProps) {
  const { userId, showName } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLImageElement> | null>()
  const { userMap } = useOutletContext<ContextType>()

  const user = useMemo(() => userMap.get(userId), [userId])

  return (
    <>
      {!showName && hintEvent && (
        <MouseHint event={hintEvent}>{user?.name || "Anonymous"}</MouseHint>
      )}

      {user && (
        <div className={styles.rolesTableUserPicName}>
          <img
            alt="User"
            src={`${process.env.PUBLIC_URL}/userpics/${user.pic || "default.png"}`}
            className={styles.rolesTableUserPic}
            onMouseMove={event => setHintEvent(event)}
            onMouseLeave={() => setHintEvent(null)}
          />
          {showName && <p>{user?.name || "Anonymous"}</p>}
        </div>
      )}
    </>
  )
}

import { Dispatch, FC, FormEventHandler, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pages } from "../../common/types";
import { copyToClipboard } from "../../common/utils";
import { Hyperlink } from "../../project/system/UX/Link";
import { ConfirmModal } from "../../project/system/components";
import { UserRole } from "../../store/appReducer/appReducerTypes";
import { createNewClient, createUser, deleteClient, deleteUser, getClientsInfo } from './http';
import { BaseClient, ClientDTO } from './types';



export const SuperadminPage = () => {
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [client, setClient] = useState<BaseClient>({
        client_name: '',
        language: 'EN'
    })
    const [userToDelete, setUserToDelete] = useState<number | null>(null)
    const [clientToDelete, setClientToDelete] = useState<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        getClientsInfo().then(data => setClients(data))
    }, [])

    const createClientHandler: FormEventHandler = (e) => {
        e.preventDefault()
        createNewClient(client).then(() => getClientsInfo().then(data => setClients(data)))
    }

    const deleteClientHandler = () => {
        deleteClient(Number(clientToDelete)).then(() => getClientsInfo().then(data => setClients(data)))
        setClientToDelete(null)
    }

    const deleteUserHandler = () => {
        deleteUser(Number(userToDelete)).then(() => getClientsInfo().then(data => setClients(data)))
        setUserToDelete(null)
    }

    return (
        <div style={{ padding: '3rem' }}>
            {userToDelete && <ConfirmModal
                onConfirm={deleteUserHandler}
                onCancel={() => setUserToDelete(null)}
                confirmText="Да"
                cancelText="Нет"
            >
                Вы действительно хотите удалить пользователя?
            </ConfirmModal>}
            {clientToDelete && <ConfirmModal
                onConfirm={deleteClientHandler}
                onCancel={() => setClientToDelete(null)}
                confirmText="Да"
                cancelText="Нет"
            >
                Вы действительно хотите удалить клиента?
            </ConfirmModal>}
            <h1
                style={{ textDecoration: "underline", color: 'blue', cursor: 'pointer', fontSize: '1.2rem', paddingBottom: '1rem'}}
                onClick={() => navigate(Pages.superadmin_management)}
                >
                    &#9658; Управление черновиками проектов и премодерация
            </h1>
            <h1
                style={{ textDecoration: "underline", color: 'blue', cursor: 'pointer', fontSize: '1.2rem', paddingBottom: '2rem'}}
                onClick={() => navigate(Pages.superadmin_dashboards)}
                >
                    &#9658; Создание и настройка дашбордов
            </h1>
            <form onSubmit={createClientHandler}>
                <p>Добавить клиента</p>
                <input
                    type="text"
                    value={client.client_name}
                    onChange={(e) => setClient({...client, client_name: e.target.value})}
                    placeholder="Название"
                />
                <div onClick={() => setClient({...client, language: 'RU'})}>
                    <input type="radio" name="ru" checked={client.language === 'RU'}/>
                    <label htmlFor="ru">Русский</label>
                </div>
                <div onClick={() => setClient({...client, language: 'EN'})}>
                    <input type="radio" name="en" checked={client.language === 'EN'}/>
                    <label htmlFor="en">English</label>
                </div>
                <button type="submit">сохранить</button>
            </form>
            <h1 style={{ margin: '2rem' }}>Проекты:</h1>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                {clients.map(client => <li key={client.client_id}>
                    <Hyperlink href={`${Pages.admin}/${client.client_id}`} style={{display: "block"}}>
                        {client.client_name}
                    </Hyperlink>
                    <span
                        style={{ textDecoration: "underline", color: 'blue', cursor: 'pointer' }}
                        onClick={() => setClientToDelete(client.client_id)}
                    >
                        Удалить клиента
                    </span>
                    <AddAdminControl client_id={client.client_id} setClients={setClients}/>
                    <p>Админы:</p>
                    <ul>
                        {client.admins.map(admin => <li style={{display: "flex", gap: '1rem'}} key={admin.id}>
                            <p>{admin.email}</p>
                            <p>{admin.name ? admin.name : 'Не авторизован'}</p>
                            <span
                                style={{ textDecoration: "underline", color: 'blue', cursor: 'pointer' }}
                                onClick={() => setUserToDelete(admin.id)}
                            >
                                Удалить админа
                            </span>
                            <span
                                style={{ textDecoration: "underline", color: 'blue', cursor: 'pointer' }}
                                onClick={() => copyToClipboard(`${window.location.origin}${Pages.auth}/${admin.link}`)}
                            >
                                Скопировать авторизационную ссылку в буфер обмена
                            </span>
                        </li>)}
                    </ul>
                </li>)}
            </ul>
        </div>
    );
};

export const AddAdminControl:FC<{ client_id: number, setClients: Dispatch<SetStateAction<ClientDTO[]>> }> = (props) => {
    const { client_id, setClients } = props
    const [mail, setMail] = useState('')

    const createUserHandler = () => {
        createUser(mail, UserRole.ADMIN, client_id).then(() => getClientsInfo().then(data => setClients(data)))
    }

    return <>
        <p>Добавить админа</p>
        <input type="text" placeholder="email" value={mail} onChange={(e) => setMail(e.target.value)}/>
        <button onClick={createUserHandler}>сохранить</button>
    </>
}
import { useToggleValue } from "../../../../common/hooks"
import { getObjectFromLocalStorage, saveObjectInLocalStorage } from "../../../../common/utils"
import {
  Card,
  CardSortingState,
  CardSortingStateInfo,
  GradeStatus,
  GroupColorsType,
  GroupState,
  LikeStatus,
  SortStage
} from "./reducers/cardSortingReducer/cardSortingReducerTypes"
import { Translates } from "./translates/translates"

export const groupColors: GroupColorsType[] = ["#DAF1FF", "#C5C1F0", "#FFD556"]
export const userGroupColors = [
  "#DAF1FF",
  "#FFD556",
  "#74C5C8",
  "#D2EADD",
  "#C5C1F0",
  "#CEE741",
  "#0CA789",
  "#12CDD4",
  "#FE7D45",
  "#DEF3E9",
]

export const groupHeightTransitionMS = 400
export const movingCardsTransitionMS = 500

export const getGroupColor = (prevColor: GroupColorsType = "#C5C1F0") => {
  const prevColorIndex = groupColors.indexOf(prevColor)
  return prevColorIndex + 1 === groupColors.length
    ? groupColors[0]
    : groupColors[prevColorIndex + 1]
}

export const countRatedCards = (cards: Card[]) => {
  let count = 0

  cards.forEach(card => {
    if (card.grade_status !== "unset") count++
  })

  return count
}

export const refreshLocalSortingState = (state: CardSortingState) => {
  state.group_state.actionCounter += 1
  const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
  saveObjectInLocalStorage(String(state.project_id), {
    ...projectDataObject,
    groupState: { ...state.group_state }
  })
}

export const isSortingEmpty = (state: GroupState) => {
  return state.groups.length === 0 && state.bin.length === 0
}

export function findMissingNumber(arr: number[]): number {
  arr.sort((a, b) => a - b)

  let missingNumber = 1

  if (arr.length === 0) return missingNumber

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] <= missingNumber) {
      missingNumber = arr[i] + 1
    } else {
      return missingNumber
    }
  }

  return arr[arr.length - 1] + 1
}

//TODO: оптимизировать функцию
export const countLikedAndDislikedCards = (groupState: GroupState) => {
  let counters = {
    likeCounter: 0,
    dislikeCounter: 0
  }

  groupState.groups.forEach(group => {
    group.cards.forEach(card => {
      if (card.grade_status === "liked") counters.likeCounter++
      if (card.grade_status === "disliked") counters.dislikeCounter++
    })
  })

  groupState.bank.forEach(card => {
    if (card.grade_status === "liked") counters.likeCounter++
    if (card.grade_status === "disliked") counters.dislikeCounter++
  })

  groupState.bin.forEach(card => {
    if (card.grade_status === "liked") counters.likeCounter++
    if (card.grade_status === "disliked") counters.dislikeCounter++
  })

  return counters
}

interface checkSortingErrorArgs {
  sortStage: SortStage
  errorSetter: (s: string) => void
  groupState: GroupState
  translates: Translates
  info: CardSortingStateInfo
  likeCounter: number
  dislikeCounter: number
  likeModal: useToggleValue
  confirmModal: useToggleValue
}

export const checkSortingErrors = ({
  sortStage,
  errorSetter,
  groupState,
  translates,
  info,
  likeCounter,
  dislikeCounter,
  likeModal,
  confirmModal
}: checkSortingErrorArgs) => {
  if (groupState.bank.length !== 0) {
    return errorSetter(translates.sortingErrors.notAllCardsSorted)
  }

  if (sortStage === SortStage.QUADRANT_SORT) {
    if (info.like_limit !== 0 && info.like_limit > likeCounter) {
      return errorSetter(translates.sortingErrors.notAllLikesGiven)
    }
    if (info.dislike_limit !== 0 && info.dislike_limit > dislikeCounter) {
      return errorSetter(translates.sortingErrors.notAllDislikesGiven)
    }
    if (info.like_limit === 0 && info.dislike_limit === 0) {
      let isAllCardsRated = true
      groupState.groups.forEach(group => {
        group.cards.forEach(card => {
          console.log(card.grade_status)
          if (card.grade_status === "unset") {
            isAllCardsRated = false
          }
        })
      })
      if (!isAllCardsRated) {
        return likeModal.toggleValue()
      }
    }
  }

  for (let i = 0; i < groupState.groups.length; i++) {
    if (groupState.groups[i].name === "")
      return errorSetter(translates.sortingErrors.emptyGroupName)
    if (groupState.groups[i].name === "bin")
      return errorSetter(translates.sortingErrors.binNameUsed)
    if (groupState.groups[i].name === "bank")
      return errorSetter(translates.sortingErrors.bankNameUsed)
  }

  confirmModal.toggleValue()
}

export const setGradeCorrectly = (grade: LikeStatus, currentStatus?: GradeStatus) => {
  if (grade === "liked") {
    return currentStatus === "liked" ? "unset" : "liked"
  } else if (grade === "disliked") {
    return currentStatus === "disliked" ? "unset" : "disliked"
  }
  return currentStatus === "neutral" ? "unset" : "neutral"
}

import React, {FC, useEffect, useState} from 'react';
import {Button, Modal} from "../../../../../system/UX";
import {useTranslate} from "../../translates/useTranslate";

import styles from "../../CardSortingStyles.module.css";
import {useAppSelector} from "../../../../../../store/store";
import {Card} from "../../reducers/cardSortingReducer/cardSortingReducerTypes";
import SimpleBar from "simplebar-react";
import CardInLikeModal from "./CardInLikeModal";
import {useToggle} from "../../../../../../common/hooks";
import {Portal} from "../../../../../system/service";
import {useFindingUngradedCards} from "../../hooks/useFindingUngradedCards";

interface LikesModalProps {
    onConfirm: (isPending: { state: boolean; toggleValue: () => void;}) => void
    onCLose: () => void
}

export const LikesModal:FC<LikesModalProps> = (props) => {
    const { onCLose, onConfirm } = props

    const isPending = useToggle(false)
    const translates = useTranslate()

    const { ungradedCards, isAllCardsGraded } = useFindingUngradedCards()

    return (
        <Portal>
            <Modal>
                <h3>{translates.setLikesRequest}</h3>
                <SimpleBar autoHide={false} className={styles.lakeModalScrollContainer}>
                    <div className={styles.likeModalCardsContainer}>
                        {ungradedCards.map(card => <CardInLikeModal card={card} />)}
                    </div>
                </SimpleBar>
                <div className={styles.likesModalButtonContainer}>
                    <Button onClick={onCLose}>Отмена</Button>
                    <Button
                        onClick={() => onConfirm(isPending)}
                        disabled={!isAllCardsGraded}
                        isPending={isPending.state}
                    >
                        Сохранить
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};
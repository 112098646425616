import classNames from "classnames"
import { FC, MouseEvent, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { MouseHint } from "../../../../../system/components"
import styles from "../../CardSortingStyles.module.css"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import { Card } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../../translates/useTranslate"
import { TrashIcon } from "../icons"
import CardText from "./CardText"
import { templateCardTextHandler } from "../../../../../../common/utils"

export interface CardProps {
  card: Card
  color?: string
}

//TODO: Вынести в переменную текущий rem
const CardInBank: FC<CardProps> = props => {
  const { text, id } = props.card
  const { color } = props

  const dispatch = useAppDispatch()
  const { moveFromBankToBin, togglePickedCard } = cardSortingSlice.actions
  const translates = useTranslate()
  const [isTextHint, setIsTextHint] = useState(false)
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
  const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
  const card_template = useAppSelector(state => state.cardSortingReducer.card_template)
  const textRef = useRef<HTMLParagraphElement>(null)

  const textHintHandler = (e: MouseEvent<HTMLParagraphElement>) => {
    if (textRef.current && (textRef.current.offsetHeight > 60 || card_template)) {
      setHintEvent(e)
      setIsTextHint(true)
    }
  }

  const cardHintTextParts = templateCardTextHandler(text, card_template)

  return (
    <div
      className={classNames(styles.card, styles.cardInBank)}
      style={{
        backgroundColor: pickedCards.cards.includes(id) ? "silver" : "white",
        ...(color && {
          border: 0,
          borderLeft: `0.5rem solid ${color}`,
          outline: "0.0625rem solid black",
          outlineOffset: "-0.0625rem"
        })
      }}
      onClick={() => dispatch(togglePickedCard({ group: "bank", cardId: id }))}
    >
      {hintEvent && (
        <MouseHint event={hintEvent}>
          {isTextHint ? cardHintTextParts.hint : translates.trashMouseHint}
        </MouseHint>
      )}
      <p
        onMouseMove={textHintHandler}
        onMouseLeave={() => setHintEvent(null)}
        className={classNames(styles.cardText, styles.cardInBankText)}
      >
        <span ref={textRef}>
          <CardText>{cardHintTextParts.card}</CardText>
        </span>
      </p>
      <div
        onMouseMove={e => {
          setHintEvent(e)
          setIsTextHint(false)
        }}
        onMouseLeave={() => setHintEvent(null)}
        className={styles.cursorPointer}
        onClick={e => {
          e.stopPropagation()
          dispatch(moveFromBankToBin(id))
        }}
      >
        <TrashIcon className={styles.trashIcon} />
      </div>
    </div>
  )
}

export default CardInBank

import React, {FC, PropsWithChildren} from 'react';
import {isHTML} from "../../../../../../common/utils";

interface CardTextProps {
    children: string
}

export const CardText:FC<PropsWithChildren<CardTextProps>> = ({ children }) => {
    return isHTML(children) ? <span dangerouslySetInnerHTML={{__html: children}}/> : <>{children}</>
};

export default CardText;
export const quadrantGroupColors3x3 = new Map([
  [0, "#F8CBAC"],
  [1, "#F2F2F2"],
  [2, "#FFF2CD"],
  [3, "#F2F2F2"],
  [4, "#D9D9D9"],
  [5, "#F2F2F2"],
  [6, "#FFF2CD"],
  [7, "#F2F2F2"],
  [8, "#C5E0B5"]
])
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileAlertModalStyles_alertModalContent__5VqBf {\n    padding: 6.25rem 2.5rem;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 5.5rem;\n}\n\n.MobileAlertModalStyles_alertText__tNY-o {\n    text-align: center;\n    font-weight: 580;\n}\n\n.MobileAlertModalStyles_buttons__4ablI {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.MobileAlertModalStyles_textButton__zTs\\+c {\n    font-weight: 700;\n}\n\n.MobileAlertModalStyles_submitButton__IJRsI {\n    color: #009D65;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/mobile/MobileAlertConfirmModal/MobileAlertModalStyles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".alertModalContent {\n    padding: 6.25rem 2.5rem;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 5.5rem;\n}\n\n.alertText {\n    text-align: center;\n    font-weight: 580;\n}\n\n.buttons {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.textButton {\n    font-weight: 700;\n}\n\n.submitButton {\n    color: #009D65;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertModalContent": "MobileAlertModalStyles_alertModalContent__5VqBf",
	"alertText": "MobileAlertModalStyles_alertText__tNY-o",
	"buttons": "MobileAlertModalStyles_buttons__4ablI",
	"textButton": "MobileAlertModalStyles_textButton__zTs+c",
	"submitButton": "MobileAlertModalStyles_submitButton__IJRsI"
};
export default ___CSS_LOADER_EXPORT___;

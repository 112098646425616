import { MouseEventHandler } from "react"
import styles from "./RadioButtonStyles.module.css"
import classNames from "classnames"

interface RadioButtonProps {
  CSSclasses?: {
    button?: string
    checked?: string
  }
  name: string
  label?: string
  checked: boolean
  onClick: MouseEventHandler
}

export function RadioButton(props: RadioButtonProps) {
  const { CSSclasses, name, label, checked, onClick } = props

  return (
    <div
      className={classNames(
        styles.radioButton, CSSclasses?.button,
        checked && styles.radioButtonChecked,
        checked && CSSclasses?.checked
      )}
    >
      {label && (
        <label htmlFor={name} onClick={onClick}>
          <p>{label}</p>
        </label>
      )}
      <input type="radio" name={name} />
    </div>
  )
}

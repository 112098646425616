// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendStyles_graphLegendIcon__Rvisv {\n  /* width: 1rem;\n  height: 1rem; */\n  border-radius: 50%;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/components/Legend/LegendStyles.module.css"],"names":[],"mappings":"AAAA;EACE;iBACe;EACf,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".graphLegendIcon {\n  /* width: 1rem;\n  height: 1rem; */\n  border-radius: 50%;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphLegendIcon": "LegendStyles_graphLegendIcon__Rvisv"
};
export default ___CSS_LOADER_EXPORT___;

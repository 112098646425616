import { FC, MouseEvent, useRef, useState } from "react"
import { useTranslate } from "../../translates/useTranslate"

import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { MouseHint } from "../../../../../system/components"
import styles from "../../CardSortingStyles.module.css"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import { SortStage } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { ArrowBackIcon, LikeIcon } from "../icons"
import { CardProps } from "./CardInBank"
import CardText from "./CardText"
import { templateCardTextHandler } from "../../../../../../common/utils"

interface CardInGroupProps extends CardProps {
  groupId: number
  color?: string
}

//TODO: тут тоже вынести rem в хендлере подсказки текста
export const CardInGroup: FC<CardInGroupProps> = props => {
  const { id, text, grade_status } = props.card

  const dispatch = useAppDispatch()
  const { moveFromGroupToBank, togglePickedCard } = cardSortingSlice.actions
  const setGrade = cardSortingSlice.actions.setGrade
  const translates = useTranslate()
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
  const [currentHint, setCurrentHint] = useState("")
  const { like_hint, dislike_hint, neutral_grade_hint } = useAppSelector(
    state => state.cardSortingReducer.info
  )
  const { sortStage, card_template } = useAppSelector(state => state.cardSortingReducer)
  const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
  const textRef = useRef<HTMLParagraphElement>(null)

  const textHintHandler = (e: MouseEvent<HTMLParagraphElement>) => {
    if (textRef.current && (textRef.current.offsetHeight > 25 || card_template)) {
      setHintEvent(e)
      setCurrentHint(cardHintTextParts.hint)
    }
  }

  const cardHintTextParts = templateCardTextHandler(text, card_template)

  return (
    <div
      className={classNames(styles.card, styles.cardInGroup)}
      style={{
        ...(!props.color && {
          backgroundColor: pickedCards.cards.includes(id) ? "silver" : "white"
        }),
        ...(props.color && {
          background: `linear-gradient(to right, ${props.color} 0.5rem, ${
            pickedCards.cards.includes(id) ? "#e7e7e7" : "white"
          } 0.5rem)`,
          padding: "0.5rem 0.5rem 0.5rem 0.7rem"
        })
      }}
      onClick={() => dispatch(togglePickedCard({ group: props.groupId, cardId: id }))}
    >
      {hintEvent && <MouseHint event={hintEvent}>{currentHint}</MouseHint>}
      <div className={styles.cardCommon}>
        <div
          onMouseEnter={() => setCurrentHint(translates.backMouseHint)}
          onMouseMove={e => setHintEvent(e)}
          onMouseLeave={() => setHintEvent(null)}
          className={styles.cursorPointer}
          onClick={e => {
            e.stopPropagation()
            dispatch(moveFromGroupToBank(id))
          }}
        >
          <ArrowBackIcon className={styles.arrowBack} />
        </div>
        <p
          onMouseMove={textHintHandler}
          onMouseLeave={() => setHintEvent(null)}
          className={classNames(styles.cardText, styles.cardInGroupText)}
        >
          <span ref={textRef}>
            <CardText>{cardHintTextParts.card}</CardText>
          </span>
        </p>
      </div>

      {sortStage === SortStage.QUADRANT_SORT && (
        <div className={styles.likeControls} onClick={e => e.stopPropagation()}>
          <div
            onMouseEnter={() => setCurrentHint(like_hint)}
            onMouseMove={e => setHintEvent(e)}
            onMouseLeave={() => setHintEvent(null)}
            className={styles.gradeContainer}
            onClick={() => dispatch(setGrade({ id, grade: "liked" }))}
          >
            <LikeIcon
              className={classNames(
                styles.likeControl,
                grade_status === "liked" && styles.activeLike
              )}
            />
          </div>
          <div
            onMouseEnter={() => setCurrentHint(neutral_grade_hint)}
            onMouseMove={e => setHintEvent(e)}
            onMouseLeave={() => setHintEvent(null)}
            className={classNames(styles.gradeContainer, styles.neutralGradeControlContainer)}
            onClick={() => dispatch(setGrade({ id, grade: "neutral" }))}
          >
            <div
              className={classNames(
                styles.neutralGradeControl,
                grade_status === "neutral" && styles.activeNeutralGrade
              )}
            />
          </div>
          <div
            onMouseEnter={() => setCurrentHint(dislike_hint)}
            onMouseMove={e => setHintEvent(e)}
            onMouseLeave={() => setHintEvent(null)}
            className={styles.gradeContainer}
            onClick={() => dispatch(setGrade({ id, grade: "disliked" }))}
          >
            <LikeIcon
              isDislike={true}
              className={classNames(
                styles.dislikeControl,
                grade_status === "disliked" && styles.activeDislike
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}

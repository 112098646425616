// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RolesUserRowStyles_rolesTableUserRow__yz\\+d9 {\n  display: flex;\n  flex-direction: row;\n  gap: 0.2rem;\n  padding: 0.5rem 0.5rem;\n}\n\n.RolesUserRowStyles_rolesTableUserRowExtraCounter__c3LvL {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 0.5rem;\n}\n\n.RolesUserRowStyles_rolesTableUserRowExtraCounter__c3LvL > p {\n  font-weight: 700;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/Roles/components/RolesUserRow/RolesUserRowStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".rolesTableUserRow {\n  display: flex;\n  flex-direction: row;\n  gap: 0.2rem;\n  padding: 0.5rem 0.5rem;\n}\n\n.rolesTableUserRowExtraCounter {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 0.5rem;\n}\n\n.rolesTableUserRowExtraCounter > p {\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rolesTableUserRow": "RolesUserRowStyles_rolesTableUserRow__yz+d9",
	"rolesTableUserRowExtraCounter": "RolesUserRowStyles_rolesTableUserRowExtraCounter__c3LvL"
};
export default ___CSS_LOADER_EXPORT___;

import { CardSortingAdminPage, CardSortingPage } from "../pages";
import { Pages, Route } from "../common/types";
import { DashboardPage } from "../pages/DashboardPage/DashboardPage";

export const privateRoutes: Route[] = [
  {
    path: `${Pages.main}/:project_id`,
    element: CardSortingPage,
  },
  {
    path: `${Pages.admin}/:client_id`,
    element: CardSortingAdminPage,
  },
  {
    path: `${Pages.dashboard}/:projectId`,
    element: DashboardPage
  },
];

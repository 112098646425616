// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreloaderStyles_circlePreloader__m4QPy {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.PreloaderStyles_circle__hH\\+2c {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    border: 3px solid rgba(0, 0, 0, 0.2);\n    border-top-color: #FFC000;\n    animation: PreloaderStyles_spin__HdXqu 1s ease-in-out infinite;\n}\n\n.PreloaderStyles_fullPageCircle__2L8pV {\n    display: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    /* width: 100vw;\n    height: 100vh; */\n}\n\n@keyframes PreloaderStyles_spin__HdXqu {\n    to {\n        transform: rotate(360deg);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/UX/PreloadBar/PreloaderStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,yBAAyB;IACzB,8DAAuC;AAC3C;;AAEA;IACI,cAAc;IACd,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ;oBACgB;AACpB;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".circlePreloader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.circle {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    border: 3px solid rgba(0, 0, 0, 0.2);\n    border-top-color: #FFC000;\n    animation: spin 1s ease-in-out infinite;\n}\n\n.fullPageCircle {\n    display: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    /* width: 100vw;\n    height: 100vh; */\n}\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circlePreloader": "PreloaderStyles_circlePreloader__m4QPy",
	"circle": "PreloaderStyles_circle__hH+2c",
	"spin": "PreloaderStyles_spin__HdXqu",
	"fullPageCircle": "PreloaderStyles_fullPageCircle__2L8pV"
};
export default ___CSS_LOADER_EXPORT___;

export function FullScreenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 19 19" fill="none">
      <path
        d="M17.5007 17.75L12.7368 12.9861M12.7368 12.9861C14.0262 11.6967 14.7505 9.94796 14.7505 8.12453C14.7505 6.30109 14.0262 4.55235 12.7368 3.26298C11.4474 1.97362 9.69869 1.24927 7.87526 1.24927C6.05183 1.24927 4.30308 1.97362 3.01372 3.26298C1.72436 4.55235 1 6.30109 1 8.12453C1 9.94796 1.72436 11.6967 3.01372 12.9861C4.30308 14.2754 6.05183 14.9998 7.87526 14.9998C9.69869 14.9998 11.4474 14.2754 12.7368 12.9861Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

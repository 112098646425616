export function DoingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="0.25rem" viewBox="0 0 16 4" fill="none">
      <path
        d="M2.75 2C2.75 2.19891 2.67098 2.38968 2.53033 2.53033C2.38968 2.67098 2.19891 2.75 2 2.75C1.80109 2.75 1.61032 2.67098 1.46967 2.53033C1.32902 2.38968 1.25 2.19891 1.25 2C1.25 1.80109 1.32902 1.61032 1.46967 1.46967C1.61032 1.32902 1.80109 1.25 2 1.25C2.19891 1.25 2.38968 1.32902 2.53033 1.46967C2.67098 1.61032 2.75 1.80109 2.75 2ZM8.75 2C8.75 2.19891 8.67098 2.38968 8.53033 2.53033C8.38968 2.67098 8.19891 2.75 8 2.75C7.80109 2.75 7.61032 2.67098 7.46967 2.53033C7.32902 2.38968 7.25 2.19891 7.25 2C7.25 1.80109 7.32902 1.61032 7.46967 1.46967C7.61032 1.32902 7.80109 1.25 8 1.25C8.19891 1.25 8.38968 1.32902 8.53033 1.46967C8.67098 1.61032 8.75 1.80109 8.75 2ZM14.75 2C14.75 2.19891 14.671 2.38968 14.5303 2.53033C14.3897 2.67098 14.1989 2.75 14 2.75C13.8011 2.75 13.6103 2.67098 13.4697 2.53033C13.329 2.38968 13.25 2.19891 13.25 2C13.25 1.80109 13.329 1.61032 13.4697 1.46967C13.6103 1.32902 13.8011 1.25 14 1.25C14.1989 1.25 14.3897 1.32902 14.5303 1.46967C14.671 1.61032 14.75 1.80109 14.75 2Z"
        stroke="#CE9B00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

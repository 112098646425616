import React, {FC} from 'react';

import styles from '../../CardSortingStyles.module.css';
import {Button} from "../../../../../system/UX";

interface HeaderWithSubheaderProps {
    header: string
    subheader: string
}

const HeaderWithSubheader: FC<HeaderWithSubheaderProps> = ({ header, subheader }) => {
    return (
        <div className={styles.headerWithSubheader}>
            <h2 className={styles.sliderHeader}>{header}</h2>
            <h3 className={styles.sliderSubheader}>{subheader}</h3>
        </div>
    );
};

export default HeaderWithSubheader;
const ruConsonants = "бвгдджзклмнпрстфхцчшщ"

export function getAbbreviation(name: string) {
  const words = name.split(" ")
  return words.reduce((accumulator, word) => {
    const lowerCaseWord = word.toLocaleLowerCase()
    const abbreviation = lowerCaseWord
      .slice(1)
      .split("")
      .filter(char => ruConsonants.includes(char))
      .join("")
      .toLocaleUpperCase()
    return (word[0].toLocaleUpperCase() + accumulator + abbreviation).slice(0, 4)
  }, "")
}

// export function getAbbreviation(name: string) {
//   const words = name.split(" ")
//   return words.reduce((accumulator, word) => {
//     const upperCaseWord = word.toLocaleUpperCase()
//     const abbreviation = upperCaseWord.slice(0, Math.min(5, upperCaseWord.length))
//     return accumulator + " " + abbreviation
//   }, "")
// }

import { DashboardNavUrl } from "../pages/DashboardPage/types/types";
import { Language } from "../store/appReducer/appReducerTypes";

export enum Pages {
    main = '/project',
    auth = '/auth',
    admin = '/client_admin',
    card_sorting_admin = '/card_sorting_admin',
    superadmin_page = '/superadmin_page',
    superadmin_management = '/superadmin_page/management',
    superadmin_dashboards = '/superadmin_page/dashboard',
    superadmin_groups = '/superadmin_page/dashboard/:projectId/groups',
    superadmin_graph = '/superadmin_page/dashboard/:projectId/graph',
    dashboard = '/dashboard',
    error = '/error'
}

export interface AppInfoIn {
    language: Language,
}

export enum ErrorMessages {
    SOMETHINGS_WRONG = 'somethings_wrong',
    AUTHORIZATION_FAILED = 'authorization_failed',
    ACCESS_DENIED = 'access_denied',
    NO_ACCESS = 'no_access'
}

export interface Route {
    path: string
    element: () => JSX.Element
}

export interface DashboardRoute {
    path: DashboardNavUrl
    element: () => JSX.Element
}

export enum StatusColors {
    red = "#FF3A3A",
    green = "#02D589",
    gray = "#818181",
    orange = "#FF8A00",
    lightOrange = "#FFC000",
    yellow = "#FFCF3C",
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuadrantGroupTypeIconStyles_QuadrantGroupTypeIcon__KUj7F {\n  width: 1.2rem;\n  height: 1.2rem;\n  border-radius: 0.3rem;\n  flex-shrink: 0;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/CardSorting/components/icons/QuadrantGroupTypeIcon/QuadrantGroupTypeIconStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".QuadrantGroupTypeIcon {\n  width: 1.2rem;\n  height: 1.2rem;\n  border-radius: 0.3rem;\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuadrantGroupTypeIcon": "QuadrantGroupTypeIconStyles_QuadrantGroupTypeIcon__KUj7F"
};
export default ___CSS_LOADER_EXPORT___;

import React, {FC} from 'react';

interface SlideArrowIconProps {
    className?: string
    onClick?: () => void
}

export const SlideArrowIcon: FC<SlideArrowIconProps> = ({ className, onClick }) => {
    return (
        <svg style={{ cursor: 'pointer' }} width="1.2rem" height="1.2rem" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <path d="M22.7778 2L36 15.5M36 15.5L22.7778 29M36 15.5L2 15.5" stroke="#323232" strokeWidth="0.25rem"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
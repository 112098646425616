import { CSSProperties, MouseEventHandler, ReactNode } from "react"
import styles from "./IconButtonStylesTemp.module.css"
import classNames from "classnames"

interface IconButtonProps {
  children: ReactNode
  onClick?: MouseEventHandler
  disabled?: boolean
  className?: string
  label?: string
  style?: CSSProperties
}

export function IconButtonTemp(props: IconButtonProps) {
  const { children, onClick, className, label, style } = props

  return (
    <div onClick={onClick} className={classNames(styles.iconButtonTemp, className)} style={style}>
      {children}
      <p>{label}</p>
    </div>
  )
}

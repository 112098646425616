export function LegendThemeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4.43rem" height="2.375rem" viewBox="0 0 71 38" fill="none">
      <line x1="5.42768" y1="14.4571" x2="10.0637" y2="23.729" stroke="#BABABA" />
      <line x1="7.38377" y1="12.6429" x2="20.5191" y2="14.9609" stroke="#BABABA" />
      <line x1="28.8382" y1="14.1899" x2="56.6541" y2="8.7812" stroke="#BABABA" />
      <line x1="54.7052" y1="28.5434" x2="56.2505" y2="11.5447" stroke="#BABABA" />
      <line x1="31.4172" y1="20.3908" x2="55.3698" y2="28.8902" stroke="#BABABA" />
      <circle cx="12.3184" cy="25.1116" r="5.29499" fill="#AF6AC5" stroke="white" />
      <circle cx="19.661" cy="29.3612" r="6.45398" fill="#AF6AC5" stroke="white" />
      <circle cx="27.3853" cy="17.7712" r="8.77198" fill="#AF6AC5" stroke="white" />
      <circle cx="4.59341" cy="12.7489" r="3.74966" fill="#AF6AC5" stroke="white" />
      <circle cx="59.4536" cy="33.611" r="2.97699" fill="#0070C0" stroke="white" />
      <circle cx="54.4321" cy="28.5886" r="4.90865" fill="#0070C0" stroke="white" />
      <circle cx="65.2485" cy="9.27193" r="4.90865" fill="#0070C0" stroke="white" />
      <circle cx="56.3638" cy="7.34032" r="6.84032" fill="#0070C0" stroke="white" />
    </svg>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileButtonStyles_button__O61Yb {\n    transition: opacity .3s;\n    display: block;\n    border: none;\n    border-radius: 100rem;\n}\n\n.MobileButtonStyles_submit__E6gew {\n    border-radius: 1.25rem;\n    background: #02D589;\n    padding: 0.45rem 1.5rem 0.25rem 1.5rem;\n}\n\n.MobileButtonStyles_disabled__Urlnk {\n    opacity: 0.3;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/mobile/MobileButton/MobileButtonStyles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,sCAAsC;AAC1C;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".button {\n    transition: opacity .3s;\n    display: block;\n    border: none;\n    border-radius: 100rem;\n}\n\n.submit {\n    border-radius: 1.25rem;\n    background: #02D589;\n    padding: 0.45rem 1.5rem 0.25rem 1.5rem;\n}\n\n.disabled {\n    opacity: 0.3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "MobileButtonStyles_button__O61Yb",
	"submit": "MobileButtonStyles_submit__E6gew",
	"disabled": "MobileButtonStyles_disabled__Urlnk"
};
export default ___CSS_LOADER_EXPORT___;

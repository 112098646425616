import React, {FC} from 'react';
import {MobileModal} from "../../../UX/mobile";

import styles from './MobileAlertModalStyles.module.css'
import classNames from "classnames";
import {Portal} from "../../../service";

interface MobileAlertModalProps {
    onConfirm: () => void
    confirmText: string
    cancel?: {
        text: string
        handler: () => void
    }
    children: string
    isPending?: boolean
}
export const MobileAlertConfirmModal: FC<MobileAlertModalProps> = (props) => {
    const {
        onConfirm,
        confirmText,
        cancel,
        children,
        isPending = false
    } = props

    return (
        <Portal>
            <MobileModal onClose={cancel ? cancel.handler : onConfirm} isCollapsible={false}>
                <div className={styles.alertModalContent}>
                    <p className={styles.alertText}>{children}</p>
                    <div className={styles.buttons} style={{ justifyContent: cancel ? 'space-between' : 'center' }}>
                        {cancel && <span
                            className={styles.textButton}
                            onClick={cancel.handler}
                        >
                            {cancel.text}
                        </span>}
                        <span
                            onClick={!isPending ? onConfirm : undefined}
                            className={classNames(styles.textButton, styles.submitButton)}
                        >
                            {confirmText}
                        </span>
                    </div>
                </div>
            </MobileModal>
        </Portal>
    );
};
import { CSSProperties, FC } from "react"
import { useTranslate } from "../translates/useTranslate"
import { AdminUserSortingStatusWithAdmins, ProjectStatus } from "../types"
import { getTranslatedAdminUserSortingStatus } from "../utils"
import { StatusIcon } from "./icons/StatusIcon"

interface StatusRowProps {
  status: AdminUserSortingStatusWithAdmins | ProjectStatus
  className?: string
  styles?: CSSProperties
}

export const StatusRow: FC<StatusRowProps> = (props) => {
  const translates = useTranslate()
  const { status, className, styles } = props

  return (
    <div className={className} style={{...styles}}>
      {status !== 'admins' && <StatusIcon status={status} />}
      <p>{getTranslatedAdminUserSortingStatus(status, translates)}</p>
    </div>
  )
}

import { ControlsContainer, SigmaContainer } from "@react-sigma/core"
import "@react-sigma/core/lib/react-sigma.min.css"
import { NodeBorderProgram } from "@sigma/node-border"
import classNames from "classnames"
import { UndirectedGraph } from "graphology"
import { circular } from "graphology-layout"
import forceAtlas2 from "graphology-layout-forceatlas2"
import _ from "lodash"
import {
  Dispatch,
  MouseEventHandler,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo
} from "react"
import { useOutletContext } from "react-router-dom"
import {
  NodeCategoryColor,
  gradeCategories
} from "../../../../../../pages/DashboardPage/types/constants"
import {
  ColoringType,
  ContextType,
  DashboardCardClusterGradeInner,
  DashboardGradesCount,
  GraphRefType,
  SerializedGraphData
} from "../../../../../../pages/DashboardPage/types/types"
import { Layouts } from "../../../../../../pages/SuperAdminPage/types"
import { IconButtonTemp } from "../../../../../UI/IconButtonTemp/IconButtonTemp"
import { GraphEvents } from "../GraphEvents"
import drawHover from "../GraphHover/GraphHover"
import drawLabel from "../GraphLabel/GraphLabel"
import { FullScreenIcon } from "../icons/FullScreenIcon"
import styles from "./GraphStyles.module.css"
import { getDarkerColor, getNodeAttributes, getNodeCategory, getPercentile } from "./utils"

interface GraphProps {
  clusterId?: number
  coloring: ColoringType
  graphData: SerializedGraphData
  fullscreenControl?: MouseEventHandler
  setHoveredNodeLabel?: Dispatch<SetStateAction<string>>
  sigmaClassName?: string
  filterGroup: number[]
  filterGrade: number[]
  // TODO temporary
  nodeSize: "absolute" | "percentile"
}

function RefGraph(props: GraphProps, ref: Ref<GraphRefType>) {
  const {
    clusterId,
    coloring,
    graphData,
    fullscreenControl,
    sigmaClassName,
    filterGroup,
    filterGrade,
    nodeSize
  } = props
  const { graphSettings, ideaGroups } = useOutletContext<ContextType>()

  useImperativeHandle(
    ref,
    () => {
      return {
        sigmaSettings: sigmaSettings,
        graph: graph
      }
    },
    []
  )

  const sigmaSettings = useMemo(
    () => ({
      //см SigmaContainer -> Settings
      nodeProgramClasses: {
        border: NodeBorderProgram
      },
      defaultNodeType: "border",
      renderEdgeLabels: false,
      labelSize: 16,
      renderLabels: false,
      defaultEdgeColor: "#D6D6D6",
      minEdgeThickness: 1.2,
      defaultDrawNodeHover: drawHover,
      defaultDrawNodeLabel: drawLabel
    }),
    []
  )

  const graph = useMemo(() => {
    if (graphData && graphData.nodes && graphData.nodes?.length !== 0 && graphSettings) {
      // создаем граф
      const graph = UndirectedGraph.from(graphData)

      // группы или идеи для легенды на фулскрине
      graph.setAttribute("type", coloring)

      // фильтруем ребра по мин. весу, прячем остальные
      // добавляем толщину ребра
      // TODO формула для толщины ребра
      const minWeight = graphSettings.general_settings.min_edge_weight
      graph.forEachEdge(edge => {
        const weight = graph.getEdgeAttribute(edge, "weight")
        if (minWeight > 1) {
          weight < minWeight
            ? graph.setEdgeAttribute(edge, "hidden", true)
            : graph.setEdgeAttribute(edge, "size", weight / 2)
        } else {
          graph.setEdgeAttribute(edge, "size", weight)
        }
      })

      // добавляем позиции по умолчанию и пейджранк
      circular.assign(graph)

      // получаем необходимые атрибуты для каждой ноды
      graph.forEachNode(node => {
        const attributes = graph.getNodeAttributes(node)
        // рассчитываем среднюю оценку и полярность по кластерам
        const grades = attributes.grades
        const clusterGrades: DashboardCardClusterGradeInner[] = []
        const totalGradesCount = grades.reduce(
          (accumulator: DashboardGradesCount, clusterGrades) => {
            accumulator.liked += clusterGrades.grades.liked
            accumulator.disliked += clusterGrades.grades.disliked
            accumulator.neutral += clusterGrades.grades.neutral
            return accumulator
          },
          { liked: 0, neutral: 0, disliked: 0 }
        )

        let totalGrades = 0

        grades.forEach(clusterGrade => {
          let totalClusterGrades = 0
          for (let count of Object.values(clusterGrade.grades)) {
            totalClusterGrades = totalClusterGrades + count
          }

          if (totalClusterGrades === 0) {
            return
          }

          totalGrades = totalGrades + totalClusterGrades

          const sumClusterGrade = clusterGrade.grades.liked - clusterGrade.grades.disliked

          clusterGrades.push({
            cluster: clusterGrade.cluster,
            averageClusterGrade: sumClusterGrade / totalClusterGrades,
            // для вычисления средней оценки ноды
            totalClusterGrades: totalClusterGrades
          })
        })

        graph.setNodeAttribute(node, "clusterGrades", clusterGrades)

        if (totalGrades === 0) {
          return
        }

        // рассчитываем среднюю ноды как среднее взвешенное, где сумма весов = 1
        const averageGrade = clusterGrades.reduce((accumulator, grades) => {
          return (
            accumulator + (grades.averageClusterGrade * grades.totalClusterGrades) / totalGrades
          )
        }, 0)

        graph.setNodeAttribute(node, "averageGrade", averageGrade)
        graph.setNodeAttribute(node, "totalGradesCount", totalGradesCount)

        const gradesToLabel = clusterId
          ? grades.find(clusterGrades => clusterGrades.cluster === clusterId)?.grades
          : totalGradesCount
        const label =
          `${attributes.text} grades: ${"\uD83D\uDC4D"} ${gradesToLabel?.liked} ` +
          `${"\u25EF"} ${gradesToLabel?.neutral} ${"\uD83D\uDC4E"} ${gradesToLabel?.disliked}`
        graph.setNodeAttribute(node, "label", label)
      })

      // определяем категорию по средним оценкам
      // определяем размер и категорию по pagerank
      const { min_node_size, node_size_factor } = graphSettings.general_settings

      // сразу получаем темные цвета, если раскраска по оценкам
      // или карту цветов групп, если по группам
      if (coloring === "grades") {
        var darkerColorMap = new Map()
        Object.entries(NodeCategoryColor).forEach(([key, value]) => {
          darkerColorMap.set(key, getDarkerColor(value))
        })
      } else if (coloring === "groups") {
        var ideaGroupMap = new Map()
        ideaGroups?.forEach(group => {
          ideaGroupMap.set(group.id, {
            color: group.color,
            darkerColor: getDarkerColor(group.color)
          })
        })
      }

      const nodesAttributes = graph
        .nodes()
        .map(node => getNodeAttributes(graph, node, coloring, clusterId))

      nodesAttributes.forEach(node => {
        if (nodeSize === "percentile") {
          // определяем процентиль по pagerank
          const pagerankPercentile = getPercentile(nodesAttributes, node, "pagerank")
          // устанавливаем размер
          graph.setNodeAttribute(
            node.key,
            "size",
            min_node_size + pagerankPercentile * node_size_factor
          )
        } else if (nodeSize === "absolute") {
          // устанавливаем размер
          node.pagerank &&
            graph.setNodeAttribute(
              node.key,
              "size",
              min_node_size + node.pagerank * node_size_factor
            )
        }

        // graph.setNodeAttribute(node.key, "pagerankPercentile", pagerankPercentile)

        // определяем категорию оценки
        const category = getNodeCategory(node.grades)

        graph.setNodeAttribute(node.key, "category", category)

        // определяем цвет ноды в зависимости от категорий оценки
        if (coloring === "grades") {
          const color = NodeCategoryColor[category]
          const darkerColor = darkerColorMap.get(category)
          graph.setNodeAttribute(node.key, "color", color)
          graph.setNodeAttribute(node.key, "borderColor", darkerColor)
        }
        // определяем цвет ноды в зависимости от идейной группы
        // подсвечиваем только ноды, созданные польз-ми этого кластера, если есть кластер
        else if (coloring === "groups") {
          if (!clusterId || node.authorClusterId === clusterId) {
            const colors = ideaGroupMap.get(node.ideaGroupId)
            graph.setNodeAttribute(node.key, "color", colors.color)
            graph.setNodeAttribute(node.key, "borderColor", colors.darkerColor)
          } else {
            graph.setNodeAttribute(node.key, "hidden", true)
          }
        }
      })
      if (graphSettings.layout === Layouts.forceAtlas) {
        const forceAtlasConfig = _.mapKeys(graphSettings.force_atlas_config, (value, key) =>
          _.camelCase(key)
        )
        // определяем позиции нод по форс атлас
        forceAtlas2.assign(graph, {
          // iterations сильнее всего влияет на скорость отрисовки
          iterations: 100,
          getEdgeWeight: "weight",
          settings: forceAtlasConfig
        })
      }
      return graph
    }
  }, [graphData, graphSettings, coloring, ideaGroups, clusterId, nodeSize])

  useEffect(() => {
    if (graph) {
      // фильтрация
      if (filterGroup?.length !== 0 || filterGrade?.length !== 0) {
        const filterGroupSet = new Set(filterGroup)
        const filterGradeSet = new Set(filterGrade)

        graph.forEachNode(node => {
          const attributes = graph.getNodeAttributes(node)
          const cluster = attributes.author?.cluster
          // группы
          const groupId = attributes.idea_group_id
          // категории оценки
          const categoryLabel = attributes.category
          const categoryId = gradeCategories?.find(
            category => category.label === categoryLabel
          )?.id
          const visible =
            (clusterId ? (coloring === "groups" ? cluster === clusterId : true) : true) &&
            (filterGrade?.length === 0 || (categoryId && filterGradeSet?.has(categoryId))) &&
            (filterGroup?.length === 0 || (groupId && filterGroupSet?.has(groupId)))
          graph.setNodeAttribute(node, "hidden", !visible)
        })
      }
    }
  }, [graph, filterGroup, filterGrade])

  return (
    <SigmaContainer
      settings={sigmaSettings}
      graph={graph}
      className={classNames(styles.sigmaContainer, sigmaClassName)}
    >
      <ControlsContainer position={"bottom-right"}>
        {/* TODO не забыть починить кнопки для архипелага */}
        <IconButtonTemp
          style={{ padding: "0.3rem 0.15rem 0.3rem 0.3rem" }}
          onClick={fullscreenControl}
        >
          <FullScreenIcon />
        </IconButtonTemp>
      </ControlsContainer>
      <GraphEvents />
    </SigmaContainer>
  )
}

export const Graph = forwardRef(RefGraph)

import React from 'react';

export const SimpleArrow = ({ isTop }: { isTop: boolean }) => {
    return (
        <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transition: 'transform .2s',
                transform: isTop ? 'rotate(180deg)' : 'unset'
            }}
        >
            <path d="M16.5 1.25L9 8.75L1.5 1.25" stroke="#323232" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UsersState} from "./usersReducerTypes";
import {BaseProject, UserRowIn, UserSettings} from "../types";

const initialState: UsersState = {
    "projects": [],
    "users": []
}


export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setProjects: (state, action: PayloadAction<BaseProject[]>) => {
            state.projects = action.payload
        },

        setUsers: (state, action: PayloadAction<UserRowIn[]>) => {
            state.users = action.payload
        },

        addUser: (state, action: PayloadAction<UserRowIn>) => {
            state.users.push(action.payload)
        },

        addUsers: (state, action: PayloadAction<UserRowIn[]>) => {
            state.users = [...state.users, ...action.payload]
        },

        deleteUser: (state, action: PayloadAction<number>) => {
            state.users = state.users.filter(user => user.id !== action.payload)
        },

        updateUser: (state, action: PayloadAction<UserSettings>) => {
            state.users = state.users.map(user => {
                if (action.payload.user_id === user.id) {
                    return {
                        id: user.id,
                        email: action.payload.email,
                        name: action.payload.name,
                        is_admin: action.payload.is_admin,
                        registration_token: user.registration_token,
                        projects: action.payload.projects,
                    }
                }

                return user
            })
        },

        deleteProject: (state, action: PayloadAction<number>) => {
            state.users.forEach(user => {
                user.projects = user.projects.filter(project => project.id !== action.payload)
            })
        },
    },
})

export default usersSlice.reducer
import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { ContextType } from "../../../../pages/DashboardPage/types/types"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { Legend } from "../../../system/components/Legend/Legend"
import { UserPic } from "../../../system/components/UserPic/UserPic"
import styles from "./MainDashboardStyles.module.css"
import { DoughnutChart } from "./components/DoughnutChart/DoughnutChart"
import { UserStatusRow } from "./components/UserStatusRow/UserStatusRow"

interface MainDashboardProps {}

// TODO разбить
// TODO translates
export function MainDashboard(props: MainDashboardProps) {
  const { ideaGroups, clusters, cardsMap, graphData, userData, info } =
    useOutletContext<ContextType>()
  const [ideaGroupsFilter, setIdeaGroupsFilter] = useState<number[]>([])
  const [pagerankDescendingSort, setPagerankDescendingSort] = useState(true)

  const doughnutChartValues = new Map()
  ideaGroups?.forEach(group => doughnutChartValues.set(group.id, 0))
  graphData.nodes.forEach(card => {
    const groupId = card.attributes.idea_group_id
    doughnutChartValues.set(groupId, doughnutChartValues.get(groupId) + 1)
  })

  const chartLegendStyles = useMemo(
    () => ({
      container: styles.mainDashboardLegend,
      checked: styles.mainDashboardLegendRowActive,
      item: styles.mainDashboardLegendRow,
      itemHover: styles.mainDashboardLegendHover,
      iconSize: "0.8rem"
    }),
    []
  )

  const filteredCards = useMemo(() => {
    if (ideaGroupsFilter.length !== 0 && graphData.nodes) {
      return graphData.nodes
        .filter(card => ideaGroupsFilter.includes(card.attributes.idea_group_id))
        .sort((a, b) =>
          pagerankDescendingSort
            ? b.attributes.pagerank - a.attributes.pagerank
            : a.attributes.pagerank - b.attributes.pagerank
        )
    } else {
      return [...graphData.nodes].sort((a, b) =>
        pagerankDescendingSort
          ? b.attributes.pagerank - a.attributes.pagerank
          : a.attributes.pagerank - b.attributes.pagerank
      )
    }
  }, [ideaGroupsFilter, graphData.nodes, pagerankDescendingSort])

  return (
    <SimpleBar
      style={{
        height: "100%",
        width: "100%"
      }}
    >
      <div className={styles.mainDashboardContainer}>
        <div className={styles.mainDashboardInfoBlock}>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{graphData.nodes.length}</span>
            <p>идей предложено</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.efficient}%</span>
            <p>идей команда оценила как эффективные</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.has_leader}%</span>
            <p>задач получили желающих лидировать</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.has_follower}%</span>
            <p>задач получили желающих участвовать</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.average_follower}</span>
            <p>желающих участвовать в среднем на каждую задачу</p>
          </div>
        </div>
        <div className={styles.mainDashboardMainBlock}>
          <div className={styles.mainDashboardCardsBlock}>
            <div className={styles.mainDashboardDoughnutChart}>
              {ideaGroups && (
                <>
                  <DoughnutChart
                    ideaGroups={ideaGroups}
                    values={doughnutChartValues}
                    sizeRem={8}
                  />
                  <SimpleBar style={{ maxHeight: "100%", width: "100%" }} autoHide={false}>
                    <Legend
                      items={ideaGroups}
                      CSSstyles={chartLegendStyles}
                      setter={setIdeaGroupsFilter}
                    />
                  </SimpleBar>
                </>
              )}
            </div>
            <div className={styles.mainDashboardCardsList}>
              <p>Рейтинг идей</p>
              <div className={styles.mainDashboardCardsListFilters}>
                {/* TODO translates */}
                <RadioButton
                  name="descending"
                  onClick={() => {
                    setPagerankDescendingSort(true)
                  }}
                  label="По популярности"
                  checked={pagerankDescendingSort}
                />
                <RadioButton
                  name="ascending"
                  onClick={() => {
                    setPagerankDescendingSort(false)
                  }}
                  label="По оригинальности"
                  checked={!pagerankDescendingSort}
                />
              </div>
              <SimpleBar style={{ maxHeight: "calc(100% - 5.5rem)", width: "100%" }} autoHide={false}>
                <div className={styles.mainDashboardCardsListUnderScroll}>
                  {filteredCards.map(card => {
                    const cardEssentials = cardsMap.get(card.key)
                    return cardEssentials ? (
                      <DashboardCard key={card.key} card={cardEssentials} />
                    ) : null
                  })}
                </div>
              </SimpleBar>
            </div>
          </div>
          <div className={styles.mainDashboardUserList}>
            <div className={styles.mainDashboardUserListHeader}>
              <p>Динамика команды</p>
            </div>
            <SimpleBar style={{ maxHeight: "100%" }} autoHide={false}>
              <div className={styles.mainDashboardUserListUnderScroll}>
                {clusters?.map((cluster, index) => (
                  <div key={cluster.id} className={styles.mainDashboardUserListCluster}>
                    <div className={styles.mainDashboardUserListRow}>
                      <p className={styles.mainDashboardUserListClusterName}>{cluster.name}</p>
                      {index === 0 && (
                        <>
                          <p>Идеи</p>
                          <p>Сортировка</p>
                          <p>Оценка</p>
                        </>
                      )}
                    </div>
                    {userData &&
                      userData
                        .filter(user => user.cluster === cluster.id)
                        .map(user => (
                          <div key={user.id} className={styles.mainDashboardUserListRow}>
                            <UserPic userId={user.id} showName={true} />
                            <UserStatusRow
                              status={user.user_status}
                              finishedCollecting={user.user_finished_collecting}
                            />
                          </div>
                        ))}
                  </div>
                ))}
                {clusters?.length === 0 && (
                  <div className={styles.mainDashboardUserListCluster}>
                    <div className={styles.mainDashboardUserListRow}>
                      <p></p>
                      <p>Идеи</p>
                      <p>Сортировка</p>
                      <p>Оценка</p>
                    </div>
                    {userData &&
                      userData.map(user => (
                        <div key={user.id} className={styles.mainDashboardUserListRow}>
                          <div className={styles.mainDashboardUserPicRow}>
                            <UserPic userId={user.id} showName={true} />
                          </div>
                          <UserStatusRow
                            status={user.user_status}
                            finishedCollecting={user.user_finished_collecting}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    </SimpleBar>
  )
}

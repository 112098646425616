import {$authHost} from "../../../../axios";
import {CardForEdit, ProjectInfo, ProjectSettingOut, ProjectSettingsIn, EmailsConfig, UserRowIn, UserSettingsDTO, MailingEvent} from "./types";
import {UserRole} from "../../../../store/appReducer/appReducerTypes";

export const getProjectSettings = async (id: number) => {
    const response = await $authHost.get<ProjectSettingsIn | null>(`/card_sorting_admin/get_project_settings?project_id=${id}`)
    return response.data
}

export const saveProjectSettings = async (settings: ProjectSettingOut) => {
    const response = await $authHost.post(`/card_sorting_admin/save_project_settings`, settings)
    return response.data
}

export const getUserRows = async (client_id: number) => {
    const response = await $authHost.get<UserRowIn[]>(`/card_sorting_admin/get_user_rows?client_id=${client_id}`)
    return response.data
}

export const addUser = async (email: string, user_role: UserRole, client_id: number) => {
    const response = await $authHost.post(`/card_sorting_admin/create_user`, { email, user_role, client_id })
    return response.data
}

export const addUsers = async (emails: string, client_id: number) => {
    const response = await $authHost.post(`/card_sorting_admin/create_users`, { emails, client_id })
    return response.data
}

export const saveUserSettings = async (settings: UserSettingsDTO) => {
    const response = await $authHost.post(`/card_sorting_admin/save_user_settings`, settings)
    return response.data
}

export const deleteUser = async (id: number) => {
    const response = await $authHost.delete(`/card_sorting_admin/delete_user?user_id=${id}`)
    return response.data
}

export const switchToStartSorting = async (project_id: number) => {
    const response = await $authHost.post(`/card_sorting_admin/start_sorting?project_id=${project_id}`)
    return response.data
}

export const sendEmails = async (project_id: number, event: MailingEvent, custom_text: string) => {
    return await $authHost.post(
        `/card_sorting_admin/send_emails?project_id=${project_id}`, {
            event: event,
            custom_text: custom_text
        } as EmailsConfig
    )
}

export const createProject = async (project_name: string, client_id: number) => {
    const response = await $authHost.post(`/card_sorting_admin/create_project`, {
        project_name,
        client_id
    })
    return response.data
}

export const getProjects = async (client_id: number) => {
    const response = await $authHost.get<ProjectInfo[]>(`/card_sorting_admin/projects_info?client_id=${client_id}`)
    return response.data
}

export const deleteProject = async (project_id: number) => {
    const response = await $authHost.delete(`/card_sorting_admin/delete_project?project_id=${project_id}`)
    return response.data
}

export const getCards = async (project_id: number) => {
    const response = await $authHost.get<CardForEdit[]>(`/card_sorting_admin/get_cards?project_id=${project_id}`)
    return response.data
}

export const saveCards = async (project_id: number, cards: CardForEdit[]) => {
    const response = await $authHost.post(`/card_sorting_admin/set_cards`, { project_id, cards })
    return response.data
}



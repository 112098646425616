import { MouseEvent, useState } from "react"
import { AnalyticsGradeColor } from "../../../../../pages/DashboardPage/types/constants"
import { LikeStatus } from "../../CardSorting/reducers/cardSortingReducer/cardSortingReducerTypes"
import { MouseHint } from "../../../../system/components"

interface GradesBarProps {
  color: LikeStatus
  totalGrades: number
  grades?: number
}

export function GradesBar(props: GradesBarProps) {
  const { color, grades, totalGrades } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()

  const xCoord = () => {
    if (grades) {
      return Math.round((61 / totalGrades) * grades)
    }
    return undefined
  }

  return (
    <div onMouseMove={event => setHintEvent(event)} onMouseLeave={() => setHintEvent(null)}>
      {hintEvent && <MouseHint event={hintEvent}>{String(grades)}</MouseHint>}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3.8125rem"
        height="0.625rem"
        viewBox="0 0 61 10"
        fill="none"
      >
        <rect width="61" height="10" rx="5" fill="#EEEEEE" />
        {xCoord() && (
          <path
            d={`M0 5C0 2.23858 2.23858 0 5 0H${xCoord()}V10H5C2.23858 10 0 7.76142 0 5Z`}
            fill={AnalyticsGradeColor[color]}
          />
        )}
      </svg>
    </div>
  )
}

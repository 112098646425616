import React from 'react';

export const MobileRatedIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <g clipPath="url(#clip0_5812_59325)">
                <path d="M5.0026 0.832031L6.2901 3.44036L9.16927 3.8612L7.08594 5.89036L7.5776 8.75703L5.0026 7.40286L2.4276 8.75703L2.91927 5.89036L0.835938 3.8612L3.7151 3.44036L5.0026 0.832031Z" stroke="#585858" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_5812_59325">
                    <rect width="10" height="10" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
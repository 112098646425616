import { ChangeEvent, Dispatch, SetStateAction } from "react"

interface CheckboxRowProps<S, U> {
  name: keyof S & string
  label: string
  setting: S[keyof S]
  defaultChecked?: boolean
  setter: Dispatch<SetStateAction<U>>
  handleChange: <U>(
    event: ChangeEvent<HTMLInputElement>,
    setter: Dispatch<SetStateAction<U>>
  ) => void
}

export function CheckboxRow<S extends Record<string, boolean | undefined>, U>(
  props: CheckboxRowProps<S, U>
) {
  const { name, setting, setter, handleChange, label, defaultChecked } = props

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        checked={setting}
        defaultChecked={defaultChecked}
        onChange={(event) => {
          handleChange(event, setter)
        }}
        type="checkbox"
      />
    </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BannerStyles_banner__o4Ltt {\n    display: flex;\n    justify-content: center;\n    padding: 1rem;\n    background-color: #FFCF3C;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/Banner/BannerStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".banner {\n    display: flex;\n    justify-content: center;\n    padding: 1rem;\n    background-color: #FFCF3C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "BannerStyles_banner__o4Ltt"
};
export default ___CSS_LOADER_EXPORT___;

interface AxesIconProps {
  sizeRem: number
}

export function AxesIcon(props: AxesIconProps) {
  const { sizeRem } = props
  const color = "#323232"
  return (
    <svg
      width={`${sizeRem}rem`}
      height={`${sizeRem}rem`}
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="10" y1="100" x2="190" y2="100" stroke={color} strokeWidth="1" />
      <line x1="190" y1="100" x2="180" y2="95" stroke={color} strokeWidth="1" />
      <line x1="190" y1="100" x2="180" y2="105" stroke={color} strokeWidth="1" />
      <line x1="100" y1="10" x2="100" y2="190" stroke={color} strokeWidth="1" />
      <line x1="100" y1="10" x2="95" y2="20" stroke={color} strokeWidth="1" />
      <line x1="100" y1="10" x2="105" y2="20" stroke={color} strokeWidth="1" />
    </svg>
  )
}

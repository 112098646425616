import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorMessages, Pages } from "../common/types";
import { isCurrentPageAuth, isTokenInStorage, readJwtPayload } from "../common/utils";
import { appSlice } from "../store/appReducer/appReducer";
import { UserRole } from "../store/appReducer/appReducerTypes";
import { useAppDispatch, useAppSelector } from "../store/store";
import { adminRoutes } from "./admin";
import { privateRoutes } from "./private";
import { publicRoutes } from "./public";
import { superadminRoutes } from "./superadmin";
import { dashboardRoutes } from './dashboard';
import { DashboardPage } from '../pages/DashboardPage/DashboardPage';

function AppRouter() {
	const user = useAppSelector(state => state.appReducer.user)
	const dispatch = useAppDispatch()
	const { setUser, setLanguage } = appSlice.actions
	const navigate = useNavigate()

	useEffect(() => {
		if (isTokenInStorage()) {
			const token = String(localStorage.getItem('token'))
			const payload = readJwtPayload(token)
			dispatch(setUser({
				id: payload.id,
				name: payload.name,
				token: payload.sub,
				email: payload.email,
				user_role: payload.user_role,
				client_id: payload.client_id
			}))
			dispatch(setLanguage(payload.language))
		} else if (!isCurrentPageAuth()) {
			navigate(`${Pages.error}/${ErrorMessages.NO_ACCESS}`)
		}
	}, [])


	return (
		<Routes>
			{publicRoutes.map(route => <Route key={route.path} path={route.path} element={<route.element />} />)}
			
			{user && privateRoutes.map(route => <Route key={route.path} path={route.path} element={<route.element />} />)}
		
			{user && <Route path='/dashboard/:projectId' element={<DashboardPage />}>
				{dashboardRoutes.map(route => <Route key={route.path} path={route.path} element={<route.element />} />)}
			</Route>}

			{(user?.user_role !== UserRole.COMMON_USER) && adminRoutes.map(route => (
				<Route key={route.path} path={route.path} element={<route.element />} />
			))}

			{(user?.user_role === UserRole.SUPERADMIN) && superadminRoutes.map(route => (
				<Route key={route.path} path={route.path} element={<route.element />} />
			))}
		</Routes>
	)
}

export default AppRouter

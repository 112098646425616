import { ChangeEvent, Dispatch, SetStateAction } from "react"
import styles from "../SuperadminStyles.module.css"
import { DashboardProjectGroupsSettings } from "../types"
import { InputRow } from "./InputRow"

interface DashboardGroupSettingsFormProps {
  groupsSettings: DashboardProjectGroupsSettings
  setGroupsSettings: Dispatch<SetStateAction<DashboardProjectGroupsSettings | null>>
  handleChange: <T>(
    event: ChangeEvent<HTMLInputElement>,
    setter: Dispatch<SetStateAction<T>>
  ) => void
}

export function DashboardGroupsSettingsForm(props: DashboardGroupSettingsFormProps) {
  const { groupsSettings, setGroupsSettings, handleChange } = props
  return (
    <div className={styles.superadminFormLayout}>
      <form>
        <InputRow
          name="groups"
          label="Желаемое кол-во групп:"
          setting={groupsSettings.groups}
          setter={setGroupsSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="resolution"
          label="Меньше 1 - группы объемнее. Больше 1 - группы меньше."
          setting={groupsSettings.resolution}
          setter={setGroupsSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="cutoff"
          label="Ранний выход на:"
          setting={groupsSettings.cutoff}
          setter={setGroupsSettings}
          handleChange={handleChange}
        />
      </form>
    </div>
  )
}

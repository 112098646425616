import { CSSProperties, MouseEvent, useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/store"
import { Portal } from "../../service"
import styles from "./DashboardCardHintStyles.module.css"
import { ContextType, DashboardCardEssentials } from "../../../../pages/DashboardPage/types/types"
import { useOutletContext } from "react-router-dom"

interface MouseHintProps {
  event: MouseEvent
  card: DashboardCardEssentials
}

function countLines(text: string, widthRem: number) {
  const words = text.split(" ")
  let linesCount = 0
  let currentLine = ""

  for (const word of words) {
    // 0.5rem примерная ширина буквы в большинстве шрифтов
    if (word.endsWith("\n")) {
      linesCount += 2
    }

    if ((currentLine.length + word.length + 1) * 0.5 <= widthRem) {
      if (currentLine) {
        currentLine += " " + word
      } else {
        currentLine = word
      }
    } else {
      linesCount += 1
      currentLine = word
    }
  }

  if (currentLine) {
    linesCount += 1
  }

  return linesCount
}

export function DashboardCardHint(props: MouseHintProps) {
  const { event, card } = props
  const { template } = useOutletContext<ContextType>()
  const [position, setPosition] = useState<CSSProperties>()
  const remValue = useAppSelector(state => state.appReducer.remValue)
  const hintOffsetX = 1 * remValue // px
  const hintOffsetY = 1 * remValue // px
  const textWidth = 18 // rem
  const maxHintWidth = textWidth + 2 // ширина контейнера из стилей (textWidth + 2 * padding)
  const hintHeight = (countLines(card.text.hint, textWidth) * 1.1 + 1) * remValue // + line gaps + padding
  const scrollbarWidth = window.innerWidth - document.body.clientWidth // px

  // TODO вынести это дело
  // TODO translates
  useEffect(() => {
    const exceedsX = event.pageX + remValue * maxHintWidth + hintOffsetX >= window.innerWidth
    const exceedsY = event.pageY + hintHeight + hintOffsetY >= window.innerHeight + window.scrollY
    const isYScrollbar =
      document.documentElement.scrollHeight > document.documentElement.clientHeight

    if (exceedsX && exceedsY) {
      setPosition({
        bottom: window.innerHeight - event.pageY + hintOffsetY,
        right:
          window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
      })
    } else if (exceedsX && !exceedsY) {
      setPosition({
        top: event.pageY + hintOffsetY,
        right:
          window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
      })
    } else if (exceedsY && !exceedsX) {
      setPosition({
        bottom: window.innerHeight - event.pageY + hintOffsetY,
        left: event.pageX + hintOffsetX
      })
    } else {
      setPosition({
        top: event.pageY + hintOffsetY,
        left: event.pageX + hintOffsetX
      })
    }
  }, [event, hintHeight, hintOffsetX, hintOffsetY, maxHintWidth, remValue, scrollbarWidth])

  return position ? (
    <Portal>
      <div
        className={styles.dashboardHint}
        style={{
          ...position,
          background: `linear-gradient(to right, ${card.ideaGroup.color} 0.8rem, white 0.8rem)`
        }}
      >
        {card.text.hint.split("\n").map((line, index) => {
          if (template) {
            const pattern = new RegExp(`^${template[index]}\\s`)
            return (
              <p key={index}>
                <b>{line.match(pattern)}</b>
                {line.replace(pattern, "")}
              </p>
            )
          }
          return <p key={index}>{line}</p>
        })}
        {card.controversial && <span>Зона потенциального конфликта на оси {card.controversial}</span>}
      </div>
    </Portal>
  ) : (
    <></>
  )
}

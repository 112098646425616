// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__uDYFo {\n    display: flex;\n    align-items: center;\n    padding: 0 3rem;\n    position: absolute;\n    height: 3.5rem;\n    top: 0;\n    left: 0;\n    width: 100%;\n    border-bottom: 0.125rem solid #585858;\n    background-color: white;\n    justify-content: space-between;\n}\n\n.Header_projectNameContainer__MwHyI {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}\n\n.Header_userContainer__Rt2dz {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,MAAM;IACN,OAAO;IACP,WAAW;IACX,qCAAqC;IACrC,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    padding: 0 3rem;\n    position: absolute;\n    height: 3.5rem;\n    top: 0;\n    left: 0;\n    width: 100%;\n    border-bottom: 0.125rem solid #585858;\n    background-color: white;\n    justify-content: space-between;\n}\n\n.projectNameContainer {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}\n\n.userContainer {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__uDYFo",
	"projectNameContainer": "Header_projectNameContainer__MwHyI",
	"userContainer": "Header_userContainer__Rt2dz"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { getErrorUrl } from "../../../common/utils"
import { RolesAndTasks } from "../../../project/modules/main/RolesAndTasks/RolesAndTasks"
import { PreloadBar } from "../../../project/system/UX/PreloadBar/PreloadBar"
import { DashboardHttp } from "../http"
import { ContextType } from "../types/types"

export function RolesAndTasksPage() {
  // const [cardSortingInfo, setCardSortingInfo] = useState<DashboardCardSortingInfoOut[]>()
  const {cardSortingInfo, setCardSortingInfo} = useOutletContext<ContextType>()
  const { projectId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (projectId) {
      DashboardHttp.getCardSortingInfo(Number(projectId))
        .then(data => {
          setCardSortingInfo(data)
        })
        .catch(error => navigate(getErrorUrl(error.message)))
    }
  }, [projectId])

  return cardSortingInfo ? (
    <RolesAndTasks cardSortingInfo={cardSortingInfo} />
  ) : (
    <PreloadBar isFullPage={true} />
  )
}

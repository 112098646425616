import { useMemo } from "react"
import styles from "./ColorIconStyles.module.css"

interface ColorIconProps {
  color?: string
  sizeRem: number
}

// TODO переделать все иконки в это (легенда, админка)
// TODO компонент с чекбокс фильтр и стилями для фильтров админки и дашборда
export function ColorIcon(props: ColorIconProps) {
  const { color, sizeRem } = props

  const style = useMemo(() => {
    return {
      backgroundColor: `${color}`,
      width: `${sizeRem}rem`,
      height: `${sizeRem}rem`
    }
  }, [color, sizeRem])

  return <div className={styles.colorIcon} style={style}></div>
}

import React from 'react';

const MobileChangeMindSlide = () => {
    return (
        <svg width="275" height="325" viewBox="0 0 275 325" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_6362_88357)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 196.847C2 189.198 8.19232 182.998 15.8309 182.998H41.1875C48.8261 182.998 55.0184 189.198 55.0184 196.847V220.697H55.0296C55.0222 220.827 55.0184 220.957 55.0184 221.088C55.0184 224.337 57.3074 227.041 60.3333 227.622H258.64C266.278 227.622 272.471 233.822 272.471 241.47V317.638H2V232.238V227.622V196.847Z" fill="#F0F0F0"/>
                <path d="M65.0044 287.252H23.5117C19.9046 287.252 16.9805 290.18 16.9805 293.792C16.9805 297.403 19.9046 300.331 23.5117 300.331H65.0044C68.6115 300.331 71.5357 297.403 71.5357 293.792C71.5357 290.18 68.6115 287.252 65.0044 287.252Z" fill="white"/>
                <path d="M120.332 287.252H81.1445C77.5374 287.252 74.6133 290.18 74.6133 293.792C74.6133 297.403 77.5374 300.331 81.1445 300.331H120.332C123.939 300.331 126.863 297.403 126.863 293.792C126.863 290.18 123.939 287.252 120.332 287.252Z" fill="white"/>
                <g opacity="0.2">
                    <path d="M245.967 189.922H215.232C206.956 189.922 200.248 196.639 200.248 204.925C200.248 213.21 206.956 219.927 215.232 219.927H245.967C254.242 219.927 260.95 213.21 260.95 204.925C260.95 196.639 254.242 189.922 245.967 189.922Z" fill="#02D589"/>
                    <path d="M223.773 205.264L229.536 211.035L238.181 198.051" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <path d="M29.2764 219.929C37.5516 219.929 44.2599 213.212 44.2599 204.927C44.2599 196.641 37.5516 189.924 29.2764 189.924C21.0013 189.924 14.293 196.641 14.293 204.927C14.293 213.212 21.0013 219.929 29.2764 219.929Z" fill="#323232"/>
                <path d="M29.4063 205.056H23.7715M29.4063 205.056V199.414M29.4063 205.056V210.698M29.4063 205.056H35.0411" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M152.439 208.902H151.446V204.926H150.453M151.446 200.951H151.456M160.379 204.926C160.379 206.101 160.147 207.264 159.699 208.349C159.25 209.434 158.592 210.42 157.762 211.251C156.933 212.082 155.948 212.74 154.865 213.189C153.78 213.639 152.619 213.87 151.446 213.87C150.273 213.87 149.112 213.639 148.028 213.189C146.944 212.74 145.959 212.082 145.13 211.251C144.3 210.42 143.642 209.434 143.194 208.349C142.745 207.264 142.514 206.101 142.514 204.926C142.514 202.554 143.455 200.279 145.13 198.602C146.805 196.925 149.077 195.982 151.446 195.982C153.815 195.982 156.087 196.925 157.762 198.602C159.437 200.279 160.379 202.554 160.379 204.926Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M83.8359 199.152H85.6286H99.972" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M98.1731 199.155V211.721C98.1731 212.197 97.984 212.654 97.6482 212.991C97.3117 213.328 96.856 213.516 96.3804 213.516H87.4157C86.9401 213.516 86.4844 213.328 86.1479 212.991C85.8121 212.654 85.623 212.197 85.623 211.721V199.155M88.3124 199.155V197.359C88.3124 196.884 88.5014 196.427 88.8372 196.09C89.1737 195.754 89.6294 195.564 90.105 195.564H93.6911C94.1667 195.564 94.6224 195.754 94.9589 196.09C95.2947 196.427 95.4837 196.884 95.4837 197.359V199.155" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M90.1074 203.641V209.026" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M93.6914 203.641V209.026" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.798 0.649793C137.005 -0.216599 135.64 -0.216597 134.847 0.649795L77.7472 63.0456C76.573 64.3288 77.4833 66.3958 79.2227 66.3958H103.839V244.809C103.839 247.57 106.078 249.809 108.839 249.809H165.636C168.397 249.809 170.636 247.57 170.636 244.809V66.3958H193.422C195.161 66.3958 196.071 64.3288 194.897 63.0456L137.798 0.649793Z" fill="#FFD556"/>
            <g filter="url(#filter1_f_6362_88357)">
                <path d="M221.604 113.57L44.3045 131.45C41.3037 131.753 39.0828 134.387 39.344 137.333L42.8289 176.647C43.0901 179.594 45.7345 181.737 48.7352 181.435L226.035 163.554C229.036 163.252 231.257 160.618 230.995 157.671L227.511 118.357C227.249 115.411 224.605 113.267 221.604 113.57Z" fill="white" stroke="#323232"/>
                <path d="M52.5586 166.463L53.6973 166.338L62.8065 165.34" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M61.7334 165.476L62.5089 173.117C62.5383 173.406 62.4453 173.696 62.2502 173.924C62.0552 174.151 61.7742 174.297 61.4691 174.329L55.7157 174.933C55.4105 174.965 55.1062 174.88 54.8696 174.698C54.633 174.516 54.4836 174.251 54.4542 173.962L53.6786 166.322M55.4046 166.14L55.2938 165.049C55.2645 164.76 55.3575 164.469 55.5525 164.242C55.7476 164.014 56.0286 163.869 56.3337 163.837L58.6351 163.595C58.9402 163.563 59.2446 163.647 59.4812 163.829C59.7178 164.011 59.8672 164.276 59.8965 164.566L60.0073 165.657" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M56.8359 168.748L57.1936 172.014" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M59.1211 168.5L59.4787 171.766" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M212.935 150.584L214.583 145.856C215.001 145.812 215.418 145.928 215.742 146.179C216.067 146.429 216.272 146.794 216.312 147.192L216.515 149.195L219.492 148.881C219.644 148.863 219.798 148.877 219.943 148.922C220.088 148.967 220.221 149.041 220.332 149.14C220.443 149.239 220.53 149.36 220.587 149.495C220.644 149.63 220.668 149.775 220.66 149.921L220.391 154.504C220.377 154.747 220.271 154.978 220.094 155.155C219.916 155.331 219.679 155.442 219.425 155.466L213.493 156.092M212.935 150.584L213.493 156.092M212.935 150.584L211.357 150.751C211.079 150.78 210.822 150.914 210.644 151.122C210.465 151.331 210.38 151.597 210.407 151.863L210.762 155.368C210.789 155.633 210.926 155.877 211.142 156.043C211.358 156.21 211.636 156.287 211.915 156.258L213.493 156.092" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M175.148 156.856L173.501 161.584C173.082 161.628 172.665 161.512 172.341 161.261C172.016 161.011 171.811 160.646 171.771 160.248L171.568 158.245L168.592 158.559C168.439 158.577 168.285 158.563 168.14 158.518C167.995 158.473 167.862 158.399 167.751 158.3C167.64 158.201 167.553 158.079 167.497 157.945C167.44 157.81 167.415 157.664 167.424 157.518L167.693 152.936C167.707 152.693 167.812 152.462 167.989 152.285C168.167 152.108 168.405 151.998 168.658 151.974L174.59 151.348M175.148 156.856L174.59 151.348M175.148 156.856L176.553 156.707C176.85 156.681 177.13 156.551 177.337 156.343C177.544 156.134 177.665 155.862 177.676 155.577L177.321 152.072C177.253 151.795 177.08 151.554 176.835 151.393C176.591 151.232 176.292 151.163 175.995 151.2L174.59 151.348" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M194.688 159.272C197.697 158.955 199.902 156.374 199.612 153.508C199.322 150.642 196.647 148.577 193.637 148.894C190.627 149.211 188.423 151.792 188.713 154.658C189.003 157.524 191.678 159.59 194.688 159.272Z" fill="#D9D9D9"/>
                <rect width="53.7238" height="6.83281" rx="3.41641" transform="matrix(0.995241 -0.0965597 0.0673264 0.997949 46.1348 139.086)" fill="#323232" fill-opacity="0.4"/>
                <rect width="13.0487" height="6.26741" rx="3.1337" transform="matrix(0.994594 -0.103384 0.0969144 0.995347 101.801 133.57)" fill="#323232" fill-opacity="0.4"/>
                <rect width="97.6125" height="7.44088" rx="3.72044" transform="matrix(0.995318 -0.0957203 0.0460722 0.999272 118.748 130.521)" fill="#323232" fill-opacity="0.4"/>
                <rect width="82.1732" height="7.24074" rx="3.62037" transform="matrix(0.995317 -0.095734 0.052443 0.998923 47.1445 147.936)" fill="#323232" fill-opacity="0.4"/>
                <path d="M225.403 93.5289L48.1033 111.409C45.1025 111.712 42.8817 114.346 43.1429 117.292L46.6277 156.606C46.8889 159.553 49.5333 161.696 52.5341 161.394L229.834 143.513C232.835 143.211 235.056 140.577 234.794 137.63L231.309 98.3164C231.048 95.3697 228.404 93.2263 225.403 93.5289Z" fill="white" stroke="#323232"/>
                <path d="M56.3574 146.422L57.4961 146.297L66.6054 145.299" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M65.5322 145.435L66.3078 153.076C66.3371 153.365 66.2441 153.655 66.0491 153.883C65.854 154.11 65.573 154.256 65.2679 154.288L59.5145 154.892C59.2094 154.924 58.905 154.839 58.6684 154.657C58.4318 154.475 58.2824 154.21 58.253 153.921L57.4775 146.281M59.2035 146.099L59.0927 145.008C59.0633 144.718 59.1563 144.428 59.3514 144.201C59.5464 143.973 59.8274 143.828 60.1325 143.796L62.4339 143.554C62.7391 143.522 63.0434 143.606 63.28 143.788C63.5166 143.97 63.666 144.235 63.6954 144.525L63.8062 145.616" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M60.6348 148.707L60.9924 151.973" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M62.9199 148.459L63.2775 151.725" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M216.734 130.543L218.381 125.815C218.8 125.771 219.217 125.887 219.541 126.138C219.866 126.388 220.071 126.753 220.111 127.151L220.314 129.154L223.29 128.84C223.443 128.822 223.597 128.836 223.742 128.881C223.887 128.926 224.02 129 224.131 129.099C224.242 129.198 224.329 129.319 224.386 129.454C224.442 129.589 224.467 129.734 224.459 129.88L224.19 134.463C224.175 134.706 224.07 134.937 223.893 135.114C223.715 135.29 223.477 135.401 223.224 135.425L217.292 136.051M216.734 130.543L217.292 136.051M216.734 130.543L215.156 130.71C214.877 130.739 214.621 130.873 214.442 131.081C214.264 131.29 214.179 131.556 214.206 131.822L214.561 135.327C214.588 135.592 214.724 135.836 214.941 136.002C215.157 136.169 215.435 136.246 215.714 136.217L217.292 136.051" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M178.947 136.815L177.299 141.543C176.881 141.587 176.464 141.471 176.14 141.22C175.815 140.97 175.61 140.605 175.57 140.207L175.367 138.204L172.391 138.518C172.238 138.536 172.084 138.522 171.939 138.477C171.794 138.432 171.661 138.357 171.55 138.259C171.439 138.16 171.352 138.038 171.296 137.904C171.239 137.769 171.214 137.623 171.222 137.477L171.492 132.895C171.505 132.652 171.611 132.421 171.788 132.244C171.966 132.067 172.203 131.957 172.457 131.933L178.389 131.307M178.947 136.815L178.389 131.307M178.947 136.815L180.351 136.666C180.649 136.64 180.929 136.51 181.135 136.302C181.343 136.093 181.464 135.821 181.475 135.536L181.12 132.031C181.052 131.754 180.879 131.513 180.634 131.352C180.39 131.191 180.09 131.122 179.794 131.159L178.389 131.307" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M198.486 139.231C201.496 138.914 203.701 136.333 203.411 133.467C203.121 130.601 200.446 128.536 197.436 128.853C194.426 129.17 192.221 131.751 192.512 134.617C192.802 137.483 195.477 139.549 198.486 139.231Z" fill="#D9D9D9"/>
                <rect width="53.7238" height="6.83281" rx="3.41641" transform="matrix(0.995241 -0.0965597 0.0673264 0.997949 49.9336 119.045)" fill="#323232" fill-opacity="0.4"/>
                <rect width="13.0487" height="6.26741" rx="3.1337" transform="matrix(0.994594 -0.103384 0.0969144 0.995347 105.6 113.529)" fill="#323232" fill-opacity="0.4"/>
                <rect width="97.6125" height="7.44088" rx="3.72044" transform="matrix(0.995318 -0.0957203 0.0460722 0.999272 122.547 110.48)" fill="#323232" fill-opacity="0.4"/>
                <rect width="82.1732" height="7.24074" rx="3.62037" transform="matrix(0.995317 -0.095734 0.052443 0.998923 50.9434 127.895)" fill="#323232" fill-opacity="0.4"/>
                <path d="M230.378 71.9508L53.0779 89.831C50.0771 90.1336 47.8563 92.7677 48.1175 95.7143L51.6023 135.028C51.8635 137.975 54.5079 140.118 57.5087 139.815L234.808 121.935C237.809 121.633 240.03 118.999 239.769 116.052L236.284 76.7382C236.023 73.7916 233.379 71.6481 230.378 71.9508Z" fill="white" stroke="#323232"/>
                <path d="M61.332 124.844L62.4707 124.719L71.58 123.721" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M70.5068 123.857L71.2824 131.497C71.3117 131.787 71.2187 132.077 71.0237 132.304C70.8286 132.532 70.5477 132.678 70.2425 132.71L64.4891 133.314C64.184 133.346 63.8796 133.261 63.643 133.079C63.4064 132.897 63.257 132.632 63.2276 132.343L62.4521 124.703M64.1781 124.521L64.0673 123.43C64.0379 123.14 64.1309 122.85 64.326 122.623C64.521 122.395 64.802 122.25 65.1072 122.218L67.4085 121.976C67.7137 121.944 68.018 122.028 68.2546 122.21C68.4912 122.392 68.6406 122.657 68.67 122.947L68.7808 124.038" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M65.6094 127.129L65.967 130.395" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M67.8945 126.881L68.2522 130.147" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M221.709 108.965L223.356 104.237C223.774 104.193 224.192 104.309 224.516 104.559C224.84 104.81 225.045 105.174 225.086 105.573L225.289 107.576L228.265 107.262C228.417 107.244 228.571 107.258 228.717 107.303C228.862 107.347 228.994 107.422 229.105 107.521C229.217 107.62 229.304 107.741 229.36 107.876C229.417 108.011 229.442 108.156 229.434 108.302L229.164 112.885C229.15 113.128 229.045 113.359 228.867 113.536C228.69 113.712 228.452 113.823 228.198 113.847L222.266 114.472M221.709 108.965L222.266 114.472M221.709 108.965L220.131 109.132C219.852 109.161 219.595 109.295 219.417 109.503C219.239 109.712 219.154 109.978 219.181 110.244L219.536 113.749C219.562 114.014 219.699 114.257 219.915 114.424C220.132 114.591 220.41 114.668 220.689 114.639L222.266 114.472" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M183.922 115.236L182.274 119.964C181.856 120.009 181.439 119.892 181.114 119.642C180.79 119.392 180.585 119.027 180.544 118.629L180.342 116.626L177.365 116.94C177.213 116.958 177.059 116.943 176.914 116.899C176.769 116.854 176.636 116.779 176.525 116.681C176.413 116.581 176.327 116.46 176.27 116.325C176.214 116.191 176.189 116.045 176.197 115.899L176.466 111.316C176.48 111.074 176.585 110.843 176.763 110.666C176.94 110.489 177.178 110.378 177.432 110.354L183.364 109.729M183.922 115.236L183.364 109.729M183.922 115.236L185.326 115.088C185.624 115.062 185.903 114.932 186.11 114.724C186.317 114.515 186.438 114.243 186.449 113.957L186.095 110.453C186.027 110.176 185.854 109.935 185.609 109.774C185.365 109.613 185.065 109.544 184.768 109.581L183.364 109.729" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M203.461 117.653C206.471 117.336 208.676 114.755 208.385 111.889C208.095 109.023 205.42 106.957 202.411 107.275C199.401 107.592 197.196 110.173 197.486 113.039C197.776 115.905 200.451 117.971 203.461 117.653Z" fill="#D9D9D9"/>
                <rect width="53.7238" height="6.83281" rx="3.41641" transform="matrix(0.995241 -0.0965597 0.0673264 0.997949 54.9082 97.4668)" fill="#323232" fill-opacity="0.4"/>
                <rect width="13.0487" height="6.26741" rx="3.1337" transform="matrix(0.994594 -0.103384 0.0969144 0.995347 110.574 91.9512)" fill="#323232" fill-opacity="0.4"/>
                <rect width="97.6125" height="7.44088" rx="3.72044" transform="matrix(0.995318 -0.0957203 0.0460722 0.999272 127.521 88.9023)" fill="#323232" fill-opacity="0.4"/>
                <rect width="82.1732" height="7.24074" rx="3.62037" transform="matrix(0.995317 -0.095734 0.052443 0.998923 55.918 106.316)" fill="#323232" fill-opacity="0.4"/>
            </g>
            <path d="M260.188 236.08H14.3049C10.0613 236.08 6.62109 239.525 6.62109 243.774V299.169C6.62109 303.418 10.0613 306.862 14.3049 306.862H260.188C264.431 306.862 267.871 303.418 267.871 299.169V243.774C267.871 239.525 264.431 236.08 260.188 236.08Z" fill="white"/>
            <rect x="17.9141" y="249.877" width="88.8772" height="7.99067" rx="3.99533" fill="#323232" fill-opacity="0.4"/>
            <rect x="111.777" y="249.877" width="24.9655" height="7.99067" rx="3.99533" fill="#323232" fill-opacity="0.4"/>
            <rect x="142.738" y="249.877" width="86.88" height="7.99067" rx="3.99533" fill="#323232" fill-opacity="0.4"/>
            <path d="M6.62109 243.774C6.62109 239.525 10.0613 236.08 14.3049 236.08V306.862C10.0613 306.862 6.62109 303.418 6.62109 299.169V243.774Z" fill="#C5C1F0"/>
            <path d="M249.815 284.937C250.12 284.631 250.535 284.459 250.967 284.459C251.182 284.459 251.393 284.501 251.591 284.583C251.789 284.665 251.968 284.785 252.12 284.937C252.271 285.088 252.391 285.268 252.473 285.466C252.555 285.665 252.597 285.877 252.597 286.091C252.597 286.305 252.555 286.518 252.473 286.716C252.391 286.913 252.271 287.093 252.12 287.245L242.515 296.862L239.441 297.631L240.21 294.554L249.815 284.937Z" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <mask id="mask0_6362_88357" maskUnits="userSpaceOnUse" x="21" y="289" width="11" height="10">
                <path d="M31.2069 289.168H21.9863V298.4H31.2069V289.168Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_6362_88357)">
                <path d="M28.3262 292.342V291.477C28.3262 291.248 28.2351 291.027 28.073 290.865C27.9109 290.703 27.691 290.611 27.4617 290.611H24.2922C24.0629 290.611 23.843 290.703 23.6809 290.865C23.5188 291.027 23.4277 291.248 23.4277 291.477V294.651C23.4277 294.88 23.5188 295.101 23.6809 295.262C23.843 295.425 24.0629 295.516 24.2922 295.516H25.1566M28.3262 292.342H28.9025C29.1317 292.342 29.3516 292.434 29.5137 292.596C29.6758 292.758 29.7669 292.979 29.7669 293.208V296.093C29.7669 296.322 29.6758 296.543 29.5137 296.705C29.3516 296.867 29.1317 296.959 28.9025 296.959H26.021C25.7917 296.959 25.5719 296.867 25.4098 296.705C25.2476 296.543 25.1566 296.322 25.1566 296.093V295.516M28.3262 292.342H26.021C25.7917 292.342 25.5719 292.434 25.4098 292.596C25.2476 292.758 25.1566 292.979 25.1566 293.208V295.516" stroke="#585858" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <path d="M65.0142 287.25H23.5215C19.9144 287.25 16.9902 290.178 16.9902 293.79C16.9902 297.401 19.9144 300.329 23.5215 300.329H65.0142C68.6213 300.329 71.5454 297.401 71.5454 293.79C71.5454 290.178 68.6213 287.25 65.0142 287.25Z" stroke="#02D589"/>
            <rect x="32.8926" y="291.826" width="31.9559" height="3.99533" rx="1.99767" fill="#323232" fill-opacity="0.4"/>
            <mask id="mask1_6362_88357" maskUnits="userSpaceOnUse" x="80" y="289" width="9" height="9">
                <path d="M88.0608 289.939H80.377V297.633H88.0608V289.939Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_6362_88357)">
                <path d="M84.2253 290.58L85.2142 292.587L87.4264 292.911L85.8258 294.472L86.2039 296.677L84.2253 295.636L82.2467 296.677L82.624 294.472L81.0234 292.911L83.2356 292.587L84.2253 290.58Z" stroke="#585858" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <path d="M120.342 287.25H81.1543C77.5472 287.25 74.623 290.178 74.623 293.79C74.623 297.401 77.5472 300.329 81.1543 300.329H120.342C123.949 300.329 126.873 297.401 126.873 293.79C126.873 290.178 123.949 287.25 120.342 287.25Z" stroke="#02D589"/>
            <rect x="91.8105" y="291.826" width="31.9559" height="3.99533" rx="1.99767" fill="#323232" fill-opacity="0.4"/>
            <path d="M141.281 291.117L136.478 295.686L131.676 291.117" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M246.354 297.633H253.269" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="171" y="258" width="77" height="67" rx="33.5" fill="url(#paint0_linear_6362_88357)"/>
            <path d="M206.039 294.349L200.199 288.507M200.199 288.507L206.039 282.666M200.199 288.507H211.879C213.428 288.507 214.913 289.123 216.009 290.218C217.104 291.314 217.72 292.8 217.72 294.349C217.72 295.898 217.104 297.383 216.009 298.48C214.913 299.575 213.428 300.19 211.879 300.19H208.959" stroke="#323232" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <filter id="filter0_f_6362_88357" x="0" y="180.998" width="274.471" height="138.641" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_6362_88357"/>
                </filter>
                <filter id="filter1_f_6362_88357" x="36.8242" y="69.4219" width="205.465" height="114.541" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_6362_88357"/>
                </filter>
                <linearGradient id="paint0_linear_6362_88357" x1="209.5" y1="258" x2="209.5" y2="325" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFD556" stop-opacity="0.44"/>
                    <stop offset="1" stop-color="#FFD556" stop-opacity="0.86"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default MobileChangeMindSlide;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButtonStyles_iconButton__zPiBe {\n    position: relative;\n    z-index: 1;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: background-color .2s;\n    outline: 0.0625rem solid #A1A1A1;\n    background-color: white;\n}\n\n.IconButtonStyles_iconButtonNormal__CGknX {\n    border-radius: 50%;\n    width: 2.35rem;\n    height: 2.35rem;\n}\n\n.IconButtonStyles_iconButtonSmall__U505w {\n    border-radius: 0.75rem;\n    width: 2.35rem;\n    height: 1.5rem;\n}\n\n.IconButtonStyles_disabledIconButton__9krqz {\n    opacity: 0.5;\n    cursor: default;\n}\n\n.IconButtonStyles_activeIconButton__yy-gg {\n    background-color: #FFC000;\n}\n\n.IconButtonStyles_iconButton__zPiBe:hover {\n    background-color: #FFC000;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/IconButton/IconButtonStyles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gCAAgC;IAChC,gCAAgC;IAChC,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".iconButton {\n    position: relative;\n    z-index: 1;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: background-color .2s;\n    outline: 0.0625rem solid #A1A1A1;\n    background-color: white;\n}\n\n.iconButtonNormal {\n    border-radius: 50%;\n    width: 2.35rem;\n    height: 2.35rem;\n}\n\n.iconButtonSmall {\n    border-radius: 0.75rem;\n    width: 2.35rem;\n    height: 1.5rem;\n}\n\n.disabledIconButton {\n    opacity: 0.5;\n    cursor: default;\n}\n\n.activeIconButton {\n    background-color: #FFC000;\n}\n\n.iconButton:hover {\n    background-color: #FFC000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "IconButtonStyles_iconButton__zPiBe",
	"iconButtonNormal": "IconButtonStyles_iconButtonNormal__CGknX",
	"iconButtonSmall": "IconButtonStyles_iconButtonSmall__U505w",
	"disabledIconButton": "IconButtonStyles_disabledIconButton__9krqz",
	"activeIconButton": "IconButtonStyles_activeIconButton__yy-gg"
};
export default ___CSS_LOADER_EXPORT___;

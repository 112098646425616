import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import {
  CardSortingInfoInner,
  ContextType,
  DashboardUserGradeInfo
} from "../../../../pages/DashboardPage/types/types"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { UserPic } from "../../../system/components/UserPic/UserPic"
import { UserSortingStatus } from "../CardSorting/reducers/cardSortingReducer/cardSortingReducerTypes"
import { LikedIcon } from "./icons/LikedIcon"
import { NeutralIcon } from "./icons/NeutralIcon"
import { NextIcon } from "./icons/NextIcon"
import { NoLikedIcon } from "./icons/NoLikedIcon"
import { PreviousIcon } from "./icons/PreviousIcon"
import styles from "./TasksStyles.module.css"
import { PreloadBar } from "../../../system/UX"

interface TasksProps {
  groupFilters: number[]
  cardSortingInfo: CardSortingInfoInner[]
}

export function Tasks(props: TasksProps) {
  const { groupFilters, cardSortingInfo } = props
  const { userData, cardsMap } = useOutletContext<ContextType>()
  const [shownUsers, setShownUsers] = useState<[number, number]>([0, 0])
  // TODO хинты на next previous

  const readyOrderedUsers = useMemo(() => {
    return userData
      .filter(user => user.user_status === UserSortingStatus.FINISHED_QUADRANT_SORT)
      .sort((a, b) => a.id - b.id)
  }, [userData])

  const maxUserCount = 14

  const userCount = useMemo(() => readyOrderedUsers.length, [readyOrderedUsers])

  const usersShownCount = useMemo(() => Math.min(maxUserCount, userCount), [userCount])

  useEffect(() => {
    setShownUsers([0, usersShownCount])
  }, [usersShownCount])

  function handleNextUser() {
    if (shownUsers[1] < userCount) {
      setShownUsers(prevState => [prevState[0] + 1, prevState[1] + 1])
    }
  }

  function handlePreviousUser() {
    if (shownUsers[0] > 0) {
      setShownUsers(prevState => [prevState[0] - 1, prevState[1] - 1])
    }
  }

  const filteredCardSortingInfo = useMemo(() => {
    return groupFilters.length !== 0
      ? cardSortingInfo.filter(cardInfo => {
          const card = cardsMap.get(String(cardInfo.id))
          return card && groupFilters.includes(card.ideaGroup.id)
        })
      : cardSortingInfo
  }, [groupFilters])

  function getLiked(userGradeInfo: DashboardUserGradeInfo[]) {
    return userGradeInfo.reduce((accumulator, userGrade) => {
      if (userGrade.grade === "liked") {
        accumulator += 1
      }
      return accumulator
    }, 0)
  }

  return shownUsers[1] === 0 ? (
    <PreloadBar isFullPage={true} />
  ) : (
    <div className={styles.tasksContainer}>
      <div className={styles.tasksTable}>
        <div
          className={classNames(styles.tasksTableHeader, styles.tasksTableRow)}
          style={{
            gridTemplateColumns: `25rem repeat(${usersShownCount}, 3fr) 7rem`
          }}
        >
          <div className={styles.tasksMoveUsersIcon} onClick={handlePreviousUser}>
            {shownUsers[0] > 0 && <PreviousIcon />}
          </div>
          {shownUsers &&
            readyOrderedUsers
              .slice(shownUsers[0], shownUsers[1])
              .map(user => <UserPic key={user.id} userId={user.id} />)}
          <div className={styles.tasksMoveUsersIcon} onClick={handleNextUser}>
            {shownUsers[1] < userCount && <NextIcon />}
          </div>
        </div>
        <div className={styles.tasksTableBody}>
          <SimpleBar style={{ maxHeight: "100%", width: "100%" }}>
            <div className={styles.tasksTableBodyUnderScroll}>
              {filteredCardSortingInfo.map(cardInfo => {
                const card = cardsMap.get(String(cardInfo.id))
                return (
                  card && (
                    <div
                      key={cardInfo.id}
                      className={styles.tasksTableRow}
                      style={{
                        gridTemplateColumns: `25rem repeat(${usersShownCount}, 3fr) 7rem`
                      }}
                    >
                      <div>
                        <DashboardCard card={card} />
                      </div>
                      {readyOrderedUsers.slice(shownUsers[0], shownUsers[1]).map(user => {
                        const grade = cardInfo.user_grade_info.find(
                          userGrades => userGrades.user_id === user.id
                        )?.grade
                        return (
                          <div key={user.id}>
                            {grade === "liked" && <LikedIcon />}
                            {grade === "neutral" && <NeutralIcon />}
                            {/* {grade === "disliked" && <DislikedIcon />} */}
                          </div>
                        )
                      })}
                      <div>
                        {(() => {
                          const likes = getLiked(cardInfo.user_grade_info)
                          return likes > 1 ? (
                            <div className={styles.tasksTableCountCell}>
                              <p>{likes} &#10005;</p>
                              <LikedIcon />
                            </div>
                          ) : likes === 1 ? (
                            <div>
                              <LikedIcon />
                            </div>
                          ) : (
                            <div>
                              <NoLikedIcon />
                            </div>
                          )
                        })()}
                      </div>
                    </div>
                  )
                )
              })}
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  )
}

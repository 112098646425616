import classNames from "classnames"
import { Dispatch, ReactNode, SetStateAction, useState } from "react"
import styles from "./CheckboxFilterStyles.module.css"

interface CheckboxFilterProps<T> {
  children: ReactNode
  onSelect?: (id: number, setter: Dispatch<SetStateAction<T>>) => void
  setter?: Dispatch<SetStateAction<T>>
  classNameChecked?: string
  id: number
}

export function CheckboxFilter(props: CheckboxFilterProps<number[]>) {
  const { children, onSelect, setter, id, classNameChecked } = props
  const [checked, setChecked] = useState(false)

  function handleChange() {
    if (onSelect && setter) {
      setChecked(!checked)
      onSelect(id, setter)
    }
  }
  return (
    <div
      className={classNames(styles.checkboxFilter, checked && classNameChecked)}
      onClick={handleChange}
    >
      <input
        type="checkbox"
        checked={checked}
        readOnly
      />
      {children}
    </div>
  )
} 

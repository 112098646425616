import React, {FC} from 'react';
import {MobileModal} from "../../../../../system/UX/mobile";

import styles from "./MobileSortingStyles.module.css";
import {MobileTrashIcon} from "../icons/MobileTrashIcon";
import {MobileCard} from "./MobileCard";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import {useAppSelector} from "../../../../../../store/store";
import {useTranslate} from "../../translates/useTranslate";

interface MobileBinProps {
    onClose: () => void
}

export const MobileBin: FC<MobileBinProps> = ({ onClose }) => {
    const translates = useTranslate()
    const bin = useAppSelector(state => state.cardSortingReducer.group_state.bin)

    return (
        <MobileModal onClose={onClose} isCollapsible={true}>
            <div className={styles.mobileBinContainer}>
                <div className={styles.mobileBinHeader}>
                    <MobileTrashIcon stroke="black"/>
                    <h2 className={styles.mobileBinHeading}>{translates.mobileTranslates.deletedCards}</h2>
                </div>
                <SimpleBar style={{ maxHeight: '30rem', paddingBottom: '0.05rem' }} autoHide={false} className="thinScrollbar">
                    <div className={styles.binCardsContainer}>
                        {bin.length === 0 && <p>{translates.mobileTranslates.binIsEmpty}</p>}
                        {bin.map(card => <MobileCard
                            key={card.id}
                            id={card.id}
                            text={card.text}
                            grade_status={card.grade_status}
                            place="bin"
                        />)}
                    </div>
                </SimpleBar>
            </div>
        </MobileModal>
    );
};
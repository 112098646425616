import classNames from "classnames"
import { MouseEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, IconButton, IconTextButton, Input } from "../../../system/UX"
import { AlertModal, ConfirmModal, MouseHint } from "../../../system/components"
import { MailingConfirmModal } from "../../../system/components/MailingConfirmModal/MailingConfirmModal"
import styles from "./CardSortingAdminStyles.module.css"
import { CardSortingSettings } from "./components/CardSortingSettings"
import { DeleteProjectIcon } from "./components/DeleteProjectIcon"
import { PreModerationModal } from "./components/PreModerationModal"
import { StatusRow } from "./components/StatusRow"
import { UserManagementControl } from "./components/UserManagementControl"
import { AddIcon } from "./components/icons/AddIcon"
import { SearchIcon } from "./components/icons/SearchIcon"
import { SeeResultsIcon } from "./components/icons/SeeResultsIcon"
import { SendEmailsIcon } from "./components/icons/SendEmailsIcon"
import { ShareResultsIcon } from "./components/icons/ShareResultsIcon"
import { StartIcon } from "./components/icons/StartIcon"
import {
  createProject,
  deleteProject,
  getProjects,
  sendEmails,
  switchToStartSorting
} from "./http"
import { usersSlice } from "./reducers/usersReducer"
import { useTranslate } from "./translates/useTranslate"
import { ProjectInfo, ProjectStatus } from "./types"

//TODO: Сортировать проекты (активные - выше)
//TODO: Сделать компонент скроллабл модальных окон
//TODO: Сделать нормальную синхронизацию с БД, когда в моменте меняешь настройки
export const CardSortingAdmin = ({ isMobile }: { isMobile: boolean }) => {
  const dispatch = useDispatch()
  const actions = usersSlice.actions
  const client_id = Number(useParams().client_id)
  const translate = useTranslate()
  const [projectName, setProjectName] = useState("")
  const [projectList, setProjectList] = useState<ProjectInfo[]>([])
  const [projectToSetUp, setProjectToSetUp] = useState(0)
  const [projectToDelete, setProjectToDelete] = useState(0)
  const [projectToSendInvitations, setProjectToSendInvitations] = useState(0)
  const [projectToAnswerModerate, setProjectToAnswerModerate] = useState(0)
  const [projectToStartSort, setProjectToStartSort] = useState(0)
  const [projectToShareResults, setProjectToShareResults] = useState(0)
  const [emailsSentAlert, setEmailsSentAlert] = useState(false)
  const [stageChangedAlert, setStageChangedAlert] = useState(false)
  const [customEmailText, setCustomEmailText] = useState("")
  const [hintEvent, setHintEvent] = useState<MouseEvent | null>()
  const [currentHint, setCurrentHint] = useState("")

  const createProjectHandler = () => {
    createProject(projectName, client_id).then(() => {
      getProjects(client_id).then(data => {
        setProjectList(data)
        setProjectName("")
        dispatch(
          actions.setProjects(data.map(project => ({ id: project.id, name: project.name })))
        )
      })
    })
  }

  const deleteProjectHandler = () => {
    deleteProject(projectToDelete).then(() => {
      getProjects(client_id).then(data => {
        setProjectList(data)
        dispatch(
          actions.setProjects(data.map(project => ({ id: project.id, name: project.name })))
        )
        dispatch(actions.deleteProject(projectToDelete))
      })
    })
    setProjectToDelete(0)
  }

  const startSortingHandler = () => {
    switchToStartSorting(projectToStartSort).then(() => {
      getProjects(client_id).then(setProjectList)
    })
    setProjectToStartSort(0)
  }

  const closeSettings = () => {
    setProjectToSetUp(0)
  }

  useEffect(() => {
    getProjects(client_id).then(data => {
      setProjectList(data)
      dispatch(actions.setProjects(data.map(project => ({ id: project.id, name: project.name }))))
    })
  }, [])

  return (
    <div
      className={classNames(
        styles.cardSortingAdminContainer,
        isMobile && styles.mobileCardSortingAdminContainer
      )}
    >
      {hintEvent && <MouseHint event={hintEvent}>{currentHint}</MouseHint>}
      <div
        className={classNames(
          styles.settingsContainer,
          isMobile && styles.mobileSettingsContainer
        )}
      >
        <UserManagementControl isMobile={isMobile} />
        <div
          className={classNames(
            styles.commonSortingSettingsContainer,
            isMobile && styles.mobileManagementContainer
          )}
        >
          <h3 className={styles.sortSettingsHeader}>{translate.sortingSettings.projectsHeader}</h3>
          <div className={styles.inputButtonContainer}>
            <Input
              placeholder={translate.sortingSettings.inputProjectPlaceholder}
              label={translate.sortingSettings.inputProjectLabel}
              containerClassName={styles.userOrProjectInputContainer}
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
            />
            {isMobile ? (
              <IconButton
                onClick={createProjectHandler}
                className={styles.mobileAddUserOrProjectButtonStyles}
                disabled={!projectName}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                onClick={createProjectHandler}
                className={styles.addUserOrProjectButtonStyles}
                disabled={!projectName}
              >
                {translate.sortingSettings.addButton}
              </Button>
            )}
          </div>
          <div className={styles.projectListContainer}>
            <p className={styles.pressNameToSetUp}>{translate.sortingSettings.pressNameToSetUp}</p>
            <ul className={styles.projectList}>
              {projectList
                .sort((p1, p2) => p2.id - p1.id)
                .map(project => (
                  <li key={project.id} className={styles.project}>
                    <div className={styles.projectNameStatusContainer}>
                      <div className={styles.projectNameStatus}>
                        <span
                          onClick={() => setProjectToSetUp(project.id)}
                          className={styles.projectName}
                        >
                          {project.name}
                        </span>
                        <StatusRow
                          status={project.status}
                          className={styles.statusRow}
                          styles={{ cursor: "default" }}
                        />
                      </div>
                      <div className={styles.projectManagementButtonContainer}>
                        <SendEmailsIcon
                          className={styles.controlIcon}
                          onClick={() => setProjectToSendInvitations(project.id)}
                          onMouseEnter={() =>
                            setCurrentHint(translate.sortingSettings.sendEmailsHint)
                          }
                          onMouseMove={e => setHintEvent(e)}
                          onMouseLeave={() => setHintEvent(null)}
                        />
                        <DeleteProjectIcon
                          className={styles.controlIcon}
                          onClick={() => setProjectToDelete(project.id)}
                          onMouseEnter={() =>
                            setCurrentHint(translate.sortingSettings.deleteProjectHint)
                          }
                          onMouseMove={e => setHintEvent(e)}
                          onMouseLeave={() => setHintEvent(null)}
                        />
                      </div>
                    </div>
                    {project.status === ProjectStatus.SORTING_STARTED && (
                      <div className={styles.projectButtons}>
                        <IconTextButton
                          onClick={() => {}}
                          className={styles.projectButtonModeration}
                          label={translate.sortingSettings.seeResultsButton}
                        >
                          <SeeResultsIcon />
                        </IconTextButton>
                        <IconTextButton
                          onClick={() => {}}
                          className={styles.projectButtonNextStageWithStroke}
                          label={translate.sortingSettings.shareResultsButton}
                        >
                          <ShareResultsIcon />
                        </IconTextButton>
                      </div>
                    )}
                    {project.status === ProjectStatus.SORTING_NOT_STARTED && (
                      <div className={styles.projectButtons}>
                        <IconTextButton
                          onClick={() => setProjectToAnswerModerate(project.id)}
                          className={styles.projectButtonModeration}
                          label={translate.sortingSettings.preModerationButton}
                        >
                          <SearchIcon />
                        </IconTextButton>
                        <IconTextButton
                          onClick={() => setProjectToStartSort(project.id)}
                          className={styles.projectButtonNextStage}
                          label={translate.sortingSettings.startSortingButton}
                        >
                          <StartIcon />
                        </IconTextButton>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
          {projectToSetUp !== 0 && (
            <CardSortingSettings
              onClose={closeSettings}
              projectId={projectToSetUp}
              setProjectList={setProjectList}
              isMobile={isMobile}
              projectStatus={projectList.find(project => project.id === projectToSetUp)?.status}
            />
          )}
          {projectToStartSort !== 0 && (
            <MailingConfirmModal
              confirmText={translate.confirmEmailButton}
              cancelText={translate.cancelEmailButton}
              inputValue={customEmailText}
              imageLabel={translate.emailImageLabel}
              inputLabel={translate.inputEmailCustomTextLabel}
              inputPlaceholder={translate.inputEmailCustomTextPlaceholder}
              modalLabel={translate.sortingSettings.labelStartSorting}
              inputOnChange={e => setCustomEmailText(e.target.value)}
              onConfirm={() => {
                startSortingHandler()
                sendEmails(projectToStartSort, "sorting", customEmailText)
                setStageChangedAlert(true)
              }}
              onCancel={() => setProjectToStartSort(0)}
            >
              {translate.sortingSettings.confirmStartSorting}
            </MailingConfirmModal>
          )}
          {projectToSendInvitations !== 0 && (
            <MailingConfirmModal
              confirmText={translate.confirmEmailButton}
              cancelText={translate.cancelEmailButton}
              inputValue={customEmailText}
              imageLabel={translate.emailImageLabel}
              inputLabel={translate.inputEmailCustomTextLabel}
              inputPlaceholder={translate.inputEmailCustomTextPlaceholder}
              inputOnChange={e => setCustomEmailText(e.target.value)}
              onConfirm={() => {
                setProjectToSendInvitations(0)
                sendEmails(projectToSendInvitations, "collecting", customEmailText)
                setEmailsSentAlert(true)
              }}
              onCancel={() => setProjectToSendInvitations(0)}
            >
              {translate.sortingSettings.confirmSendEmails}
            </MailingConfirmModal>
          )}
          {projectToDelete !== 0 && (
            <ConfirmModal
              confirmText={translate.confirmButton}
              cancelText={translate.cancelButton}
              onConfirm={deleteProjectHandler}
              onCancel={() => setProjectToDelete(0)}
            >
              {translate.sortingSettings.confirmDeleteProject}
            </ConfirmModal>
          )}
          {projectToAnswerModerate !== 0 && (
            <PreModerationModal
              projectId={projectToAnswerModerate}
              onClose={() => setProjectToAnswerModerate(0)}
            />
          )}
          {emailsSentAlert && (
            <AlertModal
              onSubmit={() => setEmailsSentAlert(false)}
              submitText={translate.alertSubmitText}
            >
              {translate.alertEmailsSentText}
            </AlertModal>
          )}
          {stageChangedAlert && (
            <AlertModal
              onSubmit={() => setStageChangedAlert(false)}
              submitText={translate.alertSubmitText}
            >
              {translate.alertStageChangedText}
            </AlertModal>
          )}
        </div>
      </div>
    </div>
  )
}

export function NeutralIcon() {
  return (
    <div
      style={{
        width: "1rem",
        height: "1rem",
        borderRadius: "50%",
        backgroundColor: "gray"
      }}
    ></div>
  )
}

import React, {FC, MouseEventHandler} from 'react';

interface MobileTrashIconProps {
    onClick?: MouseEventHandler<SVGSVGElement>
}
export const MobileArrowBackIcon: FC<MobileTrashIconProps> = ({ onClick }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={onClick}>
            <path d="M9 15L3 9M3 9L9 3M3 9H15C16.5913 9 18.1174 9.63214 19.2426 10.7574C20.3679 11.8826 21 13.4087 21 15C21 16.5913 20.3679 18.1174 19.2426 19.2426C18.1174 20.3679 16.5913 21 15 21H12" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
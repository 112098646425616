import { FC, useEffect, useState } from "react"
import { useAppSelector } from "../../../../../../../store/store"
import { Portal } from "../../../../../../system/service"
import styles from "../../../CardSortingStyles.module.css"
import { Group as GroupType } from "../../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { Group } from "./Group"

interface GroupProps {
  group: GroupType
  cursorAwayHandler: () => void
  clientX: number
  clientY: number
  heightDeltaRem: number
}

const animationDurationMS = 200

export const UnwrappedGroup: FC<GroupProps> = props => {
  const { group, cursorAwayHandler, clientY, clientX, heightDeltaRem } = props
  const remValue = useAppSelector(state => state.appReducer.remValue)

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true)
    }, 100)
  }, [])

  const startAnimationHide = () => {
    setIsMounted(false)
    setTimeout(() => {
      cursorAwayHandler()
    }, animationDurationMS)
  }

  const currentGroupY =
    clientY > window.innerHeight / 2 ? clientY - heightDeltaRem * remValue : clientY

  return (
    <Portal>
      <div
        className={styles.unwrappedGroupBackground}
        style={{
          backgroundColor: isMounted ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.0)",
          transition: `background-color ${animationDurationMS}ms`
        }}
      >
        <Group
          group={group}
          isDesktopCollapsed={false}
          cursorAwayHandler={startAnimationHide}
          style={{
            opacity: isMounted ? "1" : "0",
            transition: `opacity ${animationDurationMS}ms`,
            position: "absolute",
            top: currentGroupY,
            left: clientX
          }}
        />
      </div>
    </Portal>
  )
}

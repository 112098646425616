import { useEffect, useState } from "react"
import { ConfirmModal } from "../../project/system/components"
import { getRawProjects, deleteRawProject, createFromRawProject, createAll } from "./http"
import { RawProject } from "./types"
import styles from "./SuperadminStyles.module.css"
import classNames from "classnames"
import { useNavigate, useParams } from "react-router-dom"
import { Pages } from "../../common/types"

export function SuperadminModerationPage() {
  const [rawProjects, setRawProjects] = useState<RawProject[] | null>(null)
  const [toDelete, setToDelete] = useState<number | null>(null)
  const [toCreate, setToCreate] = useState<number | null>(null)
  const [toCreateAll, setToCreateAll] = useState(false)
  const [allProjectsCreating, setAllProjectsCreating] = useState(false)
  const [projectCreating, setProjectCreating] = useState<number | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    getRawProjects().then((data) => setRawProjects(data))
  }, [])

  function deleteRawProjectHandler() {
    deleteRawProject(Number(toDelete)).then(() =>
      getRawProjects().then((data) => setRawProjects(data))
    )
    setToDelete(null)
  }

  function createFromRawProjectHandler() {
    createFromRawProject(Number(toCreate))
      .then(() =>
        getRawProjects().then((data) => {
          setRawProjects(data)
          setProjectCreating(null)
        })
      )
      .catch((error) => alert(error.response.data.detail))
      .finally(() => setProjectCreating(null))
    setToCreate(null)
    setProjectCreating(toCreate)
  }

  function createAllHandler() {
    createAll()
      .then(() =>
        getRawProjects().then((data) => {
          setRawProjects(data)
          setAllProjectsCreating(false)
        })
      )
      .catch((error) => alert(error.response.data.detail))
      .finally(() => setAllProjectsCreating(false))
    setToCreateAll(false)
    setAllProjectsCreating(true)
  }

  return (
    <div className={styles.superadminLayout}>
      {toDelete && (
        <ConfirmModal
          onConfirm={deleteRawProjectHandler}
          onCancel={() => setToDelete(null)}
          confirmText="Да"
          cancelText="Нет"
        >
          Удалить этого клиента?
        </ConfirmModal>
      )}
      {toCreate && (
        <ConfirmModal
          onConfirm={createFromRawProjectHandler}
          onCancel={() => setToCreate(null)}
          confirmText="Да"
          cancelText="Нет"
        >
          Создать этот проект?
        </ConfirmModal>
      )}
      {toCreateAll && (
        <ConfirmModal
          onConfirm={createAllHandler}
          onCancel={() => setToCreateAll(false)}
          confirmText="Да"
          cancelText="Нет"
        >
          Создать проекты из всех данных?
        </ConfirmModal>
      )}
      <button
        className={classNames(
          styles.superadminButton,
          styles.superadminBigButton,
        )}
        style={{ fontWeight: "bold" }}
        onClick={() => navigate(Pages.superadmin_page)}
      >
        &#9668; к списку клиентов
      </button>
      {rawProjects?.map((project) => (
        <div key={project.id} className={styles.superadminProjectContainer}>
          <div className={styles.superadminProjectTable}>
            <div className={styles.superadminProjectTableRow}><p style={{ fontWeight: "bold" }}>{project.date_submit}</p></div>
            <div className={styles.superadminProjectTableRow}>
              <p style={{ fontWeight: "bold" }}>{project.id}. {project.question}</p>
            </div>
            <div className={styles.superadminProjectTableRow}><p><span style={{ fontWeight: "bold" }}>Лидер: </span>{project.leader_email}</p></div>
            <div className={styles.superadminProjectTableRow}>
              {project.users?.map((user) => (
                <div key={user.user_email} className={styles.superadminProjectUserRow}>
                  <p>{user.user_email}</p>
                  <p>{user.cluster}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.superadminProjectButtonRow}>
            <button
              className={classNames(
                styles.superadminButton,
                (projectCreating === project.id || allProjectsCreating) && styles.superadminButtonDisabled
              )}
              onClick={() => setToDelete(project.id)}
              disabled={projectCreating === project.id || allProjectsCreating}
            >
              Удалить запись
            </button>
            <button
              className={classNames(
                styles.superadminButton,
                (projectCreating === project.id || allProjectsCreating) && styles.superadminButtonDisabled
              )}
              onClick={() => setToCreate(project.id)}
              disabled={projectCreating === project.id || allProjectsCreating}
            >
              Создать проект
            </button>
          </div>
        </div>
      ))}
      <button
        className={classNames(
          styles.superadminButton,
          styles.superadminBigButton,
          (allProjectsCreating || rawProjects?.length === 0 || projectCreating !== null) && styles.superadminButtonDisabled
        )}
        onClick={() => setToCreateAll(true)}
        disabled={allProjectsCreating || rawProjects?.length === 0 || projectCreating !== null}
      >
        {rawProjects ? "Создать все оставшиеся проекты" : "Черновиков для премодерации нет"}
      </button>
    </div>
  )
}

import classNames from "classnames"
import { FC, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useToggle } from "../../../../../../common/hooks"
import { useAppSelector } from "../../../../../../store/store"
import { MobileAlertConfirmModal } from "../../../../../system/components/mobile"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import {
  Card,
  GroupColorsType,
  SortStage
} from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../../translates/useTranslate"
import { countRatedCards, movingCardsTransitionMS } from "../../utils"
import { CardCounterIcon } from "../icons/CardCounterIcon"
import { MobileArrowBackIcon } from "../icons/MobileArrowBackIcon"
import { MobileArrowIcon } from "../icons/MobileArrowIcon"
import { MobileEditGroupNameIcon } from "../icons/MobileEditGroupNameIcon"
import { MobileRatedIcon } from "../icons/MobileRatedIcon"
import { MobileAddOrEditGroupControl } from "./MobileAddOrEditGroupControl"
import { MobileCard } from "./MobileCard"
import styles from "./MobileSortingStyles.module.css"

interface MobileGroupProps {
  id: number
  name: string
  color: GroupColorsType
  cards: Card[]
  userGroupColorMap?: Map<number, string>
}

export const MobileGroup: FC<MobileGroupProps> = ({ id, name, color, cards, userGroupColorMap }) => {
  const translates = useTranslate()
  const dispatch = useDispatch()

  const groupRef = useRef<HTMLDivElement>(null)
  const cardContainer = useRef<HTMLDivElement>(null)
  const isCollapsed = useToggle(true)
  const deleteModal = useToggle(false)
  const renameModal = useToggle(false)
  const [containerHeight, setContainerHeight] = useState("0")
  const ratedCardCounter = countRatedCards(cards)
  const isAllRated = ratedCardCounter === cards.length && cards.length !== 0
  const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
  const sortStage = useAppSelector(state => state.cardSortingReducer.sortStage)

  const {
    deleteGroup,
    movePickedCardsToGroup,
    toggleCollapse,
    startMovingAnimation,
    stopMovingAnimation
  } = cardSortingSlice.actions

  useEffect(() => {
    if (!isCollapsed.state && cardContainer.current) {
      setContainerHeight(cardContainer.current.scrollHeight + "px")
    }

    if (cards.length === 0 && !isCollapsed.state) {
      isCollapsed.toggleValue()
      setContainerHeight("0")
    }
  }, [cards])

  const toggleCollapseHandler = () => {
    if (pickedCards.cards.length === 0 && cards.length !== 0) {
      if (isCollapsed.state && cardContainer.current) {
        setContainerHeight(cardContainer.current.scrollHeight + "px")
      } else {
        setContainerHeight("0px")
      }

      isCollapsed.toggleValue()
      dispatch(toggleCollapse(id))
    }
  }

  const movePickedCardsHandler = () => {
    if (pickedCards.cards.length !== 0) {
      dispatch(startMovingAnimation(groupRef.current ? groupRef.current.getClientRects()[0].y : 0))
      setTimeout(() => {
        dispatch(stopMovingAnimation())
        dispatch(movePickedCardsToGroup(id))
      }, movingCardsTransitionMS)
    }
  }

  return (
    <div
      className={styles.group}
      style={{ background: `linear-gradient(to right, ${color} 0.625rem, white 0.625rem)` }}
      onClick={movePickedCardsHandler}
      ref={groupRef}
    >
      {deleteModal.state && (
        <MobileAlertConfirmModal
          onConfirm={() => dispatch(deleteGroup(id))}
          confirmText={translates.yes}
          cancel={{
            text: translates.no,
            handler: deleteModal.toggleValue
          }}
        >
          {translates.confirmDeleteGroup}
        </MobileAlertConfirmModal>
      )}

      {renameModal.state && (
        <MobileAddOrEditGroupControl
          onClose={renameModal.toggleValue}
          group={{ id, name, color }}
        />
      )}

      <div className={styles.groupHeadingControls}>
        <p className={styles.groupName}>{name}</p>
        <div className={styles.groupCountersAndControls}>
          <div className={styles.groupCounters}>
            <div
              className={styles.counter}
              style={{ borderColor: isAllRated ? "#02D589" : "red" }}
            >
              <CardCounterIcon width="0.75rem" height="0.75rem" />
              <span className={styles.counterText}>
                {cards.length} {translates.groupCardCounter}
              </span>
            </div>
            {sortStage === SortStage.MANUAL_SORT && <div
              className={styles.counter}
              style={{ borderColor: isAllRated ? "#02D589" : "red" }}
            >
              <MobileRatedIcon />
              <span className={styles.counterText}>
                {ratedCardCounter} {translates.groupRatedCardCounter}
              </span>
            </div>}
            <MobileArrowIcon
              onClick={toggleCollapseHandler}
              className={classNames(
                styles.groupArrowIcon,
                isCollapsed.state && styles.collapsedGroupArrowIcon
              )}
            />
          </div>
          {sortStage === SortStage.MANUAL_SORT && (
            <div className={styles.groupControls}>
              <MobileArrowBackIcon onClick={deleteModal.toggleValue} />
              <MobileEditGroupNameIcon onClick={renameModal.toggleValue} />
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.collapsableGroupCardContainer}
        style={{
          height: containerHeight
        }}
      >
        <div className={styles.groupCardContainer} ref={cardContainer}>
          {cards.length === 0 && (
            <p className={styles.groupEmptyMessage}>{translates.mobileTranslates.groupIsEmpty}</p>
          )}
          {cards.map(card => (
            <MobileCard
              key={card.id}
              id={card.id}
              text={card.text}
              grade_status={card.grade_status}
              place="group"
              // @ts-ignore
              color={userGroupColorMap && userGroupColorMap.get(card.group)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import {Slider} from "../../../../../system/components";
import {Button} from "../../../../../system/UX";

import styles from '../../CardSortingStyles.module.css';
import WelcomeSlide from "./slides/desctop/WelcomeSlide";
import HeaderWithSubheader from "./HeaderWithSubheader";
import BankSlide from "./slides/desctop/BankSlide";
import AddGroupSlide from "./slides/desctop/AddGroupSlide";
import NameGroupSlide from "./slides/desctop/NameGroupSlide";
import PickGroupSlide from "./slides/desctop/PickGroupSlide";
import ReplaceCardsSlide from "./slides/desctop/ReplaceCardsSlide";
import ChangeMindSlide from "./slides/desctop/ChangeMindSlide";
import BinSlide from "./slides/desctop/BinSlide";
import GradeSlide from "./slides/desctop/GradeSlide";
import ProgressSlide from "./slides/desctop/ProgressSlide";
import InstructionSlide from "./slides/desctop/InstructionSlide";
import SubmitSlide from "./slides/desctop/SubmitSlide";
import {useAppSelector} from "../../../../../../store/store";
import MobileWelcomeSlide from "./slides/mobile/MobileWelcomeSlide";
import MobileBankSlide from "./slides/mobile/MobileBankSlide";
import MobileAddGroupSlide from "./slides/mobile/MobileAddGroupSlide";
import MobileNameGroupSlide from "./slides/mobile/MobileNameGroupSlide";
import MobileWhereGroupSlide from "./slides/mobile/MobileWhereGroupSlide";
import MobilePickCardsSlide from "./slides/mobile/MobilePickCardsSlide";
import MobilePickGroupSlide from "./slides/mobile/MobilePickGroupSlide";
import MobileChangeMindSlide from "./slides/mobile/MobileChangeMindSlide";
import MobileBinSlide from "./slides/mobile/MobileBinSlide";
import MobileGradeSlide from "./slides/mobile/MobileGradeSlide";
import MobileProgressSlide from "./slides/mobile/MobileProgressSlide";
import MobileSubmitSlide from "./slides/mobile/MobileSubmitSlide";
import PickCardsSlide from "./slides/desctop/PickCardsSlide";

const InstructionSlider = ({ onClick }: { onClick: () => void }) => {
    const isMobile = useAppSelector(state => state.appReducer.isMobile)

    const desktopSliderContent = [{
            header: <HeaderWithSubheader
                header='Добро пожаловать!'
                subheader='Давайте пройдем мини-тур по интерфейсу'
            />,
            element: <WelcomeSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>На левой стороне</strong> экрана представлены карточки с идеями, предложенными вами и вашими коллегами.
                <br/>Ваша задача — сгруппировать их по общей тематике или смыслу
            </span>,
            element: <BankSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                Ознакомьтесь со всеми идеями, подумайте, как можно их сгруппировать.
                <br/>Нажмите на <strong>“Добавить группу”</strong>, чтобы создать новую группу
            </span>,
            element: <AddGroupSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Назовите</strong> созданную группу
            </span>,
            element: <NameGroupSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Выберите</strong> одну или несколько карточек, которые хотите поместить в группу, кликнув на них
            </span>,
            element: <PickCardsSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Нажмите на группу,</strong> чтобы перенести в нее выбранные карточки
            </span>,
            element: <PickGroupSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Переместите карточку,</strong> если поняли, что она больше подходит по смыслу к другой группе.
                <br/>Кликните сначала на карточку, а потом на другую группу.
            </span>,
            element: <ReplaceCardsSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                Передумали? Нажмите стрелочку <strong>назад</strong>, и карточки вернутся в банк
            </span>,
            element: <ChangeMindSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                Если смысл карточки неясен, поместите ее в <strong>корзину</strong>.
                <br/>Если вы сомневаетесь, ее можно будет достать из корзины
            </span>,
            element: <BinSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Оцените</strong> карточки: поставьте лайк, дизлайк, или нейтральную оценку
            </span>,
            element: <GradeSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                Отслеживайте <strong>прогресс оценки</strong> карточек
            </span>,
            element: <ProgressSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                Внизу мы собрали пошаговую <strong>инструкцию</strong> и рекомендации
            </span>,
            element: <InstructionSlide />
        }, {
            header: <span className={styles.instructionSliderSpan}>
                <strong>Завершите</strong> работу нажатием кнопки “Завершить сортировку”
            </span>,
            element: <SubmitSlide />
        }, {
            header: <HeaderWithSubheader
                header='Вот и все!'
                subheader='Теперь вы готовы приступить к работе'
            />,
            element: <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                <WelcomeSlide />
                <Button style={{ width: '12rem' }} onClick={onClick}>Начать</Button>
            </div>
        }
    ]

    const mobileSliderContent = [{
            header: <HeaderWithSubheader
                header='Добро пожаловать!'
                subheader='Давайте пройдем мини-тур по интерфейсу'
            />,
            element: <MobileWelcomeSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                <strong>Вверху экрана</strong> представлены карточки с идеями, предложенными вами и вашими коллегами. Ваша задача — сгруппировать их по смыслу
            </span>,
            element: <MobileBankSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                Ознакомьтесь со всеми идеями, подумайте, как можно их сгруппировать.
                <br/><br/>Нажмите на <strong>+</strong>, чтобы добавить группу
            </span>,
            element: <MobileAddGroupSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                <strong>Назовите</strong> созданную группу
            </span>,
            element: <MobileNameGroupSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                Группа появится <strong>внизу</strong>
            </span>,
            element: <MobileWhereGroupSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                <strong>Выберите</strong> одну или несколько карточек, которые хотите поместить в группу, нажав на них
            </span>,
            element: <MobilePickCardsSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                <strong>Нажмите на группу,</strong> чтобы переместить в нее карточки
            </span>,
            element: <MobilePickGroupSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                Передумали? Нажмите стрелочку <strong>назад</strong>, и карточки вернутся в банк
            </span>,
            element: <MobileChangeMindSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                Если смысл карточки неясен, поместите ее в <strong>корзину</strong>.
                <br/>Если вы сомневаетесь, ее можно будет достать из корзины
            </span>,
            element: <MobileBinSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan} style={{ textAlign: 'center' }}>
                <strong>Оцените</strong> карточки: поставьте лайк, дизлайк, или нейтральную оценку
            </span>,
            element: <MobileGradeSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan}>
                Отслеживайте <strong>прогресс оценки</strong> карточек вверху
            </span>,
            element: <MobileProgressSlide />
        }, {
            header: <span className={styles.mobileInstructionSliderSpan} style={{ textAlign: 'center' }}>
                <strong>Завершите</strong> работу нажатием кнопки “Готово”
            </span>,
            element: <MobileSubmitSlide />
        }, {
            header: <HeaderWithSubheader
                header='Вот и все!'
                subheader='Теперь вы готовы приступить к работе'
            />,
            element: <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                <MobileWelcomeSlide />
                <Button style={{ width: '12rem' }} onClick={onClick}>Начать</Button>
            </div>
        }
    ]

    return <Slider
        content={isMobile ? mobileSliderContent : desktopSliderContent}
        containerClassName={isMobile ? styles.mobileInstructionSlider : styles.instructionSlider}
    />
};

export default InstructionSlider;
export interface User {
    id: number
    token: string
    email: string
    name: string
    user_role: UserRole
    client_id: number
}

export enum UserRole {
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
    COMMON_USER = 'common user'
}

export type Language = 'RU' | 'EN'

interface ProjectInfo {
    language: Language
    isLoading: boolean
    fetchError: string
}

export interface AppState {
    user?: User
    projectInfo: ProjectInfo
    isMobile: boolean
    remValue: number
}
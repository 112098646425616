import { $authHost } from "../../axios"
import { DashboardHttpWorker } from "./types/types"

export const DashboardHttp: DashboardHttpWorker = {
  getGraphData: async projectId => {
    const response = await $authHost.get(`/dashboard/${projectId}`)
    return response.data
  },
  getScatterData: async projectId => {
    const response = await $authHost.get(`/dashboard/scatter/${projectId}`)
    return response.data
  },
  getUserData: async projectId => {
    const response = await $authHost.get(`/dashboard/users/${projectId}`)
    return response.data
  },
  getCardSortingInfo: async projectId => {
    const response = await $authHost.get(`/dashboard/cards_info/${projectId}`)
    return response.data
  }
}

import React, {FC} from 'react';

interface MobileLikeIconProps {
    isDislike?: boolean
    onClick?: () => void
    className?: string
}

export const MobileLikeIcon: FC<MobileLikeIconProps> = (props) => {
    const { isDislike = false, onClick, className } = props

    return (
        <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            style={{ transform: isDislike ? 'rotate(180deg)' : 'none' }}
            className={className}
            onClick={onClick}
        >
            <path d="M6.41927 10.082L10.0859 1.83203C10.8153 1.83203 11.5148 2.12176 12.0305 2.63749C12.5462 3.15321 12.8359 3.85269 12.8359 4.58203V8.2487H18.0243C18.29 8.24569 18.5532 8.30049 18.7957 8.40931C19.0382 8.51814 19.2541 8.67837 19.4285 8.87892C19.6029 9.07947 19.7316 9.31553 19.8057 9.57076C19.8798 9.82599 19.8975 10.0943 19.8576 10.357L18.5926 18.607C18.5263 19.0442 18.3043 19.4427 17.9674 19.729C17.6305 20.0154 17.2014 20.1704 16.7593 20.1654H6.41927M6.41927 10.082V20.1654M6.41927 10.082H3.66927C3.18304 10.082 2.71673 10.2752 2.37291 10.619C2.02909 10.9628 1.83594 11.4291 1.83594 11.9154V18.332C1.83594 18.8183 2.02909 19.2846 2.37291 19.6284C2.71673 19.9722 3.18304 20.1654 3.66927 20.1654H6.41927" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
import classNames from "classnames"
import { Dispatch, SetStateAction } from "react"
import {
  GraphologyGradeCategory,
  GraphologyIdeaGroup
} from "../../../../pages/DashboardPage/types/types"
import { CheckboxFilter } from "../../../UI/CheckboxFilter/CheckboxFilter"
import styles from "./LegendStyles.module.css"

interface LegendProps<T> {
  items: GraphologyGradeCategory[] | GraphologyIdeaGroup[]
  setter?: Dispatch<SetStateAction<T>>
  CSSstyles?: {
    container: string
    checked: string
    item: string
    itemHover: string
    iconSize: string
  }
}


export function Legend(props: LegendProps<number[]>) {
  const { items, setter, CSSstyles } = props

  function onSelect(id: number, setter: Dispatch<SetStateAction<number[]>>) {
    setter(prevFilterGroup => {
      const index = prevFilterGroup.indexOf(id)
      if (index !== -1) {
        return [...prevFilterGroup.slice(0, index), ...prevFilterGroup.slice(index + 1)]
      } else {
        return [...prevFilterGroup, id]
      }
    })
  }

  return (
    <div className={CSSstyles?.container}>
      {items?.map(item => (
        <CheckboxFilter
          key={item.id}
          id={item.id}
          onSelect={onSelect}
          setter={setter}
          classNameChecked={CSSstyles?.checked}
        >
          <div
            className={classNames(CSSstyles?.item, setter && CSSstyles?.itemHover)}
          >
            <div
              className={styles.graphLegendIcon}
              style={{ 
                backgroundColor: `${item.color}`,
                width: `${CSSstyles?.iconSize}`,
                height: `${CSSstyles?.iconSize}`
              }}
            ></div>
            <p>{item.name}</p>
          </div>
        </CheckboxFilter>
      ))}
    </div>
  )
}

import React, {FC, useEffect, useRef} from 'react';
import {useAppSelector} from "../../../../../store/store";
import {countLikedAndDislikedCards} from "../utils";

import styles from '../CardSortingStyles.module.css';
import {MobileLikeIcon} from "./icons/MobileLikeIcon";

interface GradesCounterControlProps {
    width?: string
    isBig?: boolean
}

const setGoalWidth = (currentWidth: string, limitCounter: number | undefined, total: number, fullWidth: number) => {
    if (limitCounter) {
        const goalWidth = (fullWidth / total * limitCounter) + 'rem'
        if (parseInt(goalWidth) < parseInt(currentWidth))
            return currentWidth
        else return goalWidth
    } else return currentWidth
}


//TODO: правильно изолировать иконки в структуре папок (и вообще в нужное место перенести компонент)
export const GradesCounterControl: FC<GradesCounterControlProps> = ({
    isBig = false,
    width = '11rem'
}: GradesCounterControlProps) => {
    const { group_state, info } = useAppSelector(state => state.cardSortingReducer)
    const { likeCounter, dislikeCounter } = countLikedAndDislikedCards(group_state)
    const progressBarContainer = useRef<HTMLDivElement>(null)

    const neutralOrUngradedCounter = group_state.total_number_of_cards - likeCounter - dislikeCounter
    const fullWidth = parseInt(width)

    const currentLikesWidth = (fullWidth / group_state.total_number_of_cards * likeCounter) + 'rem'
    const goalLikesWidth = setGoalWidth(currentLikesWidth, info.like_limit, group_state.total_number_of_cards, fullWidth)

    const currentDislikesWidth = (fullWidth / group_state.total_number_of_cards * dislikeCounter) + 'rem'
    const goalDislikesWidth = setGoalWidth(currentDislikesWidth, info.dislike_limit, group_state.total_number_of_cards, fullWidth)

    return (
        <div style={{ width }} ref={progressBarContainer}>
            <div className={ styles.gradesCounters } style={{ marginBottom: isBig ? '0.25rem' : '0.13rem' }}>
                <div className={styles.gradeCounter} style={{ width: '3.5rem', justifyContent: 'start' }}>
                    <MobileLikeIcon className={styles.likeCounterIcon} isDislike={true}/>
                    <span className={styles.gradeCounterSpan}>{dislikeCounter}{info.dislike_limit ? `/${info.dislike_limit}` : ''}</span>
                </div>
                <div className={styles.gradeCounter}>
                    <div className={styles.neutralCounterIcon}/>
                    <span className={styles.gradeCounterSpan}>{neutralOrUngradedCounter}</span>
                </div>
                <div className={styles.gradeCounter} style={{ width: '3.5rem', justifyContent: 'end' }}>
                    <MobileLikeIcon className={styles.likeCounterIcon}/>
                    <span className={styles.gradeCounterSpan}>{likeCounter}{info.like_limit ? `/${info.like_limit}` : ''}</span>
                </div>
            </div>
            <div className={styles.progressBarsContainer} style={{ height: isBig ? '0.5rem' : '0.37rem' }}>
                <div className={styles.goalDislikeProgressBar} style={{ width: goalDislikesWidth }}>
                    <div className={styles.currentDislikeProgressBar} style={{ width: currentDislikesWidth }}/>
                </div>
                <div className={styles.goalLikeProgressBar} style={{ width: goalLikesWidth }}>
                    <div className={styles.currentLikeProgressBar} style={{ width: currentLikesWidth }}/>
                </div>
            </div>
        </div>
    );
};
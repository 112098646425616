import classNames from "classnames"
import { FC, MouseEvent, ReactNode, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { DashboardNavUrl } from "../../../../pages/DashboardPage/types/types"
import { MouseHint } from "../../../system/components"
import { NavigationLink } from "../../../system/UX/NavigationLink/NavigationLink"
import styles from "./DashboardFrameStyles.module.css"
import "./ScrollbarStyles.css"
import { dashboardFrameTranslates } from "./translates/ruTranslates"
import { QuestionIcon } from "./components/icons/QuestionIcon"

interface DashboardFrameProps {
  navigationUrls: DashboardNavUrl[]
  question: string
  children: ReactNode
  user: {
    pic?: string
    name: string
  }
  // userCounter?: {
  //   users_count: number
  //   users_submitted: number
  // }
}

export const DashboardFrame: FC<DashboardFrameProps> = props => {
  const { question, children, navigationUrls, user } = props
  const { projectId } = useParams()
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLParagraphElement> | null>(null)
  // TODO get translates
  const translates = dashboardFrameTranslates
  const maxQuestionLength = 160 // characters

  const shortedQuestion = useMemo(
    () =>
      question.length > maxQuestionLength
        ? `${question.slice(0, maxQuestionLength)}...`
        : question,
    [question]
  )

  return (
    <div className={styles.dashboardFrameContainer}>
      {hintEvent && <MouseHint event={hintEvent}>{question}</MouseHint>}
      <div className={styles.dashboardFrameHeader}>
        <div className={styles.dashboardFrameHeaderNavName}>
          <div
            className={styles.dashboardFrameProjectName}
            onMouseEnter={event => question.length > maxQuestionLength && setHintEvent(event)}
            onMouseLeave={() => setHintEvent(null)}
          >
            <QuestionIcon />
            <p>{shortedQuestion}</p>
          </div>
          <div className={styles.dashboardFrameNavigation}>
            {navigationUrls.map(url => (
              <NavigationLink key={url} to={`/dashboard/${projectId}/${url}`}>
                {translates.navigation[url]}
              </NavigationLink>
            ))}
          </div>
        </div>
        <div className={styles.dashboardFrameUser}>
          <div className={styles.dashboardFrameUserRow}>
            <p>{user.name}</p>
            <img
              alt="User"
              src={`${process.env.PUBLIC_URL}/userpics/${user.pic}`}
              className={styles.dashboardUserPic}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.dashboardFrameOutlet, "scrollbar")}>
          {children}
      </div>
    </div>
  )
}

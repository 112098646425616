// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MouseHintStyles_mouseHint__yTGz\\+ {\n    display: flex;\n    position: absolute;\n    background: #323232;\n    border-radius: 0.625rem;\n    width: auto;\n    z-index: 1000;\n    padding: 0.5rem 1rem;\n}\n\n.MouseHintStyles_mouseHint__yTGz\\+ p {\n    hyphens: auto;\n    word-wrap: break-word;\n    -webkit-box-orient: vertical;\n    -moz-hyphens: auto;\n    -webkit-hyphens: auto;\n    -ms-hyphens: auto;\n    width: auto;\n    color: white;\n    font-size: 1rem;\n    max-width: 14rem;\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/components/MouseHint/MouseHintStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,4BAA4B;IAC5B,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".mouseHint {\n    display: flex;\n    position: absolute;\n    background: #323232;\n    border-radius: 0.625rem;\n    width: auto;\n    z-index: 1000;\n    padding: 0.5rem 1rem;\n}\n\n.mouseHint p {\n    hyphens: auto;\n    word-wrap: break-word;\n    -webkit-box-orient: vertical;\n    -moz-hyphens: auto;\n    -webkit-hyphens: auto;\n    -ms-hyphens: auto;\n    width: auto;\n    color: white;\n    font-size: 1rem;\n    max-width: 14rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mouseHint": "MouseHintStyles_mouseHint__yTGz+"
};
export default ___CSS_LOADER_EXPORT___;

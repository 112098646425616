import React, {FC, MouseEventHandler, ReactNode} from 'react'

import styles from './MobileButtonStyles.module.css'
import classNames from "classnames";

interface MobileButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
    className?: string
    children?: string
    isPending?: boolean
    type?: 'button' | 'submit'
    disabled?: boolean
}

export const MobileButton: FC<MobileButtonProps> = (props) => {
    const {
        className,
        children,
        type = 'submit',
        onClick = () => undefined,
        isPending = false,
        disabled = false,
    } = props

    return (
        <button
            className={classNames(
                styles.button,
                type === 'submit' && styles.submit,
                disabled && styles.disabled,
                className
            )}
            type={type}
            onClick={onClick}
            disabled={disabled || isPending}
        >
            {type === 'submit' ? <MobileCheckIcon /> : children}
        </button>
    )
}

const MobileCheckIcon = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.625 10.9375L9.125 18.4375L20.375 1.5625" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
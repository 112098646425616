import React, {FC, MouseEvent, useState} from 'react';

import styles from "../../CardSortingStyles.module.css";
import {MouseHint} from "../../../../../system/components";
import {ArrowBackIcon} from "../icons";
import classNames from "classnames";
import {useTranslate} from "../../translates/useTranslate";
import {CardProps} from "./CardInBank";
import {useAppDispatch} from "../../../../../../store/store";
import {cardSortingSlice} from "../../reducers/cardSortingReducer/cardSortingReducer";
import CardText from "./CardText";

export const CardInBin: FC<CardProps> = (props) => {
    const { text, id } = props.card

    const dispatch = useAppDispatch()
    const moveFromBinToBank = cardSortingSlice.actions.moveFromBinToBank
    const translates = useTranslate()
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()

    return (
        <div className={classNames(styles.card, styles.cardInBin)}>
            {hintEvent && <MouseHint event={hintEvent}>
                {translates.backMouseHint}
            </MouseHint>}
            <div
                onMouseMove={(e) => setHintEvent(e)}
                onMouseLeave={() => setHintEvent(null)}
                className={styles.cursorPointer}
                onClick={() => dispatch(moveFromBinToBank(id))}
            >
                <ArrowBackIcon className={styles.arrowBack}/>
            </div>
            <p className={classNames(styles.cardText, styles.cardInBinText)}>
                <CardText>{text}</CardText>
            </p>
        </div>
    );
};
import classNames from "classnames"
import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { AnalyticsGradeColor } from "../../../../pages/DashboardPage/types/constants"
import { ContextType } from "../../../../pages/DashboardPage/types/types"
import { IconButtonTemp } from "../../../UI/IconButtonTemp/IconButtonTemp"
import styles from "./AnalyticsStyles.module.css"
import { CardInTable } from "./components/CardInTable/CardInTable"
import { GradesBar } from "./components/GradesBar"
import { DownloadIcon } from "./components/icons/DownloadIcon"
import { getAbbreviation } from "./utils"

interface AnalyticsProps {
  totalGrades: number
  groupFilters: number[]
}

// TODO для Иры (31.07.24): разбить страницу на компонентики поменьше
// TODO generic types
// TODO translates
export function Analytics(props: AnalyticsProps) {
  const { groupFilters, totalGrades } = props
  const { clusters, graphData, cardsMap } = useOutletContext<ContextType>()

  const nestedColumnsStyle = useMemo(
    () => ({
      gridTemplateColumns: `repeat(${clusters?.length}, 1fr)`
    }),
    [clusters]
  )

  const filteredCards = useMemo(() => {
    return groupFilters?.length === 0
      ? [...graphData.nodes]
      : graphData.nodes.filter(card => {
          return groupFilters.includes(card.attributes.idea_group_id)
        })
  }, [groupFilters])

  return (
    <div className={styles.analyticsContainer}>
      <div className={styles.analyticsFiltersClustersContainer}>
        <IconButtonTemp label=".xlml" className={styles.analyticsDownloadIcon}>
          <DownloadIcon />
        </IconButtonTemp>
      </div>
      <div className={styles.analyticsTable}>
        <div className={styles.analyticsTableHead}>
          <div className={styles.analyticsTableItem}>
            <p>Идеи</p>
          </div>
          <div className={styles.analyticsTableItem}>
            <p style={{color: AnalyticsGradeColor.liked}}>Готовы лидировать</p>
            <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
              {clusters?.map(cluster => (
                <div key={cluster.id} className={styles.analyticsTableItem}>
                  <p>{getAbbreviation(cluster.name)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.analyticsTableItem}>
            <p>Готовы участвовать</p>
            <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
              {clusters?.map(cluster => (
                <div key={cluster.id} className={styles.analyticsTableItem}>
                  <p>{getAbbreviation(cluster.name)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.analyticsTableItem}>
            <p style={{color: AnalyticsGradeColor.disliked}}>Не уверены</p>
            <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
              {clusters?.map(cluster => (
                <div key={cluster.id} className={styles.analyticsTableItem}>
                  <p>{getAbbreviation(cluster.name)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.analyticsTableBody}>
          <SimpleBar
            style={{
              maxHeight: "100%",
              width: "100%",
              paddingBottom: "0.05rem"
            }}
            autoHide={true}
          >
            {filteredCards.map(card => {
              const cardEssentials = cardsMap.get(card.key)
              return (
                <div key={card.key} className={styles.analyticsTableRow}>
                  <div
                    className={classNames(
                      styles.analyticsTableItem,
                      styles.analyticsTableRowTextItem
                    )}
                  >
                    {cardEssentials && <CardInTable card={cardEssentials} />}
                  </div>
                  <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
                    {clusters?.map(cluster => (
                      <div key={cluster.id} className={styles.analyticsTableItem}>
                        <GradesBar
                          color="liked"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === cluster.id
                            )?.grades.liked
                          }
                          totalGrades={totalGrades}
                        />
                      </div>
                    ))}
                    {clusters?.length === 0 && (
                      <div className={styles.analyticsTableItem}>
                        <GradesBar
                          color="liked"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === "no_cluster"
                            )?.grades.liked
                          }
                          totalGrades={totalGrades}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
                    {clusters?.map(cluster => (
                      <div key={cluster.id} className={styles.analyticsTableItem}>
                        <GradesBar
                          color="neutral"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === cluster.id
                            )?.grades.neutral
                          }
                          totalGrades={totalGrades}
                        />
                      </div>
                    ))}
                    {clusters?.length === 0 && (
                      <div className={styles.analyticsTableItem}>
                        <GradesBar
                          color="neutral"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === "no_cluster"
                            )?.grades.neutral
                          }
                          totalGrades={totalGrades}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.analyticsTableItemNested} style={nestedColumnsStyle}>
                    {clusters?.map(cluster => (
                      <div key={cluster.id} className={styles.analyticsTableItem}>
                        <GradesBar
                          totalGrades={totalGrades}
                          color="disliked"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === cluster.id
                            )?.grades.disliked
                          }
                        />
                      </div>
                    ))}
                    {clusters?.length === 0 && (
                      <div className={styles.analyticsTableItem}>
                        <GradesBar
                          totalGrades={totalGrades}
                          color="disliked"
                          grades={
                            card.attributes.grades.find(
                              targetCluster => targetCluster.cluster === "no_cluster"
                            )?.grades.disliked
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  )
}

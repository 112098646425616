import { CSSProperties, FC, MouseEvent, ReactNode, useEffect, useState } from "react"

import { useAppSelector } from "../../../../store/store"
import { Portal } from "../../service"
import styles from "./MouseHintStyles.module.css"

interface MouseHintProps {
  event: MouseEvent
  children: string
}

function countLines(text: string, widthRem: number) {
  const words = text.split(" ")
  const lines = []
  let currentLine = ""

  for (const word of words) {
    // 0.5rem примерная ширина буквы в большинстве шрифтов
    if ((currentLine.length + word.length + 1) * 0.5 <= widthRem) {
      if (currentLine) {
        currentLine += " " + word
      } else {
        currentLine = word
      }
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  }

  if (currentLine) {
    lines.push(currentLine)
  }

  return lines.length
}

export const MouseHint: FC<MouseHintProps> = ({ event, children }) => {
  const [position, setPosition] = useState<CSSProperties>()
  const remValue = useAppSelector(state => state.appReducer.remValue)
  const hintOffsetX = 1 * remValue // px
  const hintOffsetY = 1 * remValue // px
  const textWidth = 14 // rem
  const maxHintWidth = textWidth + 2 // ширина контейнера из стилей (textWidth + 2 * padding + отступ от event.pageX)
  const hintHeight = (countLines(children, textWidth) * 1.1 + 1) * remValue // + line gaps + padding
  const scrollbarWidth = window.innerWidth - document.body.clientWidth // px

  useEffect(() => {
    const exceedsX = event.pageX + remValue * maxHintWidth + hintOffsetX >= window.innerWidth
    const exceedsY = event.pageY + hintHeight + hintOffsetY >= window.innerHeight + window.scrollY
    const isYScrollbar =
      document.documentElement.scrollHeight > document.documentElement.clientHeight

    if (exceedsX && exceedsY) {
      setPosition({
        bottom: window.innerHeight - event.pageY + hintOffsetY,
        right:
          window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
      })
    } else if (exceedsX && !exceedsY) {
      setPosition({
        top: event.pageY + hintOffsetY,
        right:
          window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
      })
    } else if (exceedsY && !exceedsX) {
      setPosition({
        bottom: window.innerHeight - event.pageY + hintOffsetY,
        left: event.pageX + hintOffsetX
      })
    } else {
      setPosition({
        top: event.pageY + hintOffsetY,
        left: event.pageX + hintOffsetX
      })
    }
  }, [event, hintHeight, hintOffsetX, hintOffsetY, maxHintWidth, remValue, scrollbarWidth])

  return position ? (
    <Portal>
      <div className={styles.mouseHint} style={{ ...position }}>
        <p>{stripHtmlTags(children)}</p>
      </div>
    </Portal>
  ) : (
    <></>
  )
}

function stripHtmlTags(html: string): string {
  const doc = new DOMParser().parseFromString(html, "text/html")
  return doc.body.textContent || ""
}

import classNames from "classnames"
import _ from "lodash"
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { getObjectFromLocalStorage, saveObjectInLocalStorage } from "../../../../../common/utils"
import { useAppDispatch, useAppSelector } from "../../../../../store/store"
import { HintButton, InstructionBlock, MouseHint } from "../../../../system/components"
import Header from "../../../../system/components/Header/Header"
import { Button, IconTextButton, Input, MinusIcon, PlusIcon } from "../../../../system/UX"
import styles from "../CardSortingStyles.module.css"
import { saveCards } from "../http"
import { cardSortingSlice } from "../reducers/cardSortingReducer/cardSortingReducer"
import { useTranslate } from "../translates/useTranslate"
import { TemplateCard } from "./TemplateCard/TemplateCard"

interface AddCardsControlProps {
  maxCards: number
  minCards: number
  cardTemplate?: string[]
}

const isAllCardsFilled = (cards: Array<string | string[]>) => {
  return cards.every(card =>
    typeof card === "string" ? card !== "" : card.every(field => field !== "")
  )
}

export const AddCardsControl: FC<AddCardsControlProps> = props => {
  const { maxCards, minCards, cardTemplate } = props

  const translates = useTranslate()
  const dispatch = useAppDispatch()
  const question = useAppSelector(state => state.cardSortingReducer.info.question_list)
  const info = useAppSelector(state => state.cardSortingReducer.info)
  const isMobile = useAppSelector(state => state.appReducer.isMobile)
  const project_id = Number(useParams().project_id)
  const { setCardsFilled } = cardSortingSlice.actions
  const [cards, setCards] = useState<Array<string | string[]>>(
    new Array(minCards).fill(cardTemplate ? new Array<string>(cardTemplate.length).fill("") : "")
  )
  const [isPending, setIsPending] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isInstructionConfirmed, setIsInstructionConfirmed] = useState(true)
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()

  const changeCardTextByIndex = (
    event: ChangeEvent<HTMLInputElement>,
    cardIndex: number,
    fieldIndex?: number
  ) => {
    setCards(prevCards => {
      const modifiedCards = prevCards.map((card, index) => {
        if (index === cardIndex) {
          if (Array.isArray(card)) {
            return card.map((field, index) => (index === fieldIndex ? event.target.value : field))
          } else {
            return event.target.value
          }
        }
        return card
      })
      setIsDisabled(!isAllCardsFilled(modifiedCards))
      return modifiedCards
    })
  }

  const onBlur = (cards: Array<string | string[]>) => {
    const projectDataObject = getObjectFromLocalStorage(String(project_id))
    saveObjectInLocalStorage(String(project_id), {
      ...projectDataObject,
      cards
    })
  }

  const addCard = () => {
    if (cardTemplate) {
      if (cards.length < maxCards) {
        setIsDisabled(true)
        setCards(prevTemplateCards => {
          const modifiedCards = _.cloneDeep(prevTemplateCards)
          modifiedCards.push(new Array<string>(cardTemplate.length).fill(""))
          const projectDataObject = getObjectFromLocalStorage(String(project_id))
          saveObjectInLocalStorage(String(project_id), {
            ...projectDataObject,
            cards: modifiedCards
          })
          return modifiedCards
        })
      }
    } else {
      if (cards.length < maxCards) {
        setIsDisabled(true)
        const modifiedCards = [...cards]
        modifiedCards.push("")

        const projectDataObject = getObjectFromLocalStorage(String(project_id))
        saveObjectInLocalStorage(String(project_id), {
          ...projectDataObject,
          cards: modifiedCards
        })

        setCards(modifiedCards)
      }
    }
  }

  const removeCard = () => {
    if (cardTemplate) {
      if (cards.length > minCards) {
        setCards(prevTemplateCards => {
          const modifiedCards = _.cloneDeep(prevTemplateCards)
          modifiedCards.pop()
          setIsDisabled(!isAllCardsFilled(modifiedCards))
          const projectDataObject = getObjectFromLocalStorage(String(project_id))
          saveObjectInLocalStorage(String(project_id), {
            ...projectDataObject,
            cards: modifiedCards
          })
          return modifiedCards
        })
      }
    } else {
      if (cards.length > minCards) {
        const modifiedCards = [...cards]
        modifiedCards.pop()

        setIsDisabled(!isAllCardsFilled(modifiedCards))

        const projectDataObject = getObjectFromLocalStorage(String(project_id))
        saveObjectInLocalStorage(String(project_id), {
          ...projectDataObject,
          cards: modifiedCards
        })

        setCards(modifiedCards)
      }
    }
  }

  const save = () => {
    if (!isDisabled) {
      setIsPending(true)
      let cardsToSave
      if (cardTemplate) {
        cardsToSave = cards.map(card => {
          if (Array.isArray(card)) {
            const textCard = card.reduce((accumulator, field, fieldIndex) => {
              return accumulator + cardTemplate[fieldIndex] + " " + field + "\n"
            }, "")
            return textCard
          } else {
            return card
          }
        })
      } else {
        cardsToSave = cards
      }
      saveCards({
        cards: [
          {
            question_id: question ? question.questions[0].id : 0,
            cards: cardsToSave as string[]
          }
        ]
      }).then(() => {
        dispatch(setCardsFilled())
      })
    }
  }

  const showButtonHint = (e: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) setHintEvent(e)
  }

  useEffect(() => {
    const projectDataObject = getObjectFromLocalStorage(String(project_id))

    if (projectDataObject.cards) {
      setIsDisabled(!isAllCardsFilled(projectDataObject.cards))
      // TODO сюда внимательно распарсить из стораджа в зависимости от cardTemplate
      // и если поменялся cardTemplate то сторадж тоже обновить, чтобы данные там оставались одного формата
      setCards(projectDataObject.cards)
    }

    if (projectDataObject.isCardsInstructionConfirmed) setIsInstructionConfirmed(true)
    else setIsInstructionConfirmed(false)
  }, [])

  return (
    <>
      {!isInstructionConfirmed && (
        <InstructionBlock
          title={info.instruction_title}
          content={info.instruction_text}
          confirm={{
            handler: () => {
              setIsInstructionConfirmed(true)
              const projectDataObject = getObjectFromLocalStorage(String(project_id))
              saveObjectInLocalStorage(String(project_id), {
                ...projectDataObject,
                isCardsInstructionConfirmed: true
              })
            },
            confirmationText: translates.gotItButton
          }}
        />
      )}

      {isInstructionConfirmed && (
        <div className={styles.questionPlayerContainer} style={isMobile ? {padding: "2rem 0 1rem 0"} : {}}>
          {hintEvent && <MouseHint event={hintEvent}>{translates.sendCardsButtonHint}</MouseHint>}

          <p className={styles.question}>{question && question.questions[0].text}</p>
          <SimpleBar
            style={{
              ...(isMobile && {height: "22rem"}),
              ...(!isMobile && {maxHeight: "25rem"}),
              width: "100%"
            }}
            autoHide={false}
          >
            <div
              className={classNames(
                styles.questionInputContainer,
                isMobile && styles.mobileQuestionInputContainer
              )}
            >
              {!cardTemplate &&
                cards.map((card, index) => (
                  <Input
                    key={index}
                    placeholder={translates.cardInputPlaceholder}
                    isTextareaView={true}
                    inputClassName={classNames(
                      styles.questionInput,
                      isMobile && styles.mobileQuestionInput
                    )}
                    value={card as string}
                    onChange={e => changeCardTextByIndex(e, index)}
                    onBlur={() => onBlur(cards)}
                  />
                ))}
              {cardTemplate &&
                cards.map((card, index) => (
                  // TODO unique placeholders?
                  <TemplateCard
                    key={index}
                    index={index}
                    card={card as string[]}
                    template={cardTemplate}
                    onChange={changeCardTextByIndex}
                    onBlur={() => onBlur(cards)}
                    isMobile={isMobile}
                  />
                ))}
            </div>
          </SimpleBar>
          {question && question.min_cards_per_question !== question.max_cards_per_question && (
            <div
              className={classNames(
                styles.plusAndMinusControls,
                isMobile && styles.mobilePlusAndMinusControls
              )}
            >
              <IconTextButton label={translates.removeCardButton} onClick={removeCard}>
                <MinusIcon />
              </IconTextButton>
              <IconTextButton label={translates.addCardButton} onClick={addCard}>
                <PlusIcon />
              </IconTextButton>
            </div>
          )}
          <Button
            onClick={save}
            isPending={isPending}
            disabled={isDisabled}
            onMouseMove={showButtonHint}
            onMouseLeave={() => setHintEvent(null)}
          >
            {translates.save + ` (${cards.length} / ${maxCards})`}
          </Button>
          <HintButton
            onClick={() => setIsInstructionConfirmed(false)}
            className={isMobile && styles.hintButton}
          >
            {isMobile ? translates.mobileHintNotification : translates.hintNotification}
          </HintButton>
          {!isMobile && <Header />}
        </div>
      )}
    </>
  )
}

import classNames from "classnames"
import { MouseEventHandler } from "react"
import { NodeCategoryColor } from "../../../../../../pages/DashboardPage/types/constants"
import { IconButtonTemp } from "../../../../../UI/IconButtonTemp/IconButtonTemp"
import { CloseIcon } from "./icons/CloseIcon"
import { LegendGravityIcon } from "./icons/LegendGravityIcon"
import { LegendThemeIcon } from "./icons/LegendThemeIcon"
import styles from "./InstructionStyles.module.css"

interface InstructionProps {
  onClose: MouseEventHandler
  active: boolean
}

export function Instruction(props: InstructionProps) {
  const { onClose, active } = props

  return (
    <div
      className={classNames(
        styles.instructionContainer,
        active && styles.instructionContainerActive
      )}
    >
      <div className={styles.instructionLegend}>
        <div className={styles.instructionBlock}>
          <p>Пятно</p>
          <div
            className={styles.instructionNode}
            style={{ width: "1.43rem", height: "1.43rem" }}
          ></div>
          <p>Один из ответов/идей/решений/предложений</p>
        </div>
        <div className={styles.instructionBlock}>
          <p>Размер пятна</p>
          <div className={styles.instructionNodePict}>
            <div
              className={styles.instructionNode}
              style={{ width: "1.43rem", height: "1.43rem" }}
            ></div>
            <div
              className={styles.instructionNode}
              style={{ width: "1rem", height: "1rem" }}
            ></div>
            <div
              className={styles.instructionNode}
              style={{ width: "0.75rem", height: "0.75rem" }}
            ></div>
          </div>
          <p>Большие пятна - это ключевые идеи с высокой степенью влияния</p>
        </div>
        <div className={styles.instructionBlock}>
          <p>Притяжение</p>
          <LegendGravityIcon />
          <p>Чем ближе пятна друг к другу, тем больше эти идеи похожи друг на друга</p>
        </div>
        <div className={styles.instructionBlock}>
          <p>Цвета по темам</p>
          <LegendThemeIcon />
          <p>Единые тренды обозначены одним цветом</p>
        </div>
        <div className={styles.instructionBlock}>
          <p>Цвета согласованности</p>
          <div className={styles.instructionNodePict}>
            <div
              className={styles.instructionNode}
              style={{
                width: "1rem",
                height: "1rem",
                backgroundColor: `${NodeCategoryColor.bottom}`
              }}
            ></div>
            <div
              className={styles.instructionNode}
              style={{
                width: "1.43rem",
                height: "1.43rem",
                backgroundColor: `${NodeCategoryColor.local}`
              }}
            ></div>
          </div>
          <p>Показывают, что уже можно брать в работу, а где требуются уточнения</p>
        </div>
      </div>
      <IconButtonTemp onClick={onClose}>
        <CloseIcon />
      </IconButtonTemp>
    </div>
  )
}

import classNames from "classnames"
import { CSSProperties, ReactNode } from "react"
import { NavLink } from "react-router-dom"
import styles from "./NavigationLinkStyles.module.css"

interface NavigationLinkProps {
  to: string
  children: string | ReactNode
  className?: string
  style?: CSSProperties
}

export function NavigationLink(props: NavigationLinkProps) {
  const { to, children, className, style } = props

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.navLink, isActive && styles.navLinkActive, className)
      }
      style={{...style}}
    >
      {children}
    </NavLink>
  )
}

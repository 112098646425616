// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputStyles_inputContainer__4R-XB {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n}\n\n.InputStyles_input__38c\\+E {\n    outline: none;\n    font-size: 0.875rem;\n    padding: 0.6875rem 1rem;\n    border-radius: 0.625rem;\n    min-height: 2.75rem;\n    border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n    resize: none;\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/Input/InputStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;IACnB,6CAA6C;IAC7C,YAAY;AAChB","sourcesContent":[".inputContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n}\n\n.input {\n    outline: none;\n    font-size: 0.875rem;\n    padding: 0.6875rem 1rem;\n    border-radius: 0.625rem;\n    min-height: 2.75rem;\n    border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n    resize: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": "InputStyles_inputContainer__4R-XB",
	"input": "InputStyles_input__38c+E"
};
export default ___CSS_LOADER_EXPORT___;

import { MouseEvent, useState } from "react"
// @ts-ignore
import { useToggle } from "../../../../../../common/hooks"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { HintButton, MouseHint } from "../../../../../system/components"
import { IconButton, PlusIcon } from "../../../../../system/UX"
import styles from "../../CardSortingStyles.module.css"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import { useTranslate } from "../../translates/useTranslate"
import { GradesCounterControl } from "../GradesCounterControl"
import { BinIcon } from "../icons/BinIcon"
import { WrapGroupsIcon } from "../icons/WrapGroupsIcon"
import { Bin } from "./Bin"
import { SortStage } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"

//TODO: убрать из местного транслейта блок CardCounter
export const SortingControls = ({ onClick }: { onClick?: (e: any) => void }) => {
  const binModal = useToggle(false)
  const translates = useTranslate()
  const dispatch = useAppDispatch()
  const { createGroup, createGroupAndMovePickedCards, toggleDesktopGroupsCollapsed } =
    cardSortingSlice.actions
  const { group_state } = useAppSelector(state => state.cardSortingReducer)
  const sortStage = useAppSelector(state => state.cardSortingReducer.sortStage)

  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
  const [hintText, setHintText] = useState("")

  const addGroupButtonHandler = () => {
    if (group_state.pickedCards.cards.length === 0) {
      dispatch(createGroup(translates.group))
    } else {
      dispatch(createGroupAndMovePickedCards(translates.group))
    }
  }

  return (
    <div className={styles.controls}>
      {hintEvent && <MouseHint event={hintEvent}>{hintText}</MouseHint>}
      <div className={styles.addGroup}>
        <HintButton onClick={onClick}>{translates.hintNotification}</HintButton>
        <IconButton
          onClick={binModal.toggleValue}
          isActive={binModal.state}
          onMouseMove={e => {
            setHintEvent(e)
            setHintText(translates.trashButtonHint)
          }}
          onMouseLeave={() => setHintEvent(null)}
        >
          <BinIcon />
        </IconButton>
        <IconButton
          onClick={() => dispatch(toggleDesktopGroupsCollapsed())}
          onMouseMove={e => {
            setHintEvent(e)
            setHintText(
              group_state.isDesktopGroupsCollapsed
                ? translates.unwrapGroupsHint
                : translates.wrapGroupsHint
            )
          }}
          onMouseLeave={() => setHintEvent(null)}
        >
          <WrapGroupsIcon isActive={!group_state.isDesktopGroupsCollapsed} />
        </IconButton>

        {binModal.state && <Bin closeHandler={binModal.toggleValue} />}

        {sortStage === SortStage.MANUAL_SORT && (
          <button className={styles.addGroupControl} onClick={addGroupButtonHandler}>
            <PlusIcon stroke="black" />
            <p className={styles.addGroupHeader}>{translates.addGroupButton}</p>
          </button>
        )}
      </div>

      {sortStage === SortStage.QUADRANT_SORT && (
        <div className={styles.desktopGradeCounterContainer}>
          <div className={styles.desktopGradeCounter}>
            <h4 className={styles.desktopGradeCounterFont}>{translates.cardCounter.ratedCards}</h4>
            <GradesCounterControl />
          </div>
        </div>
      )}
    </div>
  )
}

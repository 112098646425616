import { useMemo, useState } from "react"
import { ContextType, DashboardScatterOut } from "../../../../pages/DashboardPage/types/types"
import { Scatter } from "./components/Scatter/Scatter"
import styles from "./ScatterChartStyles.module.css"
import { useOutletContext } from "react-router-dom"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { Legend } from "../../../system/components/Legend/Legend"
import { quadrantGroupColors3x3 } from "../../../../common/constants"
import SimpleBar from "simplebar-react"

interface ScatterChartProps {
  data: DashboardScatterOut
}

export function ScatterChart(props: ScatterChartProps) {
  const { data } = props
  // TODO 8 (always the best group)
  const [groupFilter, setGroupFilter] = useState<number[]>([])
  const [pickedCardGroup, setPickedCardGroup] = useState<number>(8)
  const { cardsMap, ideaGroups } = useOutletContext<ContextType>()

  const scatterLegendStyles = useMemo(
    () => ({
      container: styles.scatterLegend,
      checked: styles.scatterLegendRowActive,
      item: styles.scatterLegendRow,
      itemHover: styles.scatterLegendHover,
      iconSize: "0.6rem"
    }),
    []
  )

  function onClick(quadrantGroupIndex: number) {
    setPickedCardGroup(quadrantGroupIndex)
  }

  const filteredScatterData = useMemo(() => {
    if (groupFilter.length !== 0) {
      return data.cards.filter(card => {
        const ideaGroup = cardsMap.get(String(card.id))?.ideaGroup.id
        return ideaGroup && groupFilter.includes(ideaGroup)
      })
    }
    return data.cards
  }, [groupFilter])

  const sameGroupCards = useMemo(() => {
    const sameGroupCards = data.cards.filter(card => card.quadrant_group === pickedCardGroup)
    if (groupFilter.length !== 0) {
      return sameGroupCards.filter(card => {
        const ideaGroup = cardsMap.get(String(card.id))?.ideaGroup.id
        return ideaGroup && groupFilter.includes(ideaGroup)
      })
    }
    return sameGroupCards
  }, [data.cards, pickedCardGroup, groupFilter])

  return (
    <div className={styles.scatterContainer}>
      <div className={styles.scatterHeader}>
        <div>LEGEND</div>
        {ideaGroups && (
          <Legend items={ideaGroups} setter={setGroupFilter} CSSstyles={scatterLegendStyles} />
        )}
      </div>
      <div className={styles.scatterScatter}>
        <div className={styles.scatterChart}>
          <Scatter
            options={{ grid: "3x3", widthRem: 55, heightRem: 30 }}
            data={filteredScatterData}
            handlers={{ onClick: onClick }}
            info={{ groups: data.groups, areas: data.areas }}
          />
        </div>
        <div
          className={styles.scatterSameGroup}
          style={{ backgroundColor: quadrantGroupColors3x3.get(pickedCardGroup) }}
        >
          <p>{data.groups.find(group => group.index === pickedCardGroup)?.name}</p>
          <div className={styles.scatterSameGroupCards}>
            <SimpleBar style={{ maxHeight: "100%", width: "100%" }} autoHide={false}>
              <div className={styles.scatterSameGroupCardsUnderScroll}>
              {sameGroupCards.map(card => {
                const cardEssentials = cardsMap.get(String(card.id))
                return cardEssentials && <DashboardCard key={card.id} card={cardEssentials} />
              })}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuadrantSortGridControlStyles_gridControlContainer__xqYGe {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.QuadrantSortGridControlStyles_gridControlAbsolute__DT-91 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.QuadrantSortGridControlStyles_gridControlXAreasBlock__GqDqD {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.QuadrantSortGridControlStyles_gridControlYAreasBlock__B7NFQ {\n  display: flex;\n  flex-direction: column-reverse;\n  justify-content: space-between;\n}\n\n.QuadrantSortGridControlStyles_gridControlXArea__3BLh9 {\n  height: 100%;\n  background-color: #74C5C8;\n}\n\n.QuadrantSortGridControlStyles_gridControlYArea__0qWJq {\n  width: 100%;\n  background-color: #C5C1F0;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/CardSortingAdmin/components/QuadrantSortGridControl/QuadrantSortGridControlStyles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".gridControlContainer {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.gridControlAbsolute {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.gridControlXAreasBlock {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.gridControlYAreasBlock {\n  display: flex;\n  flex-direction: column-reverse;\n  justify-content: space-between;\n}\n\n.gridControlXArea {\n  height: 100%;\n  background-color: #74C5C8;\n}\n\n.gridControlYArea {\n  width: 100%;\n  background-color: #C5C1F0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridControlContainer": "QuadrantSortGridControlStyles_gridControlContainer__xqYGe",
	"gridControlAbsolute": "QuadrantSortGridControlStyles_gridControlAbsolute__DT-91",
	"gridControlXAreasBlock": "QuadrantSortGridControlStyles_gridControlXAreasBlock__GqDqD",
	"gridControlYAreasBlock": "QuadrantSortGridControlStyles_gridControlYAreasBlock__B7NFQ",
	"gridControlXArea": "QuadrantSortGridControlStyles_gridControlXArea__3BLh9",
	"gridControlYArea": "QuadrantSortGridControlStyles_gridControlYArea__0qWJq"
};
export default ___CSS_LOADER_EXPORT___;

export function splitToLines(
  context: CanvasRenderingContext2D,
  text: string,
  // fontSize: number,
  maxWidthPx: number
) {
  const parts = text.split("grades:")
  const textContent = parts[0].trim()
  
  const gradesLine = parts[1].trim()
  const templateLines = textContent.split("\n")
  
  let lines = []

  templateLines.forEach(line => {
    const words = line.split(" ")
    let currentLine = ""
  
    words.forEach(word => {
      const testLine = currentLine + word + " "
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width
  
      if (testWidth > maxWidthPx && currentLine.length > 0) {
        lines.push(currentLine.trim())
        currentLine = word + " "
      } else {
        currentLine = testLine
      }
    })
  
    if (currentLine.length > 0) {
      lines.push(currentLine.trim())
    }
  })

  lines.push(gradesLine)

  return lines
}

import { $authHost } from "../../../../axios"
import { getObjectFromLocalStorage } from "../../../../common/utils"
import {
  GroupState,
  SortStage,
  UserStatusOut
} from "./reducers/cardSortingReducer/cardSortingReducerTypes"
import { QuadrantSortingOut, SaveCardsIn, SortingStateIn } from "./types"

export const saveCards = async (cards: SaveCardsIn) => {
  const response = await $authHost.post(`/card_sorting/save_cards`, cards)
  return response.data
}

export const getSortingState = async (project_id: number) => {
  const response = await $authHost.get<SortingStateIn>(
    `/card_sorting/state?project_id=${project_id}`
  )
  return response.data
}

export const getUserStatus = async (project_id: number) => {
  const response = await $authHost.get<UserStatusOut>(
    `/card_sorting/get_user_status/${project_id}`
  )
  return response.data
}

export const startQuadrantSort = async (project_id: number) => {
  const response = await $authHost.post(`/card_sorting/start_quadrant_sort/${project_id}`)
  return response.data
}

export const getQuadrantSortingState = async (project_id: number) => {
  const response = await $authHost.get<QuadrantSortingOut>(
    `/card_sorting/quadrant_sorting/${project_id}`
  )
  return response.data
}

export const saveSorting = async (
  project_id: number,
  isSubmitted: boolean,
  sortStage: SortStage
) => {
  const group_state: GroupState = getObjectFromLocalStorage(String(project_id)).groupState

  if (group_state) {
    const groupsWithBin = [
      ...group_state.groups.map(group => ({
        id: group.id,
        name: group.name,
        cards: group.cards.map(card => ({
          id: card.id,
          text: card.text,
          grade_status: card.grade_status,
          ...(sortStage === SortStage.QUADRANT_SORT && { group: card.group })
        }))
      })),
      {
        id: -1,
        name: "bin",
        cards: group_state.bin.map(card => ({
          id: card.id,
          text: card.text,
          ...(sortStage === SortStage.MANUAL_SORT && { grade_status: card.grade_status }),
          ...(sortStage === SortStage.QUADRANT_SORT && { group: card.group })
        }))
      },
      {
        id: -2,
        name: "bank",
        cards: group_state.bank.map(card => ({
          id: card.id,
          text: card.text,
          ...(sortStage === SortStage.MANUAL_SORT && { grade_status: card.grade_status }),
          ...(sortStage === SortStage.QUADRANT_SORT && { group: card.group })
        }))
      }
    ]
    // TODO подумать
    const saveUrl =
      sortStage === SortStage.MANUAL_SORT
        ? `/card_sorting/save_sorting_state`
        : `/card_sorting/save_quadrant_sorting_state`

    const response = await $authHost.post(saveUrl, {
      project_id,
      groups: groupsWithBin,
      is_submitted: isSubmitted
    })

    return response.data
  } else {
    return "group state is undefined"
  }
}

export const saveSortingJSON = async (project_id: number) => {
  const group_state = getObjectFromLocalStorage(String(project_id)).groupState

  if (group_state) {
    const response = await $authHost.post(`/card_sorting/save_sorting_state_json`, {
      project_id,
      state_json: JSON.stringify(group_state)
    })

    return response.data
  } else {
    return "group state is undefined"
  }
}

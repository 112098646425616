import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import styles from "./ModalStyles.module.css";

interface ModalProps {
    children: ReactNode | undefined
    className?: string
}

export const Modal:FC<ModalProps> = (props) => {
    const { children, className } = props

    return (
        <div className={styles.modalBackground}>
            <div className={classNames(styles.modal, className)}>
                {children}
            </div>
        </div>
    );
};
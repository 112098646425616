import { ChangeEvent, Dispatch, SetStateAction } from "react"
import styles from "../SuperadminStyles.module.css"
import { ForceAtlasConfig, DashboardProjectGeneralSettings, Layouts } from "../types"
import { CheckboxRow } from "./CheckboxRow"
import { InputRow } from "./InputRow"

const generalSettingsDefault = {
  min_edge_weight: 2,
  mid_grade: 0.25,
  min_pagerank: 0.25,
  max_pagerank: 0.75,
  min_node_size: 5,
  node_size_factor: 10
}

const forceAtlasConfigDefault = {
  adjust_sizes: false,
  barnes_hut_optimize: true,
  barnes_hut_theta: 0.6,
  edge_weight_influence: 1,
  gravity: 2,
  lin_log_mode: false,
  outbound_attraction_distribution: false,
  scaling_ratio: 2,
  slow_down: 1,
  strong_gravity_mode: false
}

// TODO мб сделать пропсы к интупам умно а не переписывать по три раза

interface DashboardGraphSettingsFormProps {
  generalSettings: DashboardProjectGeneralSettings
  layout: Layouts
  forceAtlasConfig: ForceAtlasConfig
  setForceAtlasConfig: Dispatch<SetStateAction<ForceAtlasConfig | null>>
  setGeneralSettings: Dispatch<SetStateAction<DashboardProjectGeneralSettings | null>>
  setLayout: Dispatch<SetStateAction<Layouts>>
  handleChange: <T>(
    event: ChangeEvent<HTMLInputElement>,
    setter: Dispatch<SetStateAction<T>>
  ) => void
}

export function DashboardGraphSettingsForm(props: DashboardGraphSettingsFormProps) {
  const {
    generalSettings,
    layout,
    forceAtlasConfig,
    handleChange,
    setForceAtlasConfig,
    setGeneralSettings,
    setLayout
  } = props

  return (
    <div className={styles.superadminFormLayout}>
      <form>
        <InputRow
          name="min_edge_weight"
          label="Мин. вес ребра:"
          placeholder={`${generalSettingsDefault.min_edge_weight}`}
          setting={generalSettings.min_edge_weight}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="mid_grade"
          label="Порог средней оценки:"
          placeholder={`${generalSettingsDefault.mid_grade}`}
          setting={generalSettings.mid_grade}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="min_pagerank"
          label="Мин. порог центральности:"
          placeholder={`${generalSettingsDefault.min_pagerank}`}
          setting={generalSettings.min_pagerank}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="max_pagerank"
          label="Макс. порог центральности:"
          placeholder={`${generalSettingsDefault.max_pagerank}`}
          setting={generalSettings.max_pagerank}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="min_node_size"
          label="Мин. размер вершины:"
          placeholder={`${generalSettingsDefault.min_node_size}`}
          setting={generalSettings.min_node_size}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <InputRow
          name="node_size_factor"
          label="Коэфф. размера вершины:"
          placeholder={`${generalSettingsDefault.node_size_factor}`}
          setting={generalSettings.node_size_factor}
          setter={setGeneralSettings}
          handleChange={handleChange}
        />
        <div>
          <label htmlFor="layout">Лэйаут:</label>
          <select
            id="layout"
            value={layout}
            onChange={event => setLayout(event.target.value as Layouts)}
          >
            {Object.values(Layouts).map(layout => (
              <option key={layout}>{layout}</option>
            ))}
          </select>
        </div>
      </form>
      {layout === Layouts.forceAtlas && (
        <form>
          <a href="https://graphology.github.io/standard-library/layout-forceatlas2.html#settings"
          style={{alignSelf: "end"}}
          >
            См. справку
          </a>
          <CheckboxRow
            name="adjust_sizes"
            label="adjustSizes"
            defaultChecked={forceAtlasConfigDefault.adjust_sizes}
            setting={forceAtlasConfig.adjust_sizes}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <CheckboxRow
            name="barnes_hut_optimize"
            label="barnesHutOptimize"
            defaultChecked={forceAtlasConfigDefault.barnes_hut_optimize}
            setting={forceAtlasConfig.barnes_hut_optimize}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <InputRow
            name="barnes_hut_theta"
            label="barnesHutTheta"
            placeholder={`${forceAtlasConfigDefault.barnes_hut_theta}`}
            setting={forceAtlasConfig.barnes_hut_theta}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <InputRow
            name="edge_weight_influence"
            label="edgeWeightInfluence"
            placeholder={`${forceAtlasConfigDefault.edge_weight_influence}`}
            setting={forceAtlasConfig.edge_weight_influence}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <InputRow
            name="gravity"
            label="gravity"
            placeholder={`${forceAtlasConfigDefault.gravity}`}
            setting={forceAtlasConfig.gravity}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <CheckboxRow
            name="lin_log_mode"
            label="linLogMode"
            defaultChecked={forceAtlasConfigDefault.lin_log_mode}
            setting={forceAtlasConfig.lin_log_mode}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <CheckboxRow
            name="outbound_attraction_distribution"
            label="outboundAttractionDistribution"
            defaultChecked={forceAtlasConfigDefault.outbound_attraction_distribution}
            setting={forceAtlasConfig.outbound_attraction_distribution}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <InputRow
            name="scaling_ratio"
            label="scalingRatio"
            placeholder={`${forceAtlasConfigDefault.scaling_ratio}`}
            setting={forceAtlasConfig.scaling_ratio}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <InputRow
            name="slow_down"
            label="slowDown"
            placeholder={`${forceAtlasConfigDefault.slow_down}`}
            setting={forceAtlasConfig.slow_down}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
          <CheckboxRow
            name="strong_gravity_mode"
            label="strongGravityMode"
            defaultChecked={forceAtlasConfigDefault.strong_gravity_mode}
            setting={forceAtlasConfig.strong_gravity_mode}
            setter={setForceAtlasConfig}
            handleChange={handleChange}
          />
        </form>
      )}
    </div>
  )
}

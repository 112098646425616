import { MouseEvent, useState } from "react"
import { DashboardCardEssentials } from "../../../../../../pages/DashboardPage/types/types"
import { ColorIcon } from "../../../../../UI/ColorIcon/ColorIcon"
import { DashboardCardHint } from "../../../../../system/components/DashboardCardHint/DashboardCardHint"

interface CardInTableProps {
  card: DashboardCardEssentials
}

export function CardInTable(props: CardInTableProps) {
  const { card } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLParagraphElement> | null>()

  return (
    <>
      <ColorIcon color={card.ideaGroup.color} sizeRem={1} />
      <p onMouseMove={event => setHintEvent(event)} onMouseLeave={() => setHintEvent(null)}>
        {hintEvent && <DashboardCardHint event={hintEvent} card={card} />}
        {card.text.card}
      </p>
    </>
  )
}

import React from 'react';

const InstructionSlide = () => {
    return (
        <svg width="81.25rem" height="31.25rem" viewBox="0 0 1648 793" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="484" y="36" width="1158" height="561" rx="30" fill="#F5F5F5"/>
            <rect x="513.5" y="66.5" width="454" height="500" rx="29.5" fill="white" stroke="#D3D3D3"/>
            <rect x="984.5" y="66.5" width="454" height="500" rx="29.5" fill="white" stroke="#D3D3D3"/>
            <g opacity="0.6">
                <path d="M930.334 135H549.666C543.223 135 538 140.544 538 147.382V238.618C538 245.456 543.223 251 549.666 251H930.334C936.777 251 942 245.456 942 238.618V147.382C942 140.544 936.777 135 930.334 135Z" fill="white" stroke="#ADADAD"/>
                <path d="M558.333 229.667L552 223.333M552 223.333L558.333 217M552 223.333H564.667C566.346 223.333 567.957 224 569.145 225.188C570.333 226.377 571 227.987 571 229.667C571 231.346 570.333 232.957 569.145 234.145C567.957 235.333 566.346 236 564.667 236H561.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M910.628 156.131L915.131 146C916.026 146 916.885 146.356 917.518 146.989C918.151 147.622 918.508 148.481 918.508 149.377V153.879H924.878C925.205 153.876 925.528 153.943 925.826 154.077C926.123 154.21 926.389 154.407 926.602 154.653C926.817 154.899 926.976 155.19 927.067 155.503C927.157 155.816 927.178 156.145 927.13 156.468L925.577 166.599C925.495 167.136 925.222 167.626 924.808 167.977C924.394 168.328 923.868 168.518 923.325 168.512H910.628M910.628 156.131V168.512M910.628 156.131H907.251C906.654 156.131 906.082 156.368 905.659 156.79C905.237 157.213 905 157.785 905 158.382V166.261C905 166.858 905.237 167.432 905.659 167.853C906.082 168.275 906.654 168.512 907.251 168.512H910.628" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M921.527 230.383L917.024 240.513C916.129 240.513 915.27 240.157 914.637 239.524C914.003 238.891 913.647 238.032 913.647 237.137V232.634H907.276C906.95 232.637 906.627 232.57 906.33 232.436C906.031 232.303 905.766 232.106 905.552 231.86C905.337 231.613 905.18 231.323 905.089 231.01C904.998 230.697 904.976 230.367 905.026 230.045L906.579 219.914C906.66 219.377 906.933 218.888 907.346 218.536C907.76 218.184 908.287 217.994 908.83 218H921.527M921.527 230.383V218M921.527 230.383H924.533C925.169 230.394 925.789 230.17 926.272 229.755C926.756 229.34 927.07 228.763 927.155 228.131V220.252C927.07 219.62 926.756 219.042 926.272 218.627C925.789 218.212 925.169 217.989 924.533 218H921.527" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M915.666 205.331C922.108 205.331 927.331 200.108 927.331 193.666C927.331 187.223 922.108 182 915.666 182C909.223 182 904 187.223 904 193.666C904 200.108 909.223 205.331 915.666 205.331Z" fill="#D9D9D9"/>
                <rect x="552.029" y="152.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="672.125" y="152.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="709" y="153" width="162" height="12" rx="6" fill="#ADADAD"/>
                <rect x="552.029" y="173.48" width="175.782" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M1401.33 135H1020.67C1014.22 135 1009 140.544 1009 147.382V238.618C1009 245.456 1014.22 251 1020.67 251H1401.33C1407.78 251 1413 245.456 1413 238.618V147.382C1413 140.544 1407.78 135 1401.33 135Z" fill="white" stroke="#ADADAD"/>
                <path d="M1029.33 229.667L1023 223.333M1023 223.333L1029.33 217M1023 223.333H1035.67C1037.35 223.333 1038.96 224 1040.15 225.188C1041.33 226.377 1042 227.987 1042 229.667C1042 231.346 1041.33 232.957 1040.15 234.145C1038.96 235.333 1037.35 236 1035.67 236H1032.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1381.63 156.131L1386.13 146C1387.03 146 1387.89 146.356 1388.52 146.989C1389.15 147.622 1389.51 148.481 1389.51 149.377V153.879H1395.88C1396.2 153.876 1396.53 153.943 1396.83 154.077C1397.12 154.21 1397.39 154.407 1397.6 154.653C1397.82 154.899 1397.98 155.19 1398.07 155.503C1398.16 155.816 1398.18 156.145 1398.13 156.468L1396.58 166.599C1396.49 167.136 1396.22 167.626 1395.81 167.977C1395.39 168.328 1394.87 168.518 1394.32 168.512H1381.63M1381.63 156.131V168.512M1381.63 156.131H1378.25C1377.65 156.131 1377.08 156.368 1376.66 156.79C1376.24 157.213 1376 157.785 1376 158.382V166.261C1376 166.858 1376.24 167.432 1376.66 167.853C1377.08 168.275 1377.65 168.512 1378.25 168.512H1381.63" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1392.53 230.383L1388.02 240.513C1387.13 240.513 1386.27 240.157 1385.64 239.524C1385 238.891 1384.65 238.032 1384.65 237.137V232.634H1378.28C1377.95 232.637 1377.63 232.57 1377.33 232.436C1377.03 232.303 1376.77 232.106 1376.55 231.86C1376.34 231.613 1376.18 231.323 1376.09 231.01C1376 230.697 1375.98 230.367 1376.03 230.045L1377.58 219.914C1377.66 219.377 1377.93 218.888 1378.35 218.536C1378.76 218.184 1379.29 217.994 1379.83 218H1392.53M1392.53 230.383V218M1392.53 230.383H1395.53C1396.17 230.394 1396.79 230.17 1397.27 229.755C1397.76 229.34 1398.07 228.763 1398.15 228.131V220.252C1398.07 219.62 1397.76 219.042 1397.27 218.627C1396.79 218.212 1396.17 217.989 1395.53 218H1392.53" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1386.67 205.331C1393.11 205.331 1398.33 200.108 1398.33 193.666C1398.33 187.223 1393.11 182 1386.67 182C1380.22 182 1375 187.223 1375 193.666C1375 200.108 1380.22 205.331 1386.67 205.331Z" fill="#D9D9D9"/>
                <rect x="1023.03" y="152.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="1143" y="153" width="162" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M930.334 259H549.666C543.223 259 538 264.544 538 271.382V362.618C538 369.456 543.223 375 549.666 375H930.334C936.777 375 942 369.456 942 362.618V271.382C942 264.544 936.777 259 930.334 259Z" fill="white" stroke="#ADADAD"/>
                <path d="M558.333 353.667L552 347.333M552 347.333L558.333 341M552 347.333H564.667C566.346 347.333 567.957 348 569.145 349.188C570.333 350.377 571 351.987 571 353.667C571 355.346 570.333 356.957 569.145 358.145C567.957 359.333 566.346 360 564.667 360H561.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M910.628 280.131L915.131 270C916.026 270 916.885 270.356 917.518 270.989C918.151 271.622 918.508 272.481 918.508 273.377V277.879H924.878C925.205 277.876 925.528 277.943 925.826 278.077C926.123 278.21 926.389 278.407 926.602 278.653C926.817 278.899 926.976 279.19 927.067 279.503C927.157 279.816 927.178 280.145 927.13 280.468L925.577 290.599C925.495 291.136 925.222 291.626 924.808 291.977C924.394 292.328 923.868 292.518 923.325 292.512H910.628M910.628 280.131V292.512M910.628 280.131H907.251C906.654 280.131 906.082 280.368 905.659 280.79C905.237 281.213 905 281.785 905 282.382V290.261C905 290.858 905.237 291.432 905.659 291.853C906.082 292.275 906.654 292.512 907.251 292.512H910.628" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M921.527 354.383L917.024 364.513C916.129 364.513 915.27 364.157 914.637 363.524C914.003 362.891 913.647 362.032 913.647 361.137V356.634H907.276C906.95 356.637 906.627 356.57 906.33 356.436C906.031 356.303 905.766 356.106 905.552 355.86C905.337 355.613 905.18 355.323 905.089 355.01C904.998 354.697 904.976 354.367 905.026 354.045L906.579 343.914C906.66 343.377 906.933 342.888 907.346 342.536C907.76 342.184 908.287 341.994 908.83 342H921.527M921.527 354.383V342M921.527 354.383H924.533C925.169 354.394 925.789 354.17 926.272 353.755C926.756 353.34 927.07 352.763 927.155 352.131V344.252C927.07 343.62 926.756 343.042 926.272 342.627C925.789 342.212 925.169 341.989 924.533 342H921.527" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M915.666 329.331C922.108 329.331 927.331 324.108 927.331 317.666C927.331 311.223 922.108 306 915.666 306C909.223 306 904 311.223 904 317.666C904 324.108 909.223 329.331 915.666 329.331Z" fill="#D9D9D9"/>
                <rect x="552.029" y="276.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="672.125" y="276.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M1401.33 259H1020.67C1014.22 259 1009 264.544 1009 271.382V362.618C1009 369.456 1014.22 375 1020.67 375H1401.33C1407.78 375 1413 369.456 1413 362.618V271.382C1413 264.544 1407.78 259 1401.33 259Z" fill="white" stroke="#ADADAD"/>
                <path d="M1029.33 353.667L1023 347.333M1023 347.333L1029.33 341M1023 347.333H1035.67C1037.35 347.333 1038.96 348 1040.15 349.188C1041.33 350.377 1042 351.987 1042 353.667C1042 355.346 1041.33 356.957 1040.15 358.145C1038.96 359.333 1037.35 360 1035.67 360H1032.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1381.63 280.131L1386.13 270C1387.03 270 1387.89 270.356 1388.52 270.989C1389.15 271.622 1389.51 272.481 1389.51 273.377V277.879H1395.88C1396.2 277.876 1396.53 277.943 1396.83 278.077C1397.12 278.21 1397.39 278.407 1397.6 278.653C1397.82 278.899 1397.98 279.19 1398.07 279.503C1398.16 279.816 1398.18 280.145 1398.13 280.468L1396.58 290.599C1396.49 291.136 1396.22 291.626 1395.81 291.977C1395.39 292.328 1394.87 292.518 1394.32 292.512H1381.63M1381.63 280.131V292.512M1381.63 280.131H1378.25C1377.65 280.131 1377.08 280.368 1376.66 280.79C1376.24 281.213 1376 281.785 1376 282.382V290.261C1376 290.858 1376.24 291.432 1376.66 291.853C1377.08 292.275 1377.65 292.512 1378.25 292.512H1381.63" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1392.53 354.383L1388.02 364.513C1387.13 364.513 1386.27 364.157 1385.64 363.524C1385 362.891 1384.65 362.032 1384.65 361.137V356.634H1378.28C1377.95 356.637 1377.63 356.57 1377.33 356.436C1377.03 356.303 1376.77 356.106 1376.55 355.86C1376.34 355.613 1376.18 355.323 1376.09 355.01C1376 354.697 1375.98 354.367 1376.03 354.045L1377.58 343.914C1377.66 343.377 1377.93 342.888 1378.35 342.536C1378.76 342.184 1379.29 341.994 1379.83 342H1392.53M1392.53 354.383V342M1392.53 354.383H1395.53C1396.17 354.394 1396.79 354.17 1397.27 353.755C1397.76 353.34 1398.07 352.763 1398.15 352.131V344.252C1398.07 343.62 1397.76 343.042 1397.27 342.627C1396.79 342.212 1396.17 341.989 1395.53 342H1392.53" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1386.67 329.331C1393.11 329.331 1398.33 324.108 1398.33 317.666C1398.33 311.223 1393.11 306 1386.67 306C1380.22 306 1375 311.223 1375 317.666C1375 324.108 1380.22 329.331 1386.67 329.331Z" fill="#D9D9D9"/>
                <rect x="1023.03" y="276.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="1143.12" y="276.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="1180" y="277" width="162" height="12" rx="6" fill="#ADADAD"/>
                <rect x="1023.03" y="297.48" width="175.782" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M930.334 383H549.666C543.223 383 538 388.544 538 395.382V486.618C538 493.456 543.223 499 549.666 499H930.334C936.777 499 942 493.456 942 486.618V395.382C942 388.544 936.777 383 930.334 383Z" fill="white" stroke="#ADADAD"/>
                <path d="M558.333 477.667L552 471.333M552 471.333L558.333 465M552 471.333H564.667C566.346 471.333 567.957 472 569.145 473.188C570.333 474.377 571 475.987 571 477.667C571 479.346 570.333 480.957 569.145 482.145C567.957 483.333 566.346 484 564.667 484H561.5" stroke="#A1A1A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M910.628 404.131L915.131 394C916.026 394 916.885 394.356 917.518 394.989C918.151 395.622 918.508 396.481 918.508 397.377V401.879H924.878C925.205 401.876 925.528 401.943 925.826 402.077C926.123 402.21 926.389 402.407 926.602 402.653C926.817 402.899 926.976 403.19 927.067 403.503C927.157 403.816 927.178 404.145 927.13 404.468L925.577 414.599C925.495 415.136 925.222 415.626 924.808 415.977C924.394 416.328 923.868 416.518 923.325 416.512H910.628M910.628 404.131V416.512M910.628 404.131H907.251C906.654 404.131 906.082 404.368 905.659 404.79C905.237 405.213 905 405.785 905 406.382V414.261C905 414.858 905.237 415.432 905.659 415.853C906.082 416.275 906.654 416.512 907.251 416.512H910.628" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M921.527 478.383L917.024 488.513C916.129 488.513 915.27 488.157 914.637 487.524C914.003 486.891 913.647 486.032 913.647 485.137V480.634H907.276C906.95 480.637 906.627 480.57 906.33 480.436C906.031 480.303 905.766 480.106 905.552 479.86C905.337 479.613 905.18 479.323 905.089 479.01C904.998 478.697 904.976 478.367 905.026 478.045L906.579 467.914C906.66 467.377 906.933 466.888 907.346 466.536C907.76 466.184 908.287 465.994 908.83 466H921.527M921.527 478.383V466M921.527 478.383H924.533C925.169 478.394 925.789 478.17 926.272 477.755C926.756 477.34 927.07 476.763 927.155 476.131V468.252C927.07 467.62 926.756 467.042 926.272 466.627C925.789 466.212 925.169 465.989 924.533 466H921.527" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M915.666 453.331C922.108 453.331 927.331 448.108 927.331 441.666C927.331 435.223 922.108 430 915.666 430C909.223 430 904 435.223 904 441.666C904 448.108 909.223 453.331 915.666 453.331Z" fill="#D9D9D9"/>
                <rect x="552.029" y="400.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="672.125" y="400.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="709" y="401" width="162" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <rect opacity="0.6" x="542" y="90" width="71" height="20" rx="10" fill="#ADADAD"/>
            <rect opacity="0.6" x="1013" y="90" width="71" height="20" rx="10" fill="#ADADAD"/>
            <rect opacity="0.6" x="619" y="90" width="43" height="20" rx="10" fill="#ADADAD"/>
            <rect opacity="0.6" x="1090" y="90" width="43" height="20" rx="10" fill="#ADADAD"/>
            <rect opacity="0.6" x="667" y="90" width="137" height="20" rx="10" fill="#ADADAD"/>
            <rect x="6" y="32" width="454" height="561" rx="30" fill="#F5F5F5"/>
            <g opacity="0.6">
                <path d="M423.334 58H42.6657C36.2229 58 31 63.2229 31 69.6657V155.623C31 162.066 36.2229 167.289 42.6657 167.289H423.334C429.777 167.289 435 162.066 435 155.623V69.6657C435 63.2229 429.777 58 423.334 58Z" fill="white" stroke="#ADADAD"/>
                <path d="M400 73.9102H402.456H422.103" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M419.654 73.9119V91.1033C419.654 91.7542 419.396 92.3792 418.935 92.8397C418.474 93.3002 417.85 93.5593 417.198 93.5593H404.919C404.268 93.5593 403.643 93.3002 403.182 92.8397C402.722 92.3792 402.463 91.7542 402.463 91.1033V73.9119M406.147 73.9119V71.4559C406.147 70.8046 406.406 70.18 406.866 69.7193C407.327 69.2587 407.951 69 408.603 69H413.515C414.166 69 414.791 69.2587 415.251 69.7193C415.712 70.18 415.97 70.8046 415.97 71.4559V73.9119" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M408.602 80.0547V87.4225" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M413.518 80.0547V87.4225" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="45.0293" y="73.5898" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45.0293" y="136" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="165.125" y="73.5898" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="202" y="74" width="153" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45.0293" y="94.4805" width="175.782" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45" y="115" width="133" height="12" rx="6" fill="#ADADAD"/>
                <rect x="224" y="94" width="143" height="12" rx="6" fill="#ADADAD"/>
                <rect x="186" y="115" width="181" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M423.334 180H42.6657C36.2229 180 31 185.223 31 191.666V277.623C31 284.066 36.2229 289.289 42.6657 289.289H423.334C429.777 289.289 435 284.066 435 277.623V191.666C435 185.223 429.777 180 423.334 180Z" fill="white" stroke="#ADADAD"/>
                <path d="M400 195.91H402.456H422.103" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M419.654 195.912V213.103C419.654 213.754 419.396 214.379 418.935 214.84C418.474 215.3 417.85 215.559 417.198 215.559H404.919C404.268 215.559 403.643 215.3 403.182 214.84C402.722 214.379 402.463 213.754 402.463 213.103V195.912M406.147 195.912V193.456C406.147 192.805 406.406 192.18 406.866 191.719C407.327 191.259 407.951 191 408.603 191H413.515C414.166 191 414.791 191.259 415.251 191.719C415.712 192.18 415.97 192.805 415.97 193.456V195.912" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M408.602 202.055V209.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M413.518 202.055V209.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="45.0293" y="195.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="165.125" y="195.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M423.334 302H42.6657C36.2229 302 31 307.223 31 313.666V399.623C31 406.066 36.2229 411.289 42.6657 411.289H423.334C429.777 411.289 435 406.066 435 399.623V313.666C435 307.223 429.777 302 423.334 302Z" fill="white" stroke="#ADADAD"/>
                <path d="M400 317.91H402.456H422.103" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M419.654 317.912V335.103C419.654 335.754 419.396 336.379 418.935 336.84C418.474 337.3 417.85 337.559 417.198 337.559H404.919C404.268 337.559 403.643 337.3 403.182 336.84C402.722 336.379 402.463 335.754 402.463 335.103V317.912M406.147 317.912V315.456C406.147 314.805 406.406 314.18 406.866 313.719C407.327 313.259 407.951 313 408.603 313H413.515C414.166 313 414.791 313.259 415.251 313.719C415.712 314.18 415.97 314.805 415.97 315.456V317.912" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M408.602 324.055V331.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M413.518 324.055V331.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="45.0293" y="317.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="165.125" y="317.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="202" y="318" width="153" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45.0293" y="338.48" width="175.782" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45" y="359" width="133" height="12" rx="6" fill="#ADADAD"/>
                <rect x="224" y="338" width="143" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <g opacity="0.6">
                <path d="M423.334 424H42.6657C36.2229 424 31 429.223 31 435.666V521.623C31 528.066 36.2229 533.289 42.6657 533.289H423.334C429.777 533.289 435 528.066 435 521.623V435.666C435 429.223 429.777 424 423.334 424Z" fill="white" stroke="#ADADAD"/>
                <path d="M400 439.91H402.456H422.103" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M419.654 439.912V457.103C419.654 457.754 419.396 458.379 418.935 458.84C418.474 459.3 417.85 459.559 417.198 459.559H404.919C404.268 459.559 403.643 459.3 403.182 458.84C402.722 458.379 402.463 457.754 402.463 457.103V439.912M406.147 439.912V437.456C406.147 436.805 406.406 436.18 406.866 435.719C407.327 435.259 407.951 435 408.603 435H413.515C414.166 435 414.791 435.259 415.251 435.719C415.712 436.18 415.97 436.805 415.97 437.456V439.912" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M408.602 446.055V453.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M413.518 446.055V453.422" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="45.0293" y="439.59" width="114.868" height="12" rx="6" fill="#ADADAD"/>
                <rect x="165.125" y="439.59" width="27.8467" height="12" rx="6" fill="#ADADAD"/>
                <rect x="202" y="440" width="153" height="12" rx="6" fill="#ADADAD"/>
                <rect x="45.0293" y="460.48" width="175.782" height="12" rx="6" fill="#ADADAD"/>
                <rect x="224" y="460" width="143" height="12" rx="6" fill="#ADADAD"/>
            </g>
            <rect x="6" width="1636" height="297" fill="url(#paint0_linear_6445_19856)"/>
            <g filter="url(#filter0_d_6445_19856)">
                <path d="M6 630C6 618.954 14.9543 610 26 610H1622C1633.05 610 1642 618.954 1642 630V783H6V630Z" fill="#FFD556"/>
            </g>
            <path d="M584.918 687.455V702H582.716V689.656H582.631L579.151 691.929V689.827L582.78 687.455H584.918Z" fill="#323232"/>
            <circle cx="583" cy="695" r="21" stroke="#323232" strokeWidth="2"/>
            <path d="M745.399 702V700.409L750.321 695.31C750.847 694.756 751.28 694.27 751.621 693.854C751.966 693.432 752.224 693.032 752.395 692.653C752.565 692.275 752.651 691.872 752.651 691.446C752.651 690.963 752.537 690.546 752.31 690.196C752.082 689.841 751.772 689.569 751.379 689.379C750.986 689.185 750.544 689.088 750.051 689.088C749.53 689.088 749.076 689.195 748.688 689.408C748.299 689.621 748.001 689.921 747.793 690.31C747.584 690.698 747.48 691.152 747.48 691.673H745.385C745.385 690.788 745.589 690.014 745.996 689.351C746.403 688.688 746.962 688.174 747.672 687.81C748.382 687.44 749.189 687.256 750.094 687.256C751.008 687.256 751.813 687.438 752.509 687.803C753.209 688.162 753.756 688.655 754.149 689.28C754.542 689.9 754.739 690.601 754.739 691.382C754.739 691.922 754.637 692.45 754.433 692.966C754.234 693.482 753.886 694.057 753.389 694.692C752.892 695.321 752.201 696.086 751.315 696.986L748.425 700.011V700.118H754.973V702H745.399Z" fill="#323232"/>
            <circle cx="749" cy="695" r="21" stroke="#323232" strokeWidth="2"/>
            <path d="M915.463 702.199C914.488 702.199 913.616 702.031 912.849 701.695C912.087 701.358 911.483 700.892 911.038 700.295C910.598 699.694 910.361 698.998 910.328 698.207H912.558C912.587 698.638 912.731 699.012 912.991 699.33C913.257 699.642 913.602 699.884 914.028 700.054C914.455 700.224 914.928 700.31 915.449 700.31C916.022 700.31 916.528 700.21 916.969 700.011C917.414 699.812 917.762 699.536 918.013 699.18C918.264 698.821 918.389 698.406 918.389 697.938C918.389 697.45 918.264 697.021 918.013 696.652C917.767 696.278 917.404 695.984 916.926 695.771C916.453 695.558 915.88 695.452 915.207 695.452H913.979V693.662H915.207C915.747 693.662 916.221 693.565 916.628 693.371C917.04 693.177 917.362 692.907 917.594 692.561C917.826 692.211 917.942 691.801 917.942 691.332C917.942 690.883 917.84 690.492 917.636 690.161C917.438 689.824 917.153 689.562 916.784 689.372C916.42 689.183 915.989 689.088 915.491 689.088C915.018 689.088 914.575 689.176 914.163 689.351C913.756 689.521 913.425 689.768 913.169 690.089C912.913 690.407 912.776 690.788 912.757 691.233H910.634C910.657 690.447 910.889 689.756 911.33 689.159C911.775 688.562 912.362 688.096 913.091 687.76C913.82 687.424 914.63 687.256 915.52 687.256C916.453 687.256 917.258 687.438 917.935 687.803C918.616 688.162 919.142 688.643 919.511 689.244C919.885 689.846 920.07 690.504 920.065 691.219C920.07 692.033 919.843 692.724 919.384 693.293C918.929 693.861 918.323 694.242 917.565 694.436V694.55C918.531 694.696 919.279 695.08 919.81 695.7C920.345 696.321 920.61 697.09 920.605 698.009C920.61 698.809 920.387 699.526 919.938 700.161C919.492 700.795 918.884 701.295 918.112 701.659C917.34 702.019 916.457 702.199 915.463 702.199Z" fill="#323232"/>
            <circle cx="915" cy="695" r="21" stroke="#323232" strokeWidth="2"/>
            <path d="M1076.09 702.159V700.384L1082.38 690.455H1083.78V693.068H1082.89L1078.39 700.185V700.298H1087.01V702.159H1076.09ZM1082.99 705V701.619L1083 700.81V690.455H1085.09V705H1082.99Z" fill="#323232"/>
            <circle cx="1081" cy="698" r="21" stroke="#323232" strokeWidth="2"/>
            <path d="M715.707 695.707C716.098 695.317 716.098 694.683 715.707 694.293L709.343 687.929C708.953 687.538 708.319 687.538 707.929 687.929C707.538 688.319 707.538 688.953 707.929 689.343L713.586 695L707.929 700.657C707.538 701.047 707.538 701.681 707.929 702.071C708.319 702.462 708.953 702.462 709.343 702.071L715.707 695.707ZM617 696H715V694H617V696Z" fill="#323232"/>
            <path d="M881.707 695.707C882.098 695.317 882.098 694.683 881.707 694.293L875.343 687.929C874.953 687.538 874.319 687.538 873.929 687.929C873.538 688.319 873.538 688.953 873.929 689.343L879.586 695L873.929 700.657C873.538 701.047 873.538 701.681 873.929 702.071C874.319 702.462 874.953 702.462 875.343 702.071L881.707 695.707ZM783 696H881V694H783V696Z" fill="#323232"/>
            <path d="M1047.71 695.707C1048.1 695.317 1048.1 694.683 1047.71 694.293L1041.34 687.929C1040.95 687.538 1040.32 687.538 1039.93 687.929C1039.54 688.319 1039.54 688.953 1039.93 689.343L1045.59 695L1039.93 700.657C1039.54 701.047 1039.54 701.681 1039.93 702.071C1040.32 702.462 1040.95 702.462 1041.34 702.071L1047.71 695.707ZM949 696H1047V694H949V696Z" fill="#323232"/>
            <defs>
                <filter id="filter0_d_6445_19856" x="0" y="608" width="1648" height="185" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.715 0 0 0 0 0.715 0 0 0 0 0.715 0 0 0 0.15 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6445_19856"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6445_19856" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_6445_19856" x1="824" y1="0" x2="824" y2="297" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                    <stop offset="1" stop-color="white"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default InstructionSlide;
import classNames from "classnames"
import { MouseEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useToggle } from "../../../../../common/hooks"
import { downloadFileFromServer } from "../../../../../common/utils"
import { UserRole } from "../../../../../store/appReducer/appReducerTypes"
import { useAppSelector } from "../../../../../store/store"
import { AlertModal, MouseHint } from "../../../../system/components"
import { Button, IconButton, Input, Modal } from "../../../../system/UX"
import { Hyperlink } from "../../../../system/UX/Link"
import styles from "../CardSortingAdminStyles.module.css"
import { addUser, addUsers, getUserRows } from "../http"
import { usersSlice } from "../reducers/usersReducer"
import { useTranslate } from "../translates/useTranslate"
import { arrayOfAdminUserSortingStatus } from "../types"
import { AddIcon } from "./icons/AddIcon"
import { RefreshIcon } from "./icons/RefreshIcon"
import { SearchIcon } from "./icons/SearchIcon"
import { StatusRow } from "./StatusRow"
import { UserRow } from "./UserRow"

export const UserManagementControl = ({ isMobile }: { isMobile: boolean }) => {
  const dispatch = useDispatch()
  const { users, projects } = useAppSelector(state => state.usersReducer)
  const [clusters, setClusters] = useState<Set<string>>(new Set())
  const actions = usersSlice.actions
  const translates = useTranslate()
  const [error, setError] = useState("")
  const client_id = Number(useParams().client_id)
  const isPending = useToggle(false)
  const [email, setEmail] = useState("")
  const [emails, setEmails] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [clusterFilter, setClusterFilter] = useState("noCluster")
  const [projectFilter, setProjectFilter] = useState(0)
  const [searchFilter, setSearchFilter] = useState("")
  const isAddUsersModalOpen = useToggle(false)
  const isAddUsersPending = useToggle(false)
  const [hintEvent, setHintEvent] = useState<MouseEvent | null>()
  const [currentHint, setCurrentHint] = useState("")

  const addUserHandler = () => {
    isPending.toggleValue()
    addUser(email, UserRole.COMMON_USER, client_id)
      .then(data => {
        setEmail("")
        dispatch(actions.addUser(data))
      })
      .catch(() => {
        setEmail("")
        setError(translates.userManager.createUserError)
      })
      .finally(() => isPending.toggleValue())
  }

  const addUsersHandler = () => {
    isAddUsersPending.toggleValue()
    addUsers(emails, client_id)
      .then(data => {
        isAddUsersModalOpen.toggleValue()
        dispatch(actions.addUsers(data))
      })
      .finally(() => isAddUsersPending.toggleValue())
  }

  const filteredUsers = users
    .filter(user => {
      if (!searchFilter) return true

      const isEmailIncluded = String(user.email)
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase())
      const isNameIncluded = String(user.name)
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase())
      return isEmailIncluded || isNameIncluded
    })
    .filter(user => {
      if (projectFilter === 0) return true

      let isUserHaveFilteredProject = false
      user.projects.forEach(project => {
        if (project.id === projectFilter) {
          isUserHaveFilteredProject = true
        }
      })
      return isUserHaveFilteredProject
    })
    .filter(user => {
      if (statusFilter === "" || statusFilter === "admins") return true

      let isUserHaveFilteredStatus = false
      user.projects.forEach(project => {
        if (project.status === statusFilter) {
          isUserHaveFilteredStatus = true
        }
      })
      return isUserHaveFilteredStatus
    })
    .filter(user => {
      if (statusFilter !== "admins") return true
      return user.is_admin
    })
    .filter(user => {
      if (clusterFilter === "noCluster" || statusFilter === "admins") return true

      let isUserHaveFilteredCluster = false
      user.projects.forEach(project => {
        if (
          project.cluster === clusterFilter &&
          (projectFilter === project.id || projectFilter === 0)
        ) {
          isUserHaveFilteredCluster = true
        }
      })
      return isUserHaveFilteredCluster
    })

  useEffect(() => {
    getUserRows(client_id)
      .then(data => {
        dispatch(actions.setUsers(data))
        return data
      })
      .then(data => {
        const clusters: Set<string> = new Set()
        data.map(user_row =>
          user_row.projects.map(
            user_project => user_project.cluster && clusters.add(user_project.cluster)
          )
        )
        setClusters(clusters)
      })
  }, [])

  return (
    <div
      className={classNames(
        styles.userManagementContainer,
        isMobile && styles.mobileManagementContainer
      )}
    >
      {error && (
        <AlertModal onSubmit={() => setError("")} submitText={translates.gotItButton}>
          {error}
        </AlertModal>
      )}
      {isAddUsersModalOpen.state && (
        <Modal>
          <Input
            label={translates.userManager.addUsersControlLabel}
            value={emails}
            onChange={e => setEmails(e.target.value)}
            isTextareaView={true}
            containerClassName={styles.userOrProjectInputContainer}
            inputClassName={styles.addUsersInput}
          />
          <div className={styles.addUsersButtons}>
            <Button onClick={isAddUsersModalOpen.toggleValue}>{translates.cancel}</Button>
            <Button onClick={addUsersHandler} isPending={isAddUsersPending.state}>
              {translates.userManager.addUserButton}
            </Button>
          </div>
        </Modal>
      )}
      {hintEvent && <MouseHint event={hintEvent}>{currentHint}</MouseHint>}
      <h3 className={styles.sortSettingsHeader}>{translates.userManager.userManagementTitle}</h3>
      <div className={styles.addUserOrProjectControl}>
        <div className={styles.inputButtonContainer}>
          <Input
            placeholder={translates.userManager.addUserPlaceholder}
            label={translates.userManager.addUserLabel}
            containerClassName={styles.userOrProjectInputContainer}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          {isMobile ? (
            <IconButton
              onClick={addUserHandler}
              className={styles.mobileAddUserOrProjectButtonStyles}
              disabled={!email}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <Button
              onClick={addUserHandler}
              isPending={isPending.state}
              className={styles.addUserOrProjectButtonStyles}
              disabled={!email}
            >
              {translates.userManager.addUserButton}
            </Button>
          )}
        </div>
        <div className={styles.showAddUsersControl}>
          <p onClick={isAddUsersModalOpen.toggleValue}>{translates.userManager.addUsersControl}</p>
        </div>
      </div>
      <div className={styles.filtersContainer}>
        <p style={{ fontWeight: "700" }}>{translates.userManager.filter}</p>
        <div className={styles.filters}>
          {projects.map(project => (
            <button
              key={project.id}
              className={classNames(
                styles.filter,
                project.id === projectFilter && styles.filterProjectActive
              )}
              onClick={() => {
                if (projectFilter !== project.id) {
                  setProjectFilter(project.id)
                } else {
                  setProjectFilter(0)
                }
              }}
            >
              {project.name}
            </button>
          ))}
          {Array.from(clusters).map(cluster => (
            <button
              key={cluster}
              className={classNames(
                styles.filter,
                cluster === clusterFilter && styles.filterProjectActive
              )}
              onClick={() => {
                if (clusterFilter !== cluster) {
                  setClusterFilter(cluster)
                } else {
                  setClusterFilter("noCluster")
                }
              }}
            >
              {cluster}
            </button>
          ))}
          {arrayOfAdminUserSortingStatus.map(status => (
            <button
              className={classNames(styles.filter, status === statusFilter && styles.filterActive)}
              key={status}
              onClick={() => {
                if (statusFilter !== status) {
                  setStatusFilter(status)
                } else {
                  setStatusFilter("")
                }
              }}
            >
              <StatusRow status={status} className={styles.statusRow} />
            </button>
          ))}
        </div>
      </div>
      <div className={styles.downloadLinks}>
        {/* TODO выгрузка в админке не нужна, надо убрать с бэка и добавить в суперадминку? */}
        {/* <Hyperlink
          href="#"
          onClick={() =>
            downloadFileFromServer(
              `card_sorting_admin/generate_xlsx?client_id=${client_id}&project_id=${projectFilter}&status=${statusFilter}`,
              "cards_info.xlsx"
            )
          }
        >
          {translates.userManager.downloadXLSX}
        </Hyperlink> */}
        {projectFilter !== 0 && (
          <Hyperlink
            href="#"
            onClick={() =>
              downloadFileFromServer(
                `card_sorting_admin/generate_links_list?client_id=${client_id}&project_id=${projectFilter}&status=${statusFilter}`,
                "auth_links.xlsx"
              )
            }
          >
            {translates.userManager.downloadAuthLinks}
          </Hyperlink>
        )}
      </div>
      <div className={styles.tableManagementContainer}>
        <div className={classNames(styles.search, isMobile && styles.mobileSearch)}>
          <SearchIcon />
          <input
            type="text"
            placeholder={translates.userManager.searchByNameOrLogin}
            className={styles.searchInput}
            value={searchFilter}
            onChange={e => setSearchFilter(e.target.value)}
          />
        </div>
        <RefreshIcon
          onClick={() => getUserRows(client_id).then(data => dispatch(actions.setUsers(data)))}
          className={styles.controlIcon}
          onMouseEnter={() => setCurrentHint(translates.userManager.refreshUserDataHint)}
          onMouseMove={e => setHintEvent(e)}
          onMouseLeave={() => setHintEvent(null)}
        />
      </div>
      <div className={classNames(styles.userList, isMobile && styles.mobileUserList)}>
        {filteredUsers
          .sort((firstUser, secondUser) => secondUser.id - firstUser.id)
          .map(user => (
            <UserRow
              user={user}
              projectFilter={projectFilter}
              statusFilter={statusFilter === "admins" ? "" : statusFilter}
              clusterFilter={clusterFilter === "noCluster" ? "" : clusterFilter}
              key={user.id}
              isMobile={isMobile}
            />
          ))}
      </div>
    </div>
  )
}

import React from 'react';

const MobileSubmitSlide = () => {
    return (
        <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M84.3014 8.24617C88.465 3.78643 95.535 3.78643 99.6986 8.24617L110.196 19.4906C112.672 22.1421 116.338 23.3331 119.899 22.6431L135.001 19.7166C140.991 18.5559 146.711 22.7116 147.458 28.7669L149.341 44.0343C149.785 47.6344 152.051 50.7526 155.338 52.2875L169.276 58.7968C174.804 61.3785 176.988 68.1025 174.034 73.4405L166.584 86.8992C164.827 90.0728 164.827 93.9272 166.584 97.1008L174.034 110.56C176.988 115.898 174.804 122.621 169.276 125.203L155.338 131.712C152.051 133.247 149.785 136.366 149.341 139.966L147.458 155.233C146.711 161.288 140.991 165.444 135.001 164.283L119.899 161.357C116.338 160.667 112.672 161.858 110.196 164.509L99.6986 175.754C95.535 180.214 88.465 180.214 84.3014 175.754L73.8036 164.509C71.3281 161.858 67.6625 160.667 64.1013 161.357L48.9991 164.283C43.0092 165.444 37.2895 161.288 36.5424 155.233L34.6588 139.966C34.2147 136.366 31.9491 133.247 28.6625 131.712L14.7244 125.203C9.19628 122.621 7.01153 115.897 9.96636 110.56L17.4165 97.1008C19.1732 93.9272 19.1732 90.0728 17.4165 86.8992L9.96636 73.4405C7.01153 68.1025 9.19629 61.3785 14.7244 58.7968L28.6625 52.2875C31.9492 50.7526 34.2147 47.6344 34.6588 44.0343L36.5424 28.7669C37.2895 22.7115 43.0093 18.5559 48.9991 19.7166L64.1013 22.6431C67.6625 23.3331 71.3281 22.1421 73.8036 19.4906L84.3014 8.24617Z"
                fill="#02D589" fill-opacity="0.11"/>
            <path
                d="M115.032 70H69.9684C57.8356 70 48 79.8497 48 92C48 104.15 57.8356 114 69.9684 114H115.032C127.164 114 137 104.15 137 92C137 79.8497 127.164 70 115.032 70Z"
                fill="#02D589" fill-opacity="0.9"/>
            <path d="M81 92.556L89.8 101L103 82" fill="#02D589" fill-opacity="0.9"/>
            <path d="M81 92.556L89.8 101L103 82" stroke="white" strokeWidth="3" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default MobileSubmitSlide;
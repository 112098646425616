import { FC } from "react";
import { ControlIconProps } from "./CopyLinkIcon";

export const SendEmailsIcon: FC<ControlIconProps> = props => {
    const { className, onClick, onMouseMove, onMouseLeave, onMouseEnter } = props

    return (
        <svg
            width="1.2rem"
            height="1.2rem"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className={className}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
        >
            <path d="M0.7316 7.51491C0.619007 7.5385 0.514895 7.59211 0.430299 7.67007C0.345704 7.74802 0.283779 7.84741 0.251089 7.95771C0.218398 8.068 0.216161 8.18509 0.244614 8.29655C0.273068 8.40801 0.331151 8.5097 0.412707 8.59083L5.39505 13.5505L11.7846 10.1488C11.9338 10.0693 12.1085 10.0524 12.2702 10.1017C12.4319 10.1511 12.5674 10.2627 12.6469 10.4119C12.7263 10.5611 12.7432 10.7358 12.6939 10.8975C12.6445 11.0593 12.533 11.1947 12.3837 11.2742L5.99422 14.6759L7.32713 21.5784C7.34906 21.6912 7.40108 21.796 7.47768 21.8817C7.55428 21.9674 7.65262 22.0308 7.76229 22.0651C7.87196 22.0995 7.98888 22.1036 8.10068 22.0769C8.21248 22.0503 8.315 21.9939 8.39739 21.9138C12.5922 17.8282 16.0585 13.0563 18.6471 7.80387C18.6919 7.71316 18.7144 7.6131 18.7129 7.51196C18.7113 7.41082 18.6857 7.3115 18.6382 7.22221C18.5906 7.13292 18.5225 7.05623 18.4395 6.99847C18.3565 6.9407 18.2609 6.90353 18.1606 6.89001C12.3577 6.10475 6.46335 6.31609 0.7316 7.51491Z" />
        </svg>
    )
}



import React, {FC} from 'react';

import styles from './ProgressBarStyles.module.css';

interface ProgressBarProps {
    width: string;
    height: string;
    color?: string;
    isQuantityShown?: boolean;
    current: number;
    max: number;
}

export const ProgressBar: FC<ProgressBarProps> = (props) => {
    const { width, height, color='green', isQuantityShown=false, current, max } = props
    const progressPercentage = (current / max) * 100;

    return (
        <div className={styles.progressBarContainer}>
            {isQuantityShown && <span className={styles.counter}>{current}</span>}
            <div
                className={styles.progressBar}
                style={{
                    width,
                    height,
                    borderColor: color,
                }}
            >
                <div
                    className={styles.progressIndicator}
                    style={{
                        width: `${progressPercentage}%`,
                        backgroundColor: color
                    }}
                />
            </div>
            {isQuantityShown && <span className={styles.counter}>{max}</span>}
        </div>
    );
};

import { useOutletContext } from "react-router-dom"
import { quadrantGroupColors3x3 } from "../../../../../../common/constants"
import {
  ContextType,
  Coords,
  DashboardScatterArea,
  DashboardScatterCard,
  DashboardScatterGroup
} from "../../../../../../pages/DashboardPage/types/types"
import { useAppSelector } from "../../../../../../store/store"
import { Axes } from "../Axes"
import { ScatterDot } from "../ScatterDot/ScatterDot"
import styles from "./ScatterStyles.module.css"
import { ScatterLabels } from "../ScatterLabes/ScatterLabels"

// TODO grid
interface ScatterProps {
  data: DashboardScatterCard[]
  options: {
    grid: "3x3"
    heightRem: number
    widthRem: number
  }
  handlers: {
    onClick: (cardId: number) => void
  }
  info: {
    areas: DashboardScatterArea[],
    groups: DashboardScatterGroup[]
  }
}

type ChartArea = {
  heightRem: number
  widthRem: number
}

function getBrowserCoords(coords: Coords, chartArea: ChartArea, remValue: number): Coords {
  const halfHeight = (chartArea.heightRem * remValue) / 2
  const halfWidth = (chartArea.widthRem * remValue) / 2
  return {
    x_coord: coords.x_coord * halfWidth + halfWidth,
    y_coord: coords.y_coord * -1 * halfHeight + halfHeight
  }
}

export function Scatter(props: ScatterProps) {
  const { options, data, handlers, info } = props
  const { heightRem, widthRem } = options
  const { onClick } = handlers
  const { groups, areas } = info
  const remValue = useAppSelector(state => state.appReducer.remValue)
  const { cardsMap } = useOutletContext<ContextType>()

  return (
    <div
      className={styles.scatterSheet}
      style={{ width: `${widthRem}rem`, height: `${heightRem}rem` }}
    >
      <div className={styles.scatterGroupsLayer}>
        {groups.map(group => (
          <div
            key={group.index}
            className={styles.scatterGroup}
            style={{
              width: `${widthRem / 3}rem`,
              height: `${heightRem / 3}rem`,
              backgroundColor: `${quadrantGroupColors3x3.get(group.index)}`
            }}
          />
        ))}
      </div>
      <Axes widthRem={widthRem} heightRem={heightRem} />
      <ScatterLabels widthRem={widthRem} heightRem={heightRem} areas={areas}/>
      {data.map(card => {
        const coords = getBrowserCoords(
          { x_coord: card.x_coord, y_coord: card.y_coord },
          { heightRem: heightRem, widthRem: widthRem },
          remValue
        )
        const boundOnClick = () => onClick(card.quadrant_group)
        const cardEssentials = cardsMap.get(String(card.id))
        return (
          cardEssentials && (
            <ScatterDot
              key={card.id}
              coords={coords}
              card={cardEssentials}
              onClick={boundOnClick}
            />
          )
        )
      })}
    </div>
  )
}

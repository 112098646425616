import React from 'react';

const ProjectIcon = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75 1V12.25C2.75 12.8467 2.98705 13.419 3.40901 13.841C3.83097 14.2629 4.40326 14.5 5 14.5H7.25M2.75 1H1.25M2.75 1H19.25M7.25 14.5H14.75M7.25 14.5L6.25 17.5M19.25 1H20.75M19.25 1V12.25C19.25 12.8467 19.0129 13.419 18.591 13.841C18.169 14.2629 17.5967 14.5 17 14.5H14.75M14.75 14.5L15.75 17.5M6.25 17.5H15.75M6.25 17.5L5.75 19M15.75 17.5L16.25 19M6.5 10L9.5 7L11.648 9.148C12.6572 7.69929 13.9722 6.48982 15.5 5.605"
        stroke="#5E5E5E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ProjectIcon;
import React, {ReactNode, useRef, useState} from 'react';

import styles from './SliderStyles.module.css'
import classNames from "classnames";
import {SlideArrowIcon} from "../../UX";

interface SliderProps {
    containerClassName?: string;
    content: {
        header: string | ReactNode;
        element: ReactNode;
    }[];
}

const animationDurationMS = 100

export const Slider: React.FC<SliderProps> = ({ containerClassName, content }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startPosition, setStartPosition] = useState({
        x: 0,
        index: 0
    });

    const [currentLeftPosition, setCurrentLeftPosition] = useState(0)
    const [currentOpacity, setCurrentOpacity] = useState(1)
    const [isAnimationNow, setIsAnimationNow] = useState(false)

    const swipe = (isLeft: boolean) => {
        if (!isAnimationNow) {
            setIsAnimationNow(true)
            setCurrentLeftPosition(isLeft ? -200 : 200)
            setCurrentOpacity(0)
            setTimeout(() => {
                setCurrentLeftPosition(isLeft ? 200 : -200)
                setTimeout(() => {
                    if (isLeft) {
                        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
                    } else {
                        setCurrentIndex((prevIndex) => (prevIndex === 0 ? content.length - 1 : prevIndex - 1));
                    }
                    setCurrentLeftPosition(0)
                    setCurrentOpacity(1)
                    setTimeout(() => {
                        setIsAnimationNow(false)
                    }, animationDurationMS)
                }, animationDurationMS)
            }, animationDurationMS)
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setStartPosition({
            x: event.touches[0].clientX,
            index: currentIndex
        });
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!isAnimationNow) {
            const currentX = event.touches[0].clientX;
            const difference = startPosition.x - currentX;

            if (difference > 50) {
                swipe(true);
            } else if (difference < -50) {
                swipe(false);
            }
        }
    };

    return (
        <div
            className={classNames(styles.sliderContainer, containerClassName)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            {content[currentIndex].header}
            <div
                style={{
                    position: 'relative',
                    left: currentLeftPosition,
                    opacity: currentOpacity,
                    transition: `left ${animationDurationMS}ms, opacity ${animationDurationMS}ms`
                }}
            >
                {content[currentIndex].element}
            </div>
            <div className={styles.sliderControls}>
                <SlideArrowIcon className={styles.arrowLeft} onClick={() => swipe(false)} />
                {content.map((_, index) => (
                    <div
                        key={index}
                        className={styles.dot}
                        style={{ backgroundColor: index <= currentIndex ? 'black' : 'silver' }}
                    />
                ))}
                <SlideArrowIcon onClick={() => swipe(true)} />
            </div>
        </div>
    );
};

import { MouseEvent, useState } from "react"
import {
  Coords,
  DashboardCardEssentials
} from "../../../../../../pages/DashboardPage/types/types"
import { DashboardCardHint } from "../../../../../system/components/DashboardCardHint/DashboardCardHint"
import styles from "./ScatterDotStyles.module.css"

interface ScatterDotProps {
  coords: Coords
  card: DashboardCardEssentials
  onClick: () => void
}

export function ScatterDot(props: ScatterDotProps) {
  const { coords, card, onClick } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLElement> | null>()

  return (
    <>
      {hintEvent && (
        <DashboardCardHint
          event={hintEvent}
          card={card}
        />
      )}
      <div
        className={styles.scatterDot}
        onMouseOver={event => setHintEvent(event)}
        onMouseLeave={() => setHintEvent(null)}
        onClick={() => onClick()}
        style={{
          top: coords.y_coord, 
          left: coords.x_coord,
          backgroundColor: `${card.ideaGroup.color}`,
          border: `${card.controversial && "2px solid black"}`,
          zIndex: 4
        }}
      />
    </>
  )
}

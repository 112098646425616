import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getErrorUrl, getObjectFromLocalStorage } from "../../../../common/utils"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { InstructionBlock } from "../../../system/components"
import { PreloadBar } from "../../../system/UX"
import { AddCardsControl } from "./components/AddCardsControl"
import { Sorting } from "./components/Sorting"
import { getQuadrantSortingState, getSortingState, getUserStatus, startQuadrantSort } from "./http"
import { cardSortingSlice } from "./reducers/cardSortingReducer/cardSortingReducer"
import {
  SortStage,
  UserSortingStatus
} from "./reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "./translates/useTranslate"
import { isSortingEmpty } from "./utils"

export const CardSorting = () => {
  const { info, group_state, user_status, sortStage, card_template } = useAppSelector(
    state => state.cardSortingReducer
  )
  const [isLoading, setIsLoading] = useState(true)
  const translates = useTranslate()
  const dispatch = useAppDispatch()
  const { setState, setSortingState, setUserStatus, setQuadrantSortingState } =
    cardSortingSlice.actions
  const project_id = Number(useParams().project_id)
  const navigate = useNavigate()

  const quadrantSortStatuses = useMemo(
    () =>
      new Set([
        UserSortingStatus.DOING_QUADRANT_SORT,
        UserSortingStatus.QUADRANT_SORTING_NOT_STARTED
      ]),
    []
  )

  async function setCurrenState(project_id: number, temp_json_state?: string) {
    const projectDataObject = getObjectFromLocalStorage(String(project_id))
    if (projectDataObject.groupState && temp_json_state) {
      const temp_json_state_obj = JSON.parse(temp_json_state)
      const temp_action_counter = temp_json_state_obj.actionCounter
      const local_action_counter = projectDataObject.groupState.actionCounter

      if (
        temp_action_counter < local_action_counter &&
        !isSortingEmpty(projectDataObject.groupState)
      ) {
        dispatch(setState(projectDataObject.groupState))
      } else {
        dispatch(setState(temp_json_state_obj))
      }
    } else if (temp_json_state && !projectDataObject.groupState) {
      dispatch(setState(JSON.parse(temp_json_state)))
    } else if (!temp_json_state && projectDataObject.groupState) {
      dispatch(setState(projectDataObject.groupState))
    }
  }

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    // TODO по-хорошему разделить чтобы не запрашивать статус опять
    getUserStatus(project_id)
      .then(data => {
        dispatch(setUserStatus(data))
        return data
      })
      .then(data => {
        if (!quadrantSortStatuses.has(data.user_status)) {
          getSortingState(project_id).then(data => {
            dispatch(setSortingState(data))
            setCurrenState(project_id, data.temp_json_state)
            setIsLoading(false)
          })
        } else if (sortStage === SortStage.QUADRANT_SORT) {
          getQuadrantSortingState(project_id).then(data => {
            dispatch(setQuadrantSortingState(data))
            setCurrenState(project_id, data.temp_json_state)
            setIsLoading(false)
          })
        }
      })
      .catch(error => navigate(getErrorUrl(error.message)))
  }, [user_status, sortStage, project_id, isLoading])

  function startQuadrantSortHandler() {
    setIsLoading(true)
    startQuadrantSort(project_id)
      .then(data => {
        dispatch(setUserStatus(data))
      })
      .catch(error => navigate(getErrorUrl(error.message)))
  }

  return (
    <>
      {isLoading ? (
        <PreloadBar isFullPage={true} />
      ) : (
        <>
          {user_status === UserSortingStatus.COLLECTING_CARDS && info.question_list && (
            <AddCardsControl
              maxCards={info.question_list.max_cards_per_question}
              minCards={info.question_list.min_cards_per_question}
              cardTemplate={card_template}
            />
          )}

          {user_status === UserSortingStatus.WAITING_FOR_SORTING && (
            <InstructionBlock
              title={translates.cardsNotCollectedTittle}
              content={translates.cardsNotCollectedText}
            />
          )}

          {(user_status === UserSortingStatus.DOING_SORT ||
            user_status === UserSortingStatus.SORTING_NOT_STARTED ||
            quadrantSortStatuses.has(user_status)) &&
            group_state && <Sorting />}

          {/* TODO транслейты */}
          {(user_status === UserSortingStatus.SORTING_FINISHED ||
            user_status === UserSortingStatus.FINISHED_QUADRANT_SORT) && (
            <InstructionBlock
              title={translates.sortingFinishedTittle}
              content={user_status === UserSortingStatus.SORTING_FINISHED ? translates.sortingFinishedText : ""}
              confirm={
                user_status === UserSortingStatus.SORTING_FINISHED
                  ? {
                      confirmationText: "Начать",
                      handler: startQuadrantSortHandler
                    }
                  : undefined
              }
            />
          )}
        </>
      )}
    </>
  )
}

export default CardSorting

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LargeModalStyles_modalBackground__94V20 {\n    z-index: 100;\n    position: fixed;\n    top: 0;\n    left: 0;\n    overflow-y: scroll;\n    width: 100vw;\n    height: 100vh;\n    padding: 2rem;\n    background: rgba(0, 0, 0, 0.3);\n    display: flex;\n    justify-content: center;\n}\n\n\n@media (max-width: 1024px) {\n    .LargeModalStyles_modalBackground__94V20 {\n        padding: 0rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/UX/LargeModal/LargeModalStyles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".modalBackground {\n    z-index: 100;\n    position: fixed;\n    top: 0;\n    left: 0;\n    overflow-y: scroll;\n    width: 100vw;\n    height: 100vh;\n    padding: 2rem;\n    background: rgba(0, 0, 0, 0.3);\n    display: flex;\n    justify-content: center;\n}\n\n\n@media (max-width: 1024px) {\n    .modalBackground {\n        padding: 0rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackground": "LargeModalStyles_modalBackground__94V20"
};
export default ___CSS_LOADER_EXPORT___;

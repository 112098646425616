import Header from "../../../../../system/components/Header/Header"
import styles from "../../CardSortingStyles.module.css"
import { useTranslate } from "../../translates/useTranslate"
import { BankOfCards } from "./BankOfCards"
import { BottomControls } from "./BottomControls"
import { Groups } from "./Groups"
import { SortingControls } from "./SortingControls"


// TODO common props
interface DesktopSortingProps {
  showInstruction: () => void
  userGroupColorMap?: Map<number, string>
}

//TODO: сделать транслейты для инструкций и хэдера банка карт 
export const DesktopSorting = (props: DesktopSortingProps) => {
  const translates = useTranslate()
  
  const {showInstruction, userGroupColorMap} = props

  return (
    <>
      <Header />
      <div className={styles.sortingContainer}>
        <div className={styles.bankOfCardsContainer}>
          <h1 className={styles.bankOfCardsHeader} id="testid">
            <span className={styles.treasury}>{translates.treasury}</span>
            <p>{translates.allTheIdeas}</p>
          </h1>
          <BankOfCards userGroupColorMap={userGroupColorMap} />
        </div>
        <div className={styles.groupsAndControlsContainer}>
          <SortingControls onClick={showInstruction} />
          <Groups userGroupColorMap={userGroupColorMap}/>
        </div>
      </div>
      <BottomControls />
    </>
  )
}

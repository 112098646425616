import {useAppSelector} from "../../../../../store/store";
import {useEffect, useState} from "react";
import {Card} from "../reducers/cardSortingReducer/cardSortingReducerTypes";

export const useFindingUngradedCards = () => {
    const groups = useAppSelector(state => state.cardSortingReducer.group_state.groups)

    const [ungradedCards, setUngradedCards] = useState<Card[]>([])
    const [isAllCardsGraded, setIsAllCardsGraded] = useState(false)

    const findUngradedCards = () => {
        const cards: Card[] = []

        groups.forEach(group => {
            group.cards.forEach(card => {
                if (card.grade_status === 'unset') {
                    cards.push(card)
                }
            })
        })

        setIsAllCardsGraded(cards.length === 0)
        return cards
    }

    useEffect(() => {
        findUngradedCards()
    }, [groups])

    useEffect(() => {
        setUngradedCards(findUngradedCards)
    }, [])

    return { ungradedCards, isAllCardsGraded, setUngradedCards }
}
import React from 'react';

import styles from './Header.module.css'
import {useAppSelector} from "../../../../store/store";
import UserIcon from "./UserIcon";
import ProjectIcon from "./ProjectIcon";

const Header = () => {
  const projectName = useAppSelector(state => state.cardSortingReducer.project_name)
  const user = useAppSelector(state => state.appReducer.user)
  return (
    <div className={styles.header}>
      <div className={styles.projectNameContainer}>
        <ProjectIcon />
        {projectName}
      </div>
      <div className={styles.userContainer}>
        {user ? user.email : ''}
        <UserIcon />
      </div>
    </div>
  );
};

export default Header;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliderStyles_sliderContainer__A6dBk {\n    width: 100vw;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    gap: 1rem;\n    overflow: hidden;\n}\n\n.SliderStyles_sliderControls__BQaZy {\n    align-items: center;\n    gap: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n}\n\n.SliderStyles_arrowLeft__-EuFO {\n    transform: rotate(180deg);\n}\n\n.SliderStyles_dot__sv6vK {\n    width: 10px;\n    height: 10px;\n    background-color: #000;\n    border-radius: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/project/system/components/Slider/SliderStyles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".sliderContainer {\n    width: 100vw;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    gap: 1rem;\n    overflow: hidden;\n}\n\n.sliderControls {\n    align-items: center;\n    gap: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n}\n\n.arrowLeft {\n    transform: rotate(180deg);\n}\n\n.dot {\n    width: 10px;\n    height: 10px;\n    background-color: #000;\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderContainer": "SliderStyles_sliderContainer__A6dBk",
	"sliderControls": "SliderStyles_sliderControls__BQaZy",
	"arrowLeft": "SliderStyles_arrowLeft__-EuFO",
	"dot": "SliderStyles_dot__sv6vK"
};
export default ___CSS_LOADER_EXPORT___;

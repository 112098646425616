interface AxesProps {
  widthRem: number
  heightRem: number
}

export function Axes(props: AxesProps) {
  const { widthRem, heightRem } = props

  return (
    <svg
      width={`${widthRem}rem`}
      height={`${heightRem}rem`}
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 2 }}
    >
      {/* X-axis */}
      <line x1="-325" y1="500" x2="1325" y2="500" stroke="black" strokeWidth="1" />
      <polygon points="1309, 490 1309, 510 1341, 500" fill="gray" />
      <polygon points="-309, 490 -309, 510 -341, 500" fill="gray" />

      {/* Y-axis */}
      <line x1="500" y1="25" x2="500" y2="975" stroke="black" strokeWidth="1" />
      <polygon points="490, 41 510, 41 500, 9" fill="gray" />
      <polygon points="490, 959 510, 959 500, 991" fill="gray" />
    </svg>
  )
}

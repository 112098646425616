export function DoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 16 15" fill="none">
      <path
        d="M1 8L6.6 13.6L15 1"
        stroke="#02D589"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioButtonStyles_radioButton__voErW > input {\n  display: none;\n}\n\n.RadioButtonStyles_radioButton__voErW > label {\n  display: inline-block;\n  padding: 0.5rem 1.5rem;\n  background-color: #F9F9F7;\n  border: 0.0625rem solid #EBEBEB;\n  color: #323232;\n  border-radius: 3.125rem;\n  cursor: pointer;\n  -webkit-user-select: none;\n          user-select: none;\n  transition: 0.3s;\n}\n\n.RadioButtonStyles_radioButton__voErW > label > p {\n  font-size: 0.875rem;\n  font-weight: 400;\n  letter-spacing: 0.0175rem;\n}\n\n.RadioButtonStyles_radioButton__voErW > label:hover {\n  background: #FFC000;\n  color: #323232;\n  border: 0.0625rem solid #323232;\n}\n\n.RadioButtonStyles_radioButtonChecked__iWDPn > label  {\n  background-color: #323232;\n  color: #FFF;\n  border: 0.0625rem solid #323232;\n}\n", "",{"version":3,"sources":["webpack://./src/project/UI/RadioButton/RadioButtonStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,+BAA+B;EAC/B,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,+BAA+B;AACjC","sourcesContent":[".radioButton > input {\n  display: none;\n}\n\n.radioButton > label {\n  display: inline-block;\n  padding: 0.5rem 1.5rem;\n  background-color: #F9F9F7;\n  border: 0.0625rem solid #EBEBEB;\n  color: #323232;\n  border-radius: 3.125rem;\n  cursor: pointer;\n  user-select: none;\n  transition: 0.3s;\n}\n\n.radioButton > label > p {\n  font-size: 0.875rem;\n  font-weight: 400;\n  letter-spacing: 0.0175rem;\n}\n\n.radioButton > label:hover {\n  background: #FFC000;\n  color: #323232;\n  border: 0.0625rem solid #323232;\n}\n\n.radioButtonChecked > label  {\n  background-color: #323232;\n  color: #FFF;\n  border: 0.0625rem solid #323232;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButton": "RadioButtonStyles_radioButton__voErW",
	"radioButtonChecked": "RadioButtonStyles_radioButtonChecked__iWDPn"
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect} from 'react'
import AppRouter from './router/AppRouter'
import {BrowserRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {appSlice} from "./store/appReducer/appReducer";
import {useAppSelector} from "./store/store";

function App() {
	const dispatch = useDispatch();
	const { setIsMobile, setRemValue } = appSlice.actions
	const { remValue, isMobile } = useAppSelector(state => state.appReducer)


	// TODO: проверить иконки на то, что их стандартные размеры указаны в ремах
	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 1240));
			if (!isMobile) {
				if (window.innerWidth < 1400) {
					dispatch(setRemValue(13))
				} else {
					dispatch(setRemValue(16))
				}
			} else {
				dispatch(setRemValue(16))
			}
		};

		handleResize();
		document.documentElement.style.fontSize = remValue + 'px'
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch, remValue]);


	return (
		<BrowserRouter>
			<AppRouter />
		</BrowserRouter>
	)
}

export default App

import styles from "./QuadrantGroupTypeIconStyles.module.css"

interface QuadrantGroupTypeIconProps {
  color?: string
}

export function QuadrantGroupTypeIcon(props: QuadrantGroupTypeIconProps) {
  const { color } = props

  return <div className={styles.QuadrantGroupTypeIcon} style={{backgroundColor: color}} />
}

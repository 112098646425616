// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScatterDotStyles_scatterDot__8qoWB {\n  position: absolute;\n  height: 0.5rem;\n  width: 0.5rem;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/ScatterChart/components/ScatterDot/ScatterDotStyles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,gCAAgC;AAClC","sourcesContent":[".scatterDot {\n  position: absolute;\n  height: 0.5rem;\n  width: 0.5rem;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scatterDot": "ScatterDotStyles_scatterDot__8qoWB"
};
export default ___CSS_LOADER_EXPORT___;

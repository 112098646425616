import { useRegisterEvents, useSigma } from "@react-sigma/core"
import "@react-sigma/core/lib/react-sigma.min.css"
import { useEffect, useState } from "react"


export function GraphEvents() {
  const registerEvents = useRegisterEvents()
  const sigma = useSigma()
  const [draggedNode, setDraggedNode] = useState<string | null>(null)

  useEffect(() => {
    registerEvents({
      // clickNode: event =>
      //   console.log("clickNode", event.event, event.node, event.preventSigmaDefault),

      // On hover
        // enterNode: event => console.log(sigma.getGraph().getNodeAttribute(event.node, "label")),
        // leaveNode: () => {},

      // Drag'n'Drop
      downNode: e => {
        setDraggedNode(e.node)
        sigma.getGraph().setNodeAttribute(e.node, "highlighted", true)
      },
      mousemovebody: e => {
        if (!draggedNode) return
        const pos = sigma.viewportToGraph(e)
        sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x)
        sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y)

        e.preventSigmaDefault()
        e.original.preventDefault()
        e.original.stopPropagation()
      },
      mouseup: () => {
        if (draggedNode) {
          setDraggedNode(null)
          sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted")
        }
      },
      mousedown: () => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox())
      }
    })
  }, [registerEvents, sigma, draggedNode])

  return null
}

import { ChangeEvent, Dispatch, SetStateAction } from "react"

interface InputRowProps<S, U> {
  name: keyof S & string
  label?: string
  setting?: S[keyof S]
  placeholder?: string
  setter: Dispatch<SetStateAction<U>>
  handleChange: <U>(
    event: ChangeEvent<HTMLInputElement>,
    setter: Dispatch<SetStateAction<U>>,
  ) => void
}

export function InputRow<S extends Record<string, number | string | undefined | null>, U>(
  props: InputRowProps<S, U>
) {
  const { name, setting, setter, handleChange, label, placeholder} = props

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        value={setting || ""}
        placeholder={placeholder}
        onChange={(event) => {
          handleChange(event, setter)
        }}
      />
    </div>
  )
}

import React from 'react';

const UserIcon = () => {
  return (
    <svg width="2.5rem" height="2.5rem" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6348_79227)">
        <path
          d="M23.7023 45.285C18.631 45.285 13.631 43.4965 9.70248 40.3488C4.48825 36.0564 1.48828 29.7609 1.48828 22.9647C1.48828 10.7314 11.4882 0.71582 23.7023 0.71582C28.0594 0.71582 32.3451 2.00353 35.9165 4.36434C39.4165 6.65361 42.1307 9.8729 43.9164 13.6645C45.2735 16.5976 45.9164 19.6738 45.9164 22.9647C45.9164 25.9693 45.345 28.9024 44.1307 31.6925C44.0593 31.9071 43.9878 32.0502 43.845 32.2648C42.6307 34.9118 40.9164 37.2726 38.845 39.2042C34.7737 43.1388 29.4166 45.285 23.7023 45.285Z"
          fill="url(#paint0_linear_6348_79227)"/>
        <path
          d="M23.7018 1.43079C28.1303 1.43079 32.2017 2.71851 35.5588 5.00778C38.9159 7.2255 41.6302 10.3733 43.273 14.0218C44.5587 16.7403 45.2015 19.8165 45.2015 22.9642C45.2015 25.9689 44.6301 28.8305 43.4873 31.4059C43.4159 31.6205 43.3444 31.7636 43.273 31.9782C42.1302 34.5537 40.4159 36.8429 38.4159 38.7029C34.5588 42.2799 29.416 44.4977 23.7018 44.4977C18.559 44.4977 13.8447 42.7092 10.1305 39.7045C5.34482 35.7698 2.20199 29.7605 2.20199 22.9642C2.20199 11.0886 11.8448 1.43079 23.7018 1.43079ZM23.7018 0C11.059 0 0.773438 10.3017 0.773438 22.9642C0.773438 29.9036 3.84483 36.4137 9.27335 40.8491C13.3447 44.14 18.4875 46 23.7018 46C29.5589 46 35.1302 43.7823 39.4159 39.776C41.6302 37.7014 43.3444 35.3406 44.6301 32.5505C44.7016 32.3359 44.8444 32.1213 44.9158 31.9782C46.1301 29.1166 46.7015 26.112 46.7015 22.9642C46.7015 19.6019 45.9873 16.3826 44.6301 13.3779C42.8444 9.44323 39.9873 6.15241 36.4159 3.7916C32.6303 1.28771 28.2732 0 23.7018 0Z"
          fill="#010202"/>
        <path
          d="M15.0605 39.4189V4.29297H35.7746L35.9889 4.43605C39.4889 6.72532 42.2031 9.9446 43.9888 13.7362L44.0602 13.8793V32.1219L43.9888 32.265C42.7745 34.9119 41.0603 37.2727 38.9889 39.2043L38.7746 39.4189H15.0605Z"
          fill="white"/>
        <path
          d="M35.5598 5.00794C38.9169 7.22567 41.6311 10.3734 43.274 14.0219V31.9784C42.1311 34.5538 40.4169 36.8431 38.4169 38.7031H15.7743V5.00794H35.5598ZM35.9883 3.57715H35.5598H15.7743H14.3457V5.00794V38.7031V40.1339H15.7743H38.4169H38.9883L39.4169 39.7762C41.6311 37.7016 43.3454 35.3408 44.6311 32.5507L44.774 32.2646V31.9784V14.0219V13.7358L44.6311 13.4496C42.8454 9.51494 39.9883 6.22412 36.4169 3.86331L35.9883 3.57715Z"
          fill="black"/>
        <path
          d="M23.13 27.9007C23.9979 27.9007 24.7014 27.196 24.7014 26.3268C24.7014 25.4576 23.9979 24.7529 23.13 24.7529C22.2621 24.7529 21.5586 25.4576 21.5586 26.3268C21.5586 27.196 22.2621 27.9007 23.13 27.9007Z"
          fill="black"/>
        <path
          d="M34.9152 27.9007C35.783 27.9007 36.4866 27.196 36.4866 26.3268C36.4866 25.4576 35.783 24.7529 34.9152 24.7529C34.0473 24.7529 33.3438 25.4576 33.3438 26.3268C33.3438 27.196 34.0473 27.9007 34.9152 27.9007Z"
          fill="black"/>
        <path
          d="M32.4159 29.6172C32.4159 31.5488 30.8445 33.1226 28.916 33.1226C26.9874 33.1226 25.416 31.5488 25.416 29.6172H32.4159Z"
          fill="black"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_6348_79227" x1="1.48835" y1="22.9863" x2="45.9592" y2="22.9863"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.4086" stopColor="#B3B3B2"/>
          <stop offset="1"/>
        </linearGradient>
        <clipPath id="clip0_6348_79227">
          <rect width="45.9281" height="46" fill="white" transform="translate(0.773438)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
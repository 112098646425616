export function shuffleArray(array: Array<string>): Array<string> {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const UserPicList = [
  "alien.png",
  "angel.png",
  "astronaut.png",
  "bandit.png",
  "carnival-mask.png",
  "cat.png",
  "catwoman.png",
  "cowardly-lion.png",
  "cute-pumpkin.png",
  "devil.png",
  "dolphin.png",
  "dragon.png",
  "dwarf.png",
  "elf.png",
  "elf_1.png",
  "fairy.png",
  "frog-prince.png",
  "genie.png",
  "gladiator.png",
  "glasses.png",
  "gnome.png",
  "goblin.png",
  "groundhog.png",
  "indian.png",
  "king.png",
  "knight.png",
  "ladybug.png",
  "mad-hatter.png",
  "magician.png",
  "ninja.png",
  "ninja_1.png",
  "ninja_2.png",
  "ogre.png",
  "phoenix.png",
  "pirate.png",
  "policeman.png",
  "prince.png",
  "puss-in-boots.png",
  "robin-hood.png",
  "robot.png",
  "scarecrow.png",
  "sheriff.png",
  "spartan.png",
  "unicorn.png",
  "vampire.png",
  "werewolf.png",
  "white-rabbit.png",
  "wizard.png",
  "wolf.png",
  "yeti.png"
]

import _ from "lodash"
import { Dispatch, forwardRef, MutableRefObject, SetStateAction } from "react"
import { useOutletContext } from "react-router-dom"
import { gradeCategories } from "../../../../../../pages/DashboardPage/types/constants"
import { ContextType, GraphRefs, LegendStyles } from "../../../../../../pages/DashboardPage/types/types"
import { Legend } from "../../../../../system/components/Legend/Legend"
import { Graph } from "../Graph/Graph"
import styles from "./GraphWidgetStyles.module.css"

interface GraphWidgetProps {
  type: "grades" | "groups"
  legend: {
    styles: LegendStyles
    filterSetter: Dispatch<SetStateAction<number[]>>
  }
  filters: {
    groups: number[]
    grades: number[]
  }
  fullscreenControl: Dispatch<SetStateAction<string>>
  graphRefs: MutableRefObject<GraphRefs>
  // TODO temporapy
  nodeSize: "absolute" | "percentile"

}

function RefGraphWidget(props: GraphWidgetProps) {
  const { graphRefs, type, legend, filters, fullscreenControl, nodeSize } = props
  const { ideaGroups, graphData } = useOutletContext<ContextType>()

  return (
    <div className={styles.graphWidget}>
      {/* TODO translates */}
      {type === "groups" && (
        <p style={{ textAlign: "start" }}>Карта идей, раскрашенная по общим трендам</p>
      )}
      {type === "grades" && (
        <p style={{ textAlign: "end" }}>Карта идей, раскрашенная по результатам голосования</p>
      )}
      <div className={styles.graphWidgetLegend}>
        {type === "groups" && ideaGroups && (
          <Legend items={ideaGroups} setter={legend.filterSetter} CSSstyles={legend.styles} />
        )}
        <Graph
          graphData={_.cloneDeep(graphData)}
          coloring={type}
          fullscreenControl={() => fullscreenControl(`all-${type}`)}
          ref={ref => (graphRefs.current[`all-${type}`] = ref)}
          filterGroup={filters.groups}
          filterGrade={filters.grades}
          nodeSize={nodeSize}
        />
        {type === "grades" && (
          <Legend items={gradeCategories} setter={legend.filterSetter} CSSstyles={legend.styles} />
        )}
      </div>
    </div>
  )
}

export const GraphWidget = forwardRef(RefGraphWidget)

import { createPortal } from 'react-dom'

import styles from './BannerStyles.module.css'

const bannerRootElement = document.querySelector('#banner')

export const Banner = () => {
    if (!bannerRootElement) {
      return null;
    }
    return createPortal(
        <div className={styles.banner}>
            <p>Banner Text</p>
        </div>,
        bannerRootElement
    )
}

import { useMemo } from "react"
import { DashboardScatterArea } from "../../../../../../pages/DashboardPage/types/types"
import styles from "./ScatterLabelsStyles.module.css"

interface ScatterLabelsProps {
  widthRem: number
  heightRem: number
  areas: DashboardScatterArea[]
}

export function ScatterLabels(props: ScatterLabelsProps) {
  const { widthRem, heightRem, areas } = props

  // от начала оси
  const axesNamesY: string[] = useMemo(() => {
    const areasY = areas.filter(area => area.axis === "Y")
    return [areasY[0].name, areasY[areasY.length - 1].name]
  }, [areas])

  const axesNamesX: string[] = useMemo(() => {
    const areasX = areas.filter(area => area.axis === "X")
    return [areasX[0].name, areasX[areasX.length - 1].name]
  }, [areas])

  // TODO translates
  return (
    <div
      style={{ width: `${widthRem}rem`, height: `${heightRem}rem` }}
      className={styles.scatterLabels}
    >
      <p
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 1rem)"
        }}
      >
        Море неопределенности
      </p>
      {axesNamesX && (
        <>
          <p
            style={{
              top: "50%",
              left: "2rem",
              transform: "translate(0, 1rem)"
            }}
          >
            {axesNamesX[0]}
          </p>
          <p
            style={{
              top: "50%",
              left: "calc(100% - 2rem)",
              transform: "translate(-100%, 1rem)"
            }}
          >
            {axesNamesX[1]}
          </p>
        </>
      )}
      {axesNamesY && (
        <>
          <p
            style={{
              top: "calc(100% - 2rem)",
              left: "50%",
              transform: "translate(-50%, -100%)"
            }}
          >
            {axesNamesY[0]}
          </p>
          <p
            style={{
              top: "2rem",
              left: "50%",
              transform: "translate(-50%, 0)"
            }}
          >
            {axesNamesY[1]}
          </p>
        </>
      )}
    </div>
  )
}

import { CSSProperties, MouseEvent, ReactNode, useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/store"
import { Portal } from "../../service"
import styles from "./NodeMouseHintStyles.module.css"

interface MouseHintProps {
  event: MouseEvent
  children: ReactNode
}

export function NodeMouseHint(props: MouseHintProps) {
  const { event, children } = props
  const [position, setPosition] = useState<CSSProperties>()
  const remValue = useAppSelector(state => state.appReducer.remValue)
  const hintOffsetX = 1 * remValue // px
  const hintOffsetY = 1 * remValue // px
  // const maxHintWidth = 16 // rem
  // const hintHeight = (countLines(card.text.hint, textWidth) * 1.1) * remValue // + line gaps + padding
  // const scrollbarWidth = window.innerWidth - document.body.clientWidth // px

  // // TODO вынести это дело
  // // TODO exceeds???
  // useEffect(() => {
  //   const exceedsX = event.pageX + remValue * maxHintWidth + hintOffsetX >= window.innerWidth
  //   const exceedsY = event.pageY + hintHeight + hintOffsetY >= window.innerHeight + window.scrollY
  //   const isYScrollbar =
  //     document.documentElement.scrollHeight > document.documentElement.clientHeight

  //   if (exceedsX && exceedsY) {
  //     setPosition({
  //       bottom: window.innerHeight - event.pageY + hintOffsetY,
  //       right:
  //         window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
  //     })
  //   } else if (exceedsX && !exceedsY) {
  //     setPosition({
  //       top: event.pageY + hintOffsetY,
  //       right:
  //         window.innerWidth - event.pageX + hintOffsetX - Number(isYScrollbar) * scrollbarWidth
  //     })
  //   } else if (exceedsY && !exceedsX) {
  //     setPosition({
  //       bottom: window.innerHeight - event.pageY + hintOffsetY,
  //       left: event.pageX + hintOffsetX
  //     })
  //   } else {
  //     setPosition({
  //       top: event.pageY + hintOffsetY,
  //       left: event.pageX + hintOffsetX
  //     })
  //   }
  // }, [event, hintHeight, hintOffsetX, hintOffsetY, maxHintWidth, remValue, scrollbarWidth])

  return (
    <Portal>
      <div
        className={styles.mouseHint}
        style={{ top: event.pageY + hintOffsetY, left: event.pageX + hintOffsetX }}
      >
        {children}
      </div>
    </Portal>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserStatusIconStyles_userStatusIcon__RlgBj {\n  display: flex;\n  width: 6.75rem;\n  height: 1.5rem;\n  border-radius: 0.625rem;\n  align-items: center;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/MainDashboard/components/UserStatusIcon/UserStatusIconStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,cAAc;EACd,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".userStatusIcon {\n  display: flex;\n  width: 6.75rem;\n  height: 1.5rem;\n  border-radius: 0.625rem;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userStatusIcon": "UserStatusIconStyles_userStatusIcon__RlgBj"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorIconStyles_colorIcon__r2mFk {\n  border-radius: 0.5rem;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/project/UI/ColorIcon/ColorIconStyles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".colorIcon {\n  border-radius: 0.5rem;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorIcon": "ColorIconStyles_colorIcon__r2mFk"
};
export default ___CSS_LOADER_EXPORT___;

import { FC, MouseEvent, useState } from "react"

import classNames from "classnames"
import { useDispatch } from "react-redux"
import { useToggle } from "../../../../../common/hooks"
import { Pages } from "../../../../../common/types"
import { copyToClipboard } from "../../../../../common/utils"
import { useAppSelector } from "../../../../../store/store"
import { ConfirmModal, MouseHint } from "../../../../system/components"
import styles from "../CardSortingAdminStyles.module.css"
import { deleteUser } from "../http"
import { usersSlice } from "../reducers/usersReducer"
import { useTranslate } from "../translates/useTranslate"
import { UserRowIn } from "../types"
import { DeleteUserIcon } from "./icons/DeleteUserIcon"
import { SettingsIcon } from "./icons/SettingsIcon"
import { StatusRow } from "./StatusRow"
import { UserSettings } from "./UserSettings"

interface UserRowProps {
  user: UserRowIn
  statusFilter: string
  clusterFilter: string
  projectFilter: number
  isMobile: boolean
}

//TODO: отследить путаницу с currentUser.email и user.registration_token
export const UserRow: FC<UserRowProps> = props => {
  const { user, statusFilter, clusterFilter, projectFilter, isMobile } = props
  const currentUser = useAppSelector(state => state.appReducer.user)

  const dispatch = useDispatch()
  const actions = usersSlice.actions
  const [hintEvent, setHintEvent] = useState<MouseEvent | null>()
  const [currentHint, setCurrentHint] = useState("")
  const translates = useTranslate()
  const isDeletePending = useToggle(false)
  const deleteModal = useToggle(false)
  const settingsModal = useToggle(false)

  const copyAuthLink = (id?: number) => {
    copyToClipboard(
      id
        ? `${window.location.origin}${Pages.auth}/${user.registration_token}/${id}`
        : `${window.location.origin}${Pages.auth}/${user.registration_token}`
    ).then(() => setCurrentHint(translates.userManager.linkCopied))
  }

  const deleteUserHandler = () => {
    isDeletePending.toggleValue()
    deleteUser(user.id).then(() => {
      isDeletePending.toggleValue()
      dispatch(actions.deleteUser(user.id))
    })
  }

  const filteredProject = user.projects
    .filter(project => (statusFilter ? project.status === statusFilter : true))
    .filter(project => (clusterFilter ? project.cluster === clusterFilter : true))
    .filter(project => (projectFilter ? project.id === projectFilter : true))

  return (
    <div className={classNames(styles.userRow, isMobile && styles.mobileUserRow)} key={user.id}>
      {deleteModal.state && (
        <ConfirmModal
          onConfirm={deleteUserHandler}
          onCancel={deleteModal.toggleValue}
          confirmText={translates.confirmButton}
          cancelText={translates.cancelButton}
          isPending={isDeletePending.state}
        >
          {translates.userManager.confirmDeleteUserMessage}
        </ConfirmModal>
      )}

      {settingsModal.state && <UserSettings user={user} onClose={settingsModal.toggleValue} />}

      {hintEvent && <MouseHint event={hintEvent}>{currentHint}</MouseHint>}

      <p className={styles.userRowEmail}>
        {user.is_admin
          ? user.email +
            ` (${
              currentUser && currentUser.email === user.registration_token
                ? translates.userManager.you
                : translates.userManager.admin
            })`
          : user.email}
      </p>
      <p className={styles.userRowName}>{user.name ? user.name : translates.userManager.notAuthStatus}</p>
      <div className={styles.projects}>
        {user.is_admin && (
          <div
          className={styles.projectRow}
          onClick={() => copyAuthLink()}
          onMouseEnter={() => setCurrentHint(translates.userManager.clickToCopyAdminLinkHint)}
          onMouseMove={e => setHintEvent(e)}
          onMouseLeave={() => setHintEvent(null)}
        >
          <p className={styles.projectRowCluster}></p>
          <p className={styles.projectRowProjectName}>{translates.userManager.adminLink}</p>
          <div className={styles.projectRowStatus}></div>
        </div>
        )}
        {filteredProject.map(project => (
          <div
            key={project.id}
            className={styles.projectRow}
          >
            <p className={styles.projectRowCluster}>{project.cluster}</p>
            <p 
              className={styles.projectRowProjectName}
              onClick={() => copyAuthLink(project.id)}
              onMouseEnter={() => setCurrentHint(translates.userManager.clickToCopyLinkHint)}
              onMouseMove={e => setHintEvent(e)}
              onMouseLeave={() => setHintEvent(null)}
            >
              {project.name}
            </p>
            <div className={styles.projectRowStatus}><StatusRow status={project.status} className={styles.statusRow} /></div>
          </div>
        ))}
      </div>
      <div className={styles.controlsContainer}>
        <DeleteUserIcon
          className={styles.controlIcon}
          onClick={deleteModal.toggleValue}
          onMouseEnter={() => setCurrentHint(translates.userManager.deleteUserHint)}
          onMouseMove={e => setHintEvent(e)}
          onMouseLeave={() => setHintEvent(null)}
        />
        <SettingsIcon
          className={styles.controlIcon}
          onClick={settingsModal.toggleValue}
          onMouseEnter={() => setCurrentHint(translates.userManager.openSettingsHint)}
          onMouseMove={e => setHintEvent(e)}
          onMouseLeave={() => setHintEvent(null)}
        />
      </div>
    </div>
  )
}

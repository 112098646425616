import { MouseEvent, useState } from "react"
import SimpleBar from "simplebar-react"
import { NodeMouseHint } from "../../../../../system/components/NodeMouseHint/NodeMouseHint"
import { UserPic } from "../../../../../system/components/UserPic/UserPic"
import styles from "./RolesUserRowStyles.module.css"

interface RolesUserRowProps {
  users: number[]
}

export function RolesUserRow(props: RolesUserRowProps) {
  const { users } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
  // TODO узнать
  const maxPicsForRow = 3

  return (
    <SimpleBar style={{ maxWidth: "100%", height: "100%" }}>
      <div className={styles.rolesTableUserRow}>
        {users.slice(0, maxPicsForRow).map(userId => (
          <UserPic key={userId} userId={userId} />
        ))}
        {users.length > maxPicsForRow && (
          <div
            className={styles.rolesTableUserRowExtraCounter}
            onMouseMove={event => setHintEvent(event)}
            onMouseLeave={() => setHintEvent(null)}
          >
            {hintEvent && (
              <NodeMouseHint event={hintEvent}>
                {users.slice(maxPicsForRow).map(userId => (
                  <UserPic key={userId} userId={userId} showName={true}/>
                ))}
              </NodeMouseHint>
            )}
            <p>+ {users.length - maxPicsForRow}</p>
          </div>
        )}
      </div>
    </SimpleBar>
  )
}

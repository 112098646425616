// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScatterLabelsStyles_scatterLabels__P3ZLz {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 3;\n}\n\n.ScatterLabelsStyles_scatterLabels__P3ZLz > p {\n  position: absolute;\n  max-width: 10rem;\n  text-align: center;\n  opacity: 0.5;\n  cursor: default;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/ScatterChart/components/ScatterLabes/ScatterLabelsStyles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".scatterLabels {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 3;\n}\n\n.scatterLabels > p {\n  position: absolute;\n  max-width: 10rem;\n  text-align: center;\n  opacity: 0.5;\n  cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scatterLabels": "ScatterLabelsStyles_scatterLabels__P3ZLz"
};
export default ___CSS_LOADER_EXPORT___;

import { DashboardFrameNavTranslates, DashboardFrameTranslates } from "./translates";

const dashboardFrameNavTranslates: DashboardFrameNavTranslates = {
  // analytics: "Аналитика",
  map: "Карта всех идей",
  roles: "Роли и задачи",
  results: "Выводы и рекомендации",
  dashboard: "Дашборд",
  scatter: "Матрица идей"
}

export const dashboardFrameTranslates: DashboardFrameTranslates = {
  navigation: dashboardFrameNavTranslates,
  userCounter: "участников"
}


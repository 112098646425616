import React, {FC, MouseEvent, useState} from 'react';
import classNames from "classnames";

import styles from "../../CardSortingStyles.module.css";
import {MouseHint} from "../../../../../system/components";
import {LikeIcon} from "../icons";
import {cardSortingSlice} from "../../reducers/cardSortingReducer/cardSortingReducer";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {Card, GradeStatus} from "../../reducers/cardSortingReducer/cardSortingReducerTypes";
import {setGradeCorrectly} from "../../utils";
import CardText from "./CardText";

interface CardInLikeModalProps {
    card: Card
}

const CardInLikeModal: FC<CardInLikeModalProps> = (props) => {
    const { card } = props

    const setGrade = cardSortingSlice.actions.setGrade
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
    const [currentHint, setCurrentHint] = useState('')
    const dispatch = useAppDispatch()
    const [status, setStatus] = useState<GradeStatus | undefined>(card.grade_status)
    const { like_hint, dislike_hint, neutral_grade_hint } = useAppSelector(state => state.cardSortingReducer.info)

    return (
        <div
            key={card.id}
            style={{cursor: "pointer"}}
            className={classNames(styles.card, styles.cardInGroup)}
        >
            {hintEvent && <MouseHint event={hintEvent}>
                {currentHint}
            </MouseHint>}
            <p
                className={classNames(styles.cardText, styles.cardInGroupText)}
                onMouseEnter={() => setCurrentHint(card.text)}
                onMouseMove={(e) => setHintEvent(e)}
                onMouseLeave={() => setHintEvent(null)}
            >
                <CardText>{card.text}</CardText>
            </p>

            <div className={styles.likeControls} onClick={(e) => e.stopPropagation()}>
                <div
                    onMouseEnter={() => setCurrentHint(like_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={styles.gradeContainer}
                    onClick={() => {
                        dispatch(setGrade({id: card.id, grade: 'liked'}))
                        setStatus(setGradeCorrectly("liked", status))
                    }}
                >
                    <LikeIcon
                        className={classNames(styles.likeControl, status === 'liked' && styles.activeLike)}
                    />
                </div>
                <div
                    onMouseEnter={() => setCurrentHint(neutral_grade_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={classNames(styles.gradeContainer, styles.neutralGradeControlContainer)}
                    onClick={() => {
                        dispatch(setGrade({id: card.id, grade: 'neutral'}))
                        setStatus(setGradeCorrectly("neutral", status))
                    }}
                >
                    <div
                        className={classNames(styles.neutralGradeControl, status === 'neutral' && styles.activeNeutralGrade)}
                    />
                </div>
                <div
                    onMouseEnter={() => setCurrentHint(dislike_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={styles.gradeContainer}
                    onClick={() => {
                        dispatch(setGrade({id: card.id, grade: 'disliked'}))
                        setStatus(setGradeCorrectly("disliked", status))
                    }}
                >
                    <LikeIcon
                        isDislike={true}
                        className={classNames(styles.dislikeControl, status === 'disliked' && styles.activeDislike)}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardInLikeModal;
interface OrderingIconProps {
  descending: boolean
}

export function OrderingIcon(props: OrderingIconProps) {
  const { descending } = props
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2rem"
      height="1rem"
      viewBox="0 0 12 8"
      fill="none"
      style={descending ? {} : { transform: "rotate(180deg)" }}
    >
      <path
        d="M1 1.5L6 6.5L11 1.5"
        stroke="gray"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { useEffect, useState } from "react"
import { StatusColors } from "../../../../../../common/types"
import { AdminUserSortingStatus, ProjectStatus } from "../../types"

type IconColors = "red" | "green" | "gray" | "orange" | "lightOrange" | "yellow"

export const StatusIcon = ({ status }: { status: AdminUserSortingStatus | ProjectStatus}) => {
  const [color, setColor] = useState<IconColors>("gray")

  useEffect(() => {
    switch (status) {
      case "project_settings_unset":
        setColor("gray")
        break
      case "project_not_opened":
        setColor("red")
        break
      case "collecting_cards":
        setColor("yellow")
        break
      case "waiting_for_sorting":
        setColor("green")
        break
      case "sorting_not_started":
        setColor("orange")
        break
      case "doing_sort":
        setColor("lightOrange")
        break
      case "sorting_started":
        setColor("lightOrange")
        break
      case "sorting_finished":
        setColor("green")
        break
    }
  }, [status])

  return (
    <svg
      width="0.625rem"
      height="0.625rem"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" fill={StatusColors[color as IconColors]} />
    </svg>
  )
}

import classNames from "classnames"
import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { CardSortingInfoInner, ContextType } from "../../../../pages/DashboardPage/types/types"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { LikeStatus } from "../CardSorting/reducers/cardSortingReducer/cardSortingReducerTypes"
import styles from "./RolesStyles.module.css"
import "./ScrollBarStyles.css"
import { RolesUserRow } from "./components/RolesUserRow/RolesUserRow"
import { OrderingIcon } from "./components/icons/OrderingIcon"

interface RolesProps {
  groupFilters: number[]
  cardSortingInfo: CardSortingInfoInner[]
}

export function Roles(props: RolesProps) {
  const { groupFilters, cardSortingInfo } = props
  const { cardsMap } = useOutletContext<ContextType>()
  const [ordering, setOrdering] = useState<LikeStatus | null>()
  const [descending, setDescending] = useState(true)

  // TODO translates
  // TODO useMemo
  const columns: { name: LikeStatus; label: string }[] = [
    { name: "liked", label: "Лидер" },
    { name: "neutral", label: "Участник" },
    { name: "disliked", label: "Не уверен" }
  ]

  const radioButtonStyles = {
    button: styles.rolesRadioButton,
    checked: styles.rolesRadioButtonChecked
  }

  function onRadioClick(option: LikeStatus) {
    if (option === ordering) {
      setDescending(!descending)
    } else {
      setOrdering(option)
      setDescending(true)
    }
  }

  const orderedCardSortingInfo = useMemo(() => {
    const filteredSortingInfo =
      groupFilters?.length === 0
        ? [...cardSortingInfo]
        : cardSortingInfo.filter(cardInfo => {
            const cardInfoIdeaGroup = cardsMap.get(String(cardInfo.id))?.ideaGroup.id
            return cardInfoIdeaGroup && groupFilters.includes(cardInfoIdeaGroup)
          })

    if (ordering) {
      return filteredSortingInfo.sort((a, b) =>
        descending
          ? b.gradeUserInfo[ordering].length - a.gradeUserInfo[ordering].length
          : a.gradeUserInfo[ordering].length - b.gradeUserInfo[ordering].length
      )
    }
    return filteredSortingInfo
  }, [groupFilters, cardSortingInfo, ordering, cardsMap, descending])

  return (
    <div className={styles.rolesContainer}>
      <div className={styles.rolesHeader}>
        <div></div>
        {columns.map(option => (
          <div
            key={option.name}
            className={styles.rolesRadioOrdering}
            onClick={() => onRadioClick(option.name)}
          >
            <RadioButton
              CSSclasses={radioButtonStyles}
              name={option.name}
              label={option.label}
              onClick={() => {}}
              checked={ordering === option.name}
            />
            {ordering === option.name && <OrderingIcon descending={descending} />}
          </div>
        ))}
      </div>
      <div className={styles.rolesTable}>
        <SimpleBar style={{ maxHeight: "100%", width: "100%" }}>
          <div className={styles.rolesTableUnderScroll}>
            {orderedCardSortingInfo.map(cardInfo => {
              const card = cardsMap.get(String(cardInfo.id))
              return (
                card && (
                  <div
                    key={cardInfo.id}
                    className={classNames(styles.rolesTableRow, "rolesThinScrollBar")}
                  >
                    <DashboardCard card={card} />
                    {columns.map(column => (
                      <RolesUserRow
                        key={column.name}
                        users={cardInfo.gradeUserInfo[column.name]}
                      />
                    ))}
                  </div>
                )
              )
            })}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

import React, {FC, MouseEventHandler, ReactElement} from 'react';
//@ts-ignore
import styles from './IconButtonStyles.module.css'
import classNames from "classnames";

interface IconButtonProps {
    onClick?: (e: any) => void
    children: ReactElement
    isActive?: boolean
    className?: string
    isPending?: boolean
    disabled?: boolean
    isSmall?: boolean
    onMouseMove?: MouseEventHandler<HTMLDivElement>
    onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

export const IconButton: FC<IconButtonProps> = (props) => {
    const {
        children,
        onClick,
        className,
        isPending = false,
        isActive = false,
        disabled = false,
        isSmall = false,
        onMouseMove,
        onMouseLeave
    } = props

    return (
        <div
            className={classNames(
                styles.iconButton,
                isSmall ? styles.iconButtonSmall : styles.iconButtonNormal,
                isActive && styles.activeIconButton,
                (isPending || disabled) && styles.disabledIconButton,
                className,
            )}
            onClick={isPending || disabled ? undefined : onClick}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </div>
    );
};
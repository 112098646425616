// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardSortingAdminPageStyles_cardSortingAdminPage__CJyAk {\n    background-color: #e7e7e7;\n    padding: 2rem;\n}\n\n.CardSortingAdminPageStyles_mobile__yxwua {\n    padding: 0.5rem;\n    padding-right: calc(0.5rem - var(--scrollbar-width));\n} ", "",{"version":3,"sources":["webpack://./src/pages/CardSortingAdminPage/CardSortingAdminPageStyles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,oDAAoD;AACxD","sourcesContent":[".cardSortingAdminPage {\n    background-color: #e7e7e7;\n    padding: 2rem;\n}\n\n.mobile {\n    padding: 0.5rem;\n    padding-right: calc(0.5rem - var(--scrollbar-width));\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardSortingAdminPage": "CardSortingAdminPageStyles_cardSortingAdminPage__CJyAk",
	"mobile": "CardSortingAdminPageStyles_mobile__yxwua"
};
export default ___CSS_LOADER_EXPORT___;

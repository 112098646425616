import { FC, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useToggle } from "../../../../../../common/hooks"
import { getErrorUrl, removeFromLocalStorage } from "../../../../../../common/utils"
import { useAppSelector } from "../../../../../../store/store"
import { MobileAlertConfirmModal } from "../../../../../system/components/mobile"
import { PlusIcon } from "../../../../../system/UX"
import { MobileButton } from "../../../../../system/UX/mobile"
import { saveSorting } from "../../http"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import { useTranslate } from "../../translates/useTranslate"
import { checkSortingErrors, countLikedAndDislikedCards } from "../../utils"
import { MobileHintIcon } from "../icons/MobileHintIcon"
import { MobileTrashIcon } from "../icons/MobileTrashIcon"
import { MobileAddOrEditGroupControl } from "./MobileAddOrEditGroupControl"
import { MobileBin } from "./MobileBin"
import { MobileLikeModal } from "./MobileLikeModal"
import styles from "./MobileSortingStyles.module.css"
import { SortStage } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"

interface MobileSortingControlsProps {
  showInstruction: () => void
  height: number
}

export const MobileSortingControls: FC<MobileSortingControlsProps> = ({
  showInstruction,
  height
}) => {
  const dispatch = useDispatch()
  const translates = useTranslate()
  const navigate = useNavigate()
  const project_id = Number(useParams().project_id)
  const [isTrashOpened, setIsTrashOpened] = useState(false)
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
  const [error, setError] = useState("")
  const { createGroup, createGroupAndMovePickedCards, finishSort } = cardSortingSlice.actions
  const { group_state, info, sortStage } = useAppSelector(state => state.cardSortingReducer)
  const isPending = useToggle(false)
  const likeModal = useToggle(false)
  const confirmModal = useToggle(false)
  const { likeCounter, dislikeCounter } = countLikedAndDislikedCards(group_state)

  const addGroupButtonHandler = () => {
    if (group_state.pickedCards.cards.length === 0) {
      dispatch(createGroup(translates.group))
      setIsAddGroupModalOpen(true)
    } else {
      dispatch(createGroupAndMovePickedCards(translates.group))
    }
  }

  const checkSortingErrorHandler = () => {
    checkSortingErrors({
      sortStage,
      errorSetter: setError,
      groupState: group_state,
      translates,
      info,
      likeCounter,
      dislikeCounter,
      likeModal,
      confirmModal
    })
  }

  const submitSorting = (isPending: { state: boolean; toggleValue: () => void }) => {
    isPending.toggleValue()
    saveSorting(project_id, true, sortStage)
      .then(() => {
        isPending.toggleValue()
        confirmModal.toggleValue()
        dispatch(finishSort())
        removeFromLocalStorage(String(project_id)) 
      })
      .catch(e => navigate(getErrorUrl(e.message)))
  }

  return (
    <div className={styles.sortingControls} style={{ height }}>
      {error && (
        <MobileAlertConfirmModal
          onConfirm={() => setError("")}
          confirmText={translates.gotItButton}
        >
          {error}
        </MobileAlertConfirmModal>
      )}

      {confirmModal.state && (
        <MobileAlertConfirmModal
          onConfirm={() => submitSorting(isPending)}
          confirmText={translates.yes}
          cancel={{
            text: translates.no,
            handler: confirmModal.toggleValue
          }}
          isPending={isPending.state}
        >
          {translates.saveSortingConfirm}
        </MobileAlertConfirmModal>
      )}

      {likeModal.state && (
        <MobileLikeModal onClose={likeModal.toggleValue} onConfirm={submitSorting} />
      )}
      {isTrashOpened && <MobileBin onClose={() => setIsTrashOpened(false)} />}
      {isAddGroupModalOpen && (
        <MobileAddOrEditGroupControl onClose={() => setIsAddGroupModalOpen(false)} />
      )}
      {sortStage === SortStage.MANUAL_SORT && <div className={styles.addGroupContainer}>
        <div className={styles.addGroupButtonStyles} onClick={addGroupButtonHandler}>
          <PlusIcon stroke="white" />
        </div>
      </div>}
      <MobileTrashIcon
        stroke="black"
        width="1.75rem"
        height="1.75rem"
        onClick={() => setIsTrashOpened(true)}
      />
      <MobileHintIcon showInstruction={showInstruction} />
      <MobileButton onClick={checkSortingErrorHandler} />
    </div>
  )
}

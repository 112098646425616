import React from 'react';

export const WrapGroupsIcon = ({ isActive = false }: { isActive?: boolean }) => {
    return (
        <svg
            style={{
                position: 'relative',
                right: '0.005rem',
                transition: '.2s',
                top: isActive ? '-0.1rem' : '0.1rem',
                transform: isActive ? 'rotate(180deg)' : 'unset'
            }}
            width="1rem"
            height="1rem"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15 6.68457L8 13.0004L1 6.68457" stroke="#323232" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M15 1L8 7.31579L1 1" stroke="#323232" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
import React, {FC} from 'react';

interface PlusIconProps {
    stroke?: string
}

export const PlusIcon: FC<PlusIconProps> = (props) => {
    const { stroke = '#323232' } = props

    return (
        <svg width="1rem" height="1rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3327 9.0013H1.66602M8.99935 1.66797V16.3346V1.66797Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
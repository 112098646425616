import React, {MouseEvent, useState} from 'react';

import styles from '../../CardSortingAdminStyles.module.css'
import {useTranslate} from "../../translates/useTranslate";
import {MouseHint} from "../../../../../system/components";

export const CopyCardIcon = (props: { onClick: () => void }) => {
    const [hintEvent, setHintEvent] = useState<MouseEvent<SVGElement> | null>()
    const translate = useTranslate()

    return (
        <>
            {hintEvent && <MouseHint event={hintEvent}>
                {translate.sortingSettings.copyCardIconHint}
            </MouseHint>}

            <svg
                onClick={props.onClick}
                onMouseMove={(e) => {
                    setHintEvent(e)
                }}
                onMouseLeave={() => setHintEvent(null)}
                xmlns="http://www.w3.org/2000/svg"
                height="1.1em" viewBox="0 0 448 512"
                className={styles.cardControlIcon}
            >
                <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"/>
            </svg>
        </>
    );
};
export interface ProjectSettingsIn {
  question: string
  max_cards_per_question: string
  min_cards_per_question: string
  instruction_text: string
  instruction_title: string
  last_step_instruction_text: string
  sorting_instruction_text: string
  mobile_sorting_instruction_text: string
  sorting_instruction_title: string
  like_hint: string
  dislike_hint: string
  neutral_grade_hint: string
  like_limiter: string
  dislike_limiter: string
  min_sorted_number: string
  min_collected_percent: string
  card_template?: string[]
  quadrant_sort_info?: QuadrantSortInfo
}

export interface QuadrantSortInfo {
  sorting_instruction_text: string
  last_step_instruction_text: string
  mobile_sorting_instruction_text: string
  sorting_instruction_title: string
  areas: QuadrantSortAreas
  groups: string[]
}

export interface QuadrantSortInfoState extends Omit<QuadrantSortInfo, "areas" | "groups"> {}

export interface QuadrantSortAreas {
  X: string[]
  Y?: string[]
}

export type QuadrantSortArea = { axis: Axis; index: number }

export type Axis = "X" | "Y"

export interface ProjectSettingOut extends ProjectSettingsIn {
  id: number
}

// TODO: Подумать над правильной декомпозицией интерфейса статусов (и тут, и в модуле кард сортинка, согласовать с бэком)
export type AdminUserSortingStatus =
  | "project_not_opened"
  | "collecting_cards"
  | "waiting_for_sorting"
  | "sorting_not_started"
  | "doing_sort"
  | "sorting_finished"
  | "project_settings_unset"
  | "doing_quadrant_sort"
  | "finished_quadrant_sort"

export type AdminUserSortingStatusWithAdmins = AdminUserSortingStatus | "admins"
export const arrayOfAdminUserSortingStatus: AdminUserSortingStatusWithAdmins[] = [
  "admins",
  "project_not_opened",
  "collecting_cards",
  "waiting_for_sorting",
  "sorting_not_started",
  "doing_sort",
  "sorting_finished",
  "project_settings_unset",
  "doing_quadrant_sort",
  "finished_quadrant_sort"
]

export interface UserRowIn {
  id: number
  email: string
  name?: string
  is_admin: boolean
  registration_token: string
  projects: UserProject[]
}

export interface BaseProject {
  id: number
  name: string
}

export interface UserProject extends BaseProject {
  status: AdminUserSortingStatus
  cluster?: string
}

export enum ProjectStatus {
  SORTING_STARTED = "sorting_started",
  SORTING_NOT_STARTED = "sorting_not_started",
  UNSET = "project_settings_unset"
}

export interface ProjectInfo extends BaseProject {
  status: ProjectStatus
}

export interface BaseUserSettings {
  user_id: number
  is_admin: boolean
  email: string
  name: string
}

export interface UserSettings extends BaseUserSettings {
  projects: UserProject[]
}

export interface UserSettingsDTO extends BaseUserSettings {
  project_ids: number[]
}

export interface CardForEdit {
  id: number
  text: string
  author_id: number
  question_id: number
}

export type MailingEvent = "collecting" | "sorting" | "results"

export interface EmailsConfig {
  event: MailingEvent
  custom_text: string
}

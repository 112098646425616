import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { QuadrantSortingOut, SortingStateIn } from "../../types"
import {
  findMissingNumber,
  getGroupColor,
  groupColors,
  refreshLocalSortingState,
  setGradeCorrectly
} from "../../utils"
import {
  Card,
  CardSortingState,
  GroupState,
  LikeStatus,
  SortStage,
  UserSortingStatus,
  UserStatusOut
} from "./cardSortingReducerTypes"

//TODO: придумать лучшее решение по отображению нужного проекта (по project_id)
const initialState: CardSortingState = {
  info: {
    instruction_text: "Instruction text",
    instruction_title: "Instruction title",
    sorting_instruction_title: "Sorting instruction title",
    sorting_instruction_text: "Sorting instruction text",
    mobile_sorting_instruction_text: "Mobile sorting instruction text",
    last_step_instruction_text: "Last step instruction text",
    neutral_grade_hint: "Neutral grade hint",
    like_hint: "Like hint",
    dislike_hint: "Dislike hint",
    like_limit: 0,
    dislike_limit: 0
  },
  quadrant_sorting_info: {
    sorting_instruction_text: "Quadrant sorting instruction text",
    last_step_instruction_text: "Last step instruction text",
    mobile_sorting_instruction_text: "Mobile sorting instruction text",
    sorting_instruction_title: "Quadrant sorting instruction title"
  },
  group_state: {
    actionCounter: 0,
    isDesktopGroupsCollapsed: false,
    pickedCards: {
      group: "bank",
      cards: []
    },
    total_number_of_cards: 0,
    groups: [],
    bank: [],
    bin: []
  },
  user_groups: undefined,
  isLoading: false,
  fetchError: "",
  user_status: UserSortingStatus.COLLECTING_CARDS,
  project_id: 0,
  project_name: "Project name",
  sortStage: SortStage.MANUAL_SORT,
  quadrant_sort: false,
  card_template: undefined
}

export const cardSortingSlice = createSlice({
  name: "cardSorting",
  initialState,
  reducers: {
    setUserStatus: (state, action: PayloadAction<UserStatusOut>) => {
      state.user_status = action.payload.user_status

      switch (action.payload.user_status) {
        case UserSortingStatus.SORTING_NOT_STARTED:
        case UserSortingStatus.DOING_SORT:
          state.sortStage = SortStage.MANUAL_SORT
          break
        case UserSortingStatus.SORTING_FINISHED:
          state.sortStage = SortStage.MANUAL_SORT_FINISHED
          break
        // TODO разобраться со статусами
        // case UserSortingStatus.QUADRANT_SORTING_NOT_STARTED:
        case UserSortingStatus.DOING_QUADRANT_SORT:
          state.sortStage = SortStage.QUADRANT_SORT
          break
        case UserSortingStatus.FINISHED_QUADRANT_SORT:
          state.sortStage = SortStage.QUADRANT_SORT_FINISHED
          break
      }
    },

    setState: (state, action: PayloadAction<GroupState>) => {
      state.group_state = action.payload
    },

    setSortingState: (state, action: PayloadAction<SortingStateIn>) => {
      state.isLoading = false
      state.info = action.payload.info
      state.project_id = action.payload.project_id
      state.project_name = action.payload.info.project_name
      state.quadrant_sort = action.payload.quadrant_sort
      state.card_template = action.payload.card_template
      if (action.payload.cards) {
        state.group_state.bank = action.payload.cards
        state.group_state.total_number_of_cards = action.payload.cards.length
      }
    },

    setQuadrantSortingState: (state, action: PayloadAction<QuadrantSortingOut>) => {
      state.isLoading = false
      state.quadrant_sorting_info = action.payload.info
      state.project_id = action.payload.project_id
      state.project_name = action.payload.info.project_name
      state.card_template = action.payload.card_template
      state.user_groups = action.payload.user_groups
      state.group_state.isDesktopGroupsCollapsed = true
      // TODO осознать почему не работает
      // state.quadrant_sort = false
      if (action.payload.cards) {
        state.group_state.bank = action.payload.cards
        state.group_state.total_number_of_cards = action.payload.cards.length

        let groupColorIndex = 0
        state.group_state.groups = action.payload.groups.map(group => {
          groupColorIndex = (groupColorIndex + 1 > groupColors.length) ? 0 : groupColorIndex + 1
          return {
            ...group,
            isMobileCollapsed: false,
            color: getGroupColor(groupColors[groupColorIndex]),
            cards: []
          }
        })
      }
    },

    toggleDesktopGroupsCollapsed: state => {
      state.group_state.isDesktopGroupsCollapsed = !state.group_state.isDesktopGroupsCollapsed
    },

    startDraggingGroup: (state, action) => {
      state.group_state.draggingGroupId = action.payload
    },

    replaceDraggingGroup: (state, action) => {
      const groupId = action.payload

      const draggingGroupIndex = state.group_state.groups.findIndex(group => {
        return group.id === state.group_state.draggingGroupId
      })
      const draggingGroup = state.group_state.groups[draggingGroupIndex]
      state.group_state.groups.splice(draggingGroupIndex, 1)

      const dragOverGroupIndex = state.group_state.groups.findIndex(group => group.id === groupId)
      const indexForInsert =
        draggingGroupIndex > dragOverGroupIndex ? dragOverGroupIndex : dragOverGroupIndex + 1

      state.group_state.groups.splice(indexForInsert, 0, draggingGroup)
    },

    endDragging: state => {
      state.group_state.draggingGroupId = undefined
      refreshLocalSortingState(state)
    },

    togglePickedCard: (
      state,
      action: PayloadAction<{ group: "bank" | number; cardId: number }>
    ) => {
      if (state.group_state.pickedCards.group !== action.payload.group) {
        state.group_state.pickedCards.group = action.payload.group
        state.group_state.pickedCards.cards = [action.payload.cardId]
      } else {
        if (state.group_state.pickedCards.cards.includes(action.payload.cardId)) {
          state.group_state.pickedCards.cards = state.group_state.pickedCards.cards.filter(id => {
            return id !== action.payload.cardId
          })
        } else {
          state.group_state.pickedCards.cards.push(action.payload.cardId)
        }
      }
    },

    movePickedCardsToGroup: (state, action: PayloadAction<number>) => {
      if (
        state.group_state.pickedCards.group !== action.payload &&
        state.group_state.pickedCards.cards.length !== 0
      ) {
        const cardsToMove: Card[] = []

        state.group_state.bank.forEach(card => {
          if (state.group_state.pickedCards.cards.includes(card.id)) cardsToMove.push(card)
        })
        state.group_state.bank = state.group_state.bank.filter(
          card => !state.group_state.pickedCards.cards.includes(card.id)
        )

        state.group_state.groups.forEach(group => {
          group.cards.forEach(card => {
            if (state.group_state.pickedCards.cards.includes(card.id)) cardsToMove.push(card)
          })
          group.cards = group.cards.filter(
            card => !state.group_state.pickedCards.cards.includes(card.id)
          )
        })

        state.group_state.groups.forEach(group => {
          if (group.id === action.payload) group.cards = [...group.cards, ...cardsToMove]
        })

        state.group_state.pickedCards.cards = []

        refreshLocalSortingState(state)
      }
    },

    startMovingAnimation: (state, action: PayloadAction<number>) => {
      state.group_state.bank.forEach(card => {
        if (state.group_state.pickedCards.cards.includes(card.id)) {
          card.animationDestination = action.payload
        } else {
          card.animationDestination = "to fit"
        }
      })
    },

    stopMovingAnimation: state => {
      state.group_state.bank.forEach(card => {
        card.animationDestination = null
      })
    },

    // TODO: декомпозировать логику переноса пикнутых карточек в группу
    createGroupAndMovePickedCards: (state, action: PayloadAction<string>) => {
      const id = findMissingNumber(state.group_state.groups.map(group => group.id))
      const groupColor =
        state.group_state.groups.length === 0
          ? getGroupColor()
          : getGroupColor(state.group_state.groups[0].color)

      state.group_state.groups.unshift({
        id,
        name: action.payload + " " + (state.group_state.groups.length + 1),
        cards: [],
        color: groupColor,
        isMobileCollapsed: true
      })

      const cardsToMove: Card[] = []

      state.group_state.bank.forEach(card => {
        if (state.group_state.pickedCards.cards.includes(card.id)) cardsToMove.push(card)
      })
      state.group_state.bank = state.group_state.bank.filter(
        card => !state.group_state.pickedCards.cards.includes(card.id)
      )

      state.group_state.groups.forEach(group => {
        group.cards.forEach(card => {
          if (state.group_state.pickedCards.cards.includes(card.id)) cardsToMove.push(card)
        })
        group.cards = group.cards.filter(
          card => !state.group_state.pickedCards.cards.includes(card.id)
        )
      })

      state.group_state.groups.forEach(group => {
        if (group.id === id) group.cards = [...group.cards, ...cardsToMove]
      })

      state.group_state.pickedCards.cards = []

      refreshLocalSortingState(state)
    },

    renameGroup: (state, action: PayloadAction<{ id: number; value: string }>) => {
      state.group_state.groups.forEach(group => {
        if (group.id === action.payload.id) {
          group.name = action.payload.value
        }
      })
    },

    deleteGroup: (state, action: PayloadAction<number>) => {
      state.group_state.groups = state.group_state.groups.filter(group => {
        if (group.id === action.payload) {
          state.group_state.bank = group.cards.concat(state.group_state.bank)
        }

        return group.id !== action.payload
      })

      refreshLocalSortingState(state)
    },

    setGrade: (state, action: PayloadAction<{ id: number; grade: LikeStatus }>) => {
      state.group_state.bin.forEach(card => {
        if (card.id === action.payload.id) {
          card.grade_status = setGradeCorrectly(action.payload.grade, card.grade_status)
        }
      })

      state.group_state.bank.forEach(card => {
        if (card.id === action.payload.id) {
          card.grade_status = setGradeCorrectly(action.payload.grade, card.grade_status)
        }
      })

      state.group_state.groups.forEach(group => {
        group.cards.forEach(card => {
          if (card.id === action.payload.id) {
            card.grade_status = setGradeCorrectly(action.payload.grade, card.grade_status)
          }
        })
      })

      refreshLocalSortingState(state)
    },

    moveFromGroupToBank: (state, action: PayloadAction<number>) => {
      state.group_state.groups.forEach(group => {
        group.cards.forEach(card => {
          if (card.id === action.payload) {
            state.group_state.bank.unshift(card)
            group.cards = group.cards.filter(card => card.id !== action.payload)
          }
        })
      })

      refreshLocalSortingState(state)
    },

    moveFromGroupToBin: (state, action: PayloadAction<number>) => {
      state.group_state.groups.forEach(group => {
        group.cards.forEach(card => {
          if (card.id === action.payload) {
            state.group_state.bin.push(card)
            group.cards = group.cards.filter(card => card.id !== action.payload)
          }
        })
      })

      refreshLocalSortingState(state)
    },

    moveFromBinToBank: (state, action: PayloadAction<number>) => {
      state.group_state.bin.forEach(card => {
        if (card.id === action.payload) {
          state.group_state.bank.unshift(card)
          state.group_state.bin = state.group_state.bin.filter(card => card.id !== action.payload)
        }
      })

      refreshLocalSortingState(state)
    },

    moveFromBankToBin: (state, action: PayloadAction<number>) => {
      state.group_state.bank.forEach(card => {
        if (card.id === action.payload) {
          state.group_state.bin.push(card)
          state.group_state.bank = state.group_state.bank.filter(
            card => card.id !== action.payload
          )
        }
      })

      refreshLocalSortingState(state)
    },

    toggleCollapse: (state, action: PayloadAction<number>) => {
      state.group_state.groups = state.group_state.groups.map(group => {
        if (group.id === action.payload) {
          return { ...group, isMobileCollapsed: !group.isMobileCollapsed }
        } else return group
      })
    },

    createGroup: (state, action: PayloadAction<string>) => {
      const id = findMissingNumber(state.group_state.groups.map(group => group.id))
      const groupColor =
        state.group_state.groups.length === 0
          ? getGroupColor()
          : getGroupColor(state.group_state.groups[0].color)

      state.group_state.groups.unshift({
        id,
        name: action.payload + " " + (state.group_state.groups.length + 1),
        cards: [],
        color: groupColor,
        isMobileCollapsed: true
      })

      refreshLocalSortingState(state)
    },

    setCardsFilled: state => {
      state.user_status = UserSortingStatus.WAITING_FOR_SORTING
    },

    finishSort: state => {
      state.user_status =
        state.user_status === UserSortingStatus.DOING_SORT ||
        state.user_status === UserSortingStatus.SORTING_NOT_STARTED
          ? UserSortingStatus.SORTING_FINISHED
          : state.user_status === UserSortingStatus.DOING_QUADRANT_SORT
          ? UserSortingStatus.FINISHED_QUADRANT_SORT
          : state.user_status
    }
  }
})

export default cardSortingSlice.reducer

import classNames from "classnames"
import CardSorting from "../../project/modules/main/CardSorting/CardSorting"
import { useAppSelector } from "../../store/store"
import styles from "../CenterLayout.module.css"

export const CardSortingPage = () => {
  const isMobile = useAppSelector(state => state.appReducer.isMobile)

  return (
    <div className={classNames(styles.centerLayout)} style={isMobile ? {height: "auto"} : {}}>
      <CardSorting />
    </div>
  )
}

import React from "react"
import { useAppSelector } from "../../../../../../store/store"

interface CircleProgressBarProps {
  sizeRem: number // Размер прогресс бара
  progress: number // Заполненность прогресс бара (от 0 до 1)
}

export const CircleProgressBar: React.FC<CircleProgressBarProps> = ({ sizeRem, progress }) => {
  const remValue = useAppSelector(state => state.appReducer.remValue)

  const diameter = sizeRem * remValue
  const radius = diameter / 2
  const strokeWidth = diameter / 4
  const circumference = (diameter - strokeWidth) * Math.PI
  const adjustedProgress = Math.max(0, Math.min(1, progress))

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke="white"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke="#ffc000"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference * adjustedProgress} ${
          circumference * (1 - adjustedProgress)
        }`}
        transform={`rotate(-90 ${radius} ${radius})`}
      />
    </svg>
  )
}

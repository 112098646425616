import { DashboardNavUrl, GraphologyGradeCategory } from "./types"

export enum Layouts {
  forceAtlas = "forceAtlas2",
  another = "another"
}

export enum NodeCategoryColor {
  top = "#007F6D",
  local = "#00BAA0",
  bottom = "#E01E37",
  resist = "#F5B9C1",
  controversial = "#FFC001",
  indifferent = "#DBDBDB",
  unset = "blue"
  // array = "array"
}

export const NodeIdeaGroupColors = [
  "#FAC710",
  "#CEE741",
  "#F1BAA1",
  "#C90001",
  "#FFA570",
  "#F08080",
  "#F0E68C",
  "#EE82EE",
  "#7B68EE",
  "#9ACD32",
  "#008080",
  "#00CED1",
  "#D2691E"
]

export enum AnalyticsGradeColor {
  liked = "#007F6D",
  neutral = "#ADADAD",
  disliked = "#FF3A3A"
}

// TODO get name by label from translates
export const gradeCategories: GraphologyGradeCategory[] = [
  {
    id: 1,
    label: "top",
    name: "Участвуют > 50% от всех",
    color: NodeCategoryColor.top
  },
  {
    id: 2,
    label: "local",
    name: "Есть лидер и команда",
    color: NodeCategoryColor.local
  },
  {
    id: 3,
    label: "resist",
    name: "Есть лидер, нет команды",
    color: NodeCategoryColor.resist
  },
  // {
  //   id: 4,
  //   label: "controversial",
  //   name: "Точки разногласия",
  //   color: NodeCategoryColor.controversial
  // },
  {
    id: 5,
    label: "indifferent",
    name: "Есть команда, нет лидера",
    color: NodeCategoryColor.indifferent
  },
  {
    id: 6,
    label: "bottom",
    name: "Нет ни лидера, ни команды",
    color: NodeCategoryColor.bottom
  }
]

export const navigationUrls: DashboardNavUrl[] = [
  "dashboard",
  "scatter",
  "roles",
  "map",
  // "analytics",
  "results"
]

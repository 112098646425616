import classNames from "classnames"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useAppSelector } from "../../../../../../store/store"
import styles from "../../CardSortingStyles.module.css"
import { SortStage } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { Group } from "./Group/Group"
import { useTranslate } from "../../translates/useTranslate"
import { quadrantGroupColors3x3 } from "../../../../../../common/constants"

interface GroupsProps {
  userGroupColorMap?: Map<number, string>
}

export const Groups = (props: GroupsProps) => {
  const translate = useTranslate()
  const groups = useAppSelector(state => state.cardSortingReducer.group_state.groups)
  const isDesktopCollapsed = useAppSelector(
    state => state.cardSortingReducer.group_state.isDesktopGroupsCollapsed
  )
  const sortStage = useAppSelector(state => state.cardSortingReducer.sortStage)

  const { userGroupColorMap } = props

  return (
    <div className={styles.groupsContainer}>
      {groups.length !== 0 &&
        (sortStage === SortStage.QUADRANT_SORT && isDesktopCollapsed ? (
          <div
            className={styles.scrollableGroupsContainer}
            style={{ flexDirection: "row-reverse", height: "100%" }}
          >
            {groups.map(group => (
              <Group
                key={group.id}
                group={group}
                isDesktopCollapsed={isDesktopCollapsed}
                userGroupColorMap={userGroupColorMap}
                quadrantGroupColor={
                  group.index !== undefined ? quadrantGroupColors3x3.get(group.index) : undefined
                }
              />
            ))}
          </div>
        ) : (
          <SimpleBar
            style={{
              maxHeight: "100%",
              width: "100%",
              paddingRight: "0.2rem",
              paddingBottom: "0.05rem"
            }}
            autoHide={false}
          >
            <div
              className={styles.scrollableGroupsContainer}
              style={
                sortStage === SortStage.QUADRANT_SORT
                  ? { flexDirection: "row-reverse", height: "100%" }
                  : {}
              }
            >
              {groups.map(group => (
                <Group
                  key={group.id}
                  group={group}
                  isDesktopCollapsed={isDesktopCollapsed}
                  userGroupColorMap={userGroupColorMap}
                  quadrantGroupColor={
                    sortStage === SortStage.QUADRANT_SORT
                      ? group.index !== undefined
                        ? quadrantGroupColors3x3.get(group.index)
                        : undefined
                      : undefined
                  }
                />
              ))}
            </div>
          </SimpleBar>
        ))}

      {!groups.length && (
        <p className={classNames(styles.containerPlaceholder, styles.groupContainerPlaceholder)}>
          {translate.groupContainerPlaceholder}
        </p>
      )}
    </div>
  )
}

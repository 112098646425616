import React, {FC} from 'react';

interface MobileHintIconProps {
    showInstruction?: () => void
}

export const MobileHintIcon: FC<MobileHintIconProps> = (props) => {
    return (
        <svg onClick={props.showInstruction} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7917 18.6667H13.5V13.5H12.2083M13.5 8.33333H13.5129M25.125 13.5C25.125 15.0266 24.8243 16.5383 24.2401 17.9487C23.6559 19.3591 22.7996 20.6406 21.7201 21.7201C20.6406 22.7996 19.3591 23.6559 17.9487 24.2401C16.5383 24.8243 15.0266 25.125 13.5 25.125C11.9734 25.125 10.4617 24.8243 9.05131 24.2401C7.6409 23.6559 6.35936 22.7996 5.27988 21.7201C4.2004 20.6406 3.34411 19.3591 2.7599 17.9487C2.17569 16.5383 1.875 15.0266 1.875 13.5C1.875 10.4169 3.09977 7.45999 5.27988 5.27988C7.45999 3.09977 10.4169 1.875 13.5 1.875C16.5831 1.875 19.54 3.09977 21.7201 5.27988C23.9002 7.45999 25.125 10.4169 25.125 13.5Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
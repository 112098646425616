// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateCardStyles_templateCard__X2Qci {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  align-items: flex-end;\n  font-size: 0.875rem;\n  padding: 0.6875rem 1rem;\n  border-radius: 0.625rem;\n  border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n}\n\n.TemplateCardStyles_mobileTemplateCard__5L7HN {\n  height: 4rem;\n}\n\n.TemplateCardStyles_templateInput__dA2XY {\n  height: 10rem;\n}\n\n.TemplateCardStyles_templateInputLabel__bpsJ\\+ {\n  font-weight: 700;\n  width: 16rem;\n}\n\n.TemplateCardStyles_mobileTemplateInput__6um9m {\n  height: 10rem;\n  width: 18rem;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/CardSorting/components/TemplateCard/TemplateCardStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,6CAA6C;AAC/C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".templateCard {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  align-items: flex-end;\n  font-size: 0.875rem;\n  padding: 0.6875rem 1rem;\n  border-radius: 0.625rem;\n  border: 0.0625rem solid rgba(50, 50, 50, 0.2);\n}\n\n.mobileTemplateCard {\n  height: 4rem;\n}\n\n.templateInput {\n  height: 10rem;\n}\n\n.templateInputLabel {\n  font-weight: 700;\n  width: 16rem;\n}\n\n.mobileTemplateInput {\n  height: 10rem;\n  width: 18rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateCard": "TemplateCardStyles_templateCard__X2Qci",
	"mobileTemplateCard": "TemplateCardStyles_mobileTemplateCard__5L7HN",
	"templateInput": "TemplateCardStyles_templateInput__dA2XY",
	"templateInputLabel": "TemplateCardStyles_templateInputLabel__bpsJ+",
	"mobileTemplateInput": "TemplateCardStyles_mobileTemplateInput__6um9m"
};
export default ___CSS_LOADER_EXPORT___;

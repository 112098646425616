// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScatterStyles_scatterSheet__rHZUc {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n}\n\n.ScatterStyles_scatterDot__nvPtR {\n  position: absolute;\n  height: 0.5rem;\n  width: 0.5rem;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.ScatterStyles_scatterGroupsLayer__aD7Fk {\n  position: absolute;\n  display: flex;\n  flex-direction: row-reverse;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.ScatterStyles_scatterGroup__V6gmC {\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/ScatterChart/components/Scatter/ScatterStyles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".scatterSheet {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n}\n\n.scatterDot {\n  position: absolute;\n  height: 0.5rem;\n  width: 0.5rem;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.scatterGroupsLayer {\n  position: absolute;\n  display: flex;\n  flex-direction: row-reverse;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.scatterGroup {\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scatterSheet": "ScatterStyles_scatterSheet__rHZUc",
	"scatterDot": "ScatterStyles_scatterDot__nvPtR",
	"scatterGroupsLayer": "ScatterStyles_scatterGroupsLayer__aD7Fk",
	"scatterGroup": "ScatterStyles_scatterGroup__V6gmC"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { CardSortingInfoInner, ContextType } from "../../../../pages/DashboardPage/types/types"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { UserPic } from "../../../system/components/UserPic/UserPic"
import {
  LikeStatus,
  UserSortingStatus
} from "../CardSorting/reducers/cardSortingReducer/cardSortingReducerTypes"
import styles from "./PeopleStyles.module.css"
import SimpleBar from "simplebar-react"

interface PeopleProps {
  groupFilters: number[]
  cardSortingInfo: CardSortingInfoInner[]
}

export function People(props: PeopleProps) {
  const { cardSortingInfo, groupFilters } = props
  const { userData, cardsMap } = useOutletContext<ContextType>()
  const [userFilter, setUserFilter] = useState<number>()

  // TODO translates
  const columns: { name: LikeStatus; label: string }[] = useMemo(
    () => [
      { name: "liked", label: "Готов лидировать" },
      { name: "neutral", label: "Готов участвовать" },
      { name: "disliked", label: "Не уверен в своем участии" }
    ],
    []
  )

  const readyUsers = useMemo(
    () => userData.filter(user => user.user_status === UserSortingStatus.FINISHED_QUADRANT_SORT),
    [userData]
  )

  useEffect(() => readyUsers && setUserFilter(readyUsers[0].id), [readyUsers])

  // TODO generic types
  const userCardSortingInfo: {
    liked: number[]
    disliked: number[]
    neutral: number[]
  } = useMemo(() => {
    const filteredCardSortingInfo =
      groupFilters.length !== 0
        ? cardSortingInfo.filter(cardInfo => {
            const card = cardsMap.get(String(cardInfo.id))
            return card && groupFilters.includes(card.ideaGroup.id)
          })
        : cardSortingInfo
    const userCardSortingInfo = filteredCardSortingInfo.reduce<{
      liked: number[]
      disliked: number[]
      neutral: number[]
    }>(
      (accumulator, cardSortingInfo) => {
        const userGradeInfo = cardSortingInfo.user_grade_info.find(
          userGradeInfo => userGradeInfo.user_id === userFilter
        )
        if (userGradeInfo) {
          accumulator[userGradeInfo.grade].push(cardSortingInfo.id)
        }
        return accumulator
      },
      {
        liked: [],
        disliked: [],
        neutral: []
      }
    )
    return userCardSortingInfo
  }, [userFilter, groupFilters])

  return (
    <div className={styles.peopleContainer}>
      <div className={styles.peopleHeader}>
        <div className={styles.peopleUsers}>
          {readyUsers &&
            readyUsers.map(user => (
              <div
                key={user.id}
                onClick={() => setUserFilter(user.id)}
                style={{ opacity: userFilter === user.id ? "1" : "0.35" }}
              >
                <UserPic userId={user.id} />
              </div>
            ))}
        </div>
      </div>
      <div className={styles.peopleCards}>
        {columns.map((column, index) => (
          <div key={index} className={styles.peopleCardsColumn}>
            <p>{column.label}</p>
            <div className={styles.peopleCardsList}>
              <SimpleBar style={{ maxHeight: "100%", width: "100%" }} autoHide={false}>
                <div className={styles.peopleCardsColumnUnderScroll}>
                  {userCardSortingInfo[column.name].map(cardId => {
                    const card = cardsMap.get(String(cardId))
                    return card && <DashboardCard key={cardId} card={card} />
                  })}
                </div>
              </SimpleBar>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

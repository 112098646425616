import classNames from "classnames"
import { ChangeEvent } from "react"
import { Input } from "../../../../../system/UX/Input/Input"
import styles from "./TemplateCardStyles.module.css"

interface TemplateCardProps {
  index: number
  placeholder?: string
  template: string[]
  isMobile: boolean
  card: string[]
  onChange: (event: ChangeEvent<HTMLInputElement>, cardIndex: number, fieldIndex: number) => void
  onBlur: () => void
}

export function TemplateCard(props: TemplateCardProps) {
  const { index, isMobile, card, template, onBlur, onChange } = props

  return (
    <div
      onBlur={onBlur}
      className={classNames(styles.templateCard, isMobile && styles.mobileQuestionCard)}
      style={{flexDirection: isMobile ? "column" : "row",
        // width: isMobile ? "100%" : "auto"
      }}
    >
      {template.map((label, fieldIndex) => (
        <Input
          isTextareaView={true}
          key={fieldIndex}
          label={label}
          labelClassName={styles.templateInputLabel}
          placeholder={"..."}
          inputClassName={classNames(styles.templateInput, isMobile && styles.mobileTemplateInput)}
          value={card[fieldIndex]}
          onChange={event => onChange(event, index, fieldIndex)}
        />
      ))}
    </div>
  )
}

import { UserSortingStatus } from "../../../CardSorting/reducers/cardSortingReducer/cardSortingReducerTypes"
import { UserStatusIcon } from "../UserStatusIcon/UserStatusIcon"

interface UserStatusRowProps {
  status: UserSortingStatus
  finishedCollecting: boolean
}

export function UserStatusRow(props: UserStatusRowProps) {
  const { status, finishedCollecting } = props

  // div нужны для выравнивания в таблице
  return (
    <>
      <div>
        <UserStatusIcon variant={finishedCollecting ? "done" : "none"} />
      </div>
      <div>
        <UserStatusIcon
          variant={
            status === UserSortingStatus.SORTING_NOT_STARTED
              ? "none"
              : status === UserSortingStatus.DOING_SORT
              ? "doing"
              : "done"
          }
        />
      </div>
      <div>
        <UserStatusIcon
          variant={
            status === UserSortingStatus.DOING_QUADRANT_SORT
              ? "doing"
              : status === UserSortingStatus.FINISHED_QUADRANT_SORT
              ? "done"
              : "none"
          }
        />
      </div>
    </>
  )
}

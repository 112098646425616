import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import { Input } from "../../../../system/UX/Input/Input"
import styles from "../CardSortingAdminStyles.module.css"
import { Axis, QuadrantSortArea, QuadrantSortAreas, QuadrantSortInfoState } from "../types"
import { QuadrantSortGridControl } from "./QuadrantSortGridControl/QuarantSortGridControl"

interface QuadrantSortProps {
  hidden: boolean
  areas: QuadrantSortAreas
  groups: string[]
  info: QuadrantSortInfoState
  onChangeInfo: Dispatch<SetStateAction<QuadrantSortInfoState>>
  onChangeAreaName: (event: ChangeEvent<HTMLInputElement>, axis: Axis, index: number) => void
  onChangeGroupNames: (event: ChangeEvent<HTMLInputElement>, index: number) => void
}

export function QuadrantSortControl(props: QuadrantSortProps) {
  const { hidden, onChangeAreaName, info, onChangeInfo, areas, groups, onChangeGroupNames } = props

  // TODO 3 (когда буду добавлять другие сетки, поднять стейт)
  const xAreas = Array.from({ length: 3 }, (_, index) => index)
  const yAreas = Array.from({ length: 3 }, (_, index) => index)

  const [activeAreas, setActiveAreas] = useState<QuadrantSortArea[]>([])

  function onFocusAreaInput (axis: Axis, index: number) {
    setActiveAreas(prevState => {
      return [...prevState, {axis: axis, index: index}]
    })
  }
  
  function onFocusGroupInput (index: number) {
    // TODO 3
    const y_index = Math.floor(index / 3)
    const x_index = index % 3
    
    setActiveAreas(prevState => {
      return [...prevState, {axis: "X", index: x_index}, {axis: "Y", index: y_index}]
    })
  }


  return (
    <div className={styles.settingsQuadrantSortControl} style={hidden ? { height: "0" } : {}}>
      {/* TODO translates */}
      <Input
        label="Заголовок инструкции"
        placeholder={"Введите заголовок инструкции..."}
        value={info.sorting_instruction_title}
        onChange={event =>
          onChangeInfo({ ...info, sorting_instruction_title: event.target.value })
        }
        containerClassName={styles.settingsInputContainer}
      />
      <Input
        label="Текст инструкции"
        placeholder={"Введите текст инструкции..."}
        value={info.sorting_instruction_text}
        onChange={event => onChangeInfo({ ...info, sorting_instruction_text: event.target.value })}
        containerClassName={styles.settingsInputContainer}
      />
      <Input
        label="Текст инструкции для мобильной версии"
        placeholder={"Введите текст инструкции для мобильной версии..."}
        value={info.mobile_sorting_instruction_text}
        onChange={event =>
          onChangeInfo({ ...info, mobile_sorting_instruction_text: event.target.value })
        }
        containerClassName={styles.settingsInputContainer}
      />
      <Input
        label="Текст для последнего шага инструкции"
        placeholder={"Введите текст для последнего шага инструкции..."}
        value={info.last_step_instruction_text}
        onChange={event =>
          onChangeInfo({ ...info, last_step_instruction_text: event.target.value })
        }
        containerClassName={styles.settingsInputContainer}
      />
      <div className={styles.settingsQuadrantSortAreasControl}>
        <div className={styles.settingsQuadrantSortAreas}>
          <QuadrantSortGridControl activeAreas={activeAreas} sizeRem={11.5}/>
        </div>
        <div className={styles.settingsQuadrantSortNames}>
          {xAreas.map((_, index) => (
            <div key={index} className={styles.settingsQuadrantSortInputContainer}>
              <p>{1 + index}.</p>
              <Input
                inputClassName={styles.settingsTemplateCardsControlInput}
                value={areas.X && areas.X[index]}
                placeholder="..."
                onChange={event => onChangeAreaName(event, "X", index)}
                onBlur={() => setActiveAreas([])}
                onFocus={() => onFocusAreaInput("X", index)}
              />
            </div>
          ))}
          {yAreas.map((_, index) => (
            <div key={index} className={styles.settingsQuadrantSortInputContainer}>
              <p>{1 + index + xAreas.length}.</p>
              <Input
                inputClassName={styles.settingsTemplateCardsControlInput}
                value={areas.Y && areas.Y[index]}
                placeholder="..."
                onChange={event => onChangeAreaName(event, "Y", index)}
                onBlur={() => setActiveAreas([])}
                onFocus={() => onFocusAreaInput("Y", index)}
              />
            </div>
          ))}
        </div>
      </div>
      {/* TODO translates, адекватный текст + перевод + дизайн */}
      <p>
        Эти названия групп будут использоваться в интерфейсе сортировки. Вы можете изменить их при
        необходимости.
      </p>
      {groups.map((group, index) => (
        <Input
          key={index}
          value={group}
          placeholder="..."
          inputClassName={styles.settingsTemplateCardsControlInput}
          onChange={event => onChangeGroupNames(event, index)}
          onBlur={() => setActiveAreas([])}
          onFocus={() => onFocusGroupInput(index)}
        />
      ))}
    </div>
  )
}

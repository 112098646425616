import React, {FC, MouseEventHandler, ReactNode} from 'react';

import styles from "./ConfirmModalStyles.module.css";
import classNames from "classnames";
import {Button, Modal} from "../../UX";
import {Portal} from "../../service";

interface ConfirmModalProps {
    children: string
    onConfirm: MouseEventHandler<HTMLButtonElement>
    onCancel: MouseEventHandler<HTMLButtonElement>
    confirmText: string
    cancelText: string
    pClassName?: string
    isPending?: boolean
}

export const ConfirmModal: FC<ConfirmModalProps> = (props) => {
    const { children, onConfirm, onCancel, cancelText, confirmText, pClassName, isPending = false } = props

    return (
        <Portal>
            <Modal>
                <p className={classNames(styles.modalText, pClassName)}>{children}</p>

                <div className={styles.buttonsContainer}>
                    <Button onClick={onCancel} variant="outlined" disabled={isPending}>{cancelText}</Button>
                    <Button onClick={onConfirm} variant="outlined" isPending={isPending}>{confirmText}</Button>
                </div>
            </Modal>
        </Portal>
    );
};
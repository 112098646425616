export function QuestionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.625rem" height="1.625rem" viewBox="0 0 26 26" fill="none">
      <path
        d="M10.7023 8.14558C11.9708 7.03517 14.0292 7.03517 15.2978 8.14558C16.5674 9.256 16.5674 11.0565 15.2978 12.1669C15.0778 12.3608 14.8319 12.5201 14.5719 12.6458C13.7648 13.0368 13.0011 13.728 13.0011 14.625V15.4375M22.75 13C22.75 14.2804 22.4978 15.5482 22.0078 16.7312C21.5178 17.9141 20.7997 18.9889 19.8943 19.8943C18.9889 20.7997 17.9141 21.5178 16.7312 22.0078C15.5482 22.4978 14.2804 22.75 13 22.75C11.7196 22.75 10.4518 22.4978 9.26884 22.0078C8.08591 21.5178 7.01108 20.7997 6.10571 19.8943C5.20034 18.9889 4.48216 17.9141 3.99217 16.7312C3.50219 15.5482 3.25 14.2804 3.25 13C3.25 10.4141 4.27723 7.93419 6.10571 6.10571C7.93419 4.27723 10.4141 3.25 13 3.25C15.5859 3.25 18.0658 4.27723 19.8943 6.10571C21.7228 7.93419 22.75 10.4141 22.75 13ZM13 18.6875H13.0087V18.6962H13V18.6875Z"
        fill="#FFD556"
      />
      <path
        d="M10.7022 8.14558C11.9708 7.03517 14.0292 7.03517 15.2978 8.14558C16.5674 9.256 16.5674 11.0565 15.2978 12.1669C15.0778 12.3608 14.8319 12.5201 14.5719 12.6458C13.7648 13.0368 13.0011 13.728 13.0011 14.625V15.4375M22.75 13C22.75 14.2804 22.4978 15.5482 22.0078 16.7312C21.5178 17.9141 20.7997 18.9889 19.8943 19.8943C18.9889 20.7997 17.9141 21.5178 16.7312 22.0078C15.5482 22.4978 14.2804 22.75 13 22.75C11.7196 22.75 10.4518 22.4978 9.26884 22.0078C8.08591 21.5178 7.01108 20.7997 6.10571 19.8943C5.20034 18.9889 4.48216 17.9141 3.99217 16.7312C3.50219 15.5482 3.25 14.2804 3.25 13C3.25 10.4141 4.27723 7.93419 6.10571 6.10571C7.93419 4.27723 10.4141 3.25 13 3.25C15.5859 3.25 18.0658 4.27723 19.8943 6.10571C21.7228 7.93419 22.75 10.4141 22.75 13ZM13 18.6875H13.0087V18.6962H13V18.6875Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React, {FC} from 'react';
import {MobileModal} from "../../../../../system/UX/mobile";
import {Input} from "../../../../../system/UX";

import styles from "./MobileSortingStyles.module.css";
import {useTranslate} from "../../translates/useTranslate";
import {useAppSelector} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {cardSortingSlice} from "../../reducers/cardSortingReducer/cardSortingReducer";
import {GroupColorsType} from "../../reducers/cardSortingReducer/cardSortingReducerTypes";

interface MobileAddOrEditGroupControlProps {
    onClose: () => void
    group?: {
        id: number
        name: string
        color: GroupColorsType
    }
}


// TODO: правильно разместить Portal (ВО ВСЕХ КОМПОНЕНТАХ)
export const MobileAddOrEditGroupControl: FC<MobileAddOrEditGroupControlProps> = ({ onClose, group }) => {
    const dispatch = useDispatch()
    const translate = useTranslate()

    const lastCreatedGroup = useAppSelector(state => state.cardSortingReducer.group_state.groups[0])
    const renameGroup = cardSortingSlice.actions.renameGroup
    const groupToSetName = group ? group : lastCreatedGroup

    return (
        <MobileModal onClose={onClose} isCollapsible={false}>
            <div
                className={styles.MobileAddOrEditGroupControlContent}
                style={{ background: `linear-gradient(to bottom, ${groupToSetName.color} 2.75rem, white 2.75rem)` }}
            >
                <Input
                    label={group ? translate.mobileTranslates.editGroup : translate.mobileTranslates.addGroup}
                    isTextareaView={true}
                    inputClassName={styles.addOrEditGroupInput}
                    labelClassName={styles.addOrEditGroupInputLabel}
                    onChange={e => dispatch(renameGroup({id: groupToSetName.id, value: e.target.value}))}
                    value={groupToSetName.name}
                />
                <span className={styles.addOrEditGroupDoneButton} onClick={onClose}>
                    {translate.mobileTranslates.done}
                </span>
            </div>
        </MobileModal>
    );
};
import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import {
  CardSortingInfoInner,
  ContextType,
  DashboardCardSortingInfoOut,
  GradeUserInfo
} from "../../../../pages/DashboardPage/types/types"
import { Legend } from "../../../system/components/Legend/Legend"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { Roles } from "../Roles/Roles"
import styles from "./RolesAndTasksStyles.module.css"
import { Analytics } from "../Analytics/Analytics"
import { People } from "../People/People"
import { Tasks } from "../Tasks/Tasks"

type moduleToDisplay = "people" | "roles" | "clusters" | "tasks"

interface RolesAndTasksProps {
  cardSortingInfo: DashboardCardSortingInfoOut[]
}

export function RolesAndTasks(props: RolesAndTasksProps) {
  const { cardSortingInfo } = props
  const [moduleToDisplay, setModuleToDisplay] = useState<moduleToDisplay>("people")
  const [groupFilter, setGroupFilter] = useState<number[]>([])
  const { ideaGroups, clusters } = useOutletContext<ContextType>()

  const radioOptions: { name: moduleToDisplay; label: string }[] = useMemo(
    () => [
      { name: "people", label: "По людям" },
      { name: "roles", label: "По ролям" },
      { name: "clusters", label: "По департаментам" },
      { name: "tasks", label: "По задачам" }
    ],
    []
  )

  const rolesTasksLegendStyles = useMemo(
    () => ({
      container: styles.rolesTasksLegend,
      checked: styles.rolesTasksLegendRowActive,
      item: styles.rolesTasksLegendRow,
      itemHover: styles.rolesTasksLegendHover,
      iconSize: "0.6rem"
    }),
    []
  )

  const cardSortingInfoInner: CardSortingInfoInner[] = useMemo(() => {
    return cardSortingInfo.map(cardInfo => {
      const gradeUserInfo = cardInfo.user_grade_info.reduce(
        (accumulator: GradeUserInfo, userGrade) => {
          switch (userGrade.grade) {
            case "liked":
              accumulator.liked.push(userGrade.user_id)
              break
            case "disliked":
              accumulator.disliked.push(userGrade.user_id)
              break
            case "neutral":
              accumulator.neutral.push(userGrade.user_id)
              break
          }
          return accumulator
        },
        { liked: [], disliked: [], neutral: [] }
      )
      return {
        ...cardInfo,
        gradeUserInfo: gradeUserInfo
      }
    })
  }, [])

  const totalGrades: number = useMemo(() => cardSortingInfo[0].user_grade_info.length, [])

  return (
    // TODO translates
    <div className={styles.rolesTasksContainer}>
      <div className={styles.rolesTasksHeader}>
        <div className={styles.rolesTasksRadioButtons}>
          {radioOptions.map(option => (
            <RadioButton
              key={option.name}
              name={option.name}
              label={option.label}
              checked={moduleToDisplay === option.name}
              onClick={() => setModuleToDisplay(option.name)}
            />
          ))}
        </div>
        {ideaGroups && (
          <Legend items={ideaGroups} setter={setGroupFilter} CSSstyles={rolesTasksLegendStyles} />
        )}
      </div>
      {/* TODO generic props */}
      {moduleToDisplay === "roles" && (
        <Roles cardSortingInfo={cardSortingInfoInner} groupFilters={groupFilter} />
      )}
      {moduleToDisplay === "clusters" && (
        <Analytics totalGrades={totalGrades} groupFilters={groupFilter} />
      )}
      {moduleToDisplay === "people" && (
        <People cardSortingInfo={cardSortingInfoInner} groupFilters={groupFilter} />
      )}
      {moduleToDisplay === "tasks" && (
        <Tasks cardSortingInfo={cardSortingInfoInner} groupFilters={groupFilter} />
      )}
    </div>
  )
}
